import React, { Fragment, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { axiosAPI } from "../Axios/Axios";
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";

const Qualification = ({ style, value, setValue }) => {
  const [Qualifications, setQualifications] = useState([]);
  useEffect(() => {
    const getQualifications = async () => {
      const result = await axiosAPI.get("/getEducation");
      const { success, data } = await result.data;
      if (success === true) {
        setQualifications(data);
      } else {
        setQualifications([]);
      }
    };
    getQualifications();
  }, []);

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <Fragment>
      <FormControl sx={{ width: "100%" }}>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          value={value ? value :""}
          onChange={handleChange}
          fullWidth
          displayEmpty
          variant="outlined"
          sx={{
            padding: "10px 12px",
            fontFamily: "Poppins",

            border: `2px solid black`,
            "& fieldset": {
              border: "none", // Remove the default border
            },
            "&:hover": {
              border: `2px solid black`,
            },
            "&.Mui-focused": {
              border: `2px solid black`,
            },
            ...style,
          }}

          renderValue={(selected) => {
            const selectedArray = Array.isArray(selected) ? selected : [selected];
  
            if (selectedArray[0] === undefined || selectedArray[0] === null) {
              return (
                <>
                  <span style={{ color: "rgb(188, 188, 188)" }}>
                  {Labels?.Qualifications}
                  </span>
                </>
              );
            }
              const selectedNames = selectedArray.map((val) => Qualifications.find((item) => item.id === val)?.name).join(", ");
            return Qualifications?.length?selectedNames: <Skeleton variant="rectangular" width={`90%`} height={20} />;
          }}
        >

          {Qualifications &&
            Qualifications.map((val, index) => {
              return (
                <MenuItem key={index} value={val.id}>
                  {val.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </Fragment>
  );
};

export default Qualification;
