import React, { useEffect } from "react";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import EmployerCreatejob from "../views/Employer/CreateJob/EmployerCreatejob";
import EmployerDashboard from "../views/Employer/EmployerDashboard/EmployerDashboard";
import EmployerProfile from "../views/Employer/EmployerProfile/EmployerProfile";
import EmployerCreatedJobList from "../views/Employer/EmployerDashboard/EmployerCreatedJobList";
import MatchingJobseekers from "../views/Employer/EmployerDashboard/MatchingJobseekers";
import AppliedJobSeekers from "../views/Employer/CreateJob/AppliedJobSeekers";
import EditJob from "../views/Employer/CreateJob/EditJob";
import ShortListedJobSeekers from "../views/Employer/EmployerDashboard/ShortListedJobSeekers";
import SearchJobSeekers from "../views/Employer/CreateJob/SearchJobSeekers";
import EmployerSideMenu from "../views/Employer/EmployerComponents/EmployerSideMenu";
import EmployerNavbar from "../views/Employer/EmployerComponents/EmployerNavbar";
import { Container, Grid } from "@mui/material";
import { useStyles } from "../views/CommonCode/Commonfunc";
import Notification from "../views/Employer/EmployerNotification/notification";
import CreateGroups from "../views/Employer/employerGroup/createGroup";
import SearchGroupLists from "../views/Employer/employerGroup/searchGroupList";
import SearchGroupsDetail from "../views/Employer/employerGroup/searchGroupDetails";
import MyGroupsList from "../views/Employer/employerGroup/myGroupList";
import MyGroupDetailed from "../views/Employer/employerGroup/detailedgroup";
import ForumList from "../views/Employer/employerForum/forumList";
import CreateForms from "../views/Employer/employerForum/createForum";
import ForumDetails from "../views/Employer/employerForum/forumDetails";
import MarkedJobSeekers from "../views/Employer/EmployerDashboard/MarkedJobSeekers";
import EditEmployerGroup from "../views/Employer/employerGroup/editEmployerGroup";
import { useState } from "react";
import UserActivatonModal from "../views/Employer/EmployerDashboard/UserActivatonModal";
import { useSelector } from "react-redux";
import JobEnquires from "../views/Employer/EmployerDashboard/JobEnquires";
import JobInfo from "../views/Employer/CreateJob/JobInfo";
import EditForum from "../views/Employer/employerForum/editForum";
import JobSeekersList from "../views/Employer/EmployerComponents/JobSeekersList";
import OnlineChatMessenger from "../views/Employer/EmployerMessages/onlineChat";
import EmployerMessages from "../views/Employer/EmployerMessages/messages";
import JobSeekerProfileView from "../views/Employer/CreateJob/JobSeekerProfileView";
import MyForumList from "../views/Employer/employerForum/myForumList";
import MyForumDetails from "../views/Employer/employerForum/myForumDetails";
import GroupDetailedPage from "../views/Employer/employerGroup/detailedgroup";
import UserList from "../views/Employer/User/userList";
import CompanyList from "../views/Employer/Companies/companyList";
import EmployerPasswordChange from "../views/Login/EmployerPwdChange";
import CustomEmployerSignup from "../views/Login/CustomSignup/CustomEmployer";
import { Button } from "@mui/material";
import { axiosAPI } from "../views/Axios/Axios";
import MFAPopup from "../views/CommonCode/MFA/MFAPopup";
import BackBtn from "../Images/left-arrow.png";

function ProtectedRoute({ value }) {
  const classes = useStyles();
  const Navigate = useNavigate();
  const isAuth = localStorage.getItem("employerToken");
  const location = useLocation();
  const [openActivationModal, setActivationModal] = useState(false);
  const employerLoginData = useSelector((state) => state.createLoginDetails.loginDetails);
  const [isValid, setIsValid] = useState(true);

  const isAccountActive = () => {   
    if (employerLoginData?.activatedOn) {
      setActivationModal(false);
    } else {
      setActivationModal(true);
    }
  };

  useEffect(() => {
    isAccountActive();
  }, [value]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [prevbtn, setprevbtn] = useState(false);

  useEffect(() => {
    setprevbtn(location.pathname.includes("jobInfo"));
  }, [location]);

  const goToPreviousRoute = () => {
    Navigate("/employerJobList");
  };

  const [openpopup, setopenpopup] = useState(false);
  
  useEffect(() => {
    axiosAPI
      .post(`/getCompanyProfile`)
      .then((res) => {
        if (res.data.data.twoFactorPreferred) {
          localStorage.getItem("VerifiedMFA") == "true"
            ? setopenpopup(false)
            : setopenpopup(true);
        }
      })
      .catch((err) => {
      });
  }, []);

  return isAuth ? (
    <>
      <MFAPopup openpopup={openpopup} setopenpopup={setopenpopup} />
      <Container
        maxWidth="100%"
        sx={{
          margin: "0 auto",
          padding: "0 !important",
          background: "#EAEFF1",
          minHeight: "100vh",
        }}
      >
        <EmployerNavbar />
        {isValid ? (
          <Grid
            container
            spacing={2}
            sx={{
              px: 2,
              pb: 2,
              pt: {
                xs: "70px",
                sm: "85px",
              },
            }}
          >
            <Grid item lg={3} xl={2}>
              <EmployerSideMenu value={value} />
            </Grid>
            <Grid
              item
              xs={12}
              lg={9}
              xl={10}
              sx={{ py: 1 }}
              className={classes.customScrollbar}
            >
              {prevbtn && (
                <img
                  height={40}
                  style={{
                    position: "relative",
                    top: "25px",
                    cursor: "pointer",
                  }}
                  src={BackBtn}
                  onClick={goToPreviousRoute}
                />
              )}

              <UserActivatonModal
                open={openActivationModal}
                handleClose={setActivationModal}
              />
              <Outlet />
            </Grid>
          </Grid>
        ) : (
          <CustomEmployerSignup />
        )}
      </Container>
    </>
  ) : (
    <Navigate to="/landingpage" />
  );
}

function ActivatedRoute() {
  const employerLoginData = useSelector(
    (state) => state.createLoginDetails.loginDetails
  );
  const isActivated = employerLoginData.activatedOn;
  return isActivated ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to="/employerDashboard" />
  )
}

export default function EmployerRoutes() {
  const [isRouteChange, setRouteChange] = useState(0);
  const [jobSeekerListPage, setJobSeekerListPage] = useState("");
  const [isJobDetailsCalled, setJobDetailsCall] = useState(0);
  
  return (
    <Routes>
      <Route element={<ProtectedRoute value={isRouteChange} />}>
        <Route
          path="/employerDashboard"
          element={<EmployerDashboard routeChange={setRouteChange} />}
        />
        <Route element={<ActivatedRoute />}>
          <Route path="/employerProfile" element={<EmployerProfile routeChange={setRouteChange} />}/>
          
          {/* --------------- User --------------- */}
          <Route path="/user" element={<UserList />} />

          {/* --------------- Jobs --------------- */}
          <Route path="/Createjob" element={<EmployerCreatejob />} />
          <Route path="/employerJobList" element={<EmployerCreatedJobList />} />

          <Route path="/jobInfo/:id/:svalue?" element={<JobSeekersList jobSeekerListPage={jobSeekerListPage} isJobDetailsCalled={isJobDetailsCalled} />}/>
          
          <Route path="/editJob/:id" element={<EditJob />} />
          <Route path="/searchJobSeeker" element={<SearchJobSeekers />} />
          <Route path="/jobSeekerProfileView/:id" element={<JobSeekerProfileView />}/>

          {/* --------------- Company --------------- */}
          <Route path="/CompanyList" element={<CompanyList />} />

          {/* --------------- Groups --------------- */}
          <Route path="/create-group" element={<CreateGroups />} />
          <Route path="/search-groupList" element={<SearchGroupLists />} />

          <Route path="/my-groupList" element={<MyGroupsList />} />
          <Route path="/group-detail/:id" element={<GroupDetailedPage />} />
          <Route path="/edit-group/:id" element={<EditEmployerGroup />} />

          {/* --------------- Forums --------------- */}
          <Route path="/forumList" element={<ForumList />} />
          <Route path="/myForumList" element={<MyForumList />} />
          <Route path="/create-Forum" element={<CreateForms />} />
          <Route path="/forum-detail/:id" element={<ForumDetails />} />
          <Route path="/myForum-detail/:id" element={<MyForumDetails />} />
          <Route path="/edit-forum/:id" element={<EditForum />} />
          {/* --------------- Messages --------------- */}
          <Route path="/chats" element={<OnlineChatMessenger />} />

          {/* <Route path="/chats/:id" element={<SelectedChat />} /> */}
          <Route path="/messages" element={<EmployerMessages />} />

          {/* --------------- Notification --------------- */}
          <Route path="/notification" element={<Notification />} />

        </Route>
      </Route>
    </Routes>
  );
}
