import {
  Box,
  Button,
  Card,
  Chip,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

import worfromhome from "../../Images/worfromhome5.png";
import PlaceIcon from "@mui/icons-material/Place";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";

export default function MostSearchedJobs() {
  const redirect = useNavigate();
  const Labels = useSelector((state) => state.allLabels.labels);
  const [jobId, setjobId] = useState("");
  const allList = useSelector((state) => state.allList.list);
  const postData = {
    search: "",
    skills: "",
    city: "",
    category: "",
    type: "",
  };
  const viewDetailedJob = (data) => {
    redirect(`/jobSearchPrelogin/${data.jobid}`);
  };
  return (
    <Stack sx={{ mb: 6 }}>
      <Typography
        sx={{
          fontSize: { xs: "1.7rem", lg: "2.5rem" },
          fontWeight: "600",
          pl: 6,
          color: "#BA8C04",
        }}
      >
        {Labels?.Most}
      </Typography>
      <Typography
        sx={{
          fontSize: { xs: "1.7rem", lg: "2.5rem" },
          fontWeight: "600",
          pl: 6,
          pt: { xs: 5, lg: 7 },
          color: "#1C614E",
          mb: { xs: "10px", md: "0px" },
          position: "absolute",
          mb: 2,
        }}
      >
        {Labels?.SearchedJobs}
      </Typography>
      <Grid container sx={{ height: "100%" }}>
        <Grid
          item
          md={9}
          sm={12}
          xs={12}
          sx={{
            backgroundColor: "rgba(178, 207, 207, 0.3)",
            paddingBottom: { xs: "30px", md: "0px" },
            paddingTop: { xs: "60px", md: "0px" },
          }}
        >
          <Stack
            direction="column"
            justifyContent="space-evenly"
            marginLeft={4}
            marginRight={2}
            sx={{
              height: "100%",
              alignItems: { xs: "center", md: "flex-end" },
              py: 2,
              mt: { xs: 0, md: 3 },
            }}
          >
            <Grid container spacing={2}>
              {allList?.mostSearchedJobs &&
                allList?.mostSearchedJobs.map((data) => (
                  <Grid item md={4} sm={6} xs={12} key={data.jobid}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "white",
                        textAlign: "center",
                        borderRadius: "5px",
                        py: { xs: 4, sm: 3, lg: 3 },
                        width: "100%",
                        height: "100%",
                        minHeight: "150px",
                        cursor: "pointer",
                        "&:hover": {
                          boxShadow:
                            "0 0 1px rgba(0,0,0,.125),0 1px 3px rgba(0,0,0,.2)",
                        },
                      }}
                      onClick={(e) => viewDetailedJob(data)}
                    >
                      <Typography
                        variant="h6"
                        sx={{ mb: "4px", height: "32px", overflow: "hidden" }}
                      >
                        {data?.jobName.length > 15
                          ? data?.jobName.slice(0, 15) + "..."
                          : data?.jobName}
                      </Typography>
                      <Stack direction="row">
                        {data?.address || data?.country ? (
                          <PlaceIcon
                            sx={{
                              color: "#1C614E",
                              fontSize: "1.3rem",
                            }}
                          />
                        ) : null}

                        <Typography
                          sx={{ fontSize: "14px", filter: "blur(4px)" }}
                        >
                          {data?.address || data?.country}
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                ))}
            </Grid>
            <Button
              variant="outlined"
              endIcon={<ArrowForwardIcon />}
              sx={{
                borderRadius: "20px",
                color: "#1C614E",
                border: "1px solid #1C614E",
                textTransform: "none",
                "&:hover": {
                  border: "1px solid #1C614E",
                },
                mr: 4,
                mt: { xs: 2, md: 0 },
              }}
              onClick={() =>
                redirect("/jobSearchPrelogin", { state: postData })
              }
            >
              {Labels?.viewAll}
            </Button>
          </Stack>
        </Grid>
        <Grid item md={3} display={{ sm: "none", md: "block" }}>
          <img src={worfromhome} alt="joboffer" className="workFromHome-img" />
        </Grid>
      </Grid>
    </Stack>
  );
}
