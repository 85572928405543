import React, { useState, useEffect, useRef } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import GroupsIcon from "@mui/icons-material/Groups";
import {
  errorNotify,
  succesNotify,
  warningNotify,
  useStyles,
} from "../../CommonCode/Commonfunc";
import "./group.css";
import DeleteIcon from "@mui/icons-material/Delete";
import ReplyIcon from "@mui/icons-material/Reply";
import SendIcon from "@mui/icons-material/Send";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import { useParams, useNavigate } from "react-router-dom";
import { axiosAPI, axiosupload } from "../../Axios/Axios";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { PUBLIC_IMAGE_FOLDER } from "../../Axios/Constant";
import {
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInMonths,
  parseISO,
} from "date-fns";
import { useSelector } from "react-redux";
import DeleteCommentModal from "./deleteCommentModal";
import DeleteReplyModal from "./deleteReplyModal";
import DeletePostModal from "./deletePostModal";
import GroupInfo from "./groupInfo";
import GroupDetails from "./searchGroupDetails";
import { profanityfn,profanitySend } from "../../Profanity/Profanity";
import { Item } from "semantic-ui-react";

function MyDetailedGroup() {
  const classes = useStyles();
  const inputRef = useRef(null);
  const [content, setContent] = useState("");
  const [attachment, setAttachment] = useState("");
  const [postId, setPostId] = useState("");
  const [openPostDeleteModal, setPostDeleteModal] = useState(false);
  const [groupInfo, setGroupInfo] = useState({});
  const [attachmentPreview, setAttachmentPreview] = useState(null);
  const [refreshState, setRefreshState] = useState(false);
  const [loading, setloading] = useState(false);
  const redirect = useNavigate();
  const [refresh, setrefresh] = useState(false);
  // -------------------- GroupDetails --------------------
  const getGroupDetails = async () => {
    if (id) {
      const result = await axiosAPI.get(`/groupDetails?groupid=${id}`);
      const { status, data } = result.data;
      if (status) {
        setloading(true);
        setGroupInfo(data);
      } else {
        setloading(true);
        redirect("/myGroupList");
        setGroupInfo([]);
      }
    }
  };
  // -------------------- Jobseeker Data --------------------
  const data = useSelector(
    (state) => state.getJobseekerProfileData.jobseekerDetails
  );

  const Labels = useSelector((state) => state.allLabels.labels);

  const tokens = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };
  const { id } = useParams();

  const [updatebtn, setupdatebtn] = useState(false);
  const [btndisable, setbtndisable] = useState(false);

  // -------------------- Add post --------------------
  const AddPostProfanity = (e) => {
    const data = {
      "attachment": attachment,
      "message": content,
      "groupid": id,
      "type": "group",
      "language": localStorage.getItem("language")
  };
  

    let profanityDetected;
    for (const key in data) {
      if (profanitySend(ProfanityList, data[key])) {
        profanityDetected = true;
        break;
      } else {
        profanityDetected = false;
      }
    }

    if (profanityDetected == false) {
      addPost(e);
    } else {
      warningNotify(Labels.ProfanityLabel);
    }
  };
  const addPost = async (e) => {
    setbtndisable(true);
    e.preventDefault();
    try {
      e.preventDefault();
      if (content || attachment) {
        const data = new FormData();
        data.append("attachment", attachment);
        data.append("message", content);
        data.append("groupid", id);
        data.append("type", "group");
        data.append('language',localStorage.getItem("language"))
        const result = await axiosupload.post("/addPost", data, tokens);
        const { status, message } = result.data;
        if (status) {
          succesNotify(message);
          setContent("");
          getGroupDiscussion();
          setAttachment(null);
          setAttachmentPreview(null);
          setRefreshState((prev) => !prev);
          setbtndisable(false);
        } else {
          setbtndisable(false);
          if (content.length > 15000) {
            errorNotify(Labels.Contentshouldcharacterslong);
          }
        }
      } else {
        errorNotify(Labels.enterMessage);
        setbtndisable(false);
      }
    } catch (error) {
      setbtndisable(false);
    }
  };
  // -------------------- Delete Post --------------------
  const deletePost = (event, id) => {
    event.stopPropagation();
    setPostId(id);
    setPostDeleteModal(true);
    getGroupDiscussion();
  };
  const closeDeletePostModal = () => {
    getGroupDiscussion();
    setPostDeleteModal(false);
  };

  // -----------------updatePost-------------------
  const [updatePostMessage, setUpdatePostMessage] = useState("");
  const updatePost = (message, id, img) => {
    setupdatebtn(true);
    setUpdatePostMessage(message);
    setContent(message);
    setPostId(id);
    setAttachmentPreview(img);
    setAttachment(img);
    getGroupDiscussion();
  };

  const UpdatePostProfanity = (e) => {
    const postData = {
      message: content,
    };

    let profanityDetected;
    for (const key in postData) {
      if (profanitySend(ProfanityList, postData[key])) {
        profanityDetected = true;
        break;
      } else {
        profanityDetected = false;
      }
    }

    if (profanityDetected == false) {
      updatePostMessageAPI(e);
    } else {
      warningNotify(Labels.ProfanityLabel);
    }
  };

  
  const updatePostMessageAPI = async () => {
    try {
      const postData = {
        gmh_id: postId,
        type: "group",
        message: content,
        attachment: attachment,
        language:localStorage.getItem("language")
      };
      const result = await axiosupload.post("/editPost", postData, tokens);
      const { status, message } = result.data;
      if (status) {
        getGroupDiscussion();
        succesNotify(message);
        setContent("");
        setUpdatePostMessage("");
        setAttachmentPreview(null);
        setAttachment(null);
        setupdatebtn(false);
      } else {
        if (content.length > 15000) {
          // Display an error message or take appropriate action
          errorNotify(Labels.Contentshouldcharacterslong);
        }
      }
    } catch (error) {
    }
  };

  // -------------------- Time Difference --------------------
  const getTimeDifference = (createdOn) => {
    const dateNow = new Date();
    const createdDate = parseISO(createdOn);

    const diffInSeconds = differenceInSeconds(dateNow, createdDate);
    if (diffInSeconds < 60) {
      return `${diffInSeconds} ${Labels?.secAgo}`;
    }

    const diffInMinutes = differenceInMinutes(dateNow, createdDate);
    if (diffInMinutes < 60) {
      return `${diffInMinutes} ${Labels?.minAgo}`;
    }

    const diffInHours = differenceInHours(dateNow, createdDate);
    if (diffInHours < 24) {
      return `${diffInHours} ${Labels?.hourAgo}`;
    }

    const diffInDays = differenceInDays(dateNow, createdDate);
    if (diffInDays < 30) {
      return `${diffInDays}  ${Labels?.daysago}`;
    }

    const diffInMonths = differenceInMonths(dateNow, createdDate);
    return `${diffInMonths} ${Labels?.monthAgo}`;
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setAttachment(selectedFile);
    const reader = new FileReader();
    reader.onload = (e) => {
      setAttachmentPreview(e.target.result);
    };
    reader.readAsDataURL(selectedFile);
    setRefreshState((prev) => !prev);
  };
  
  const removeAttachment = () => {
    setAttachment(null);
    setAttachmentPreview(null);
  };
  // -------------------- Post Message Response --------------------
  const [groupDiscussion, setGroupDiscussion] = useState([]);
  const getGroupDiscussion = async () => {
    try {
      const result = await axiosAPI.post("/getGroupDiscussion", {
        groupid: id,
        type: "group",
      });
      const { status, data } = result.data;
      if (status) {
        setGroupDiscussion(data);
      } else {
        setGroupDiscussion([]);
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    getGroupDiscussion();
    getGroupDetails();
  }, [id]);

  useEffect(() => {
    getGroupDiscussion();
  }, [refresh]);

  // -------------------- Add Comment --------------------
  const [commentBoxStatus, setCommentBoxStatus] = useState({});

  const openCommentBox = (postId) => {
    setCommentBoxStatus((prevState) => ({
      ...prevState,
      [postId]: " ",
    }));
  };

  const closeCommentBox = (postId) => {
    setCommentBoxStatus((prevState) => ({
      ...prevState,
      [postId]: "",
    }));
  };
  const ProfanityList = useSelector((state) => state.Profanity.wordlist);

  const onChangeComment = (e, postId) => {
    setCommentBoxStatus((prevState) => ({
      ...prevState,
      [postId]: profanityfn(ProfanityList, e.target.value),
    }));
  };
  const AddPostCommentProfanity = (e,parentId) => {
    const data = {
      "message": commentBoxStatus[parentId],
    };

    let profanityDetected;
    for (const key in data) {
      if (profanitySend(ProfanityList, data[key])) {
        profanityDetected = true;
        break;
      } else {
        profanityDetected = false;
      }
    }

    if (profanityDetected == false) {
      addPostComment(e,parentId);
    } else {
      warningNotify(Labels.ProfanityLabel);
    }
  };

  const addPostComment = async (e, parentId) => {
    e.preventDefault();
    setbtndisable(true);
    setrefresh(!refresh);
    try {
      const data = new FormData();
      data.append("attachment", attachment);
      data.append("message", commentBoxStatus[parentId]);
      data.append("groupid", id);
      data.append("type", "group");
      data.append("parentID", parentId);
      data.append('language',localStorage.getItem("language"))

      const result = await axiosupload.post("/addReply", data, tokens);
      const { status, message } = result.data;

      // const inputValue = inputRef.current.value.trim(); // Trim leading and trailing whitespaces

      if (status) {
        setbtndisable(false);
        succesNotify(message);
        setCommentBoxStatus((prevState) => ({
          ...prevState,
          [parentId]: " ",
        }));
        getGroupDiscussion();
        inputRef.current.value = "";
        getGroupDiscussion();
      } else {
        setbtndisable(false);
        if (inputRef.current.value.length > 15000) {
          errorNotify(Labels.Contentshouldcharacterslong);
        }
      }
    } catch (error) {
    }
  };

  // -------------------- Delete Comment --------------------
  const [commentDeleteModal, setCommentDeleteModal] = useState(false);
  const [commentId, setCommentId] = useState("");

  const deleteComment = (event, id) => {
    event.stopPropagation();
    setCommentId(id);
    getGroupDiscussion();
    setCommentDeleteModal(true);
  };
  const closeDeleteCommentModal = () => {
    getGroupDiscussion();
    setCommentDeleteModal(false);
  };
  // -------------------- Add Reply --------------------
  const [replyBoxStatus, setReplyBoxStatus] = useState({});

  const openReplyBox = (postId) => {
    setReplyBoxStatus((prevState) => ({
      ...prevState,
      [postId]: " ",
    }));
  };

  const closeReplyBox = (postId) => {
    setReplyBoxStatus((prevState) => ({
      ...prevState,
      [postId]: "",
    }));
  };

  const onChangeReply = (e, postId) => {
    setReplyBoxStatus((prevState) => ({
      ...prevState,
      [postId]: profanityfn(ProfanityList, e.target.value),
    }));
  };
  
  const AddCommentReplyProfanity = (e,parentId) => {
    const data = {
      "message": replyBoxStatus[parentId],
    };

    let profanityDetected;
    for (const key in data) {
      if (profanitySend(ProfanityList, data[key])) {
        profanityDetected = true;
        break;
      } else {
        profanityDetected = false;
      }
    }

    if (profanityDetected == false) {
      addCommentReply(e,parentId);
    } else {
      warningNotify(Labels.ProfanityLabel);
    }
  };

  const addCommentReply = async (e, parentID) => {
    e.preventDefault();
    setbtndisable(true);
    try {
      const postData = {
        message: replyBoxStatus[parentID],
        groupid: id,
        type: "group",
        parentID: parentID,
        language:localStorage.getItem("language")
      };
      const result = await axiosAPI.post("/addCommentReply", postData);
      const { status, message } = result.data;
      if (status) {
        setbtndisable(false);
        succesNotify(message);
        setReplyBoxStatus((prevState) => ({
          ...prevState,
          [parentID]: " ",
        }));
        getGroupDiscussion();
      } else {
        setbtndisable(false);
        setReplyBoxStatus((prevState) => ({
          ...prevState,
          [parentID]: " ",
        }));
        errorNotify(message);
      }
    } catch (error) {
    }
  };
  // -------------------- Delete Reply --------------------
  const [replyDeleteModal, setReplyDeleteModal] = useState(false);
  const [replyId, setReplyId] = useState("");

  const deleteReply = (event, id) => {
    event.stopPropagation();
    setReplyId(id);
    setReplyDeleteModal(true);
    getGroupDiscussion();
  };
  const closeDeleteReplyModal = () => {
    setReplyDeleteModal(false);
    getGroupDiscussion();
  };

  const handleKeyDown = async (e) => {
    try {
      if (e.key === "Enter") {
        e.preventDefault();

        if (updatePostMessage) {
          UpdatePostProfanity();
        } else {
          AddPostProfanity(e); // Pass the event to addPost function
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      {/* -------------------- Delete Dialog -------------------- */}
      <>
        <DeletePostModal
          open={openPostDeleteModal}
          handleClose={closeDeletePostModal}
          postId={postId}
        />
        <DeleteCommentModal
          open={commentDeleteModal}
          handleClose={closeDeleteCommentModal}
          commentId={commentId}
        />
        <DeleteReplyModal
          open={replyDeleteModal}
          handleClose={closeDeleteReplyModal}
          replyId={replyId}
        />
      </>

      {!loading ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <Item>
                <Skeleton variant="rectangular" width={`100%`} height={150} />
                <br />
                <Skeleton variant="rectangular" width={`100%`} height={150} />
                <br />
                <Skeleton variant="rectangular" width={`100%`} height={150} />
                <br />
                <Skeleton variant="rectangular" width={`100%`} height={150} />
              </Item>
            </Grid>
            <Grid item xs={3}>
              <Item>
                <Skeleton variant="rounded" width={`100%`} height={`100vh`} />
              </Item>
            </Grid>
          </Grid>
        </>
      ) : groupInfo?.memberStatusKey !== "Accepted" ? (
        <GroupDetails setApiCalled={getGroupDetails} />
      ) : (
        groupInfo?.memberStatusKey === "Accepted" && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={8} xl={9}>
              <Paper
                elevation={0}
                sx={{
                  my: 1,
                  position: "relative",
                }}
              >
                <Box sx={{ overflow: "auto", height: "80vh" }}>
                  {groupDiscussion.length ? (
                    groupDiscussion.map((item, index) => (
                      <Box
                        key={index}
                        sx={{
                          border: "1px solid #0000001A",
                          borderRadius: 1,
                          p: 2,
                          m: 2,
                          zIndex: 2,
                        }}
                        className={classes.customScrollbar}
                      >
                        <>
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                          >
                            {item.postedLogo?.endsWith("/") ? null : (
                              <Avatar
                                src={`${item?.postedLogo}`}
                                alt=" "
                                style={{
                                  maxWidth: 30,
                                  maxHeight: 30,
                                  margin: "10px",
                                }}
                              />
                            )}

                            <Box>
                              <Typography variant="subtitle2">
                                {item?.postedName}
                              </Typography>
                              <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                              >
                                <Typography variant="body2">
                                  {item?.createdOn}
                                </Typography>
                                <GroupsIcon />{" "}
                              </Stack>
                            </Box>
                          </Stack>
                          <Box sx={{ mt: 1 }}>
                            <Typography variant="body1">
                              {item?.message}
                            </Typography>

                            {item?.attachmentsPath?.endsWith("/") ? null : (
                              <img
                                src={`${item.attachmentsPath}`}
                                alt=""
                                style={{
                                  maxHeight: 200,
                                  margin: "10px 0",
                                }}
                              />
                            )}
                            {groupInfo.statusKey == "Expired" ||
                            groupInfo.statusKey == "In-Active" ||
                            groupInfo.statusKey == "Closed" ? null : (
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                                sx={{ my: 0.5 }}
                              >
                                <Stack
                                  // sx={{ my: 2 }}
                                  direction="row"
                                  spacing={0.5}
                                  alignItems="center"
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => {
                                    if (!commentBoxStatus[item.id]) {
                                      openCommentBox(item.id);
                                    } else {
                                      closeCommentBox(item.id);
                                    }
                                  }}
                                >
                                  <ChatBubbleOutlineOutlinedIcon
                                    sx={{ fontSize: 17 }}
                                  />
                                  {item.comments.length > 0 ? (
                                    <Typography variant="caption">
                                      {item.comments.length}
                                    </Typography>
                                  ) : null}
                                  <Typography variant="caption">
                                    {Labels?.comment}
                                  </Typography>
                                </Stack>
                                {data?.userid === item.fromUser ? (
                                  <Stack
                                    direction="row"
                                    spacing={2}
                                    alignItems="center"
                                  >
                                    <Box
                                      sx={{
                                        borderRadius: 1,
                                        display: "flex",
                                        p: 0.5,
                                        justifyContent: "end",
                                        alignItems: "center",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        updatePost(
                                          item?.message,
                                          item.id,
                                          item.attachmentsPath
                                            ? item.attachmentsPath
                                            : null
                                        )
                                      }
                                    >
                                      <EditIcon sx={{ fontSize: 16 }} />
                                      <Typography
                                        variant="body2"
                                        sx={{ p: "0 5px" }}
                                      >
                                        {Labels?.edit}
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        borderRadius: 1,
                                        display: "flex",
                                        p: 0.5,
                                        justifyContent: "end",
                                        alignItems: "center",
                                        cursor: "pointer",
                                      }}
                                      onClick={(event) =>
                                        deletePost(event, item.id)
                                      }
                                    >
                                      <DeleteIcon sx={{ fontSize: 17 }} />
                                      <Typography
                                        variant="body2"
                                        sx={{ p: "0 5px" }}
                                      >
                                        {Labels?.delete}
                                      </Typography>
                                    </Box>
                                  </Stack>
                                ) : null}
                              </Stack>
                            )}
                          </Box>
                        </>
                        {/* -------------------- Comment -------------------- */}
                        {commentBoxStatus[item.id] ? (
                          <Box
                            sx={{
                              overflow: "auto",
                              maxHeight: "50vh",
                              background: "#fff",
                              // border: "1px solid #0000001A",
                              position: "relative",
                              borderRadius: 1,
                              // p: 2,
                              mt: 1,
                              zIndex: 1,
                            }}
                          >
                            <CommentInput
                              Labels={Labels}
                              data={data}
                              commentBoxStatus={commentBoxStatus[item.id]}
                              onChangeComment={onChangeComment}
                              setAttachment={setAttachment}
                              addPostComment={AddPostCommentProfanity}
                              btndisable={btndisable}
                              parentId={item.id}
                            />
                            {item.comments.length > 0 ? (
                              item.comments.map((item, index) => (
                                <Box
                                  key={item.commentid}
                                  sx={{
                                    zIndex: 2,
                                    my: 0.75,
                                    borderRadius: 1,
                                  }}
                                  className={classes.customScrollbar}
                                >
                                  <Box sx={{ px: 2, py: 0.5 }}>
                                    {item.attachmentsPath?.endsWith(
                                      "/"
                                    ) ? null : (
                                      <img
                                        src={`${item.attachmentsPath}`}
                                        alt=""
                                        style={{
                                          maxHeight: 200,
                                          margin: "10px 0",
                                        }}
                                      />
                                    )}

                                    <Stack
                                      direction="row"
                                      spacing={2}
                                      alignItems="center"
                                    >
                                      {item.commentedUserlogo?.endsWith(
                                        "/"
                                      ) ? null : (
                                        <Avatar
                                          src={`${item.commentedUserlogo}`}
                                          alt=" "
                                          style={{
                                            maxWidth: 25,
                                            maxHeight: 25,
                                          }}
                                        />
                                      )}

                                      <Box
                                        sx={{
                                          background: "#F3F2F2",
                                          borderRadius: 1,
                                          p: 1,
                                          minWidth: "50%",
                                        }}
                                      >
                                        <Typography variant="subtitle2">
                                          {item.commentedUsername}
                                        </Typography>
                                        <Typography variant="body2">
                                          {item.comment}
                                        </Typography>
                                      </Box>
                                    </Stack>

                                    <Stack
                                      direction="row"
                                      spacing={2}
                                      alignItems="center"
                                      sx={{ ml: 5 }}
                                    >
                                      <Typography variant="caption">
                                        {item.commentCreated}
                                        {/* {getTimeDifference(item.commentCreated)} */}
                                      </Typography>
                                      <Stack
                                        sx={{ cursor: "pointer" }}
                                        direction="row"
                                        spacing={0.5}
                                        alignItems="center"
                                        onClick={() => {
                                          if (!replyBoxStatus[item.commentid]) {
                                            openReplyBox(item.commentid);
                                          } else {
                                            closeReplyBox(item.commentid);
                                          }
                                        }}
                                      >
                                        <ReplyIcon />
                                        {item.replies.length > 0 ? (
                                          <Typography variant="caption">
                                            {item.replies.length}
                                          </Typography>
                                        ) : null}
                                        <Typography variant="caption">
                                          {Labels?.reply}
                                        </Typography>
                                      </Stack>
                                      {data?.userid === item.fromUser ? (
                                        <Stack
                                          sx={{ cursor: "pointer" }}
                                          direction="row"
                                          spacing={0.5}
                                          alignItems="center"
                                          onClick={(event) =>
                                            deleteComment(event, item.commentid)
                                          }
                                        >
                                          <DeleteIcon />
                                          <Typography variant="caption">
                                            {Labels?.delete}
                                          </Typography>
                                        </Stack>
                                      ) : null}
                                    </Stack>
                                  </Box>
                                  {/* -------------------- Reply -------------------- */}
                                  {replyBoxStatus[item.commentid] ? (
                                    <Box
                                      sx={{
                                        overflow: "auto",
                                        maxHeight: "50vh",
                                        background: "#fff",
                                        // border: "1px solid #0000001A",
                                        position: "relative",
                                        borderRadius: 1,
                                        px: 4,
                                        my: 2,
                                        zIndex: 3,
                                      }}
                                    >
                                      {item.replies.length > 0 ? (
                                        item.replies.map((item, index) => (
                                          <Box
                                            key={item.replyid}
                                            sx={{
                                              zIndex: 2,
                                              px: 2,
                                              py: 0.5,
                                              my: 0.75,
                                              borderRadius: 1,
                                            }}
                                            className={classes.customScrollbar}
                                          >
                                            <Stack
                                              direction="row"
                                              spacing={2}
                                              alignItems="center"
                                              sx={{ ml: 5 }}
                                            >
                                              {item.repliedUserlogo?.endsWith(
                                                "/"
                                              ) ? null : (
                                                <Avatar
                                                  src={`${item.repliedUserlogo}`}
                                                  alt=" "
                                                  style={{
                                                    maxWidth: 25,
                                                    maxHeight: 25,
                                                  }}
                                                />
                                              )}

                                              <Box
                                                sx={{
                                                  background: "#F1F6F6",
                                                  borderRadius: 1,
                                                  p: 1,
                                                  minWidth: "50%",
                                                }}
                                              >
                                                <Typography variant="subtitle2">
                                                  {item.repliedUsername}
                                                </Typography>
                                                <Typography variant="body2">
                                                  {item.reply}
                                                </Typography>
                                              </Box>
                                            </Stack>
                                            <Stack
                                              direction="row"
                                              spacing={2}
                                              alignItems="center"
                                              sx={{ ml: 10 }}
                                            >
                                              <Typography variant="caption">
                                                {/* {getTimeDifference(
                                                  item.replyCreated
                                                )} */}
                                                {item.replyCreated}
                                              </Typography>

                                              {data?.userid ===
                                              item.fromUser ? (
                                                <Stack
                                                  sx={{ cursor: "pointer" }}
                                                  direction="row"
                                                  spacing={0.5}
                                                  alignItems="center"
                                                  onClick={(event) =>
                                                    deleteReply(
                                                      event,
                                                      item.replyid
                                                    )
                                                  }
                                                >
                                                  <DeleteIcon />
                                                  <Typography variant="caption">
                                                    {Labels?.delete}
                                                  </Typography>
                                                </Stack>
                                              ) : null}
                                            </Stack>
                                          </Box>
                                        ))
                                      ) : (
                                        <Typography
                                          variant="body1"
                                          sx={{ p: 1 }}
                                        >
                                          {Labels?.noReplyAdded}
                                        </Typography>
                                      )}

                                      {/* -------------------- Reply Input -------------------- */}
                                      <ReplyInput
                                        Labels={Labels}
                                        data={data}
                                        replyBoxStatus={
                                          replyBoxStatus[item.commentid]
                                        }
                                        onChangeReply={onChangeReply}
                                        addCommentReply={AddCommentReplyProfanity}
                                        parentID={item.commentid}
                                        btndisable={btndisable}
                                      />
                                    </Box>
                                  ) : null}
                                </Box>
                              ))
                            ) : (
                              <Typography variant="body1" sx={{ p: 1 }}>
                                {Labels?.noCommentAdded}
                              </Typography>
                            )}
                            {/* -------------------- Comment Input -------------------- */}

                            {/* <CommentInput
                              Labels={Labels}
                              data={data}
                              commentBoxStatus={commentBoxStatus[item.id]}
                              onChangeComment={onChangeComment}
                              setAttachment={setAttachment}
                              addPostComment={addPostComment}
                              parentId={item.id}
                            /> */}
                          </Box>
                        ) : null}
                      </Box>
                    ))
                  ) : (
                    <Typography variant="body1" sx={{ p: 1 }}>
                      {Labels?.noPostAdded}
                    </Typography>
                  )}
                </Box>
                {/* -------------------- Post Input -------------------- */}
                <Divider />
                <Stack direction="row" justifyContent="flex-end">
                  {attachmentPreview ? (
                    attachmentPreview.endsWith("/") ? null : (
                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        spacing={1}
                        sx={{ background: "#F3F2F2", p: 1, width: "47%" }}
                      >
                        <img
                          src={attachmentPreview}
                          alt="Selected Attachment"
                          style={{ maxWidth: "100px", maxHeight: "100px" }}
                        />
                        <CloseOutlinedIcon onClick={removeAttachment} />
                      </Stack>
                    )
                  ) : null}
                </Stack>
                {groupInfo.statusKey == "Expired" ||
                groupInfo.statusKey == "In-Active" ||
                groupInfo.statusKey == "Closed" ? (
                  <Box
                    sx={{
                      backgroundColor: "#ff6161",
                      color: "#fff",
                      textAlign: "center",
                      padding: 1,
                      borderRadius: "5px",
                    }}
                  >
                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                      {Labels?.TheGroupis} {groupInfo?.status}
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      background: "#fff",
                      position: "sticky",
                      bottom: 0,
                      // borderTop: "1px solid #00000040",
                      px: 2,
                    }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ width: "100%" }}
                    >
                      {data?.profilePicPath?.endsWith("/") ? null : (
                        <Avatar
                          src={`${data?.profilePicPath}`}
                          sx={{
                            width: 35,
                            height: 35,
                          }}
                        />
                      )}

                      <FormControl
                        sx={{ m: 1, width: "100%" }}
                        variant="outlined"
                      >
                        {/* post input */}
                        <OutlinedInput
                          placeholder={Labels?.Typehere}
                          onChange={(e) =>
                            setContent(
                              profanityfn(ProfanityList, e.target.value)
                            )
                          }
                          value={content || updatePostMessage}
                          onKeyDown={handleKeyDown}
                          endAdornment={
                            <InputAdornment position="start">
                              <label htmlFor="attachment-input">
                                <IconButton component="span">
                                  <CameraAltIcon />
                                </IconButton>
                              </label>
                              <input
                                type="file"
                                id="attachment-input"
                                hidden
                                onChange={handleFileChange}
                                onKeyDown={(e) =>
                                  e.key === "Enter" && AddPostProfanity(e)
                                }
                              />
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                      {updatebtn ? (
                        <Button
                          sx={{
                            background: "#1C614E",
                            "&:hover": {
                              background: "#1C614E",
                            },
                            "&:active": {
                              backgroundColor: "#1C614E",
                            },
                            "&:disabled": { color:"white",
                              backgroundColor: "#1C614E",
                            },
                          }}
                          variant="contained"
                          endIcon={<SendIcon />}
                          onClick={UpdatePostProfanity}
                        >
                          {Labels?.Update}
                        </Button>
                      ) : (
                        <Button
                          sx={{
                            background: "#1C614E",
                            "&:hover": {
                              background: "#1C614E",
                            },
                            "&:active": {
                              backgroundColor: "#1C614E",
                            },
                            "&:disabled": { color:"white",
                              backgroundColor: "#1C614E",
                            },
                          }}
                          variant="contained"
                          endIcon={<SendIcon />}
                          disabled={btndisable}
                          onClick={AddPostProfanity}
                        >
                          {Labels?.Send}
                        </Button>
                      )}
                    </Stack>
                  </Box>
                )}
              </Paper>
            </Grid>
            {/* -------------------- GroupInfo -------------------- */}
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <GroupInfo />
            </Grid>
          </Grid>
        )
      )}
    </>
  );
}

export default MyDetailedGroup;

const CommentInput = ({
  btndisable,
  Labels,
  data,
  onChangeComment,
  commentBoxStatus,
  setAttachment,
  addPostComment,
  parentId,
}) => {
  return (
    <Box
      sx={{
        mt: 2,
        mb: 0,
        position: "sticky",
        bottom: 0,
        background: "#fff",
        borderTop: "1px solid #0000001A",
      }}
    >
      <Stack direction="row" alignItems="center" sx={{ width: "100%" }}>
        <Avatar
          src={`${data?.profilePicPath}`}
          sx={{ width: 25, height: 25 }}
        />
        <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
          <OutlinedInput
            placeholder={Labels?.Typehere}
            autoComplete="off"
            onChange={(e) => onChangeComment(e, parentId)}
            value={commentBoxStatus}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault(); // Prevents the default behavior (e.g., new line)
                addPostComment(e, parentId); // Your function to send the comment
              }
            }}
            endAdornment={
              <InputAdornment position="start">
                <input
                  type="file"
                  id={`attachment-input-${parentId}`}
                  hidden
                  onChange={(event) => setAttachment(event.target.files[0])}
                />
              </InputAdornment>
            }
          />
        </FormControl>
        <Button
          sx={{
            background: "#1C614E",
            "&:hover": {
              background: "#1C614E",
            },
            "&:active": {
              backgroundColor: "#1C614E",
            },
            "&:disabled": { color:"white",
              backgroundColor: "#1C614E",
            },
          }}
          variant="contained"
          endIcon={<SendIcon />}
          disabled={btndisable}
          onClick={(e) => {
            addPostComment(e, parentId);
          }}
        >
          {Labels?.Send}
        </Button>
      </Stack>
    </Box>
  );
};
const ReplyInput = ({
  Labels,
  data,
  onChangeReply,
  replyBoxStatus,
  addCommentReply,
  parentID,
  btndisable,
}) => {
  return (
    <Box
      sx={{
        mt: 2,
        mb: 0,
        position: "sticky",
        bottom: 0,
        background: "#fff",
        borderTop: "1px solid #0000001A",
      }}
    >
      <Stack direction="row" alignItems="center" sx={{ width: "100%" }}>
        <Avatar
          src={`${data?.profilePicPath}`}
          sx={{ width: 25, height: 25 }}
        />
        <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
          <OutlinedInput
            placeholder={Labels?.Typehere}
            autoComplete="off"
            onChange={(e) => onChangeReply(e, parentID)}
            value={replyBoxStatus}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault(); // Prevents the default behavior (e.g., new line)
                addCommentReply(e, parentID); // Your function to send the comment
              }
            }}
          />
        </FormControl>
        <Button
          sx={{
            background: "#1C614E",
            "&:hover": {
              background: "#1C614E",
            },
            "&:active": {
              backgroundColor: "#1C614E",
            },
            "&:disabled": { color:"white",
              backgroundColor: "#1C614E",
            },
          }}
          variant="contained"
          endIcon={<SendIcon />}
          disabled={btndisable}
          onClick={(e) => {
            addCommentReply(e, parentID);
          }}
        >
          {Labels?.Send}
        </Button>
      </Stack>
    </Box>
  );
};
