import {
  Avatar,
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { axiosAPI } from "../../Axios/Axios";
import { PUBLIC_IMAGE_FOLDER } from "../../Axios/Constant";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function Notification() {
  const redirect = useNavigate();

  // --------------get notifications---------------------
  const [notifications, setNotifications] = useState([]);
  const [loading, setloading] = useState(true);
  const getNotifications = async () => {
    const result = await axiosAPI.get("/getNotificationsList");
    const { status, data } = result.data;

    if (status) {
      setNotifications(data);
      setloading(false);
    } else {
      setloading(false);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getNotifications();
  }, []);

  const notificationRedirect = (data) => {
    if (data.type === "job") {
      localStorage.setItem("jobId", data?.typeID);
      window.location.href = `/jobInfo/${data?.typeID}`;
    } else if (data.type === "group") {
      window.location.href = `/group-detail/${data?.typeID}`;
    }
  };
  const Labels = useSelector((state) => state.allLabels.labels);

  const clearnotificationfn = async (id) => {
    const result = await axiosAPI.post("/readNotification", { id: id });
    const { status, data } = result.data;
    getNotifications();
  };

  return (
    <>
      <Typography
        sx={{
          mb: 2,
          fontSize: "20px",
          color: "rgba(28, 97, 78, 1)",
          fontWeight: 500,
        }}
      >
        {Labels?.Notifications}
      </Typography>

      {loading ? (
        <>
          <Skeleton
            variant="rectangular"
            width={`100%`}
            height={65}
            style={{ margin: "10px auto" }}
          />
          <Skeleton
            variant="rectangular"
            width={`100%`}
            height={65}
            style={{ margin: "10px auto" }}
          />
          <Skeleton
            variant="rectangular"
            width={`100%`}
            height={65}
            style={{ margin: "10px auto" }}
          />
          <Skeleton
            variant="rectangular"
            width={`100%`}
            height={65}
            style={{ margin: "10px auto" }}
          />
          <Skeleton
            variant="rectangular"
            width={`100%`}
            height={65}
            style={{ margin: "10px auto" }}
          />
          <Skeleton
            variant="rectangular"
            width={`100%`}
            height={65}
            style={{ margin: "10px auto" }}
          />
          <Skeleton
            variant="rectangular"
            width={`100%`}
            height={65}
            style={{ margin: "10px auto" }}
          />
          <Skeleton
            variant="rectangular"
            width={`100%`}
            height={65}
            style={{ margin: "10px auto" }}
          />
          <Skeleton
            variant="rectangular"
            width={`100%`}
            height={65}
            style={{ margin: "10px auto" }}
          />
          <Skeleton
            variant="rectangular"
            width={`100%`}
            height={65}
            style={{ margin: "10px auto" }}
          />
        </>
      ) : notifications.length === 0 ? (
        <Paper
          sx={{
            padding: "15px",
            boxShadow: "none",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 1,
          }}
        >
          <Stack direction="row" alignItems="center">
            <Typography sx={{ fontSize: "16px" }}>
              {Labels?.NoNotificationsAvailable}
            </Typography>
          </Stack>
        </Paper>
      ) : (
        notifications.map((data, index) => (
          <Paper
            key={index}
            sx={{
              padding: "15px",
              boxShadow: "none",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 1,
              border: data.status == 0 ? "2px solid green" : null,
              cursor:"pointer"
            }}
            onClick={() => {
              clearnotificationfn(data.id);
              notificationRedirect(data);
            }}
          >
            <Stack direction="row" alignItems="center">
              <Avatar sx={{ mr: 2 }} src={`${data.logo}`} />
              <Typography sx={{ fontSize: "16px" }}>{data?.message}</Typography>
            </Stack>
          </Paper>
        ))
      )}

    
    </>
  );
}

export default Notification;
