
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCmsAtmSurVRJyeB6e1apx0fZphXeWcClc",
  authDomain: "plus-cf181.firebaseapp.com",
  projectId: "plus-cf181",
  storageBucket: "plus-cf181.appspot.com",
  messagingSenderId: "467189145554",
  appId: "1:467189145554:web:a34079ee86167b0d3a320b"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app)

export { app, auth };
