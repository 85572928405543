import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Card,
  Grid,
  Box,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Divider,
  Skeleton,
} from "@mui/material";
import {
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInMonths,
  parseISO,
} from "date-fns";
import { useNavigate } from "react-router-dom";
import PublicIcon from "@mui/icons-material/Public";
import CustomButton from "../../Components/CustomButton";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import "../../Landinpage1/Style.css";
import { axiosAPI } from "../../Axios/Axios";
import { PUBLIC_IMAGE_FOLDER } from "../../Axios/Constant";
import dummyLogo from "../../../Images/dummyLogo.png";
import {
  errorNotify,
  infoNotify,
  succesNotify,
  useStyles,
} from "../../CommonCode/Commonfunc";
import TextfieldCustom from "../../Components/TextfieldCustom";
import { useSelector } from "react-redux";
import { Fragment } from "react";
import { Item } from "semantic-ui-react";

const SearchGroupLists = () => {
  const data = useSelector(
    (state) => state.getJobseekerProfileData.jobseekerDetails
  );
  const resettedByAdmin = localStorage.getItem("resettedByAdmin");
  const Labels = useSelector((state) => state.allLabels.labels);
  const redirect = useNavigate();
  const classes = useStyles();
  const [groups, setGroups] = useState([]);
  const [view, setView] = useState("module");
  const [loading, setloading] = useState(false);
  const [loading2, setloading2] = useState(false);

  const handleChange = (event, nextView) => {
    if (nextView !== null) {
      setView(nextView);
    }
  };

  const getGroups = async () => {
    try {
      const result = await axiosAPI.post("/getGroupList");
      const { data, status } = result.data;
      if (status) {
        setloading(true);
        setGroups(data);
      } else {
        setloading(true);
        setGroups([]);
      }
    } catch (error) {
    }
  };
  // ---------------Search Groups--------------------
  const [searchKeyword, setSearchKeyword] = useState("");
  const searchGroups = async () => {
    if (!searchKeyword) {
      getGroups();
    } else {
      try {
        const result = await axiosAPI.post("/getGroupList", {
          search: searchKeyword,
        });
        const { data, status, message } = result.data;
        if (status) {
          setGroups(data);
        } else {
          infoNotify(message);
        }
      } catch (error) {
      }
    }
  };

  //------------------Request to groups -------------------
  const postRequestToGroup = async (id) => {
    const result = await axiosAPI.post(`/requestToGroup?groupid=${id}`,{language:localStorage.getItem("language")});
    const { status, message } = result.data;
    if (status) {
      succesNotify(message);
      getGroups();
    } else {
      errorNotify(message);
    }
  };
  //------------------Cancel requests to groups -------------------
  const postRemoveRequestToGroup = async (id) => {
    const result = await axiosAPI.post(`/cancelRequest?groupid=${id}`,{language:localStorage.getItem("language")});
    const { status, message } = result.data;
    if (status) {
      succesNotify(message);
      getGroups();
    } else {
      errorNotify(message);
    }
  };
  // --------------- Popular Groups--------------------
  const [popularGroup, setPopularGroup] = useState([]);
  const getPopularGroup = async () => {
    try {
      const result = await axiosAPI.post("/getPopularList", { type: "group" });
      const { data, status } = result.data;
      if (status) {
        setPopularGroup(data);
        setloading2(true);
      } else {
        setloading2(true);
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    getPopularGroup();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getGroups();
  }, []);

  const viewDetailedGroup = (id) => {
    redirect(`/group-detail/${id}`);
  };

  // -------------------- Time Difference --------------------
  const getTimeDifference = (createdOn) => {
    const dateNow = new Date();
    const createdDate = parseISO(createdOn);

    const diffInSeconds = differenceInSeconds(dateNow, createdDate);
    if (diffInSeconds < 60) {
      return `${diffInSeconds} ${Labels?.secAgo}`;
    }

    const diffInMinutes = differenceInMinutes(dateNow, createdDate);
    if (diffInMinutes < 60) {
      return `${diffInMinutes} ${Labels?.minAgo}`;
    }

    const diffInHours = differenceInHours(dateNow, createdDate);
    if (diffInHours < 24) {
      return `${diffInHours} ${Labels?.hourAgo}`;
    }

    const diffInDays = differenceInDays(dateNow, createdDate);
    if (diffInDays < 30) {
      return `${diffInDays}  ${Labels?.daysago}`;
    }

    const diffInMonths = differenceInMonths(dateNow, createdDate);
    return `${diffInMonths} ${Labels?.monthAgo}`;
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" sx={{ color: "#1C614E" }}>
          {Labels?.DiscoverGroups}
        </Typography>
      </Stack>
      <Stack
        direction={{ xs: "column", md: "row" }}
        sx={{ my: 1 }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack  direction={{ xs: "column", sm: "row" }} alignItems="center">
          <TextfieldCustom
            label={Labels?.searchGroupName}
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
          />
          <CustomButton
            label={Labels?.search}
            style={{ borderRadius: "10px", margin: "10px" }}
            onClick={searchGroups}
          />
            <CustomButton
              label={Labels?.Reset}
              style={{ borderRadius: "10px", margin: "10px",  background: "white",
                color: "#1C614E",
                border: "2px solid #1C614E",
                "&:hover": {
                  backgroundColor: "white",
                  color: "#1C614E",
                  border: "2px solid #1C614E",
                },
                "&:active": {
                  backgroundColor: "white",
                  color: "#1C614E",
                  border: "2px solid #1C614E",
                },
                "&:disabled": { color: "white", backgroundColor: "#1C614E" } }}
              onClick={()=>{  getGroups();setSearchKeyword("")}}
            />
        </Stack>
        <div style={{ margin: "15px 0" }}>
          <ToggleButtonGroup value={view} exclusive onChange={handleChange}>
            <ToggleButton value="module" aria-label="module">
              <ViewModuleIcon />
            </ToggleButton>
            <ToggleButton value="list" aria-label="list">
              <ViewListIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8} md={7} lg={8} xl={9}>
          {loading ? (
            <Grid container spacing={2}>
              {groups.length > 0 ? (
                groups.map((val) => (
                  <Fragment>
                    {view === "module" ? (
                      <Grid item xs={12} md={6} lg={6} key={val.id}>
                        <Card
                          sx={{
                            borderRadius: "15px",
                            boxShadow: "0px 4px 39px rgba(0, 0, 0, 0.1)",
                            cursor: "pointer",
                            padding: "10px 0",
                            filter:
                              val.status !== "Active"
                                ? "grayscale(100%)"
                                : "none",
                            "&:hover": {
                              backgroundColor: "#ffffffe5",
                            },
                            
                          }}
                          onClick={
                            val.status === "Active"
                              ? () => viewDetailedGroup(val.id)
                              : null
                          }
                        >
                          {val.status !== "Active" && (
                            <Box
                              sx={{
                                width: "100%",
                                backgroundColor: "#bfbfbf",
                                color: "#fff",
                                textAlign: "center",
                                padding: 1,
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                borderRadius: "5px",
                                zIndex: 1,
                              }}
                            >
                              {val.status === "Expired"
                                ? Labels?.expired
                                : Labels?.inactive}
                            </Box>
                          )}
                          <Stack direction="row">
                            <Avatar
                              src={`${val.grpLogoPath}`}
                              alt=" "
                              style={{
                                maxWidth: 70,
                                maxHeight: 70,
                                margin: "10px",
                              }}
                            />
                            <Box
                              sx={{
                                margin: "10px",
                              }}
                            >
                              <Typography
                                variant="subtitle1"
                                sx={{ color: "#1C614E", fontWeight: 600 }}
                              >
                                {val.groupName}
                              </Typography>
                              {val.statusKey == "Expired" ||
                              val.statusKey == "In-Active" ||
                              val.statusKey == "Closed" ? (
                                <Typography
                                  variant="caption"
                                  sx={{
                                    color: "#ED4C5C",
                                    background: "rgb(255, 246, 247)",
                                    p: 0.75,
                                    borderRadius: 1,
                                  }}
                                >
                                  {val.status}
                                </Typography>
                              ) : null}
                              <Stack direction="row" alignItems="center">
                                <PublicIcon sx={{ fontSize: 17 }} />
                                <Typography
                                  variant="body2"
                                  sx={{ color: "#1C614E" }}
                                >
                                  &nbsp;{val.categoryName}
                                </Typography>
                              </Stack>
                            </Box>
                          </Stack>
                          <Typography
                            sx={{
                              overflowY: "hidden",
                              minHeight: "70px",
                              maxHeight: "70px",
                              lineHeight: "23px",
                              fontSize: "15px",
                              fontWeight: 300,
                              color: "#716D6DD9",
                              margin: "10px",
                            }}
                          >
                            {val.description.length > 75
                              ? val.description.slice(0, 100) + "..."
                              : val.description}
                          </Typography>
                          <div
                            style={{
                              margin: "10px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Stack direction="row">
                              <Typography variant="body2">
                                {val.createdOn}
                              </Typography>
                              &nbsp;-&nbsp;
                              <Typography variant="body2">
                                {val.membersCount}&nbsp;
                                {val.membersCount==1?Labels?.Member:Labels?.Members}
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              justifyContent="right"
                              alignItems="center"
                              spacing={1}
                            >
                              {val.status !==
                              "Active" ? null : val.memberStatusKey ===
                                "Invited" ? (
                                <Button
                                  variant="text"
                                  color="success"
                                  sx={{
                                    textTransform: "capitalize",
                                    p: 0,
                                  }}
                                >
                                  {Labels?.invited}
                                </Button>
                              ) : val.memberStatusKey === "Requested" ? (
                                <Button
                                  variant="text"
                                  color="success"
                                  sx={{
                                    textTransform: "capitalize",
                                    p: 0,
                                  }}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    postRemoveRequestToGroup(val.id);
                                  }}
                                >
                                  {Labels?.requested}
                                </Button>
                              ) : (
                                <Button
                                  variant="text"
                                  color="success"
                                  sx={{
                                    textTransform: "capitalize",
                                    p: 0,
                                  }}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    postRequestToGroup(val.id);
                                  }}
                                >
                                  {Labels?.Join}
                                </Button>
                              )}
                            </Stack>
                          </div>
                        </Card>
                      </Grid>
                    ) : (
                      <Grid item xs={12} key={val.id}>
                        <Card
                          sx={{
                            borderRadius: "17px",
                            boxShadow: "0px 4px 39px rgba(0, 0, 0, 0.1)",
                            cursor: "pointer",
                            display: { xs: "block", md: "flex" },
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "10px 5px",
                            filter:
                              val.status !== "Active"
                                ? "grayscale(100%)"
                                : "none",
                            "&:hover": {
                              backgroundColor: "#ffffffe5",
                            },
                           
                          }}
                          onClick={
                            val.status === "Active"
                              ? () => viewDetailedGroup(val.id)
                              : null
                          }
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={3} lg={1}>
                              <Avatar
                                src={`${val.grpLogoPath}`}
                                alt=" "
                                style={{
                                  maxWidth: 70,
                                  maxHeight: 70,
                                  margin: "10px",
                                }}
                              />
                            </Grid>
                            <Grid item xs={9} lg={4}>
                              <Typography
                                variant="subtitle1"
                                sx={{ color: "#1C614E", fontWeight: 600 }}
                              >
                                {val.groupName}
                              </Typography>
                              {val.statusKey == "Expired" ||
                              val.statusKey == "In-Active" ||
                              val.statusKey == "Closed" ? (
                                <Typography
                                  variant="caption"
                                  sx={{
                                    color: "#ED4C5C",
                                    background: "rgb(255, 246, 247)",
                                    p: 0.75,
                                    borderRadius: 1,
                                  }}
                                >
                                  {val.status}
                                </Typography>
                              ) : null}
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                              >
                                <PublicIcon sx={{ fontSize: 17 }} />
                                <Typography
                                  variant="body2"
                                  sx={{ color: "#1C614E" }}
                                >
                                  &nbsp;{val.categoryName}
                                </Typography>
                              </Stack>
                              <Stack direction="row">
                                <Typography variant="body2">
                                  {val.createdOn}
                                </Typography>
                                &nbsp;-&nbsp;
                                <Typography variant="body2">
                                  {val.membersCount}&nbsp;
                                  {Labels?.Members}
                                </Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={12} lg={5}>
                              <Typography
                                sx={{
                                  overflowY: "hidden",
                                  lineHeight: "23px",
                                  fontSize: "15px",
                                  fontWeight: 300,
                                  color: "#716D6DD9",
                                  margin: "10px",

                                  textAlign: "left",
                                }}
                              >
                                {val.description.length > 75
                                  ? val.description.slice(0, 100) + "..."
                                  : val.description}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} lg={1}>
                              <Stack
                                direction="row"
                                justifyContent="right"
                                alignItems="center"
                                //----------------------changed posiotion of button---------------------
                                my={2}
                              >
                                {val.status !== "Active" ? (
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ color: "#2e7d32" }}
                                  >
                                    {val.status}
                                  </Typography>
                                ) : val.memberStatusKey !== "Requested" ? (
                                  <Button
                                    variant="text"
                                    color="success"
                                    sx={{
                                      textTransform: "capitalize",
                                      p: 0,
                                    }}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      postRequestToGroup(val.id);
                                    }}
                                  >
                                    {Labels?.Join}
                                  </Button>
                                ) : (
                                  <Button
                                    variant="text"
                                    color="success"
                                    sx={{
                                      textTransform: "capitalize",
                                      p: 0,
                                    }}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      postRemoveRequestToGroup(val.id);
                                    }}
                                  >
                                    {Labels?.requested}
                                  </Button>
                                )}
                              </Stack>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    )}
                  </Fragment>
                ))
              ) : (
                <Typography variant="body1" sx={{ p: 1 }}>
                  {Labels?.noGroupFound}
                </Typography>
              )}
            </Grid>
          ) : (
            <>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Item>
                    <Skeleton variant="rounded" width={`100%`} height={200} />
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  <Item>
                    <Skeleton variant="rounded" width={`100%`} height={200} />
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  <Item>
                    <Skeleton variant="rounded" width={`100%`} height={200} />
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  <Item>
                    <Skeleton variant="rounded" width={`100%`} height={200} />
                  </Item>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={4} md={5} lg={4} xl={3}>
          <Card
            sx={{
              borderRadius: 1,
              padding: 0,
              boxShadow: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 20,
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ color: "#1C614E", fontWeight: 500 }}
              >
                {Labels?.popularGroups}
              </Typography>
            </div>
            <Divider />
            {!loading2 ? (
              <>
                <Skeleton variant="rounded" width={`95%`} height={70} style={{margin:"5px auto"}}/>
                <Skeleton variant="rounded" width={`95%`} height={70} style={{margin:"5px auto"}}/>
                <Skeleton variant="rounded" width={`95%`} height={70} style={{margin:"5px auto"}}/>
                <Skeleton variant="rounded" width={`95%`} height={70} style={{margin:"5px auto"}}/>
                <Skeleton variant="rounded" width={`95%`} height={70} style={{margin:"5px auto"}}/>
                <Skeleton variant="rounded" width={`95%`} height={70} style={{margin:"5px auto"}}/>
              </>
            ) : (
              <Box
                sx={{ height: { xs: "50vh", md: "70vh" }, overflow: "auto" }}
                className={classes.customColoredScrollbar}
              >
                {popularGroup.map((item, i) => (
                  <Card
                    sx={{
                      borderRadius: 1,
                      boxShadow: "none",
                      borderBottom: "1px solid #1C614E33",
                      cursor: "pointer",
                      p: 1,

                      "&:hover": {
                        backgroundColor: "#ffffffe5",
                      },
                     
                    }}
                    key={item.id}
                    onClick={() => viewDetailedGroup(item.id)}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Avatar
                          src={`${item.grpLogoPath}`}
                          alt=" "
                          style={{
                            maxWidth: 30,
                            maxHeight: 30,
                          }}
                        />
                        <Stack>
                          <Typography
                            variant="subtitle1"
                            sx={{ color: "#2B4947", fontWeight: 600 }}
                          >
                            {item.groupName}
                          </Typography>
                          <Stack direction="row" alignItems="center">
                            <Typography
                              variant="body2"
                              sx={{ color: "#1C614E" }}
                            >
                              {item.categoryName}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                      {/* <Stack>
                      <Typography variant="caption">
                        {getTimeDifference(item.createdOn)}
                      </Typography>
                    </Stack> */}
                    </Stack>
                  </Card>
                ))}
              </Box>
            )}
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default SearchGroupLists;
