import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";

export default function SimpleBackdrop({ open }) {
  return (
    <div>
      <Backdrop
        // sx={{
        //   color: "#fff",
        //   backgroundColor: `rgba(0, 0, 0, 0.581)`,
        //   backdropFilter: `blur(2px)`,
        //   zIndex: (theme) => theme.zIndex.drawer + 1,
        // }}

        sx={{
          color: "white", // Light gray text color (grayscale)
          backgroundColor: `rgba(0, 0, 0, 0.65)`, // Medium gray background (with opacity)
          backdropFilter: `blur(2px)`,
          zIndex: (theme) => theme.zIndex.drawer + 1,
          filter: 'grayscale(100%) brightness(80%)',
          border: "2px solid #888", // Medium gray border
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)", // Subtle gray shadow
        }}


        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
