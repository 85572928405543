import React, { useState } from "react";
import TwoFactorAuthentication from "./TwoFactorAuthentication";
const MFAPopup = ({ openpopup, setopenpopup}) => {
  // setopenpopup(false);

  if (openpopup) {
    return (
      <div className="custom-modal-overlay">
        <>
          <div className="custom-modal-content">
            <TwoFactorAuthentication/>
          </div>
        </>
      </div>
    );
  }
};

export default MFAPopup;
