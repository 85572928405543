import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosAPI } from "../views/Axios/Axios";
const initialState = {
  loading: false,
  error: "",
  languages: [],
};

const getAllLanguage = createAsyncThunk("api/getLanguage", async () => {
  return await axiosAPI.get("/getLanguages").then((response) => {
    return response.data.data;                         
  });
});  

const getLanguageSlice = createSlice({
  name: "createLanguage",
  initialState,
  extraReducers: {    
    [getAllLanguage.pending]: (state) => {
      state.loading = true;
    },
    [getAllLanguage.fulfilled]: (state, { payload }) => {
      state.languages = payload;    
      state.loading = false;
    },
    [getAllLanguage.rejected]: (state) => {
      state.error = "something went wrong";
      state.loading = false;
    },
  },
});  
export { getAllLanguage };

export default getLanguageSlice.reducer;
 