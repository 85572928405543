import React, { Fragment, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { axiosAPI } from "../Axios/Axios";
import { useSelector } from "react-redux";
import { Autocomplete, Skeleton, TextField } from "@mui/material";
import { profanityfn } from "../Profanity/Profanity";

const CounsultancyAddress = ({value, setValue, countrycompany, style, companyName}) => {
  const ProfanityList = useSelector((state) => state.Profanity.wordlist);
  const Labels = useSelector((state) => state.allLabels.labels);
  const usertype = localStorage.getItem("userType");
  const [States, setStates] = useState([]);

  useEffect(() => {
    const getStates = async () => {
      const result = await axiosAPI.get(`/getConsultancyAddress?countryid=${countrycompany?.id}&companyid=${companyName}`);
      const { status, data } = await result.data;
      if (status === true) {
        setStates(data);
      } else {
        setStates([]);
      }
    };
    getStates();
  }, [countrycompany, companyName]);

  const handleChange = (event, newValue) => {
    setValue(profanityfn(ProfanityList, newValue));
  };

  return (
    <FormControl sx={{ width: "100%" }}>
      <Autocomplete
        value={value}
        autoComplete={`off`}
        onChange={handleChange}
        options={States}
        sx={{ ...style }}
        readOnly={usertype === "Recruiter" ? true : false}
        getOptionLabel={(option) => {
          const trimmedName = option.name.trimEnd();
          return trimmedName.endsWith(",") 
            ? trimmedName.slice(0, -1) 
            : trimmedName;
        }}
        renderInput={(params) => (
          <TextField {...params} placeholder={Labels?.SelectState}
          // sx={{
          //   ...style, // Retain consistent styling
          //   backgroundColor: !value ? "#fadde1" : "white", // Conditional background color
          // }} 
          
          />
        )}
        noOptionsText={Labels?.notAvailable}
      />
    </FormControl>
  );
};

export default CounsultancyAddress;
