import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  Stack,
  Typography,
  TextField,
  Autocomplete,
  Chip,
  styled,
  LinearProgress,
  linearProgressClasses,
  useMediaQuery,
  useTheme,
  Skeleton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useSelector } from "react-redux";
import { axiosAPI } from "../../Axios/Axios";

import {
  errorNotify,
  infoNotify,
  succesNotify,
  warningNotify,
} from "../../CommonCode/Commonfunc";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: `white`,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#1C614E",
  },
}));

const SkillsaddModal = ({ open, handleClose, skillsName }) => {
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [skillDetail, setSkillDetail] = useState("");
  const [skillsList, setSkillsList] = useState([]);
  const [isButtonDisable, setButtonDisable] = useState(false);
  const [newskills, setnewskills] = useState([]);
  const [view, setview] = useState(false);

  const Labels = useSelector((state) => state.allLabels.labels);

  useEffect(() => {
    if (skillsName?.length > 0) {
      setnewskills(
        skillsName?.map((skill) => ({
          skillId: skill.id,
          detail: skill.detail,
        }))
      );
    }

    const fetchSkills = async () => {
      const result = await axiosAPI.get("/getSkills");
      const { success, data } = await result.data;
      if (success) {
        setview(true);
        setSkillsList(data);
      } else {
        setSkillsList([]);
      }
    };

    fetchSkills();
  }, []);

  const handleAddSkill = () => {
    if (!skillDetail || !selectedSkill?.id) {
      return warningNotify(Labels.skillPercentIsRequired);
    }

    const skillExists = newskills.find(
      (skill) => skill.skillId === selectedSkill?.id
    );
    if (skillExists) {
      return infoNotify(`${selectedSkill.name} ${Labels.isalreadyadded}`);
    }

    setnewskills([
      ...newskills,
      { skillId: selectedSkill?.id, detail: skillDetail },
    ]);
    setSelectedSkill(null);
    setSkillDetail("");
  };

  const handleSubmit = async () => {
    if (newskills.length === 0) {
      return warningNotify(`${Labels?.skillUpdation}`);
    }

    const postData = {
      skills: newskills,
      language: localStorage.getItem("language"),
    };

    setButtonDisable(true);
    const result = await axiosAPI.post("/updateUserProfile", postData);
    const { success, message } = result.data;
    if (success) {
      succesNotify(message);
      handleClose();
      setButtonDisable(false);
    } else {
      errorNotify(message);
      setButtonDisable(false);
    }
  };
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.between("xs", "sm"));

  return (
    <Dialog open={open} onClose={handleClose}>
      <div>
        {view ? (
          <Box
            sx={{ p: 1.5, minWidth: isXs ? "300px" : isSm ? "300px" : "500px" }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ py: 1.5, width: "100%", overflow: "none" }}
            >
              <Typography variant="h6" sx={{ color: "#1C614E" }}>
                {Labels?.KeySkills}
              </Typography>
              <HighlightOffIcon
                onClick={handleClose}
                sx={{ color: "#1C614E", cursor: "pointer" }}
              />
            </Stack>
            <Autocomplete
              value={selectedSkill}
              onChange={(e, newValue) => setSelectedSkill(newValue)}
              options={skillsList}
              getOptionLabel={(option) => option?.name || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={Labels?.skills}
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "black", // Default label color
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#1C614E", // Label color when focused
                    },
                  }}
                  placeholder={Labels?.selectSkill}
                />
              )}
              noOptionsText={Labels?.notAvailable}
            />

            {selectedSkill && (
              <TextField
                type="number"
                inputProps={{
                  min: 0,
                  max: 100,
                  step: 1,
                }}
                sx={{
                  "& .MuiInputLabel-root": {
                    color: "black", // Default label color
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#1C614E", // Label color when focused
                  },
                  mt: 2,
                }}
                label={Labels?.skillpercent}
                value={skillDetail}
                onChange={(e) => {
                  const value = e.target.value;
                  if (
                    value === "" ||
                    (Number(value) >= 0 && Number(value) <= 100)
                  ) {
                    setSkillDetail(value);
                  }
                }}
                onBlur={(e) => {
                  let value = Number(e.target.value);
                  if (value < 0) {
                    value = 0;
                  } else if (value > 100) {
                    value = 100;
                  }
                  setSkillDetail(value);
                }}
                fullWidth
                variant="outlined"
              />
            )}
            {selectedSkill ? (
              <Button
                sx={{
                  mt: 2,
                  background: "#1C614E",
                  borderRadius: 2,
                  textTransform: "capitalize",
                  color: "white",
                  "&:hover": { backgroundColor: "#1C614E" },
                  "&:disabled": { color: "white", backgroundColor: "#1C614E" },
                }}
                variant="contained"
                fullWidth
                onClick={handleAddSkill}
              >
                {Labels.EnterSkills}
              </Button>
            ) : (
              ""
            )}

            <Stack
              spacing={1}
              mt={2}
              sx={{
                maxHeight: "300px",
                overflowY: "auto",
              }}
            >
              {newskills.length
                ? newskills.map((val, index) => (
                    <Stack
                      key={val.skillId}
                      direction={{ xs: "column", sm: "row" }}
                      alignItems="center"
                      spacing={2}
                      sx={{
                        margin: "4px 0px",
                        p: 1,
                        border: "1px solid #e0e0e0",
                        borderRadius: 2,
                        justifyContent: "space-between", // Ensure even spacing
                      }}
                    >
                      {/* Skill Name */}
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: 600,
                          color: "#2B4947",
                          flexBasis: { xs: "100%", sm: "25%" },
                          flexShrink: 0, // Prevent shrinking
                          textAlign: { xs: "center", sm: "left" }, // Center-align for small screens
                          display: { xs: "none", sm: "block" },
                        }}
                      >
                        {skillsList.find((s) => s.id === val.skillId)?.name}
                      </Typography>

                      {/* Skill Name with Detail for Small Screens */}
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: 600,
                          color: "#2B4947",
                          flexBasis: { xs: "100%", sm: "25%" },
                          flexShrink: 0, // Prevent shrinking
                          textAlign: { xs: "center", sm: "left" }, // Center-align for small screens
                          display: { xs: "block", sm: "none" },
                        }}
                      >
                        {skillsList.find((s) => s.id === val.skillId)?.name}{" "}
                        {val?.detail ? ` - ${val?.detail}%` : ""}
                      </Typography>

                      {/* Progress Bar */}
                      <Box
                        sx={{
                          flexGrow: 1,
                          width: "100%",
                          marginRight: { xs: 0, sm: "16px" }, // Add space between progress bar and delete icon on larger screens
                        }}
                      >
                        <BorderLinearProgress
                          variant="determinate"
                          value={val.detail}
                          sx={{
                            "& .MuiLinearProgress-bar": {
                              backgroundColor:
                                val.detail >= 50 ? "#1C614E" : "#FFD700",
                            },
                          }}
                        />
                      </Box>

                      {/* Skill Name with Detail for Large Screens */}
                      <Typography
                        sx={{
                          minWidth: "50px",
                          textAlign: "center",
                          fontWeight: 500,
                          color: "black",
                          display: { xs: "none", sm: "block" }, // Visible only on smaller screens
                        }}
                      >
                        {val.name} {val?.detail ? `${val?.detail}%` : ""}
                      </Typography>

                      {/* Delete Icon */}
                      <DeleteIcon
                        onClick={() =>
                          setnewskills(newskills.filter((_, i) => i !== index))
                        }
                        sx={{
                          cursor: "pointer",
                          color: "#1C614E",
                          alignSelf: "center", // Center-align on small screens
                        }}
                      />
                    </Stack>
                  ))
                : null}
            </Stack>

            <Button
              sx={{
                mt: 2,
                background: "#1C614E",
                borderRadius: 2,
                textTransform: "capitalize",
                color: "white",
                "&:hover": { backgroundColor: "#1C614E" },
                "&:disabled": { color: "white", backgroundColor: "#1C614E" },
              }}
              variant="contained"
              fullWidth
              onClick={handleSubmit}
              disabled={isButtonDisable}
            >
              {newskills.length ? `${Labels?.Update}` : `${Labels?.Add}`}
            </Button>
          </Box>
        ) : (
          <Box
            sx={{
              p: 1.5,
              minWidth: isXs ? "300px" : isSm ? "300px" : "500px",
              height: "280px",
            }}
          >
            <Stack spacing={1}>
              {/* For variant="text", adjust the height via font-size */}
              <Skeleton variant="rectangular" width={100} height={40} />
              <br />
              <Skeleton variant="rectangular" width={`100%`} height={40} />
              <Skeleton variant="rectangular" width={`100%`} height={40} />
              <Skeleton variant="rectangular" width={`100%`} height={40} />
              <Skeleton variant="rectangular" width={`100%`} height={40} />
            </Stack>
          </Box>
        )}
      </div>
    </Dialog>
  );
};

export default SkillsaddModal;
