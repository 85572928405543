import {
  Box,
  Card,
  Container,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Header from "./Header";
import PageHeading from "./PageHead";
import Footer from "./Footer";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CallIcon from "@mui/icons-material/Call";
import InputWithLabel from "../Components/InputWithLabel";
import CustomButton from "../Components/CustomButton";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import "./Style.css";
import { useNavigate } from "react-router-dom";
import {
  errorNotify,
  succesNotify,
  validatePhoneNumber,
} from "../CommonCode/Commonfunc";
import { axiosAPI } from "../Axios/Axios";

import { useSelector } from "react-redux";
import {profanityfn}  from "../Profanity/Profanity";

function Contact() {
  const redirect = useNavigate();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    language:localStorage.getItem("language")
  });
  const [formErrors, setFormErrors] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });
  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let newValue = value;
    if (name === "phone") {
      newValue = value.replace(/[^0-9]/g, "");
    }
    setFormData({
      ...formData,
      [name]: profanityfn(ProfanityList,newValue),
    });
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  const submitContactForm = async (e) => {
    e.preventDefault();
    let errors = {};
    if (formData.name === "") {
      errors.name = Labels?.nameRequired;
    }
    if (formData.phone === "") {
      errors.phone =  Labels?.phoneNumberRequired;
    } else if (!/^\d+$/.test(formData.phone)) {
      errors.phone = Labels?.phoneNumberValidation;
    }
    if (formData.email === "") {
      errors.email =  Labels?.emailValidation;
    }
    if (formData.message === "") {
      errors.message = Labels?.Messageisrequired;
    }
    if (Object.keys(errors).length > 0) {
      // Set the form errors and display error messages
      setFormErrors(errors);
      return;
    }
    axiosAPI.post("/saveContactUs", formData)
      .then((response) => {
        const { status, message } = response.data;

        if (status === true) {
          setFormData({
            name: "",
            phone: "",
            email: "",
            message: "",
          });
          succesNotify(message);
          setTimeout(() => { redirect("/landingpage")}, 1000);
        } else {
          errorNotify(message);     
        }
      })
      .catch((error) => {
        errorNotify(error);
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <Container
      maxWidth="100%"
      sx={{ margin: "0 auto", padding: "0 !important" }}
    >
      <PageHeading pageHeading={Labels?.Contactus} />
      <Container maxWidth="xl">
        <Paper
          elevation={1}
          sx={{ margin: "50px 0 40px", padding: 0, boxShadow: "none" }}
        >
          <Grid container spacing={2} sx={{ padding: 0 }}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: 0,
              }}
            >
              <form
                // onSubmit={submitContactForm}
                style={{
                  padding: "15px 10px",
                  margin: { xs: 1, md: 3 },
                }}
              >
                <Typography
                  component="h6"
                  variant="h6"
                  color="inherit"
                  sx={{
                    color: "#2B4947",
                    fontWeight: 600,
                    margin: "10px auto",
                    textAlign: "center",
                    fontSize: 25,
                  }}
                >
                  {Labels?.ContactForm}
                </Typography>
                <InputWithLabel
                  style={{ width: "100%" }}
                  InputLabel={Labels?.name}
                  label={Labels?.name}
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  errorMsg={formErrors.name}
                />
                <InputWithLabel
                  style={{ width: "100%" }}
                  InputLabel={Labels?.Phone}
                  label={Labels?.Phone}
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  errorMsg={formErrors.phone}
                />

                <InputWithLabel
                  style={{ width: "100%" }}
                  InputLabel={Labels?.emailAddress}
                  label={Labels?.emailAddress}
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  errorMsg={formErrors.email}
                />

                <InputWithLabel
                  style={{ width: "100%" }}
                  InputLabel={Labels?.Messages}
                  label={Labels?.Messages}
                  type="text"
                  name="message"
                  multiline
                  rows={4}
                  value={formData.message}
                  onChange={handleInputChange}
                  errorMsg={formErrors.message}
                />

                <CustomButton
                  label={Labels?.Submit}
                  // type="submit"
                  style={{ margin: "auto", width: "100%" }}
                  onClick={submitContactForm}
                />
              </form>
            </Grid>
            <Grid item xs={12} md={6} sx={{ padding: "0 !important" }}>
              <Box
                sx={{
                  background: "#1C614E1C",
                  padding: 0,
                  height: "100%",
                  textAlign: "center",
                }}
                className="contact"
              >
                <Typography
                  component="h6"
                  variant="h6"
                  color="inherit"
                  sx={{
                    color: "#2B4947",
                    padding: 7,
                    fontWeight: 600,
                    fontSize: 25,
                  }}
                >
                  {Labels?.ContactInformation}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LocationOnIcon sx={{ fontSize: 25, margin: "0 10px" }} />
                  <p>
                    ABC Street, city
                    <br />
                    State, Country
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CallIcon sx={{ fontSize: 25, margin: "0 10px" }} />
                  <p>
                    <a href="tel:+48691620736">+48 69 162 0736</a>
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MarkunreadIcon sx={{ fontSize: 25, margin: "0 10px" }} />
                  <p>
                    <a href="mailto:abc@example.com">abc@example.com</a>
                  </p>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <Footer />
    </Container>
  );
}
export default Contact;
