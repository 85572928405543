import React, { useEffect, useState } from "react";

import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Paper,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
  Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  InputLabel,
  Modal,
  useMediaQuery,
  useTheme,
  TextField,
} from "@mui/material";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import EmployerSideMenu from "../EmployerComponents/EmployerSideMenu";
import TextfieldCustom from "../../Components/TextfieldCustom";
import CustomButton from "../../Components/CustomButton";
import { axiosAPI } from "../../Axios/Axios";
import { PUBLIC_IMAGE_FOLDER } from "../../Axios/Constant";
import { useNavigate } from "react-router-dom";
import Skills from "../../CommonCode/Skills";
import ExperienceLevel from "../../CommonCode/ExperienceLevel";
import { useSelector } from "react-redux";
import SkillsNew from "../../CommonCode/SkillsNew";
import QualificationSearch from "../../CommonCode/QualificationSearch";
import Country from "../../CommonCode/Country";
import State from "../../CommonCode/State";
import AllLanguageFilter from "../../CommonCode/AllLanguageFilter";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
  padding:"0px"
}));

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300, // Adjust the maximum height as needed
      width: 350,
      overflowX: "auto",
      margin: "5px",
    },
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  boxShadow: 24,
  padding: "10px",
  width: `50vw`,
  height: "auto",
};

export default function SearchJobSeekers() {
  const redirect = useNavigate();

  const theme = useTheme();
  const filterView = useMediaQuery(theme.breakpoints.down("md"));
  const Labels = useSelector((state) => state.allLabels.labels);

  const [open, setOpen] = useState(false);
  const [loading, setloading] = useState(true);

  const [jobSeekers, setJobSeekers] = useState([]);
  const [postedJobList, setPostedJobList] = useState([]);
  const [jobId, setJobId] = useState("");
  const [keyword, setKeyWord] = useState("");
  const [skills, setSkills] = useState([]);
  const [userExperienceLevel, setUserExperienceLevel] = useState("");
  const [educationhistory, setEducationhistory] = useState([]);
  const [language, setLanguage] = useState([]);

  const [country, setcountry] = useState(null);
  const [state, setState] = useState(null);
  const [city, setcity] = useState("");

  const [userchangedcountry, setuserchangedcountry] = useState(null);

  const searchJobSeekers = async () => {
    setloading(true);
    const skill = JSON.stringify(skills);
    const postData = {
      jobid: jobId,
      experienceLevel: userExperienceLevel,
      skill: skill.slice(1, skill.length - 1),
      search: keyword,

      education: educationhistory?.toString(),
      language: language?.toString(),
      country: country?.id,
      state: state?.id,
      city: city,
    };

    const result = await axiosAPI.post("/search", postData);
    const { message, data, status } = result.data;
    if (status) {
      setJobSeekers(data);
      setloading(false);
    } else {
      setJobSeekers([]);
      setloading(false);
    }
  };

  const getPostedJobList = async () => {
    const result = await axiosAPI.post("/listjobs");
    const { success, data, message } = result.data;
    if (success) {
      setPostedJobList(data.reverse());
    }
  };

  useEffect(() => {
    getPostedJobList();
    searchJobSeekers();
    window.scrollTo(0, 0);
  }, []);

  const handleClick = () => {
    setJobId("");
    setUserExperienceLevel("");
    setKeyWord("");
    setSkills([]);
    setJobSeekers({});
    setEducationhistory([]);
    setLanguage([])
    setcountry(null)
    setState(null)
    setcity('')
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [textvalue, settextvalue] = useState(Labels.Open);

  const [expanded1, setExpanded1] = useState(false); // expanding value for filters
  const [expanded2, setExpanded2] = useState(false); // expanding value for filters
  const [expanded3, setExpanded3] = useState(false); // expanding value for filters
  const [expanded4, setExpanded4] = useState(false); // expanding value for filters
  const [expanded5, setExpanded5] = useState(false); // expanding value for filters
  const [expanded6, setExpanded6] = useState(false); // expanding value for filters

  const handleExpandAll = () => {
    setExpanded1(true);
    setExpanded2(true);
    setExpanded3(true);
    setExpanded4(true);
    setExpanded5(true);
    setExpanded6(true);
    settextvalue(Labels.close);
  };

  const handleCloseAll = () => {
    setExpanded1(false);
    setExpanded2(false);
    setExpanded3(false);
    setExpanded4(false);
    setExpanded5(false);
    setExpanded6(false);
    settextvalue(Labels.Open);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            ...style,
            width: filterView ? "80vw" : "550px",
            maxHeight: "90vh",
            overflow: "none",
            borderRadius: "20px",
            display: "flex",
            flexDirection: "column", // Ensure it's a column layout for vertical alignment
            justifyContent: "space-between", // Add space between sections
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CloseIcon
                sx={{ float: "right", cursor: "pointer" }}
                onClick={() => {
                  setOpen(false);
                }}
              />

              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 600, color: "#1c614d" }}
              >
                <h2 style={{ textAlign: "center" }}> {Labels.AdvancedSearch} </h2>
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <Item>
                <p style={{ textAlign: "right" }}>
                  {textvalue == Labels.Open && (
                    <Button
                      variant="contained"
                      color="success"
                      sx={{
                        backgroundColor: "#1c614d",
                        "&:hover": {
                          backgroundColor: "#1c614d",
                        },
                        "&:active": {
                          backgroundColor: "#1c614d",
                        },
                      }}
                      onClick={handleExpandAll}
                    >
                      {textvalue} {Labels.AllFilters}
                    </Button>
                  )}
                  {textvalue == Labels.close && (
                    <Button
                      variant="contained"
                      color="success"
                      sx={{
                        backgroundColor: "#1c614d",
                        "&:hover": {
                          backgroundColor: "#1c614d",
                        },
                        "&:active": {
                          backgroundColor: "#1c614d",
                        },
                      }}
                      onClick={handleCloseAll}
                    >
                      {textvalue} {Labels.AllFilters}
                    </Button>
                  )}
                </p>
              </Item>
            </Grid>

            <Grid item xs={12}>
              <Item>
                <>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Item
                        sx={{
                          maxHeight: "350px",
                          overflowY: "auto",
                          padding: "10px",
                        }}
                      >
                        <Accordion
                          sx={{ boxShadow: "none" }}
                          expanded={expanded1}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            onClick={() => {
                              setExpanded1(!expanded1);
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: 600, color: "#1c614d" }}
                            >
                              {Labels.Jobs}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <FormControl sx={{ width: "100%" }}>
                              <Select
                                MenuProps={MenuProps}
                                value={jobId}
                                onChange={(e) => setJobId(e.target.value)}
                                variant="outlined"
                                displayEmpty
                                sx={{ background: "white" }}
                                inputProps={{ poppins: "Select a job" }}
                              >
                                <MenuItem value="" disabled>
                                  <span style={{ color: "#808080" }}>
                                    {" "}
                                    {Labels?.SelectaJob}
                                  </span>{" "}
                                </MenuItem>
                                {postedJobList &&
                                  postedJobList.map((val, index) => {
                                    return (
                                      <MenuItem key={index} value={val.eja_id}>
                                        {val.jobName}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </FormControl>
                          </AccordionDetails>
                        </Accordion>

                        <Accordion
                          sx={{ boxShadow: "none" }}
                          expanded={expanded2}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            onClick={() => {
                              setExpanded2(!expanded2);
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: 600, color: "#1c614d" }}
                            >
                              {Labels.skills}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <SkillsNew
                              style={{ background: "white" }}
                              value={skills}
                              setValue={setSkills}
                            />
                          </AccordionDetails>
                        </Accordion>

                        <Accordion
                          sx={{ boxShadow: "none" }}
                          expanded={expanded3}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            onClick={() => {
                              setExpanded3(!expanded3);
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: 600, color: "#1c614d" }}
                            >
                              {Labels.ExperienceLevel}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <ExperienceLevel
                              value={userExperienceLevel}
                              setValue={setUserExperienceLevel}
                              isEdit={false}
                              style={{
                                background: "white",
                              }}
                            />
                          </AccordionDetails>
                        </Accordion>

                        <Accordion
                          sx={{ boxShadow: "none" }}
                          expanded={expanded4}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            onClick={() => {
                              setExpanded4(!expanded4);
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: 600, color: "#1c614d" }}
                            >
                              {Labels.Education}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <QualificationSearch
                              value={educationhistory}
                              setValue={setEducationhistory}
                              style={{ width: "100%" }}
                            />
                          </AccordionDetails>
                        </Accordion>

                        <Accordion
                          sx={{ boxShadow: "none" }}
                          expanded={expanded5}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            onClick={() => {
                              setExpanded5(!expanded5);
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: 600, color: "#1c614d" }}
                            >
                              {Labels.Languages}
                            </Typography>
                          </AccordionSummary>

                          <AccordionDetails>
                            <AllLanguageFilter
                              value={language}
                              setValue={setLanguage}
                              label={Labels?.language}
                              // setFilterLanguage={setFilterLanguage}
                              // jobreqlang={jobRequiredLanguages}
                            />
                          </AccordionDetails>
                        </Accordion>

                        <Accordion
                          sx={{ boxShadow: "none" }}
                          expanded={expanded6}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            onClick={() => {
                              setExpanded6(!expanded6);
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: 600, color: "#1c614d" }}
                            >
                              {Labels.location}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6}>
                                <Box sx={{ m: "10px 5px" }}>
                                  <label>{Labels?.Country}</label>
                                  <Country
                                    value={country}
                                    setValue={setcountry}
                                    setStateId={setState}
                                    userchangedcountry={userchangedcountry}
                                    setuserchangedcountry={
                                      setuserchangedcountry
                                    }
                                    isEdit={false}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <Box sx={{ m: "0px 5px" }}>
                                  <label>{Labels?.State}</label>
                                  <State
                                    value={state}
                                    setValue={setState}
                                    country={country}
                                    userchangedcountry={userchangedcountry}
                                    setuserchangedcountry={
                                      setuserchangedcountry
                                    }
                                    isEdit={false}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <Box sx={{ m: "0px 5px" }}>
                                  <label>
                                    {Labels?.City}
                                  </label>
                                  <TextField
                                    name="city"
                                    value={city}
                                    onChange={(e) => setcity(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                    placeholder={Labels?.City}
                                    sx={{
                                      position: "relative",
                                      width: "100%",
                                      padding: "0px 15px",
                                      "& .MuiOutlinedInput-root": {
                                        borderRadius: "4px", // Adjust border radius if needed
                                        border: `2px solid black`,
                                        "& fieldset": {
                                          border: "none", // Remove the default border
                                        },
                                        "&:hover": {
                                          border: `2px solid black`,
                                        },
                                        "&.Mui-focused": {
                                          border: `2px solid black`,
                                        },
                                      },
                                    }}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </Item>
                    </Grid>
                  </Grid>
                </>
              </Item>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomButton
                label={Labels?.search}
                style={{
                  float: "right",
                  margin: "10px",
                  borderRadius: "10px",
                  background: "#1C614E",
                  color: "white",
                  border: "2px solid #1C614E",
                  "&:hover": {
                    backgroundColor: "#1C614E",
                    color: "white",
                    border: "2px solid #1C614E",
                  },
                  "&:active": {
                    backgroundColor: "#1C614E",
                    color: "white",
                    border: "2px solid #1C614E",
                  },
                  "&:disabled": { color: "white", backgroundColor: "#1C614E" },
                }}
                onClick={() => {
                  searchJobSeekers();
                  handleClose();
                }}
              />

              <CustomButton
                label={Labels?.Reset}
                style={{
                  float: "right",
                  margin: "10px",
                  borderRadius: "10px",
                  background: "white",
                  color: "#1C614E",
                  border: "2px solid #1C614E",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "#1C614E",
                    border: "2px solid #1C614E",
                  },
                  "&:active": {
                    backgroundColor: "white",
                    color: "#1C614E",
                    border: "2px solid #1C614E",
                  },
                  "&:disabled": { color: "white", backgroundColor: "#1C614E" },
                }}
                onClick={() => {
                  handleClick();
                  handleClose();
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Typography variant="h6" sx={{ color: "#1C614E", fontWeight: 600 }}>
        {Labels?.SearchCandidates}
      </Typography>

      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems="center"
        spacing={2}
        mb={1}
        width={{ xs: `100%`, md: "100%", lg: `70%`, xl: `80%` }}
      >
        <Button
          variant="contained"
          onClick={handleOpen}
          sx={{
            background: "#1C614E",
            color: "white",
            border: "2px solid #1C614E",
            "&:hover": {
              background: "#1C614E",
              color: "white",
            },
            "&:active": {
              background: "#1C614E",
              color: "white",
            },
            "&:disabled": { color: "white", backgroundColor: "#1C614E" },
          }}
        >
          {/* Open Advanced Filter */}
          {/* {Labels.Open} {Labels.AdvancedSearch} */}
          <FilterAltIcon/>
            
        </Button>

        <Stack>
          <TextfieldCustom
            value={keyword}
            label={Labels?.searchJobSeeker}
            style={{
              borderRadius: "10px",
            }}
            onChange={(e) => setKeyWord(e.target.value)}
          />
        </Stack>

        <CustomButton
          label={Labels?.search}
          style={{ borderRadius: 1 }}
          onClick={searchJobSeekers}
        />
        <CustomButton
          label={Labels?.Reset}
          style={{
            borderRadius: "10px",
            background: "white",
            color: "#1C614E",
            border: "2px solid #1C614E",
            "&:hover": {
              backgroundColor: "white",
              color: "#1C614E",
              border: "2px solid #1C614E",
            },
            "&:active": {
              backgroundColor: "white",
              color: "#1C614E",
              border: "2px solid #1C614E",
            },
            "&:disabled": { color: "white", backgroundColor: "#1C614E" },
          }}
          onClick={() => {
            handleClick();
          }}
        />
      </Stack>

      <Grid container spacing={2}>
        {loading ? (
          <>
            <Grid container spacing={2} sx={{ margin: "10px auto 0px" }}>
              <Grid item xs={12} sm={6} lg={4} xl={4}>
                <Item>
                  <Skeleton
                    variant="rounded"
                    width={`95%`}
                    height={70}
                    style={{ margin: "0px auto" }}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} xl={4}>
                <Item>
                  <Skeleton
                    variant="rounded"
                    width={`95%`}
                    height={70}
                    style={{ margin: "0px auto" }}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} xl={4}>
                <Item>
                  <Skeleton
                    variant="rounded"
                    width={`95%`}
                    height={70}
                    style={{ margin: "0px auto" }}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} xl={4}>
                <Item>
                  <Skeleton
                    variant="rounded"
                    width={`95%`}
                    height={70}
                    style={{ margin: "0px auto" }}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} xl={4}>
                <Item>
                  <Skeleton
                    variant="rounded"
                    width={`95%`}
                    height={70}
                    style={{ margin: "0px auto" }}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} xl={4}>
                <Item>
                  <Skeleton
                    variant="rounded"
                    width={`95%`}
                    height={70}
                    style={{ margin: "0px auto" }}
                  />
                </Item>
              </Grid>
            </Grid>
            
          </>
        ) : jobSeekers.length === 0 ? (
          <Stack direction="row" justifyContent="center">
            <Typography
              sx={{ p: "15px", color: "#6a6a6a", mt: 1 }}
              variant="h6"
            >
              {Labels?.Jobseekersnotfound}
            </Typography>
          </Stack>
        ) : (
          Object.values(jobSeekers).map((jobseeker) => (
            <Grid item xs={12} sm={6} lg={4} xl={4}>
              <Card
                key={jobseeker.jsp_id}
                sx={{
                  p: 1,
                  background: "#FFFFFF",
                  border: "1px solid rgba(0, 0, 0, 0.1)",
                  borderRadius: 2,
                  cursor: "pointer",
                  boxShadow: "none",
                }}
                onClick={() =>
                  redirect(`/jobSeekerProfileView/${jobseeker.userid}`)
                }
              >
                <Stack direction="row" spacing={1}>
                  {jobseeker?.profilePicPath?.endsWith("/") ? null : (
                    <Avatar
                      src={`${jobseeker?.profilePicPath}`}
                      sx={{
                        width: 50,
                        height: 50,
                        borderRadius: "100%",
                      }}
                    />
                  )}

                  <Box>
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                      {jobseeker?.displayName}
                    </Typography>
                    <Typography variant="body2">{jobseeker?.city}</Typography>
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{ my: 1 }}
                >
                  <Typography variant="body2">
                    {jobseeker.experience ? "Seniority Level" : null}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#686868cc" }}>
                    {jobseeker.experience ? ":" : ""}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#686868cc" }}>
                    {jobseeker.experience ? jobseeker.experience : null}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="flex-end">
                  <Button
                    variant="outlined"
                    sx={{
                      textTransform: "capitalize",
                      color: "#2B4947",
                      border: "1px solid #e0e0e0",
                    }}
                    // onClick={() => getJobSeekerData(jobseeker.jobseekerid)}
                  >
                    {Labels?.View}
                  </Button>
                </Stack>
              </Card>
            </Grid>
          ))
        )}
      </Grid>
    </>
  );
}
