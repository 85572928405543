import {
  Avatar,
  Box,
  Card,
  Grid,
  Paper,
  Stack,
  Typography,
  Container,
  Skeleton,
  Chip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  tooltipClasses,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  CardContent,
  Alert,
} from "@mui/material";

import { IconButton } from "@mui/material";
import { Facebook, LinkedIn, Instagram } from "@mui/icons-material";
import EmailIcon from "@mui/icons-material/Email";

import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import dummyLogo from "../../Images/dummyLogo.png";
import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import ShareIcon from "@mui/icons-material/Share";
import PlaceIcon from "@mui/icons-material/Place";
import CustomButton from "../Components/CustomButton";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import JobSerachSideGrid from "./JobSerachSideGrid";
import { axiosAPI } from "../Axios/Axios";
import { useState } from "react";
import {
  parseISO,
  differenceInDays,
  differenceInHours,
  differenceInMonths,
  isSameDay,
} from "date-fns";
import { PUBLIC_IMAGE_FOLDER } from "../Axios/Constant";
import {
  errorNotify,
  infoNotify,
  succesNotify,
  useStyles,
  warningNotify,
} from "../CommonCode/Commonfunc";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useSelector } from "react-redux";
import SalaryCalculationTable from "../Jobseeker/JobSearchPostLogin/CalculationTable";
// import JobInfo from "../CommonCode/JobInformation";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: "15px",
  },
}));

const JobDetailedview = ({ jobId }) => {
  const redirect = useNavigate();
  const authtoken = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");
  const location = useLocation();
  const { id } = useParams();
  const classes = useStyles();
  const [jobDetails, setJobinfo] = useState({});
  const [dynamicFields, setDynamicFields] = useState([]);
  const Labels = useSelector((state) => state.allLabels.labels);
  const [showSkeleton, setshowSkeleton] = useState(true);

  const [hasEmptyAnswer, setHasEmptyAnswer] = useState(false);

  useEffect(() => {
    if (jobDetails && Object.keys(jobDetails).length != 0) {
      jobDetails?.Jobinformations.forEach((info) => {
        if (info.key === "others") {
          if (info?.value) {
            setDynamicFields(JSON.parse(info?.value));
            const containsEmptyAnswer = JSON.parse(info?.value).every(
              (field) => field.answer == "<p><br></p>"
            );
            setHasEmptyAnswer(containsEmptyAnswer);
          } else {
            setDynamicFields([]);
          }
        }
      });
    }
  }, [jobDetails]);

  //---------Get API For job details------------
  const getjobdetails = async () => {
    const result = await axiosAPI.post(
      `/getJobInfo?jobid=${jobId ? jobId : id}`
    );
    const { success, data } = result.data;
    if (success === true) {
      setJobinfo(data);
      setshowSkeleton(false);
    } else {
      setshowSkeleton(false);
    }
  };

  useEffect(() => {
    getjobdetails();
  }, [jobId]);

  // ---------Apply jobs ------------

  const ApplyJob = async () => {
    if (authtoken) {
      const postData = {
        jobid: id,
        jobseekerid: uid,
      };
      try {
        const result = await axiosAPI.post("/applyjob", postData);
        const { success, data } = result.data;

        if (success === true) {
          succesNotify(Labels.jobAppliedSuccess);
        } else {
        }
      } catch (error) {
        infoNotify(Labels.applyMessage);
        setTimeout(() => {
          redirect("/login");
        }, 1000);
      }
    } else {
      infoNotify(Labels.applyMessage);
      setTimeout(() => {
        redirect("/login");
      }, 1000);
    }
  };

  const daysRemaining = jobDetails?.remainDays;

  const handlecompanynameClick = (employerId) => {
    redirect(`/company/${employerId}`);
  };

  const handleLinkClick = () => {
    const email = ""; // Replace with the recipient's email address
    const subject = "Job Application";
    const body = `Job Link : https://50plus.zone/jobSearchPrelogin/${
      jobId ? jobId : id
    }`;

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  const [open, setOpen] = useState(false);

  const jobLink = "https://example.com/job-posting"; // Replace with your actual job link
  const jobMessage = "Check out this amazing job opportunity!";

  const handleShare = (platform) => {
    switch (platform) {
      case "facebook":
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            jobLink
          )}&quote=${encodeURIComponent(jobMessage)}`,
          "_blank"
        );
        break;

      case "linkedin":
        window.open(
          `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
            jobLink
          )}&title=${encodeURIComponent(
            jobMessage
          )}&summary=${encodeURIComponent(
            "Apply now for this exciting role."
          )}&source=${encodeURIComponent("YourCompany")}`,
          "_blank"
        );
        break;

      case "instagram":
        window.open(`https://www.instagram.com/`, "_blank");
        alert(
          "Instagram does not support direct posting from web. Open Instagram to post manually."
        );
        break;

      case "email":
        handleLinkClick();
        break;

      default:
        break;
    }
  };

  const allValuesEmpty = jobDetails?.Jobinformations?.every(
    (obj) => !obj.value.trim() || obj.value === "[]"
  );

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <Typography variant="h6">Share Job Posting</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Help us spread the word about this job opening! Share on:
          </Typography>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "20px" }}
          >
            <IconButton
              onClick={() => handleShare("facebook")}
              color="primary"
              aria-label="Share on Facebook"
            >
              <Facebook fontSize="large" />
            </IconButton>

            <IconButton
              onClick={() => handleShare("linkedin")}
              color="primary"
              aria-label="Share on LinkedIn"
            >
              <LinkedIn fontSize="large" />
            </IconButton>
            {/* <IconButton
          onClick={() => handleShare("instagram")}
          color="secondary"
          aria-label="Share on Instagram"
        >
          <Instagram fontSize="large" />
        </IconButton> */}
            <IconButton
              onClick={() => handleShare("email")}
              color="primary"
              aria-label="Share on Facebook"
            >
              <EmailIcon fontSize="large" />
            </IconButton>
          </div>
        </DialogContent>
      </Dialog>

      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        sx={{
          background: "white",
          padding: "20px 10px",
          marginTop:"5px",
          position: "relative",
          height: "85vh",
          overflowY: "auto",
          borderRadius:"20px"
        }}
      >
        {showSkeleton ? (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* Profile Picture Skeleton */}
              <Skeleton variant="circular" width={230} height={190} />
              <Skeleton
                variant="rectangular"
                width={`100%`}
                height={100}
                
                style={{ marginLeft: 16 }}
              />
            </div>
            <br />
            <Skeleton variant="rectangular" width={"100%"} height={150} />
            <br />
            <Skeleton variant="rectangular" width={"100%"} height={150} />
          </>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                mb={1}
                spacing={4}
              >
                <Stack direction="row">
                  <ShareIcon
                    // onClick={handleLinkClick}
                    onClick={() => setOpen(true)}
                    sx={{
                      color: "#2B4947",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#C0C0C0",
                      },

                      width: 30,
                      height: 30,
                    }}
                  />
                </Stack>
              </Stack>
            </Grid>
            {/* Logo Grid */}

            <Grid item xs={12} sm={12} md={12} lg={12} xl={3}>
              <Box
                className={classes.box30}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "top",
                  height: "100%",
                }}
                style={{
                  userSelect: "none",
                  filter: "blur(5px) grayscale(100%)",
                }}
              >
                {/* <Avatar
                  // src={jobDetails.companyLogo ? `${jobDetails.companyLogo}` : null }
                  style={{ width: 150, height: 160 }}
                >
                  {jobDetails.companyLogo ? null : (
                    <>
                      {jobDetails?.jobName
                        ? jobDetails.jobName
                            .split(" ")
                            .map((word) => word[0].toUpperCase())
                            .join("")
                        : null}
                    </>
                  )}
                </Avatar> */}
                <Tooltip title={Labels.logintoseeimg}>
                  <Avatar
                    style={{ width: 150, height: 160 }}
                    sx={{
                      backgroundColor: "grey", // Set the background color to grey
                      filter: "none", // Remove any existing filter effects
                      userSelect: "none", // Disable text selection
                    }}
                  />
                </Tooltip>
              </Box>
            </Grid>

            {/* Card Grid */}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={9}>
              <Box className={classes.box70}>
                <Typography
                  variant="h5"
                  sx={{ color: "#2B4947", fontWeight: 600 }}
                >
                  {jobDetails?.jobName}
                </Typography>
                <Tooltip title={Labels.logintoseeimg}>
                  <Typography
                    variant="subtilte1"
                    sx={{ fontSize: "1.2rem", cursor: "pointer" }}
                    style={{ userSelect: "none", filter: "blur(4px)" }}
                    // onClick={() => handlecompanynameClick(jobDetails?.employerid)}
                  >
                    {jobDetails?.employer}
                    {/* {"*".repeat((jobDetails?.employer || "").length)} */}
                  </Typography>
                </Tooltip>
                <Tooltip title={Labels.logintoseeimg}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "1.2rem",
                      color: "#1C614E",
                      margin: 0,
                    }}
                    style={{ userSelect: "none", filter: "blur(4px)" }}
                  >
                    {jobDetails?.companyAddress}
                    {jobDetails?.companyAddress &&
                    !jobDetails.companyAddress.endsWith(",")
                      ? ", "
                      : " "}
                    {jobDetails?.country}
                    &nbsp;
                  </Typography>
                </Tooltip>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "1.1rem", color: "#808080" }}
                >
                  {jobDetails?.createdOn} - {jobDetails?.jobseekers}{" "}
                  {jobDetails?.jobseekers == 1
                    ? Labels?.Applicant
                    : Labels?.Applicants}
                  <br />
                  {daysRemaining > 0 ? (
                    <span
                      style={{
                        color:
                          daysRemaining && daysRemaining < 5
                            ? "red"
                            : "#808080",
                      }}
                    >
                      {daysRemaining}{" "}
                      {daysRemaining == 1
                        ? Labels.dayRemaining
                        : Labels.daysRemaining}
                    </span>
                  ) : (
                    <span style={{ color: "red" }}>{Labels.expired} </span>
                  )}
                </Typography>
              </Box>
              <Box className={classes.box70}>
                <Stack direction="row" spacing={1}>
                  {/* Details Chips */}
                  <Grid container direction="row">
                    <Grid item xs={4} direction="column">
                      {jobDetails?.officePresenceName && (
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          mx={1}
                        >
                          <LightTooltip
                            title={Labels.officePresencetooltip}
                            arrow
                          >
                            <Chip
                              // key={index}
                              label={jobDetails?.officePresenceName}
                              size="medium"
                              sx={{
                                color: "#BA8C04",
                                background: "#FFFFFF",
                                borderRadius: 1,
                                width: "100%",
                                fontSize: "1.1rem",
                                margin: "10px 12px 10px 0",
                                border: "1px solid #BA8C04",
                              }}
                            />
                          </LightTooltip>
                        </Stack>
                      )}

                      {jobDetails.officePercentage != null && (
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          mx={1}
                        >
                          <LightTooltip
                            title={Labels.officePercentagetooltip}
                            arrow
                          >
                            <Chip
                              key={""}
                              label={
                                jobDetails.officePercentage == 100
                                  ? `${jobDetails.officePercentage}%`
                                  : jobDetails.officePercentage == 0
                                  ? `${jobDetails.remotePercentage}%`
                                  : jobDetails.officePercentage > 0 &&
                                    jobDetails.officePercentage < 100
                                  ? `${jobDetails.officePercentage}% (${Labels.Office})`
                                  : null
                              }
                              size="medium"
                              sx={{
                                color: "#BA8C04",
                                background: "#FFFFFF",
                                borderRadius: 1,
                                width: "100%",
                                fontSize: "1.1rem",
                                margin: "10px 12px 10px 0",
                                border: "1px solid #BA8C04",
                              }}
                            />
                          </LightTooltip>
                        </Stack>
                      )}
                    </Grid>

                    <Grid item xs={4} direction="column">
                      {jobDetails?.workingHours != null &&
                      jobDetails?.hourPeriod != null ? (
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          mx={1}
                        >
                          <LightTooltip
                            title={Labels.workingHourstooltip}
                            arrow
                          >
                            <Chip
                              key={""}
                              label={`${
                                jobDetails?.salaryCalucateitem
                                  ? JSON.parse(jobDetails.salaryCalucateitem)
                                      ?.hoursPerDay
                                  : jobDetails?.workingHours || ""
                              } ${
                                (jobDetails?.salaryCalucateitem
                                  ? JSON.parse(jobDetails.salaryCalucateitem)
                                      ?.hoursPerDay
                                  : jobDetails?.workingHours || "") === 1
                                  ? "hr"
                                  : "hrs"
                              }/${jobDetails?.hourPeriod || ""}`}
                              size="medium"
                              sx={{
                                color: "#BA8C04",
                                background: "#FFFFFF",
                                borderRadius: 1,
                                width: "100%",
                                fontSize: "1.1rem",
                                margin: "10px 12px 10px 0",
                                border: "1px solid #BA8C04",
                              }}
                            />
                          </LightTooltip>
                        </Stack>
                      ) : null}

                      {jobDetails?.salaryMax || jobDetails?.salary ? (
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          mx={1}
                        >
                          <LightTooltip
                            title={`
                            ${
                              jobDetails?.salaryMax
                                ? jobDetails?.salaryMax
                                    ?.toString()
                                    .replace(/[^0-9]/g, "")
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                : jobDetails?.salary
                                ? jobDetails?.salary
                                    ?.toString()
                                    .replace(/[^0-9]/g, "")
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                : ""
                            } ${
                              jobDetails?.salaryMax || jobDetails?.salary
                                ? jobDetails?.currencySymbol +
                                  (jobDetails?.salaryPeriodName
                                    ? `/ ${jobDetails?.salaryPeriodName}`
                                    : "")
                                : ""
                            }`}
                            arrow
                          >
                            <Chip
                              key=""
                              label={`
                              ${
                                jobDetails?.salaryMax
                                  ? jobDetails?.salaryMax
                                      ?.toString()
                                      .replace(/[^0-9]/g, "")
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  : jobDetails?.salary
                                  ? jobDetails?.salary
                                      ?.toString()
                                      .replace(/[^0-9]/g, "")
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  : ""
                              } ${
                                jobDetails?.salaryMax || jobDetails?.salary
                                  ? jobDetails?.currencySymbol +
                                    (jobDetails?.salaryPeriodName
                                      ? `/ ${jobDetails?.salaryPeriodName}`
                                      : "")
                                  : ""
                              }`}
                              size="medium"
                              sx={{
                                color: "#BA8C04",
                                background: "#FFFFFF",
                                borderRadius: 1,
                                width: "100%",
                                fontSize: "1.1rem",
                                margin: "10px 12px 10px 0",
                                border: "1px solid #BA8C04",
                              }}
                            />
                          </LightTooltip>
                        </Stack>
                      ) : null}
                    </Grid>

                    <Grid item xs={4} direction="column">
                      {jobDetails.jobTypes ? (
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          mx={1}
                          //   my={2}
                        >
                          {jobDetails?.jobTypeName
                            ?.split(",")
                            ?.map((jobType, i) => (
                              <LightTooltip
                                title={Labels.jobtitletooltip}
                                arrow
                                key={jobType}
                              >
                                <Chip
                                  key={jobType}
                                  label={jobType}
                                  size="medium"
                                  sx={{
                                    color: "#BA8C04",
                                    background: "#FFFFFF",
                                    borderRadius: 1,
                                    width: "100%",
                                    fontSize: "1.1rem",
                                    margin: "10px 12px 10px 0",
                                    border: "1px solid #BA8C04",
                                  }}
                                />
                              </LightTooltip>
                            ))
                            .slice(0, 2)}
                        </Stack>
                      ) : null}
                      {jobDetails?.typePercentage && jobDetails.jobTypes ? (
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          mx={1}
                        >
                          <LightTooltip
                            title={Labels.typepercentagetooltip}
                            arrow
                          >
                            <Chip
                              key={""}
                              label={`${jobDetails?.typePercentage}0%`}
                              size="medium"
                              sx={{
                                color: "#BA8C04",
                                background: "#FFFFFF",
                                borderRadius: 1,
                                width: "100%",
                                fontSize: "1.1rem",
                                margin: "10px 12px 10px 0",
                                border: "1px solid #BA8C04",
                              }}
                            />
                          </LightTooltip>
                        </Stack>
                      ) : null}
                    </Grid>
                  </Grid>
                </Stack>
              </Box>
            </Grid>

            {/* Content Grid */}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ margin: 4 }}
            >
              <Box className={classes.box70}>
                {/* Level,travelling, employee count etc.. */}
                <Stack>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {jobDetails?.withinCountry == 1 ? (
                        <Chip
                          label={Labels.Withinsamecountry}
                          style={{ border: "2px solid #2B4947", margin: "5px" }}
                          variant="outlined"
                        />
                      ) : null}

                      {jobDetails?.withinContinents == 1 ? (
                        <Chip
                          label={Labels.WithinsameContinent}
                          style={{
                            border: "2px solid #2B4947",
                            margin: "5px",
                          }}
                          variant="outlined"
                        />
                      ) : null}

                      {jobDetails?.jobRadious ? (
                        <Chip
                          label={`${Labels.Withinaradius} of ${jobDetails?.jobRadious} km`}
                          style={{
                            border: "2px solid #2B4947",
                            margin: "5px",
                          }}
                          variant="outlined"
                        />
                      ) : null}
                    </Grid>

                    {jobDetails?.salaryCalucateitem?.length > 0 && (
                      <SalaryCalculationTable jobDetails={jobDetails} />
                    )}

                    {/* seniorityLevel */}
                    {jobDetails?.seniorityLevel ? (
                      <>
                        <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                          <Typography sx={{ fontWeight: "600" }}>
                            {" "}
                            {Labels.ExperienceLevel}{" "}
                          </Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                          <Typography>:</Typography>
                        </Grid>
                        <Grid item xs={7} sm={7} md={7} lg={8} xl={9}>
                          <Typography>{jobDetails?.seniorityLevel}</Typography>
                        </Grid>
                      </>
                    ) : null}

                    {/* Travel and travelPercentage */}
                    {jobDetails?.travelPercentage ? (
                      <>
                        <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                          <Typography sx={{ fontWeight: "600" }}>
                            {" "}
                            {Labels.Travelling}{" "}
                          </Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                          <Typography>:</Typography>
                        </Grid>
                        <Grid item xs={7} sm={7} md={7} lg={8} xl={9}>
                          <Typography>
                            {jobDetails?.travelPercentage} %
                          </Typography>
                        </Grid>
                      </>
                    ) : null}

                    {/*companySize */}
                    {jobDetails?.companySize?.length ? (
                      <>
                        <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                          <Typography sx={{ fontWeight: "600" }}>
                            {" "}
                            {Labels?.Employees}{" "}
                          </Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                          <Typography>:</Typography>
                        </Grid>
                        <Grid item xs={7} sm={7} md={7} lg={8} xl={9}>
                          <Typography>
                            {jobDetails?.companySize
                              ? jobDetails.companySize
                                  ?.split("-")
                                  ?.map((part) =>
                                    part
                                      ?.toString()
                                      ?.replace(/[^0-9]/g, "")
                                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  )
                                  .join(" - ")
                              : ""}
                          </Typography>
                        </Grid>
                      </>
                    ) : null}

                    {/*qualifications*/}
                    {jobDetails?.qualifications?.length ? (
                      <>
                        <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                          <Typography sx={{ fontWeight: "600" }}>
                            {" "}
                            {Labels.Required}{" "}
                          </Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                          <Typography>:</Typography>
                        </Grid>
                        <Grid item xs={7} sm={7} md={7} lg={8} xl={9}>
                          <Typography fontSize="1.2rem">
                            {jobDetails.qualifications &&
                              Array.isArray(jobDetails.qualifications) &&
                              jobDetails.qualifications.map((data, index) => (
                                <>
                                  {" "}
                                  {data.educationName}
                                  {index !==
                                    jobDetails.qualifications.length - 1 && ","}
                                </>
                              ))}
                          </Typography>
                        </Grid>
                      </>
                    ) : null}

                    {/* Additional Qualifications */}
                    {jobDetails?.additionalQualification && (
                      <>
                        <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                          <Typography sx={{ fontWeight: "600" }}>
                            {" "}
                            {Labels?.Nicetohave}{" "}
                          </Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                          <Typography>:</Typography>
                        </Grid>
                        <Grid item xs={7} sm={7} md={7} lg={8} xl={9}>
                          <Typography>
                            {jobDetails.additionalQualification}
                          </Typography>
                        </Grid>
                      </>
                    )}
                    {/* Languages Required */}
                    {jobDetails?.languages &&
                      jobDetails?.languages?.length > 0 && (
                        <>
                          <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                            <Typography sx={{ fontWeight: "600" }}>
                              {" "}
                              {Labels.languageRequired}{" "}
                            </Typography>
                          </Grid>
                          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                            <Typography>:</Typography>
                          </Grid>
                          <Grid item xs={12} sm={7} md={7} lg={8} xl={9}>
                            {/* Languages Required */}
                            {jobDetails?.languages && (
                              <Grid item xs={12}>
                                <TableContainer
                                  component={Paper}
                                  sx={{ boxShadow: "none", border: "none" }}
                                >
                                  <Table
                                    aria-label="language requirement table"
                                    sx={{ border: "none" }}
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            p: 0.2, // Reduced padding
                                            fontWeight: "bold",
                                            width: "230px",
                                            border: "none", // Remove cell borders
                                          }}
                                        >
                                          {Labels.language}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            p: 0.2, // Reduced padding
                                            fontWeight: "bold",
                                            width: "200px",
                                            border: "none", // Remove cell borders
                                          }}
                                        >
                                          {Labels.Spoken}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            p: 0.2, // Reduced padding
                                            fontWeight: "bold",
                                            border: "none", // Remove cell borders
                                          }}
                                        >
                                          {Labels.Written}
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {jobDetails?.languages?.map(
                                        (lang, index) => (
                                          <TableRow
                                            key={index}
                                            sx={{ border: "none" }} // Remove row borders
                                          >
                                            <TableCell
                                              sx={{ p: 0.2, border: "none" }}
                                            >
                                              {lang.language}
                                            </TableCell>
                                            <TableCell
                                              sx={{ p: 0.2, border: "none" }}
                                            >
                                              {lang.spoken}
                                            </TableCell>
                                            <TableCell
                                              sx={{ p: 0.2, border: "none" }}
                                            >
                                              {lang.written}
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Grid>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <Alert severity="info">{Labels.CEFR}</Alert>
                          </Grid>
                        </>
                      )}

                    {/* Additional Language */}
                    <>
                      {jobDetails.additionalLanguage &&
                      jobDetails.additionalLanguage.length > 0 ? (
                        <>
                          <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                            <Typography sx={{ fontWeight: "600" }}>
                              {Labels.AdditionalLanguages}{" "}
                            </Typography>
                          </Grid>
                          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                            <Typography>:</Typography>
                          </Grid>
                          <Grid item xs={7} sm={7} md={7} lg={8} xl={9}>
                            <Typography>
                              {Array.isArray(jobDetails.additionalLanguage) &&
                                jobDetails.additionalLanguage.map(
                                  (data, index) => (
                                    <span>
                                      {data.language1}
                                      {index !==
                                        jobDetails?.additionalLanguage.length -
                                          1 && ", "}
                                    </span>
                                  )
                                )}
                            </Typography>
                          </Grid>
                        </>
                      ) : null}
                    </>
                    {/* Category Section */}
                    {jobDetails?.jobCategoryName ? (
                      <>
                        <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                          <Typography sx={{ fontWeight: "600" }}>
                            {" "}
                            {Labels?.categories}{" "}
                          </Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                          <Typography>:</Typography>
                        </Grid>
                        <Grid item xs={7} sm={7} md={7} lg={8} xl={9}>
                          <Chip
                            label={jobDetails?.jobCategoryName}
                            size="medium"
                            sx={{
                              color: "#BA8C04",
                              background: "#FFFFFF",
                              borderRadius: 1,
                              minWidth: 120,
                              fontSize: "1.1rem",
                              border: "1px solid #BA8C04",
                            }}
                          />
                        </Grid>
                      </>
                    ) : null}

                    {/* Skills Required Section */}
                    {jobDetails?.jobSkills?.length ? (
                      <>
                        <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                          <Typography sx={{ fontWeight: "600" }}>
                            {" "}
                            {Labels?.ExpectedSkills}{" "}
                          </Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                          <Typography>:</Typography>
                        </Grid>
                        <Grid item xs={7} sm={7} md={7} lg={8} xl={9}>
                          {jobDetails?.jobSkills?.length
                            ? jobDetails.jobSkills.map((skill) => (
                                <Chip
                                  key={skill.id}
                                  label={skill?.skillName}
                                  size="medium"
                                  sx={{
                                    color: "#BA8C04",
                                    background: "#FFFFFF",
                                    borderRadius: 1,
                                    minWidth: 120,
                                    fontSize: "1.1rem",
                                    margin: "0px 12px 10px 0",
                                    border: "1px solid #BA8C04",
                                  }}
                                />
                              ))
                            : null}
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                </Stack>
              </Box>

              <Box className={classes.box70}>
                {/* --------------------------------who is hiring-------------------------- */}
                {jobDetails?.showProfile && jobDetails.recruiterName != null ? (
                  <Stack>
                    <Box
                      sx={{
                        margin: "15px 10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 600,
                        }}
                      >
                        {Labels?.WhoisHiring}
                      </Typography>
                    </Box>

                    <Stack direction="row">
                      <Box
                        sx={{
                          minHeight: "80px",
                          maxHeight: "80px",
                          overflowY: "hidden",
                          margin: "10px",
                        }}
                      >
                        <Avatar
                          src={
                            jobDetails.recruiterLogo
                              ? `${jobDetails.recruiterLogo}`
                              : null
                          }
                          alt=" "
                          style={{ width: 70, height: 70, margin: "10px" }}
                        />
                      </Box>

                      <Box
                        sx={{
                          minHeight: "80px",
                          maxHeight: "80px",
                          overflowY: "hidden",
                          margin: "15px",
                        }}
                      >
                        {jobDetails?.recruiterName != null ? (
                          <Typography
                            variant="subtitle1"
                            sx={{
                              color: "#2B4947",
                              fontWeight: 600,
                              // color: jobId === val.eja_id ? "#fff" : "#000",
                            }}
                          >
                            {jobDetails?.recruiterName?.length > 20
                              ? jobDetails?.recruiterName.slice(0, 20) + "..."
                              : jobDetails?.recruiterName}
                          </Typography>
                        ) : null}
                        {jobDetails.designation != null ? (
                          <Typography
                            variant="body1"
                            sx={{
                              color: "#2B4947",
                              // fontWeight: 600,
                              // color: jobId === val.eja_id ? "#fff" : "#000",
                            }}
                          >
                            {jobDetails?.designation?.length > 20
                              ? jobDetails?.designation.slice(0, 20) + "..."
                              : jobDetails?.designation}
                          </Typography>
                        ) : null}
                      </Box>
                    </Stack>
                  </Stack>
                ) : null}

                {/* -------------------- Job Description -------------------- */}
                {jobDetails?.jobdescription ? (
                  <Box sx={{ margin: "15px 10px" }}>
                    {/* <Typography
                   sx={{ margin: "15px auto 0px", fontWeight: 500 }}
                   variant="h6"
                 >
                   {Labels.JobDescription}
                 </Typography> */}

                    <Typography
                      sx={{ margin: "15px auto 0px", fontWeight: 500 }}
                      variant="subtitle1"
                    >
                      {jobDetails?.jobdescription ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: jobDetails?.jobdescription,
                          }}
                        />
                      ) : null}
                    </Typography>
                  </Box>
                ) : (
                  ""
                )}

                <Box sx={{ margin: "15px 10px" }}>
                  {jobDetails?.Jobinformations?.length && !hasEmptyAnswer ? (
                    <Grid container spacing={2}>
                      {/* Job Description Header */}
                      {!allValuesEmpty && (
                        <Grid item xs={12}>
                          <Typography variant="h5" sx={{ fontWeight: 600 }}>
                            {Labels?.jobDescription}
                          </Typography>
                        </Grid>
                      )}
                      {/* Job Details */}
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          {/* Job Details Items */}
                          {jobDetails?.whatYouWillDo && (
                            <Grid item xs={12} md={12} lg={12} xl={12}>
                              <Card variant="outlined" sx={{ p: 1 }}>
                                <Typography
                                  sx={{ fontWeight: 500 }}
                                  variant="subtitle1"
                                >
                                  {Labels.Whatyouwilldo}
                                </Typography>
                                <Typography variant="body2">
                                  {jobDetails.whatYouWillDo}
                                </Typography>
                              </Card>
                            </Grid>
                          )}

                          {jobDetails?.whoWeWouldLikeToHave && (
                            <Grid item xs={12} md={12} lg={12} xl={12}>
                              <Card variant="outlined" sx={{ p: 1 }}>
                                <Typography
                                  sx={{ fontWeight: 500 }}
                                  variant="subtitle1"
                                >
                                  {Labels.WhoWeWouldLikeToHave}
                                </Typography>
                                <Typography variant="body2">
                                  {jobDetails.whoWeWouldLikeToHave}
                                </Typography>
                              </Card>
                            </Grid>
                          )}

                          {jobDetails?.weWorkWith && (
                            <Grid item xs={12} md={12} lg={12} xl={12}>
                              <Card variant="outlined" sx={{ p: 1 }}>
                                <Typography
                                  sx={{ fontWeight: 500 }}
                                  variant="subtitle1"
                                >
                                  {Labels.WeWorkWith}
                                </Typography>
                                <Typography variant="body2">
                                  {jobDetails.weWorkWith}
                                </Typography>
                              </Card>
                            </Grid>
                          )}

                          {jobDetails?.WouldBeAdvantageToHave && (
                            <Grid item xs={12} md={12} lg={12} xl={12}>
                              <Card variant="outlined" sx={{ p: 1 }}>
                                <Typography
                                  sx={{ fontWeight: 500 }}
                                  variant="subtitle1"
                                >
                                  {Labels.WouldBeAdvantageToHave}
                                </Typography>
                                <Typography variant="body2">
                                  {jobDetails.WouldBeAdvantageToHave}
                                </Typography>
                              </Card>
                            </Grid>
                          )}

                          {/* Dynamic Fields */}
                          {dynamicFields?.length
                            ? dynamicFields?.map(
                                (item, index) =>
                                  item?.answer != "<p><br></p>" && (
                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      lg={12}
                                      xl={12}
                                      key={index}
                                    >
                                      <Card variant="outlined" sx={{ p: 1 }}>
                                        <Typography
                                          sx={{ fontWeight: 500 }}
                                          variant="subtitle1"
                                        >
                                          {/* {item?.question} */}
                                          {item?.question ? (
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: item?.question,
                                              }}
                                            />
                                          ) : null}
                                        </Typography>
                                        <Typography variant="body2">
                                          {/* {item?.answer} */}
                                          {item?.answer?.length ? (
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: item?.answer,
                                              }}
                                            />
                                          ) : null}
                                        </Typography>
                                      </Card>
                                    </Grid>
                                  )
                              )
                            : null}
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}
                </Box>

                {jobDetails?.companyName && (
                  <Box sx={{ margin: "15px 10px" }}>
                    <Tooltip title={Labels.logintoseeimg}>
                      <Typography
                        sx={{ margin: "15px auto 0px", fontWeight: 500 }}
                        variant="subtitle1"
                      >
                        {Labels.Hiringfor}{" "}
                        <span
                          style={{ userSelect: "none", filter: "blur(4px)" }}
                        >
                          {jobDetails?.companyName}
                        </span>
                      </Typography>
                    </Tooltip>
                  </Box>
                )}

                {jobDetails?.Experience && (
                  <Box
                    sx={{
                      margin: "15px 10px",
                    }}
                  >
                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                      {Labels?.Experience}
                    </Typography>
                    <Typography variant="body1" sx={{ my: 2 }}>
                      {jobDetails?.experianceYears}
                    </Typography>
                  </Box>
                )}

                {jobDetails?.CompanyLink && (
                  <Box
                    sx={{
                      margin: "15px 10px",
                    }}
                  >
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                      {Labels?.CompanyLink}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "#000000A1" }}>
                      {jobDetails.companyLink}&nbsp;
                    </Typography>
                  </Box>
                )}
              </Box>

              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  my: 2,
                  flexDirection: {
                    xs: "column",
                    md: "row",
                  },
                }}
              >
                <Stack direction="row" spacing={2} my={2}>
                  <Stack>
                    <CustomButton
                      variant="contained"
                      sx={{
                        textTransform: "capitalize",
                        borderRadius: "10px",
                        backgroundColor: "#1C614E",
                        "&:hover": {
                          backgroundColor: "#1C614E",
                        },
                        "&:active": {
                          backgroundColor: "#1C614E",
                        },
                        "&:disabled": {
                          color: "white",
                          backgroundColor: "#1C614E",
                        },
                      }}
                      label={Labels?.ApplyNow}
                      onClick={ApplyJob}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default JobDetailedview;
