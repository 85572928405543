import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Group755 from "../../Images/Group755.png";
import Group839 from "../../Images/Group839.png";
import Stack from "@mui/material/Stack";
import { axiosAPI } from "../Axios/Axios";
import { Chip, Container } from "@mui/material";
import CustomButton from "../Components/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./Style.css";
import {
  errorNotify,
  infoNotify,
  isValidEmail,
  succesNotify,
  warningNotify,
} from "../CommonCode/Commonfunc";

import { useSelector } from "react-redux";
import { profanityfn } from "../Profanity/Profanity";

const MainFeaturedPost = () => {
  const theme = useTheme();
  const redirect = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const Labels = useSelector((state) => state.allLabels.labels);
  const allList = useSelector((state) => state.allList.list);
  const location = useLocation();
  const postData = location.state;
  const { jobsearch, city, skills, category, type, industryID } = postData
    ? postData
    : {};

  // -----------------Search Jobs----------------------
  const ProfanityList = useSelector((state) => state.Profanity.wordlist);

  const [formdata, setformData] = useState({
    jobsearch: "",
    city: "",
    skills: "",
    category: "",
    type: "",
    industryID: "",
  });

  useEffect(() => {
    setformData({
      jobsearch: jobsearch ? jobsearch : "",
      city: city ? city : "",
      skills: skills ? skills : "",
      category: category ? category : "",
      type: type ? type : "",
      industryID: industryID ? industryID : "",
    });
  }, [postData]);

  const JobSerach = (e) => {
    const value = e.target.value;
    setformData({
      ...formdata,
      [e.target.name]: profanityfn(ProfanityList, value),
    });
  };
  const SearchJobs = (e) => {
    e.preventDefault();
    if (!formdata.jobsearch && !formdata.city) {
      infoNotify(Labels.jobTitleLocation);
    } else {
      const postData = {
        jobsearch: formdata.jobsearch ? formdata.jobsearch : "",
        city: formdata.city ? formdata.city : "",
        skills: skills ? skills : "",
        category: category ? category : "",
        type: type ? type : "",
        industryID: industryID ? industryID : "",
      };
      redirect("/jobSearchPrelogin", { state: postData });
    }
  };
  const searchByHotkeys = (e, data) => {
    e.preventDefault();
    const postData = {
      category: [data.id].toString(),
      jobsearch: jobsearch ? jobsearch : "",
      city: city ? city : "",
      skills: skills ? skills : "",
      type: type ? type : "",
      industryID: industryID ? industryID : "",
    };
    redirect("/jobSearchPrelogin", { state: postData });
  };

  const searchJobByMoreCategory = (e) => {
    e.preventDefault();
    const postData = {
      search: "",
      city: "",
      isCategoryMore: "true",
    };
    redirect("/jobSearchPrelogin", { state: postData });
  };

  return (
    <Paper
      sx={{
        position: "relative",
        color: "#fff",
        mb: 4,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url(${Group839})`,
        marginTop: { xs: "55px", sm: "60px", md: "75px" },
      }}
    >
      <img
        style={{ display: "none" }}
        src={Group839}
        alt={"background image"}
      />
      <Container maxWidth="xl">
        <div className="display-flex zIndex">
          <div className="landingPage">
            <Typography
              variant="h3"
              sx={{
                fontWeight: 600,
                fontSize: {
                  xs: "30px",
                  lg: "60px",
                },
              }}
              color="inherit"
              gutterBottom
            >
              {Labels?.FindYourDream}
            </Typography>
            <Stack direction="row" sx={{ marginBottom: 2 }}>
              <Typography
                variant="h3"
                color="inherit"
                sx={{
                  color: "#BA8C04",
                  fontWeight: 600,
                  fontSize: {
                    xs: "30px",
                    lg: "60px",
                  },
                }}
              >
                {Labels?.Job}&nbsp;
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  fontWeight: 600,
                  fontSize: {
                    xs: "30px",
                    lg: "60px",
                  },
                }}
                color="inherit"
              >
                {Labels?.inoneplace}
              </Typography>
            </Stack>
          </div>
          <div>
            <img src={Group755} alt="50plus" className="landingPage-img1" />
          </div>
        </div>
        <Stack sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
          <div className="inputField">
            <IconButton
              sx={{
                pl: "24px",
                color: "#2B4947",
              }}
              aria-label="menu"
            >
              <SearchIcon
                sx={{
                  fontSize: "40px",
                }}
              />
            </IconButton>
            <InputBase
              sx={{
                ml: 1,
                flex: 1,
                color: "#2B4947",
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "22px",
                lineHeight: "44px",
              }}
              name="jobsearch"
              value={formdata.jobsearch}
              onChange={(e) => JobSerach(e)}
              placeholder={Labels?.JobtitleorKeyword}
              inputProps={{ "aria-label": "search google maps" }}
            />
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton
              sx={{ pl: "10px", pt: "2px", color: "#2B4947" }}
              aria-label="menu"
            >
              <LocationOnOutlinedIcon
                sx={{
                  fontSize: "40px",
                }}
              />
            </IconButton>
            <InputBase
              sx={{
                ml: 1,
                flex: 1,
                color: "#2B4947",
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "22px",
                lineHeight: "44px",
              }}
              placeholder={Labels?.location}
              name="city"
              value={formdata.city}
              onChange={(e) => JobSerach(e)}
              inputProps={{ "aria-label": "search google maps" }}
            />
            <CustomButton
              label={Labels?.search}
              style={{
                ml: -10,
                width: 150,
                height: 65,
                fontSize: "23px",
              }}
              onClick={SearchJobs}
            />
          </div>
        </Stack>
        {isMobile ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              marginTop: { xs: "25px" },
            }}
          >
            <Paper
              sx={{
                pr: 4,
                display: "flex",
                alignItems: "center",
                height: 40,
                boxShadow: "0px 4px 35px rgba(0, 0, 0, 0.06)",
                borderRadius: "5px",
                width: "100%",
              }}
            >
              <IconButton
                sx={{
                  pl: "10px",
                  color: "#2B4947",
                }}
                aria-label="menu"
              >
                <SearchIcon
                  sx={{
                    fontSize: "28px",
                  }}
                />
              </IconButton>
              <InputBase
                sx={{
                  ml: 1,
                  flex: 1,
                  color: "#2B4947",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "20px",
                  lineHeight: "44px",
                }}
                name="jobsearch"
                value={formdata.jobsearch}
                onChange={(e) => JobSerach(e)}
                placeholder={Labels?.JobtitleorKeyword}
                inputProps={{ "aria-label": "search google maps" }}
              />
            </Paper>
            <Paper
              sx={{
                marginTop: 1,
                pr: 4,
                display: "flex",
                alignItems: "center",
                height: 40,
                boxShadow: "0px 4px 35px rgba(0, 0, 0, 0.06)",
                borderRadius: "5px",
                width: "100%",
              }}
            >
              <IconButton
                sx={{ pl: "10px", pt: "2px", color: "#2B4947" }}
                aria-label="menu"
              >
                <LocationOnOutlinedIcon
                  sx={{
                    fontSize: "28px",
                  }}
                />
              </IconButton>
              <InputBase
                sx={{
                  ml: 1,
                  flex: 1,

                  color: "#2B4947",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "20px",
                  lineHeight: "44px",
                }}
                placeholder={Labels?.location}
                name="city"
                value={formdata.city}
                onChange={(e) => JobSerach(e)}
                inputProps={{ "aria-label": "search google maps" }}
              />
            </Paper>
            <CustomButton
              label={Labels?.search}
              style={{
                fontSize: "18px",
                marginTop: 2,
                width: 120,
                height: 40,
                background: "white",
                color: "#2B4947",
                "&:hover": {
                  color: "white",
                },
              }}
              onClick={SearchJobs}
            />
          </Box>
        ) : null}
        {/* -------------------- Skills -------------------- */}
        <Box
          sx={{
            pt: 7,
            pb: 5,
            display: {
              xs: "block",
              sm: "block",
              md: "block",
              lg: "block",
              xl: "flex",
            },
            justifyContent: "space-between",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {allList?.categories && allList?.categories.length > 8
            ? allList?.categories.slice(0, 7).map((val, index) => {
                return (
                  <Chip
                    variant="outlined"
                    label={val.categoryName}
                    key={index}
                    sx={{
                      mb: 1,
                      mr: 1,
                      color: "white",
                      height: 40,
                      fontWeight: 300,
                      fontSize: { xs: "14px", md: "18px" },
                      lineHeight: "30px",
                      fontStyle: "normal",
                      borderRadius: "10px",
                      border: "1px solid #FFFFFF",
                      minWidth: "165px",
                      cursor: "pointer",
                      "&:hover": {
                        boxShadow: "0px 0px 3px 0px #fff",
                      },
                    }}
                    onClick={(e) => searchByHotkeys(e, val)}
                  />
                );
              })
            : allList?.categories &&
              allList?.categories.map((val, index) => {
                return (
                  <Chip
                    variant="outlined"
                    label={val.categoryName}
                    key={index}
                    sx={{
                      mb: 1,
                      mr: 1,
                      color: "white",
                      height: 40,
                      fontWeight: 300,
                      fontSize: { xs: "14px", md: "18px" },
                      lineHeight: "30px",
                      fontStyle: "normal",
                      borderRadius: "10px",
                      border: "1px solid #FFFFFF",
                      minWidth: "165px",
                      cursor: "pointer",
                      "&:hover": {
                        boxShadow: "0px 0px 3px 0px #fff",
                      },
                    }}
                    onClick={(e) => searchByHotkeys(e, val)}
                  />
                );
              })}
          {allList?.categories && allList?.categories.length > 8 && (
            <Typography
              sx={{ fontSize: "18px", cursor: "pointer", mb: 1 }}
              onClick={(e) => searchJobByMoreCategory(e)}
            >
              +{allList?.categories.length - 7} {Labels?.More}
            </Typography>
          )}
        </Box>
      </Container>
    </Paper>
  );
};

export default MainFeaturedPost;
