import React, { Fragment, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { axiosAPI } from "../Axios/Axios";
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";



const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300, // Adjust the maximum height as needed
      width: 350,
      overflowX:"auto",
      margin:"5px"
    },
  },
};


const SkillsNew = ({ style, value, setValue }) => {
  const [Skill, setSkill] = useState([]);
  useEffect(() => {
    const getSkills = async () => {
      const result = await axiosAPI.get("/getSkills");
      const { success, data } = await result.data;
      if (success === true) {
        setSkill(data);
      } else {
        setSkill([]);
      }
    };
    getSkills();
  }, []);
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    const updatedValue = Array.isArray(selectedValue)
      ? selectedValue
      : [selectedValue];
    setValue(updatedValue);
  };

  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <FormControl sx={{ width: "100%" }}>
      <Select
       MenuProps={MenuProps}
        labelId="demo-multiple-name-label"
        id="demo-multiple-name"
        value={value}
        onChange={handleChange}
        fullWidth
        multiple
        displayEmpty
        variant="outlined"
        sx={{ padding: "10px 12px", fontFamily: "Poppins", ...style }}
        renderValue={(selected) => {
          const selectedArray = Array.isArray(selected) ? selected : [selected];

          if (selectedArray[0] === undefined || selectedArray[0] === null) {
            return (
              <>
                <span style={{ color: "rgb(188, 188, 188)" }}>
                  {Labels?.skills}
                </span>
              </>
            );
          }
          const selectedNames = selectedArray.map((val) => Skill.find((item) => item.id === val)?.name).join(", ");
          return Skill?.length?Skill?.length<4?selectedNames:selectedNames.slice(0,40)+" ...": <Skeleton variant="rectangular" width={`90%`} height={20} />;
        }}
      >
        {Skill &&
          Skill.map((val, index) => {
            return (
              <MenuItem key={index} value={val.id}>
                {val.name}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};

export default SkillsNew;
