import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { PUBLIC_IMAGE_FOLDER } from "../Axios/Constant";
import Header from "./Header";
import { Container } from "@mui/material";
import { axiosAPI } from "../Axios/Axios";
import dummyLogo from "../../Images/dummyLogo.png";
import { useNavigate } from "react-router-dom";
import PageHeading from "./PageHead";
import { useSelector } from "react-redux";

export default function AllCategory() {
  const redirect = useNavigate();
  const [categories, setCategories] = useState([]);
  const getCategories = async () => {
    try {
      const result = await axiosAPI("/getAllCategories");
      const { success, message, data } = result.data;
      if (success) {
        setCategories(data);
      } 
    } catch (error) {
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const searchJobsByCateogry = (categoryId) => {
    const postData = { category: categoryId };
    redirect("/jobSearchPrelogin", { state: postData });
  };
  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <div>
      <PageHeading pageHeading={Labels?.categories} />
      <Container
        maxWidth="xl"
        sx={{
          paddingTop: {
            xs: "20px",
          },
        }}
      >
        <Grid container spacing={2}>
          {categories.length === 0 ? (
            <Grid item xs={12}>
              <Typography variant="h6">{Labels?.Nocategoriesfound}</Typography>
            </Grid>
          ) : (
            categories.map((category) => (
              <Grid item xs={12} sm={4} md={3}>
                <Card
                  key={category.id}
                  sx={{
                    display: "flex",
                    padding: "10px",
                    boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.13)",
                    borderRadius: "11px",
                    cursor: "pointer",
                  }}
                  onClick={() => searchJobsByCateogry(category.id)}
                >
                  <CardContent sx={{ flex: 1 }}>
                    <div style={{ display: "flex" }}>
                      <img
                        src={
                          category.categoryIcon === null || ""
                            ? `${dummyLogo}`
                            : `${PUBLIC_IMAGE_FOLDER}${category.categoryIcon}`
                        }
                        style={{ marginRight: 10 }}
                        height="45px"
                        width="45px"
                      />
                      <div>
                        <Typography
                          sx={{
                            fontWeight: "600",
                            fontSize: {
                              xs: "14px",
                              md: "15px",
                            },
                            color: "#2B4947",
                            alignItems: "center",
                          }}
                        >
                          {category.categoryName}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "rgba(116, 116, 116, 0.85);",
                          }}
                        >
                          {category.jobs} {Labels?.JobsAvailable}
                        </Typography>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ))
          )}
        </Grid>
      </Container>
    </div>
  );
}
