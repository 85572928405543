import {
  Box,
  Button,
  Card,
  Chip,
  Container,
  Grid,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Skeleton,
  Tooltip,
  tooltipClasses,
  Pagination,
  Divider,
} from "@mui/material";

import { IconButton } from "@mui/material";
import { Facebook, LinkedIn, Instagram } from "@mui/icons-material";
import EmailIcon from "@mui/icons-material/Email";

import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import React, { useState, useEffect, useRef } from "react";
import {
  parseISO,
  differenceInDays,
  differenceInHours,
  differenceInMonths,
  isSameDay,
} from "date-fns";
import { PUBLIC_IMAGE_FOLDER } from "../Axios/Constant";
import dummyLogo from "../../Images/dummyLogo.png";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import BusinessIcon from "@mui/icons-material/Business";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import ShareIcon from "@mui/icons-material/Share";
import { axiosAPI } from "../Axios/Axios";
import JobSerachSideGrid from "./JobSerachSideGrid";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import TextfieldCustom from "../Components/TextfieldCustom";
import CustomButton from "../Components/CustomButton";
import { infoNotify, useStyles } from "../CommonCode/Commonfunc";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import "../Landinpage1/Style.css";
import { Avatar, styled } from "@material-ui/core";
import "./style.css";
import { useSelector } from "react-redux";
import JobDetailedview from "./JobDetailedview";
import { profanityfn } from "../Profanity/Profanity";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: "15px",
  },
}));

const JobSerachPreloginGrid = () => {
  const topRef = useRef(null);
  const [jobId, setjobId] = useState("");
  const [expanded, setExpanded] = useState(true);
  const [loading, setloading] = useState(true);

  const classes = useStyles();
  const redirect = useNavigate();
  const location = useLocation(); // location.state is null
  const postData = location.state || {}; // which makes postData null
  const { jobsearch, city, skills, category, type, industryID } = postData;
  const [jobs, setjobs] = useState([]);

  const [formdata, setformData] = useState({
    jobsearch: "",
    city: "",
  });

  useEffect(() => {
    setformData({
      jobsearch: jobsearch ? jobsearch : "",
      city: city ? city : "",
    });
  }, []);

  const [filtercate, setfiltercate] = useState([]);
  const [selectedJobId, setSelectedJobId] = useState(null);

  const cardRefs = useRef([]);

  const Labels = useSelector((state) => state.allLabels.labels);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(3); // derived from pagination.last_page
  const [rowsPerPage, setrowsPerPage] = useState(9);
  const handlePageChange = (event, value) => {
    setPage(value); // Update page
  };

  const getjobs = async () => {
    try {
      const postDataWithPageCount = {
        ...postData,
        pageItem_count: rowsPerPage,
      };
      const result = await axiosAPI.post(
        `/searchjobs?page=${page}`,
        postDataWithPageCount
      );
      const { data, success } = result.data;
      if (success === true) {
        setloading(false);
        setjobs(data);
        setTotalPages(result?.data?.pagination?.last_page);
        setJobDetail(false);
      } else {
        setloading(false);
      }
    } catch (error) {}
  };

  const getjobsManual = async (searchdata) => {
    try {
      const postDataWithPageCount = {
        ...searchdata,
        pageItem_count: rowsPerPage,
      };
      const result = await axiosAPI.post("/searchjobs", postDataWithPageCount);
      const { data, success } = result.data;
      if (success === true) {
        setloading(false);
        setjobs(data);
      } else {
        setloading(false);
      }
    } catch (error) {}
  };

  const [view, setView] = useState("module");

  const { id } = useParams();

  useEffect(() => {
    if (id && !loading) {
      setSelectedJobId(id);
      setJobDetail(true);
    }
  }, [id, loading]);

  useEffect(() => {
    if (selectedCardRef.current) {
      selectedCardRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [selectedJobId]);

  const handleCardClick = (val, index) => {
    setSelectedJobId(val);
    setJobDetail(true);
    if (cardRefs.current[index]) {
      cardRefs.current[index].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    setExpanded(false);
  };

  // ------------------Search job by industries------------------------

  const ProfanityList = useSelector((state) => state.Profanity.wordlist);

  const setJobSerach = (e) => {
    const value = e.target.value;
    setformData({
      ...formdata,
      [e.target.name]: profanityfn(ProfanityList, value),
    });
  };
  const searchJobs = (e) => {
    e.preventDefault();
    if (!formdata.jobsearch && !formdata.city) {
      infoNotify(Labels.jobTitleLocation);
    } else {
      const searchData = {
        jobsearch: formdata.jobsearch,
        city: formdata.city,
        skills: skills ? skills : "",
        category: category ? category : "",
        type: type ? type : "",
        industryID: industryID ? industryID : "",
      };
      getjobsManual(searchData);
      redirect("/jobSearchPrelogin", { state: searchData });
    }
  };

  const [resetbox, setresetbox] = useState(true);

  const resetFields = () => {
    setformData({ jobsearch: "", city: "" });
    const searchData = {
      jobsearch: "",
      city: "",
      skills: "",
      category: "",
      type: "",
      industryID: "",
    };
    setresetbox(!resetbox);
    getjobsManual(searchData);
    redirect("/jobSearchPrelogin", { state: searchData });
  };

  const handleClick = () => {
    resetFields();
  };

  const [jobDetail, setJobDetail] = useState(false);

  const containerRef = useRef(null);
  const selectedCardRef = useRef(null);

  const viewDetailedJob = (id) => {
    setJobDetail(true);
    setjobId(id);
  };

  const theme = useTheme();
  const isWebView = useMediaQuery(theme.breakpoints.up("md")); // Only true for 'md' and larger screens

  useEffect(() => {
    if (isWebView) {
      window.scrollTo(0, 0);
    }
  }, [selectedJobId]);

  const handleLinkClick = (jobid) => {
    const email = ""; // Replace with the recipient's email address
    const subject = "Job Application";
    // const body = `Job Link : localhost:3000/jobSearchPrelogin/${jobid}`
    const body = `Job Link :   https://50plus.zone/jobSearchPrelogin/${jobid}`;

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  const [open, setOpen] = useState(false);

  const jobLink = "https://example.com/job-posting"; // Replace with your actual job link
  const jobMessage = "Check out this amazing job opportunity!";

  const handleShare = (platform) => {
    switch (platform) {
      case "facebook":
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            jobLink
          )}&quote=${encodeURIComponent(jobMessage)}`,
          "_blank"
        );
        break;

      case "linkedin":
        window.open(
          `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
            jobLink
          )}&title=${encodeURIComponent(
            jobMessage
          )}&summary=${encodeURIComponent(
            "Apply now for this exciting role."
          )}&source=${encodeURIComponent("YourCompany")}`,
          "_blank"
        );
        break;

      case "instagram":
        window.open(`https://www.instagram.com/`, "_blank");
        alert(
          "Instagram does not support direct posting from web. Open Instagram to post manually."
        );
        break;

      case "email":
        handleLinkClick();
        break;

      default:
        break;
    }
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  useEffect(() => {
    getjobs();
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (!isEmptyObject(postData)) {
      getjobs();
    }
  }, [postData, page, rowsPerPage]);

  const savefn = () => {
    // infoNotify(Labels.applyMessage);
    infoNotify(Labels.LoginRequired);
    setTimeout(() => {
      redirect("/login");
    }, 1000);
  };

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <Typography variant="h6">Share Job Posting</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Help us spread the word about this job opening! Share on:
          </Typography>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "20px" }}
          >
            <IconButton
              onClick={() => handleShare("facebook")}
              color="primary"
              aria-label="Share on Facebook"
            >
              <Facebook fontSize="large" />
            </IconButton>

            <IconButton
              onClick={() => handleShare("linkedin")}
              color="primary"
              aria-label="Share on LinkedIn"
            >
              <LinkedIn fontSize="large" />
            </IconButton>
            {/* <IconButton
      onClick={() => handleShare("instagram")}
      color="secondary"
      aria-label="Share on Instagram"
    >
      <Instagram fontSize="large" />
    </IconButton> */}
            <IconButton
              onClick={() => handleShare("email")}
              color="primary"
              aria-label="Share on Facebook"
            >
              <EmailIcon fontSize="large" />
            </IconButton>
          </div>
        </DialogContent>
      </Dialog>
      <Container
        maxWidth="100%"
        sx={{
          margin: "0 auto ",
          padding: "0 0 !important",
          background: "#EAEFF1",
          minHeight: "100vh",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            px: 5,
            pb: 2,
            paddingTop: {
              xs: "55px",
              sm: "85px",
            },
            "@media (max-width: 599px)": {
              px: 1,
            },
          }}
        >
          <Grid item xs={12} md={4} lg={3} xl={2}>
            <JobSerachSideGrid
              setfiltercate={setfiltercate}
              resetbox={resetbox}
            />
          </Grid>

          <Grid item xs={12} md={8} lg={9} xl={10}>
            <Stack
              direction={{ md: "column", lg: "row" }}
              spacing={2}
              sx={{ margin: "10px 0px" }}
            >
              <TextfieldCustom
                label={Labels?.JobTitle}
                style={{
                  borderRadius: "10px",
                  margin: "5px 0px",
                }}
                name="jobsearch"
                value={formdata.jobsearch}
                onChange={(e) => setJobSerach(e)}
              />
              <TextfieldCustom
                label={Labels?.JobLocation}
                style={{
                  borderRadius: "10px",
                  margin: "5px 0px",
                }}
                name="city"
                value={formdata.city}
                onChange={(e) => setJobSerach(e)}
              />

              <CustomButton
                label={Labels?.search}
                style={{
                  borderRadius: "10px",
                  border: "2px solid #1C614E",
                  maxHeight: "40px",
                  position: "relative",
                  top: "7px",
                  margin: "5px 0px",
                }}
                onClick={(e) => {
                  searchJobs(e);
                }}
              />

              <CustomButton
                label={Labels?.Reset}
                style={{
                  borderRadius: "10px",
                  maxHeight: "40px",
                  position: "relative",
                  top: "7px",
                  background: "white",
                  color: "#1C614E",
                  border: "2px solid #1C614E",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "#1C614E",
                    border: "2px solid #1C614E",
                  },
                  "&:active": {
                    backgroundColor: "white",
                    color: "#1C614E",
                    border: "2px solid #1C614E",
                  },
                  "&:disabled": { color: "white", backgroundColor: "#1C614E" },
                }}
                onClick={() => {
                  handleClick();
                }}
              />
            </Stack>
            <div className="flex" style={{}}>
              <p style={{ margin: "10px 10px 10px 0", color: "#2B4947" }}>
                {Labels?.Showing}&nbsp;
                <span style={{ color: "#BA8C04" }}>{jobs.length}&nbsp;</span>
                {Labels?.Jobs}&nbsp;
                {jobsearch ? (
                  <span style={{ color: "#BA8C04" }}>{jobsearch}&nbsp;</span>
                ) : null}
                {city ? (
                  <>
                    {Labels?.in}&nbsp;
                    <span style={{ color: "#BA8C04" }}>{city}&nbsp;</span>
                  </>
                ) : null}
              </p>
            </div>

            <Grid container spacing={2} sx={{ padding: "12px" }}>
              {/* Mobile View*/}
              <Grid
                item
                xs={12}
                sx={{
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "block",
                    lg: "none",
                    xl: "none",
                  },
                }}
              >
                <Accordion expanded={expanded}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    onClick={() => {
                      setExpanded(!expanded);
                    }}
                  >
                    {Labels.viewAll} {Labels.JobsList}
                  </AccordionSummary>
                  <AccordionDetails sx={{ background: "rgb(234,239,241)" }}>
                    <Grid
                      item
                      xs={12}
                      sm={jobDetail ? 12 : 12}
                      md={jobDetail ? 12 : 12}
                      lg={jobDetail ? 12 : 12}
                      xl={jobDetail ? 4 : 12}
                    >
                      <div style={{ position: "relative" }}>
                        <Grid
                          container
                          spacing={2}
                          className="scrollable-container-Jobsearchpostlogin"
                          ref={containerRef}
                          style={{ height: jobDetail ? "85vh" : `100%` }}
                        >
                          {loading ? (
                            <>
                              <Grid
                                item
                                xs={12}
                                sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                                md={jobDetail ? 12 : view == "module" ? 6 : 12}
                                lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                              >
                                <Skeleton
                                  variant="rectangular"
                                  width={`100%`}
                                  style={{
                                    minHeight:
                                      view == "module" ? "350px" : "100px",
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                                md={jobDetail ? 12 : view == "module" ? 6 : 12}
                                lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                              >
                                <Skeleton
                                  variant="rectangular"
                                  width={`100%`}
                                  style={{
                                    minHeight:
                                      view == "module" ? "350px" : "100px",
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                                md={jobDetail ? 12 : view == "module" ? 6 : 12}
                                lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                              >
                                <Skeleton
                                  variant="rectangular"
                                  width={`100%`}
                                  style={{
                                    minHeight:
                                      view == "module" ? "350px" : "100px",
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                                md={jobDetail ? 12 : view == "module" ? 6 : 12}
                                lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                              >
                                <Skeleton
                                  variant="rectangular"
                                  width={`100%`}
                                  style={{
                                    minHeight:
                                      view == "module" ? "350px" : "100px",
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                                md={jobDetail ? 12 : view == "module" ? 6 : 12}
                                lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                              >
                                <Skeleton
                                  variant="rectangular"
                                  width={`100%`}
                                  style={{
                                    minHeight:
                                      view == "module" ? "350px" : "100px",
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                                md={jobDetail ? 12 : view == "module" ? 6 : 12}
                                lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                              >
                                <Skeleton
                                  variant="rectangular"
                                  width={`100%`}
                                  style={{
                                    minHeight:
                                      view == "module" ? "350px" : "100px",
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                                md={jobDetail ? 12 : view == "module" ? 6 : 12}
                                lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                              >
                                <Skeleton
                                  variant="rectangular"
                                  width={`100%`}
                                  style={{
                                    minHeight:
                                      view == "module" ? "350px" : "100px",
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                                md={jobDetail ? 12 : view == "module" ? 6 : 12}
                                lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                              >
                                <Skeleton
                                  variant="rectangular"
                                  width={`100%`}
                                  style={{
                                    minHeight:
                                      view == "module" ? "350px" : "100px",
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                                md={jobDetail ? 12 : view == "module" ? 6 : 12}
                                lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                              >
                                <Skeleton
                                  variant="rectangular"
                                  width={`100%`}
                                  style={{
                                    minHeight:
                                      view == "module" ? "350px" : "100px",
                                  }}
                                />
                              </Grid>
                            </>
                          ) : (
                            jobs &&
                            jobs.map((val, i) => (
                              <Grid
                                item
                                xs={12}
                                sm={jobDetail ? 12 : view == "module" ? 12 : 12}
                                md={jobDetail ? 12 : view == "module" ? 12 : 12}
                                lg={jobDetail ? 12 : view == "module" ? 6 : 12}
                                xl={jobDetail ? 12 : view == "module" ? 4 : 12}
                                key={i}
                                ref={
                                  (selectedJobId || id) == val.eja_id
                                    ? selectedCardRef
                                    : null
                                }
                              >
                                <Card
                                  sx={{
                                    backgroundColor:
                                      selectedJobId === val.eja_id
                                        ? "#1C614E" // Green background if selected
                                        : // : val.status === "Applied" ||
                                          //   val.status == 4
                                          // ? "#E0FDE3" // Light green for "Applied" status
                                          "#FFFFFF", // White otherwise
                                    borderRadius: "17px",
                                    boxShadow: "none",
                                    cursor: "pointer",
                                    display: "flex",
                                    color:
                                      selectedJobId === val.eja_id
                                        ? "white"
                                        : "",
                                    // justifyContent: "space-between",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "10px 5px",
                                    position: "relative",
                                    // border: "1px solid grey",
                                    margin: "5px 10px",
                                    minHeight:
                                      view == "module" ? "200px" : "200px",
                                  }}
                                  onClick={() => {
                                    handleCardClick(val.eja_id);
                                  }}
                                >
                                  {(val.status == "Applied" ||
                                    val.status == 4) && (
                                    <span class={`stamp-${view} is-applied`}>
                                      {Labels.Applied}
                                    </span>
                                  )}
                                  {val.status == "Shortlisted" && (
                                    <span
                                      class={`stamp-${view} is-shortlisted`}
                                    >
                                      {Labels.Shortlisted}
                                    </span>
                                  )}

                                  <Grid container spacing={2} direction="row">
                                    <Grid
                                      sx={{
                                        width: "90%",
                                        margin: "auto",
                                        position: "absolute",
                                        top: "10px",
                                        left: "25px",
                                      }}
                                      container
                                      direction="row"
                                      justifyContent="space-between"
                                    >
                                      <Grid item direction="row">
                                        <ShareIcon
                                          // onClick={() => {handleLinkClick(val.eja_id)}}
                                          onClick={() => setOpen(true)}
                                          sx={{
                                            color: "#2B4947",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </Grid>
                                      {/* <Grid item direction="row">
                                      {val.save_status === 0 ? (
                                        <BookmarkBorderOutlinedIcon
                                          onClick={() => {
                                            savefn(val);
                                          }}
                                          sx={{
                                            color: "#2B4947",
                                            cursor: "pointer",
                                          }}
                                        />
                                      ) : null}
                                      {val.save_status === 1 ? (
                                        <BookmarkAddedIcon
                                          onClick={() => {
                                            savefn(val);
                                          }}
                                          sx={{
                                            color: "#2B4947",
                                            cursor: "pointer",
                                          }}
                                        />
                                      ) : null}
                                    </Grid> */}
                                    </Grid>

                                    {/* Avatar */}
                                    <Grid
                                      item
                                      xs={3}
                                      onClick={() => {
                                        viewDetailedJob(`${val.eja_id}`);
                                      }}
                                      sm={
                                        jobDetail ? 3 : view == "module" ? 3 : 2
                                      }
                                      md={
                                        jobDetail ? 3 : view == "module" ? 3 : 2
                                      }
                                      lg={
                                        jobDetail ? 3 : view == "module" ? 3 : 2
                                      }
                                      sx={{ position: "relative", top: "30px" }}
                                      direction="row"
                                    >
                                      <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        height="100%"
                                        style={{
                                          userSelect: "none",
                                          filter: "blur(5px) grayscale(100%)",
                                        }}
                                      >
                                        {/* <Avatar
                                          // src={val.companyLogo? `${val.companyLogo}` : null}
                                          alt=" "
                                          style={{
                                            minWidth: 60,
                                            minHeight: 60,
                                            margin: "25px",
                                          }}
                                        >
                                          {val.companyLogo ? null : (
                                            <>
                                              {val?.jobName
                                                ? val.jobName
                                                    .split(" ")
                                                    .map((word) =>
                                                      word[0].toUpperCase()
                                                    )
                                                    .join("")
                                                : null}
                                            </>
                                          )}
                                        </Avatar> */}
                                        <Tooltip title={Labels.logintoseeimg}>
                                          <Avatar
                                            style={{
                                              minWidth: 60,
                                              minHeight: 60,
                                              margin: "25px",
                                            }}
                                            sx={{
                                              backgroundColor: "grey", // Set the background color to grey
                                              filter: "none", // Remove any existing filter effects
                                              userSelect: "none", // Disable text selection
                                            }}
                                          />
                                        </Tooltip>
                                      </Box>
                                    </Grid>

                                    {/* Job details */}
                                    <Grid
                                      item
                                      xs={9}
                                      onClick={() => {
                                        viewDetailedJob(`${val.eja_id}`);
                                      }}
                                      sm={
                                        jobDetail ? 9 : view == "module" ? 9 : 4
                                      }
                                      md={
                                        jobDetail ? 9 : view == "module" ? 9 : 4
                                      }
                                      lg={
                                        jobDetail ? 9 : view == "module" ? 9 : 4
                                      }
                                      sx={{ position: "relative", top: "30px" }}
                                      //   onClick={() =>
                                      //     redirect(`/detailedJobView/${val.eja_id}`)
                                      //   }
                                    >
                                      <Box
                                        sx={{
                                          direction: "row",
                                          Width: 100,
                                        }}
                                      >
                                        <Typography
                                          variant="h6"
                                          sx={{
                                            color:
                                              selectedJobId === val.eja_id
                                                ? "white"
                                                : "#2B4947",
                                            fontWeight: 600,
                                          }}
                                        >
                                          {val.jobName}
                                        </Typography>
                                        <Tooltip title={Labels.logintoseeimg}>
                                          <Typography
                                            variant="subtitle1"
                                            sx={{
                                              color:
                                                selectedJobId === val.eja_id
                                                  ? "white"
                                                  : "#2B4947",
                                            }}
                                            style={{
                                              userSelect: "none",
                                              filter: "blur(4px)",
                                            }}
                                          >
                                            {val.employer}
                                          </Typography>
                                        </Tooltip>
                                        <Tooltip title={Labels.logintoseeimg}>
                                          <Typography
                                            variant="body2"
                                            sx={{
                                              color:
                                                selectedJobId === val.eja_id
                                                  ? "white"
                                                  : "#2B4947",
                                            }}
                                            style={{
                                              userSelect: "none",
                                              filter: "blur(4px)",
                                            }}
                                          >
                                            {val?.companyAddress},{" "}
                                            {val?.country}
                                          </Typography>
                                        </Tooltip>

                                        <Typography
                                          variant="body2"
                                          sx={{
                                            color:
                                              selectedJobId === val.eja_id
                                                ? "white"
                                                : "#2B4947",
                                          }}
                                        >
                                          {val.days}
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            color:
                                              selectedJobId === val.eja_id
                                                ? "white"
                                                : "#2B4947",
                                          }}
                                        >
                                          {val.jobseekers}{" "}
                                          {val.jobseekers?.length == 1
                                            ? Labels?.Applicant
                                            : Labels?.Applicants}
                                        </Typography>
                                      </Box>
                                    </Grid>

                                    {/* Job Box */}
                                    <Grid
                                      item
                                      xs={12}
                                      onClick={() => {
                                        viewDetailedJob(`${val.eja_id}`);
                                      }}
                                      sm={
                                        jobDetail
                                          ? 12
                                          : view == "module"
                                          ? 12
                                          : 6
                                      }
                                      md={
                                        jobDetail
                                          ? 12
                                          : view == "module"
                                          ? 12
                                          : 5
                                      }
                                      lg={
                                        jobDetail
                                          ? 12
                                          : view == "module"
                                          ? 12
                                          : 5
                                      }
                                      mt={2}
                                      //   onClick={() =>redirect(`/detailedJobView/${val.eja_id}`)}
                                    >
                                      <Box
                                        sx={{
                                          position: "relative", // Ensure the parent container is a positioning context
                                          display: "flex",
                                          paddingRight: 4,
                                          minHeight: "0px", // Set a minimum height to create space for other content
                                        }}
                                      >
                                        <Grid item xs={4} direction="column">
                                          {val.officePercentage != null && (
                                            <Stack
                                              direction="row"
                                              alignItems="center"
                                              spacing={1}
                                              mx={1}
                                              my={2}
                                              // style={{ border: '1px solid red' }}
                                            >
                                              <Chip
                                                // key={index}
                                                label={val?.officePresence}
                                                size="medium"
                                                sx={{
                                                  fontWeight:
                                                    selectedJobId === val.eja_id
                                                      ? 600
                                                      : 400,
                                                  backgroundColor:
                                                    "transparent",
                                                  color:
                                                    selectedJobId === val.eja_id
                                                      ? "white"
                                                      : "#2B4947",
                                                  // background: "#FFFFFF",
                                                  fontSize: "1rem",
                                                  borderRadius: 1,
                                                  border: "1px solid #BA8C04",
                                                  width: "150px", // Set your desired fixed width
                                                  whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                                  wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                                }}
                                              />
                                            </Stack>
                                          )}
                                          {val.officePercentage != null && (
                                            <Stack
                                              direction="row"
                                              alignItems="center"
                                              spacing={1}
                                              mx={1}
                                              my={2}
                                            >
                                              <Chip
                                                key={""}
                                                label={
                                                  val.officePercentage == 100
                                                    ? `${val.officePercentage}%`
                                                    : val.officePercentage == 0
                                                    ? `${val.remotePercentage}%`
                                                    : val.officePercentage >
                                                        0 &&
                                                      val.officePercentage < 100
                                                    ? `${val.officePercentage}% (${Labels.Office})`
                                                    : null
                                                }
                                                size="medium"
                                                sx={{
                                                  fontWeight:
                                                    selectedJobId === val.eja_id
                                                      ? 600
                                                      : 400,
                                                  backgroundColor:
                                                    "transparent",
                                                  color:
                                                    selectedJobId === val.eja_id
                                                      ? "white"
                                                      : "#2B4947",
                                                  // background: "#FFFFFF",
                                                  fontSize: "1rem",
                                                  borderRadius: 1,
                                                  border: "1px solid #BA8C04",
                                                  width: "150px", // Set your desired fixed width
                                                  whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                                  wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                                }}
                                              />
                                            </Stack>
                                          )}
                                        </Grid>

                                        <Grid item xs={4} direction="column">
                                          {val?.workingHours != null &&
                                          val?.hourPeriod != null ? (
                                            <Stack
                                              direction="row"
                                              alignItems="center"
                                              spacing={1}
                                              mx={1}
                                              my={2}
                                            >
                                              <Chip
                                                key={""}
                                                label={`${
                                                  val?.salaryCalucateitem
                                                    ? JSON.parse(
                                                        val.salaryCalucateitem
                                                      )?.hoursPerDay
                                                    : val?.workingHours || ""
                                                } hrs/${val?.hourPeriod || ""}`}
                                                size="medium"
                                                sx={{
                                                  fontWeight:
                                                    selectedJobId === val.eja_id
                                                      ? 600
                                                      : 400,
                                                  backgroundColor:
                                                    "transparent",
                                                  color:
                                                    selectedJobId === val.eja_id
                                                      ? "white"
                                                      : "#2B4947",
                                                  // background: "#FFFFFF",
                                                  fontSize: "1rem",
                                                  borderRadius: 1,
                                                  border: "1px solid #BA8C04",
                                                  width: "150px", // Set your desired fixed width
                                                  whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                                  wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                                }}
                                              />
                                            </Stack>
                                          ) : null}

                                          {(val?.anualSalaryMin != null &&
                                            val?.anualSalaryMin != 0) ||
                                          (val?.anualsalaryMax != null &&
                                            val?.anualsalaryMax != 0 &&
                                            val?.currencySymbol != null) ? (
                                            <Stack
                                              direction="row"
                                              alignItems="center"
                                              spacing={1}
                                              mx={1}
                                              my={2}
                                            >
                                              <Chip
                                                key={""}
                                                label={`
                                                ${
                                                  val?.salaryMax
                                                    ? val?.salaryMax
                                                        ?.toString()
                                                        .replace(/[^0-9]/g, "")
                                                        .replace(
                                                          /\B(?=(\d{3})+(?!\d))/g,
                                                          ","
                                                        )
                                                    : val?.salary
                                                    ? val?.salary
                                                        ?.toString()
                                                        .replace(/[^0-9]/g, "")
                                                        .replace(
                                                          /\B(?=(\d{3})+(?!\d))/g,
                                                          ","
                                                        )
                                                    : ""
                                                } ${
                                                  val?.salaryMax || val?.salary
                                                    ? val?.currencySymbol +
                                                      (val?.salaryPeriod
                                                        ? `/ ${val?.salaryPeriod}`
                                                        : "")
                                                    : ""
                                                }`}
                                                size="medium"
                                                sx={{
                                                  fontWeight:
                                                    selectedJobId === val.eja_id
                                                      ? 600
                                                      : 400,
                                                  backgroundColor:
                                                    "transparent",
                                                  color:
                                                    selectedJobId === val.eja_id
                                                      ? "white"
                                                      : "#2B4947",
                                                  // background: "#FFFFFF",
                                                  fontSize: "1rem",
                                                  borderRadius: 1,
                                                  border: "1px solid #BA8C04",
                                                  width: "150px", // Set your desired fixed width
                                                  whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                                  wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                                }}
                                              />
                                            </Stack>
                                          ) : null}
                                        </Grid>

                                        <Grid item xs={4} direction="column">
                                          {val.jobTypes ? (
                                            <Stack
                                              direction="row"
                                              alignItems="center"
                                              spacing={1}
                                              mx={1}
                                              my={2}
                                            >
                                              {val?.jobTypeName
                                                ?.split(",")
                                                ?.map((jobType, i) => (
                                                  <Chip
                                                    key={jobType}
                                                    label={jobType}
                                                    size="medium"
                                                    sx={{
                                                      fontWeight:
                                                        selectedJobId ===
                                                        val.eja_id
                                                          ? 600
                                                          : 400,
                                                      backgroundColor:
                                                        "transparent",
                                                      color:
                                                        selectedJobId ===
                                                        val.eja_id
                                                          ? "white"
                                                          : "#2B4947",
                                                      // background: "#FFFFFF",
                                                      fontSize: "1rem",
                                                      borderRadius: 1,
                                                      border:
                                                        "1px solid #BA8C04",
                                                      width: "150px", // Set your desired fixed width
                                                      whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                                      wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                                    }}
                                                  />
                                                ))
                                                .slice(0, 2)}
                                            </Stack>
                                          ) : null}
                                          {val?.typePercentage &&
                                          val.jobTypes ? (
                                            <Stack
                                              direction="row"
                                              alignItems="center"
                                              spacing={1}
                                              mx={1}
                                              my={2}
                                            >
                                              <Chip
                                                key={""}
                                                label={`${val?.typePercentage}0%`}
                                                size="medium"
                                                sx={{
                                                  fontWeight:
                                                    selectedJobId === val.eja_id
                                                      ? 600
                                                      : 400,
                                                  backgroundColor:
                                                    "transparent",
                                                  color:
                                                    selectedJobId === val.eja_id
                                                      ? "white"
                                                      : "#2B4947",
                                                  // background: "#FFFFFF",
                                                  fontSize: "1rem",
                                                  borderRadius: 1,
                                                  border: "1px solid #BA8C04",
                                                  width: "150px", // Set your desired fixed width
                                                  whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                                  wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                                }}
                                              />
                                            </Stack>
                                          ) : null}
                                        </Grid>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Card>
                              </Grid>
                            ))
                          )}
                        </Grid>
                      </div>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {/* Web View*/}
                <Grid container spacing={2}>
                  <Grid
                    xs={12}
                    sm={jobDetail ? 12 : 12}
                    md={jobDetail ? 12 : 12}
                    lg={jobDetail ? 5 : 12}
                    xl={jobDetail ? 4 : 12}
                    sx={{
                      display: {
                        xs: "none",
                        sm: "none",
                        md: "none",
                        lg: "block",
                        xl: "block",
                      },
                    }}
                    item
                  >
                    <div style={{ position: "relative" }}>
                      <Grid
                        container
                        spacing={2}
                        className="scrollable-container-Jobsearchpostlogin"
                        ref={containerRef}
                        style={{ height: jobDetail ? "85vh" : `100%` }}
                      >
                        {loading ? (
                          <>
                            <Grid
                              item
                              xs={12}
                              sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                              md={jobDetail ? 12 : view == "module" ? 6 : 12}
                              lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                            >
                              <Skeleton
                                variant="rectangular"
                                width={`100%`}
                                style={{
                                  minHeight:
                                    view == "module" ? "350px" : "100px",
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                              md={jobDetail ? 12 : view == "module" ? 6 : 12}
                              lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                            >
                              <Skeleton
                                variant="rectangular"
                                width={`100%`}
                                style={{
                                  minHeight:
                                    view == "module" ? "350px" : "100px",
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                              md={jobDetail ? 12 : view == "module" ? 6 : 12}
                              lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                            >
                              <Skeleton
                                variant="rectangular"
                                width={`100%`}
                                style={{
                                  minHeight:
                                    view == "module" ? "350px" : "100px",
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                              md={jobDetail ? 12 : view == "module" ? 6 : 12}
                              lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                            >
                              <Skeleton
                                variant="rectangular"
                                width={`100%`}
                                style={{
                                  minHeight:
                                    view == "module" ? "350px" : "100px",
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                              md={jobDetail ? 12 : view == "module" ? 6 : 12}
                              lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                            >
                              <Skeleton
                                variant="rectangular"
                                width={`100%`}
                                style={{
                                  minHeight:
                                    view == "module" ? "350px" : "100px",
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                              md={jobDetail ? 12 : view == "module" ? 6 : 12}
                              lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                            >
                              <Skeleton
                                variant="rectangular"
                                width={`100%`}
                                style={{
                                  minHeight:
                                    view == "module" ? "350px" : "100px",
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                              md={jobDetail ? 12 : view == "module" ? 6 : 12}
                              lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                            >
                              <Skeleton
                                variant="rectangular"
                                width={`100%`}
                                style={{
                                  minHeight:
                                    view == "module" ? "350px" : "100px",
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                              md={jobDetail ? 12 : view == "module" ? 6 : 12}
                              lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                            >
                              <Skeleton
                                variant="rectangular"
                                width={`100%`}
                                style={{
                                  minHeight:
                                    view == "module" ? "350px" : "100px",
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                              md={jobDetail ? 12 : view == "module" ? 6 : 12}
                              lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                            >
                              <Skeleton
                                variant="rectangular"
                                width={`100%`}
                                style={{
                                  minHeight:
                                    view == "module" ? "350px" : "100px",
                                }}
                              />
                            </Grid>
                          </>
                        ) : (
                          jobs &&
                          jobs.map((val, i) => (
                            <Grid
                              item
                              xs={12}
                              sm={jobDetail ? 12 : 6}
                              md={jobDetail ? 12 : 6}
                              lg={jobDetail ? 12 : 6}
                              xl={jobDetail ? 12 : 4}
                              key={i}
                              ref={
                                (selectedJobId || id) == val.eja_id
                                  ? selectedCardRef
                                  : null
                              }
                            >
                              <Card
                                sx={{
                                  backgroundColor:
                                    selectedJobId === val.eja_id
                                      ? "#1C614E"
                                      : "#FFFFFF", // White otherwise
                                  color:
                                    selectedJobId === val.eja_id ? "white" : "",
                                  borderRadius: "17px",
                                  boxShadow: "none",
                                  cursor: "pointer",
                                  display: "flex",
                                  // justifyContent: "space-between",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  padding: "5px",
                                  position: "relative",
                                  minHeight:
                                    view == "module" ? "270px" : "150px",
                                  overflow: "auto",
                                  margin: "5px 10px",
                                }}
                                onClick={() => {
                                  handleCardClick(val.eja_id);
                                }}
                              >
                                {/* {(val.status == "Applied" || val.status == 4) && (
                                <span class={`stamp-${view} is-applied`}>
                                  {Labels.Applied}
                                </span>
                              )}
                              {val.status == "Shortlisted" && (
                                <span class={`stamp-${view} is-shortlisted`}>
                                  {Labels.Shortlisted}
                                </span>
                              )} */}

                                <Grid container spacing={2} direction="row">
                                  <Grid
                                    sx={{
                                      width: "90%",
                                      margin: "auto",
                                      position: "absolute",
                                      top: "20px",
                                      left: "25px",
                                    }}
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                  >
                                    <Grid item direction="row">
                                      <ShareIcon
                                        // onClick={() => {handleLinkClick(val.eja_id)}}
                                        onClick={() => setOpen(true)}
                                        sx={{
                                          color:
                                            selectedJobId === val.eja_id
                                              ? "white"
                                              : "#2B4947",
                                          cursor: "pointer",
                                          width: 30,
                                          height: 30,
                                        }}
                                      />
                                    </Grid>
                                  </Grid>

                                  {/* Avatar */}
                                  <Grid
                                    item
                                    xs={3}
                                    onClick={() => {
                                      viewDetailedJob(`${val.eja_id}`);
                                    }}
                                    sm={
                                      jobDetail ? 3 : view == "module" ? 3 : 2
                                    }
                                    md={
                                      jobDetail ? 3 : view == "module" ? 3 : 2
                                    }
                                    lg={
                                      jobDetail ? 3 : view == "module" ? 3 : 2
                                    }
                                    sx={{
                                      position: "relative",
                                      top:
                                        view == "module"
                                          ? "30px"
                                          : jobDetail
                                          ? "20px"
                                          : "0px",
                                    }}
                                    direction="row"
                                  >
                                    <Box
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                      height="100%"
                                      style={{
                                        userSelect: "none",
                                        filter: "blur(5px) grayscale(100%)",
                                      }}
                                    >
                                      {/* <Avatar
                                        src={ val.companyLogo ? `${val.companyLogo}`: null }
                                        alt=" "
                                        style={{
                                          minWidth: 60,
                                          minHeight: 60,
                                          margin: "25px",
                                        }}
                                      >
                                        {val.companyLogo ? null : (
                                          <>
                                            {val?.jobName
                                              ? val.jobName
                                                  ?.split(" ")
                                                  .map((word) =>
                                                    word[0].toUpperCase()
                                                  )
                                                  .join("")
                                              : null}
                                          </>
                                        )}
                                      </Avatar> */}
                                      <Tooltip title={Labels.logintoseeimg}>
                                        <Avatar
                                          style={{
                                            minWidth: 60,
                                            minHeight: 60,
                                            margin: "25px",
                                          }}
                                          sx={{
                                            backgroundColor: "grey", // Set the background color to grey
                                            filter: "none", // Remove any existing filter effects
                                            userSelect: "none", // Disable text selection
                                          }}
                                        />
                                      </Tooltip>
                                    </Box>
                                  </Grid>

                                  {/* Job details */}
                                  <Grid
                                    item
                                    xs={9}
                                    onClick={() => {
                                      viewDetailedJob(`${val.eja_id}`);
                                    }}
                                    sm={
                                      jobDetail ? 9 : view == "module" ? 9 : 4
                                    }
                                    md={
                                      jobDetail ? 9 : view == "module" ? 9 : 4
                                    }
                                    lg={
                                      jobDetail ? 9 : view == "module" ? 9 : 4
                                    }
                                    sx={{
                                      position: "relative",
                                      top:
                                        view == "module"
                                          ? "30px"
                                          : jobDetail
                                          ? "20px"
                                          : "0px",
                                    }}
                                    //   onClick={() =>
                                    //     redirect(`/detailedJobView/${val.eja_id}`)
                                    //   }
                                  >
                                    <Box
                                      sx={{
                                        direction: "row",
                                        Width: 100,
                                      }}
                                    >
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          color:
                                            selectedJobId === val.eja_id
                                              ? "white"
                                              : "#2B4947",
                                          fontWeight: 600,
                                        }}
                                      >
                                        {val.jobName}
                                      </Typography>
                                      <Tooltip title={Labels.logintoseeimg}>
                                        <Typography
                                          variant="subtitle1"
                                          sx={{
                                            color:
                                              selectedJobId === val.eja_id
                                                ? "white"
                                                : "#2B4947",
                                            fontWeight: 600,
                                          }}
                                          style={{
                                            userSelect: "none",
                                            filter: "blur(4px)",
                                          }}
                                        >
                                          {val.employer}
                                        </Typography>
                                      </Tooltip>
                                      <Tooltip title={Labels.logintoseeimg}>
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            color:
                                              selectedJobId === val.eja_id
                                                ? "white"
                                                : "#2B4947",
                                            fontWeight: 600,
                                          }}
                                          style={{
                                            userSelect: "none",
                                            filter: "blur(4px)",
                                          }}
                                        >
                                          {val?.companyAddress}, {val?.country}
                                        </Typography>
                                      </Tooltip>

                                      <Typography
                                        variant="body2"
                                        sx={{
                                          color:
                                            selectedJobId === val.eja_id
                                              ? "white"
                                              : "#2B4947",
                                        }}
                                      >
                                        {val.days}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          color:
                                            selectedJobId === val.eja_id
                                              ? "white"
                                              : "#2B4947",
                                        }}
                                      >
                                        {val.jobseekers}{" "}
                                        {val.jobseekers == 1
                                          ? Labels?.Applicant
                                          : Labels?.Applicants}
                                      </Typography>
                                    </Box>
                                  </Grid>

                                  {/* Job Box */}
                                  <Grid
                                    item
                                    xs={12}
                                    onClick={() => {
                                      viewDetailedJob(`${val.eja_id}`);
                                    }}
                                    sm={
                                      jobDetail ? 12 : view == "module" ? 12 : 6
                                    }
                                    md={
                                      jobDetail ? 12 : view == "module" ? 12 : 5
                                    }
                                    lg={
                                      jobDetail ? 12 : view == "module" ? 12 : 5
                                    }
                                    mt={2}
                                    //   onClick={() =>redirect(`/detailedJobView/${val.eja_id}`)}
                                  >
                                    <Box
                                      sx={{
                                        position: "relative", // Ensure the parent container is a positioning context
                                        display: "flex",
                                        paddingRight: 4,
                                        minHeight: "0px", // Set a minimum height to create space for other content
                                      }}
                                    >
                                      <Grid item xs={4} direction="column">
                                        {val?.officePresence != null ? (
                                          <Stack
                                            direction="row"
                                            alignItems="center"
                                            spacing={1}
                                            mx={1}
                                            my={2}
                                            // style={{ border: '1px solid red' }}
                                          >
                                            <LightTooltip
                                              title={
                                                Labels.officePresencetooltip
                                              }
                                              arrow
                                            >
                                              <Chip
                                                // key={index}
                                                label={val?.officePresence}
                                                size="medium"
                                                sx={{
                                                  fontWeight:
                                                    selectedJobId === val.eja_id
                                                      ? 600
                                                      : 400,
                                                  backgroundColor:
                                                    "transparent",
                                                  color:
                                                    selectedJobId === val.eja_id
                                                      ? "white"
                                                      : "#BA8C04",
                                                  // background: "#FFFFFF",
                                                  fontSize: "1rem",
                                                  borderRadius: 1,
                                                  border: "1px solid #BA8C04",
                                                  width: "150px", // Set your desired fixed width
                                                  whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                                  wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                                }}
                                              />
                                            </LightTooltip>
                                          </Stack>
                                        ) : null}
                                        {val.officePercentage != null ? (
                                          <Stack
                                            direction="row"
                                            alignItems="center"
                                            spacing={1}
                                            mx={1}
                                            my={2}
                                          >
                                            <LightTooltip
                                              title={
                                                Labels.officePercentagetooltip
                                              }
                                              arrow
                                            >
                                              <Chip
                                                key={""}
                                                // label={`${val.officePercentage}%`}
                                                label={
                                                  val.officePercentage == 100
                                                    ? `${val.officePercentage}%`
                                                    : val.officePercentage == 0
                                                    ? `${val.remotePercentage}%`
                                                    : val.officePercentage >
                                                        0 &&
                                                      val.officePercentage < 100
                                                    ? `${val.officePercentage}% (${Labels.Office})`
                                                    : null
                                                }
                                                size="medium"
                                                sx={{
                                                  fontWeight:
                                                    selectedJobId === val.eja_id
                                                      ? 600
                                                      : 400,
                                                  backgroundColor:
                                                    "transparent",
                                                  color:
                                                    selectedJobId === val.eja_id
                                                      ? "white"
                                                      : "#BA8C04",
                                                  // background: "#FFFFFF",
                                                  fontSize: "1rem",
                                                  borderRadius: 1,
                                                  border: "1px solid #BA8C04",
                                                  width: "150px", // Set your desired fixed width
                                                  whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                                  wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                                }}
                                              />
                                            </LightTooltip>
                                          </Stack>
                                        ) : null}
                                      </Grid>
                                      <Grid item xs={4} direction="column">
                                        {val?.workingHours != null &&
                                        val?.hourPeriod != null ? (
                                          <Stack
                                            direction="row"
                                            alignItems="center"
                                            spacing={1}
                                            mx={1}
                                            my={2}
                                          >
                                            <LightTooltip
                                              title={Labels.workingHourstooltip}
                                              arrow
                                            >
                                              <Chip
                                                key={""}
                                                label={`${
                                                  val?.salaryCalucateitem
                                                    ? JSON.parse(
                                                        val.salaryCalucateitem
                                                      )?.hoursPerDay
                                                    : val?.workingHours || ""
                                                } ${
                                                  (val?.salaryCalucateitem
                                                    ? JSON.parse(
                                                        val.salaryCalucateitem
                                                      )?.hoursPerDay
                                                    : val?.workingHours ||
                                                      "") === 1
                                                    ? "hr"
                                                    : "hrs"
                                                }/${val?.hourPeriod || ""}`}
                                                size="medium"
                                                sx={{
                                                  fontWeight:
                                                    selectedJobId === val.eja_id
                                                      ? 600
                                                      : 400,
                                                  backgroundColor:
                                                    "transparent",
                                                  color:
                                                    selectedJobId === val.eja_id
                                                      ? "white"
                                                      : "#BA8C04",
                                                  // background: "#FFFFFF",
                                                  fontSize: "1rem",
                                                  borderRadius: 1,
                                                  border: "1px solid #BA8C04",
                                                  width: "150px", // Set your desired fixed width
                                                  whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                                  wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                                }}
                                              />
                                            </LightTooltip>
                                          </Stack>
                                        ) : null}

                                        {val?.salaryMax || val?.salary ? (
                                          <Stack
                                            direction="row"
                                            alignItems="center"
                                            spacing={1}
                                            mx={1}
                                            my={2}
                                          >
                                            <LightTooltip
                                              title={`
                                              ${
                                                val?.salaryMax
                                                  ? val?.salaryMax
                                                      ?.toString()
                                                      .replace(/[^0-9]/g, "")
                                                      .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                      )
                                                  : val?.salary
                                                  ? val?.salary
                                                      ?.toString()
                                                      .replace(/[^0-9]/g, "")
                                                      .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                      )
                                                  : ""
                                              } ${
                                                val?.salaryMax || val?.salary
                                                  ? val?.currencySymbol +
                                                    (val?.salaryPeriod
                                                      ? `/ ${val?.salaryPeriod}`
                                                      : "")
                                                  : ""
                                              }`}
                                              arrow
                                            >
                                              <Chip
                                                key={""}
                                                label={`
                                                ${
                                                  val?.salaryMax
                                                    ? val?.salaryMax
                                                        ?.toString()
                                                        .replace(/[^0-9]/g, "")
                                                        .replace(
                                                          /\B(?=(\d{3})+(?!\d))/g,
                                                          ","
                                                        )
                                                    : val?.salary
                                                    ? val?.salary
                                                        ?.toString()
                                                        .replace(/[^0-9]/g, "")
                                                        .replace(
                                                          /\B(?=(\d{3})+(?!\d))/g,
                                                          ","
                                                        )
                                                    : ""
                                                } ${
                                                  val?.salaryMax || val?.salary
                                                    ? val?.currencySymbol +
                                                      (val?.salaryPeriod
                                                        ? `/ ${val?.salaryPeriod}`
                                                        : "")
                                                    : ""
                                                }`}
                                                size="medium"
                                                sx={{
                                                  fontWeight:
                                                    selectedJobId === val.eja_id
                                                      ? 600
                                                      : 400,
                                                  backgroundColor:
                                                    "transparent",
                                                  color:
                                                    selectedJobId === val.eja_id
                                                      ? "white"
                                                      : "#BA8C04",
                                                  // background: "#FFFFFF",
                                                  fontSize: "1rem",
                                                  borderRadius: 1,
                                                  border: "1px solid #BA8C04",
                                                  width: "150px", // Set your desired fixed width
                                                  whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                                  wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                                }}
                                              />
                                            </LightTooltip>
                                          </Stack>
                                        ) : null}
                                      </Grid>
                                      <Grid item xs={4} direction="column">
                                        {val.jobTypes ? (
                                          <Stack
                                            direction="row"
                                            alignItems="center"
                                            spacing={1}
                                            mx={1}
                                            my={2}
                                          >
                                            {val?.jobTypeName
                                              ?.split(",")
                                              ?.map((jobType, i) => (
                                                <LightTooltip
                                                  title={Labels.jobtitletooltip}
                                                  arrow
                                                >
                                                  <Chip
                                                    key={jobType}
                                                    label={jobType}
                                                    size="medium"
                                                    sx={{
                                                      fontWeight:
                                                        selectedJobId ===
                                                        val.eja_id
                                                          ? 600
                                                          : 400,
                                                      backgroundColor:
                                                        "transparent",
                                                      color:
                                                        selectedJobId ===
                                                        val.eja_id
                                                          ? "white"
                                                          : "#BA8C04",
                                                      // background: "#FFFFFF",
                                                      fontSize: "1rem",
                                                      borderRadius: 1,
                                                      border:
                                                        "1px solid #BA8C04",
                                                      width: "150px", // Set your desired fixed width
                                                      whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                                      wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                                    }}
                                                  />
                                                </LightTooltip>
                                              ))
                                              .slice(0, 2)}
                                          </Stack>
                                        ) : null}

                                        {val?.typePercentage != null &&
                                        val.jobTypes ? (
                                          <Stack
                                            direction="row"
                                            alignItems="center"
                                            spacing={1}
                                            mx={1}
                                            my={2}
                                          >
                                            <LightTooltip
                                              title={
                                                Labels.typepercentagetooltip
                                              }
                                              arrow
                                            >
                                              <Chip
                                                key={""}
                                                label={`${val?.typePercentage}0%`}
                                                size="medium"
                                                sx={{
                                                  fontWeight:
                                                    selectedJobId === val.eja_id
                                                      ? 600
                                                      : 400,
                                                  backgroundColor:
                                                    "transparent",
                                                  color:
                                                    selectedJobId === val.eja_id
                                                      ? "white"
                                                      : "#BA8C04",
                                                  // background: "#FFFFFF",
                                                  fontSize: "1rem",
                                                  borderRadius: 1,
                                                  border: "1px solid #BA8C04",
                                                  width: "150px", // Set your desired fixed width
                                                  whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                                  wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                                }}
                                              />
                                            </LightTooltip>
                                          </Stack>
                                        ) : null}
                                      </Grid>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Card>
                            </Grid>
                          ))
                        )}
                      </Grid>
                    </div>
                  </Grid>

                  {jobDetail && jobs.length ? (
                    <Grid item xs={12} sm={12} md={12} lg={7} xl={8}>
                      <JobDetailedview jobId={jobId} />
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>

              <Divider sx={{ width: "100%", margin: "10px" }} />
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs={12} sm={12} container justifyContent="center">
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    size="large"
                    sx={{
                      "& .MuiPaginationItem-root": {
                        color: "#1C614E",
                        "&:hover": {
                          backgroundColor: "rgba(0, 128, 0, 0.1)",
                          color: "green",
                        },
                      },
                      "& .MuiPaginationItem-root.Mui-selected": {
                        backgroundColor: "#1C614E",
                        color: "white",
                        "&:hover": { backgroundColor: "#1C614E" },
                      },
                      "& .MuiPaginationItem-previousNext": {
                        color: "#1C614E",
                        "&:hover": { backgroundColor: "rgba(0, 128, 0, 0.1)" },
                      },
                      "& .MuiPaginationItem-ellipsis": {
                        color: "#1C614E", // Text color for ellipsis
                      },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  container
                  justifyContent="flex-start"
                >
                  <p> {Labels.Rowsperpage} </p>
                  <select
                    value={rowsPerPage}
                    onChange={(e) => {
                      setrowsPerPage(e.target.value);
                      setPage(1);
                    }}
                    style={{
                      padding: "8px",
                      fontSize: "16px",
                      margin: "10px",
                    }}
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default JobSerachPreloginGrid;
