import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Stack,
  TextField,
  Typography,
  Chip,
  Switch,
  Dialog,
  DialogContent,
  Button,
  DialogTitle,
  DialogActions,
  InputAdornment,
} from "@mui/material";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import EditIcon from "@mui/icons-material/Edit";
import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  createRef,
} from "react";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import CustomButton from "../../Components/CustomButton";
import PhoneIcon from "@mui/icons-material/Phone";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import TourOutlinedIcon from "@mui/icons-material/TourOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useSelector } from "react-redux";
import { axiosupload, axiosAPI } from "../../Axios/Axios";
import { PUBLIC_IMAGE_FOLDER } from "../../Axios/Constant";
import Designation from "../../CommonCode/Designation";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import {
  errorNotify,
  succesNotify,
  validatePhoneNumber,
  warningNotify,
} from "../../CommonCode/Commonfunc";
import Country from "../../CommonCode/Country";
import RecruiterCountry from "../../CommonCode/RecruiterCountry";
import State from "../../CommonCode/State";
import { useStyles } from "../../CommonCode/Commonfunc";
import EmployerPasswordChangeModal from "./EmployerPasswordChangeModal";
import IconLabelTextField from "../../Components/iconLabelText";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import SimpleBackdrop from "../../CommonCode/SimpleBackdrop";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Close, Label } from "@mui/icons-material";
import CompanyType from "../../CommonCode/CompanyType";
import BusinessCategory from "../../CommonCode/BusinessCategory";
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";
import BallotOutlinedIcon from "@mui/icons-material/BallotOutlined";
import LanguageIcon from "@mui/icons-material/Language";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FlagIcon from "@mui/icons-material/Flag";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { getCompanyProfile } from "../../../redux/CompanyDetailSlice";
import { useDispatch } from "react-redux";
import YesNo from "../../CommonCode/YesNo";
import JobPostingDuration from "../../CommonCode/JobDuration";
import InputWithLabel from "../../Components/InputWithLabel";
import { useDropzone } from "react-dropzone";
import { Select } from "@material-ui/core";
import CompanyBranches from "./CompanyBranches";
import JobInfo from "../../CommonCode/JobInformation";
import EditBranches from "./EditCompanyBranch";
import CompanySize from "../../CommonCode/CompanySize";
import BranchState from "../../CommonCode/BranchEditState";
import { profanityfn, profanitySend } from "../../Profanity/Profanity";
import { useRecoilState } from "recoil";
import { updateStateAtom } from "../../../App";

import ReactQuill from "react-quill";


export default function EmployerProfile({
  routeChange,
  isDisable,
  placeHolder,
  value,
  setvalue,
}) {
  const classes = useStyles();

  const [employerData, setEmployerData] = useState({});

  const [isButtonDisabled1, setButtonDisabled1] = useState(false); // company information
  const [isButtonDisabled2, setButtonDisabled2] = useState(false); // company location
  const [isButtonDisabled3, setButtonDisabled3] = useState(true); // about company

  const [isButtonDisabled4, setButtonDisabled4] = useState(false);
  const [isButtonDisabled5, setButtonDisabled5] = useState(true); // misc

  const [receiveCoverLetter, setExpectCoverLetter] = useState(0);
  const [showProfile, setShowProfile] = useState(0);
  const [contactBymail, setShowEmail] = useState(0);
  const [JobPostDuration, setJobPostDuration] = useState(4);
  const [backdropOpen, setBackdrop] = useState(false);
  const [openPasswordResetModal, setPasswordResetModal] = useState(false);
  const [designation, setDesignation] = useState("");
  const [businessCategories, setBusinessCategories] = useState([]);
  const [companyTypes, setCompanyTypes] = useState("");
  const [countryId, setCountryId] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [headQuarters, setHeadQuarters] = useState(null);
  const [headQuarterStates, setHeadQuarterStates] = useState(null);
  const Labels = useSelector((state) => state.allLabels.labels);
  const usertype = localStorage.getItem("userType");

  // const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const [slogan, setSlogan] = useState("");


  const resettedByAdmin = localStorage.getItem("resettedByAdmin");
  const [CompanyBranch, setCompanyBranch] = useState([]);
  const [openBranchesAddModel, setopenBranchesModel] = useState(false);
  const [updatecompany, setupdatecompany] = useState({});
  const [openEditBranchmodal, setopenEditBranchmodal] = useState(false);
  const [companySize, setCompanySize] = useState("");


  const [sloganimg,setsloganimg] = useState('')
  const [profileimg,setprofileimg] = useState('')

  // -------------Set country code ------------------
  const [countryCode, setCountryCode] = useState("");
  const [companyTypeKey, setcompanyTypeKey] = useState("");

  const [updateState, setUpdateState] = useRecoilState(updateStateAtom);

  const [companyinfobackup, setcompanyinfobackup] = useState({});

  // Initial API call for fetching data
  useEffect(() => {
    getEmployerData();
  }, [updateState]);

  // Setting default language
  useEffect(() => {
    setEmployerData({
      ...employerData,
      language: localStorage.getItem("language"),
    });
  }, [employerData?.companyName]);

  // company info btn feature
  useEffect(() => {
    if (isMounted.current) {
      if (!isApiUpdate.current) {
        setButtonDisabled1(false);
      } else {
        setButtonDisabled1(true);
        isApiUpdate.current = false; // Reset API update flag
      }
    } else {
      isMounted.current = true; // Mark as mounted after first render
    }

    if (Object.keys(companyinfobackup).length > 0) {
      if (
        companyinfobackup.companyName == employerData?.companyName &&
        companyinfobackup.businessCategory == businessCategories &&
        companyinfobackup.companySize == companySize &&
        JSON.stringify(companyinfobackup.headQuarters) == JSON.stringify(headQuarters) &&
        JSON.stringify(companyinfobackup.stateId) == JSON.stringify(stateId) &&
        companyinfobackup.headQuarterCity == employerData?.headQuarterCity &&
        companyinfobackup.zip == employerData?.zip &&
        companyinfobackup.website == employerData?.website
      ) {
        setButtonDisabled1(true);
      } else {
        setButtonDisabled1(false);
      }
    }
  }, [
    employerData?.companyName,
    businessCategories,
    companySize,
    headQuarters,
    stateId,
    employerData?.headQuarterCity,
    employerData?.zip,
    employerData?.website,
  ]);

  // Misc btn feature
  useEffect(() => {
    if (Object.keys(companyinfobackup).length > 0) {
      if (
        companyinfobackup.showProfile == showProfile &&
        companyinfobackup.contactBymail == contactBymail &&
        companyinfobackup.receiveCoverLetter == receiveCoverLetter &&
        companyinfobackup.JobPostDuration == JobPostDuration
      ) {
        setButtonDisabled5(true);
      } else {
        setButtonDisabled5(false);
      }
    }
  }, [showProfile, contactBymail, JobPostDuration, receiveCoverLetter]);

  // About Company btn feature
  useEffect(() => {
    if (Object.keys(companyinfobackup).length > 0) {
      if (
        companyinfobackup.companyDescription == employerData?.companyDescription
      ) {
        setButtonDisabled3(true);
      } else {
        setButtonDisabled3(false);
      }
    }
  }, [employerData?.companyDescription]);

  const tokens = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("employerToken"),
    },
  };

  const ProfanityList = useSelector((state) => state.Profanity.wordlist);
  const isMounted = useRef(false);
  const isApiUpdate = useRef(false);



  const getEmployerData = async () => {
    isApiUpdate.current = true;
    setBackdrop(true);
    const result = await axiosAPI.post("/getCompanyProfile");
    const { status, data, message } = result.data;
    if (status) {
      setEmployerData(data);
      setsloganimg(data?.slogan)
      setprofileimg(data?.logoPath)
      setcompanyTypeKey(data?.companyTypeKey);
      setDesignation( data.contactPersonDesignation ? data.contactPersonDesignation : 0 );
      setIsChecked(data.twoFactorPreferred == 1 ? true : false);
      setShowProfile(data.showProfile ? data.showProfile : 0);
      setShowEmail(data.contactBymail ? data.contactBymail : 0);
      setExpectCoverLetter( data.receiveCoverLetter ? data.receiveCoverLetter : 0);
      setJobPostDuration(data.JobPostDuration ? data.JobPostDuration : 4);
      setCountryId( data.countryId ? { id: data.countryId, name: data.countryName } : null);
      setStateId(
        data.headQuarterState
          ? { id: data.headQuarterState, name: data.headOfStateName }
          : null
      );
      setHeadQuarters(
        data?.headQuarterCountry
          ? { id: data?.headQuarterCountry, name: data.headOfCountryName }
          : null
      );

      setCompanySize(data.companySize ? data.companySize : "");
      setCompanyBranch(data?.locations);
      setBackdrop(false);
      setCountryCode(data.countryCode ? data.countryCode : "1");
      setCompanyTypes(data.companyType ? data.companyType : "");
      setBusinessCategories(data.businessCategory ? data.businessCategory : "");

      setcompanyinfobackup({
        companyName: data?.companyName,
        businessCategory: data?.businessCategory,
        companySize: data?.companySize,
        headQuarters: {
          id: data?.headQuarterCountry,
          name: data?.headOfCountryName,
        },

        stateId: { id: data?.headQuarterState, name: data?.headOfStateName },
        headQuarterCity: data?.headQuarterCity,
        zip: data?.zip,
        website: data?.website,

        businessCategory: data?.businessCategory,

        showProfile: data?.showProfile,
        contactBymail: data?.contactBymail,
        receiveCoverLetter: data?.receiveCoverLetter,
        JobPostDuration: data?.JobPostDuration,
        companyDescription: data?.companyDescription,
        companyBranches: data?.companyBranches,
        contactBymail:data?.contactBymail,
        showProfile:data?.showProfile,
        receiveCoverLetter:data?.receiveCoverLetter,
        JobPostDuration:data?.JobPostDuration,
        
      });
    } else {
      setBackdrop(false);
    }
  };

  const handleChange = (e) => {
    if (e.target.name == "companyDescription") {
      setButtonDisabled3(false);
    }

    setEmployerData({
      ...employerData,
      [e.target.name]: profanityfn(ProfanityList, e.target.value),
    });
  };

  // --------------------Update Profile ----------------------------------------------
  const getEmployerProfileData = async () => {
    isApiUpdate.current = true;
    const result = await axiosAPI.post("/getCompanyProfile");
    const { status, data, message } = result.data;

    if (status) {
      setDesignation(
        data.contactPersonDesignation ? data.contactPersonDesignation : 0
      );
      setCountryCode(data.countryCode ? data.countryCode : "1");
      setCompanyTypes(data.companyType ? data.companyType : "");
      setCompanySize(data.companySize ? data.companySize : "");
      setBusinessCategories(data.businessCategory ? data.businessCategory : "");
      setHeadQuarters(
        data?.headQuarterCountry
          ? { id: data?.headQuarterCountry, name: data.headOfCountryName }
          : null
      );
      setStateId(
        data.headQuarterState
          ? { id: data.headQuarterState, name: data.headOfStateName }
          : null
      );
      setEmployerData({ ...employerData, website: data?.website });

      setcompanyinfobackup({
        companyName: data?.companyName,
        businessCategory: data?.businessCategory,
        companySize: data?.companySize,
        headQuarters: {
          id: data?.headQuarterCountry,
          name: data?.headOfCountryName,
        },
        stateId: { id: data?.headQuarterState, name: data?.headOfStateName },
        headQuarterCity: data?.headQuarterCity,
        website: data?.website,
        zip: data?.zip,
        businessCategory: data?.businessCategory,

        showProfile: data?.showProfile,
        contactBymail: data?.contactBymail,
        receiveCoverLetter: data?.receiveCoverLetter,
        JobPostDuration: data?.JobPostDuration,
        companyDescription: data?.companyDescription,
      });
    }
  };

  const profanityCheckerProfile = () => {
    let profanityDetected;
    for (const key in employerData) {
      if (profanitySend(ProfanityList, employerData[key])) {
        profanityDetected = true;
        break;
      } else {
        profanityDetected = false;
      }
    }

    if (profanityDetected == false) {
      updateEmployerProfile();
    } else {
      warningNotify(Labels.ProfanityLabel);
    }
  };

  const updateEmployerProfile = async () => {
    setButtonDisabled1(true);
    employerData.userType = localStorage.getItem("userType");

    if (designation) {
      employerData.contactPersonDesignation = designation;
    }
    if (countryCode) {
      employerData.countryCode = countryCode;
    }
    if (companyTypes) {
      employerData.companyType = companyTypes;
    }
    if (companySize) {
      employerData.companySize = companySize;
    }
    if (businessCategories.length !== 0) {
      employerData.businessCategory = businessCategories
        .toString()
        .replace(/(^,|,$|,,+)/g, "");
    } else {
      employerData.businessCategory = null;
    }
    if (true) {
      employerData.headQuarterCountry = headQuarters?.id;
    }
    if (true) {
      employerData.headQuarterState = stateId?.id;
    }

    if (!employerData?.companyName) {
      errorNotify(Labels.companyNameRequired);
      setButtonDisabled1(false);
    } else if (employerData?.companyName?.length > 200) {
      errorNotify(Labels.companyNameValidation);
      setButtonDisabled1(false);
    } else if (!stateId?.id) {
      errorNotify(Labels?.SelectState);
      setButtonDisabled1(false);
    } else {
      const result = await axiosAPI.post("/updateEmpProfile", employerData);
      const { success, message } = result.data;
      if (success) {
        succesNotify(message);
        getEmployerProfileData();
        setUpdateState(!updateState);
        routeChange(Math.random());
      } else {
        setButtonDisabled1(false);
        errorNotify(message);
      }
    }
  };

  // --------------------Update Description ------------------------------------------
  const getCompanyDescription = async () => {
    isApiUpdate.current = true;
    const result = await axiosAPI.post("/getCompanyProfile");
    const { status, data, message } = result.data;

    if (status) {
      setEmployerData({
        ...employerData,
        companyDescription: data?.companyDescription,
      });
      setcompanyinfobackup({
        companyName: data?.companyName,
        businessCategory: data?.businessCategory,
        companySize: data?.companySize,
        headQuarters: {
          id: data?.headQuarterCountry,
          name: data?.headOfCountryName,
        },
        stateId: { id: data?.headQuarterState, name: data?.headOfStateName },
        headQuarterCity: data?.headQuarterCity,
        website: data?.website,

        businessCategory: data?.businessCategory,

        showProfile: data?.showProfile,
        contactBymail: data?.contactBymail,
        receiveCoverLetter: data?.receiveCoverLetter,
        JobPostDuration: data?.JobPostDuration,
        companyDescription: data?.companyDescription,
      });
      setButtonDisabled3(true);
    }
  };

  const profanityCheckerCompanyDesc = () => {
    let profanityDetected;
    for (const key in employerData) {
      if (profanitySend(ProfanityList, employerData[key])) {
        profanityDetected = true;
        break;
      } else {
        profanityDetected = false;
      }
    }

    if (profanityDetected == false) {
      updateCompanyDescription();
    } else {
      warningNotify(Labels.ProfanityLabel);
    }
  };

  const updateCompanyDescription = async (e) => {
    setButtonDisabled3(true);
    if (businessCategories.length !== 0) {
      employerData.businessCategory = businessCategories
        .toString()
        .replace(/(^,|,$|,,+)/g, "");
    } else {
      employerData.businessCategory = null;
    }

    if (!employerData?.companyDescription) {
      setButtonDisabled3(false);
      return errorNotify(Labels.writeCompanyDescription);
    } else if (employerData?.companyDescription.length > 7000) {
      setButtonDisabled3(false);
      errorNotify(Labels.companyDescriptionValidation);
    } else {
      setEmployerData({
        ...employerData,
        language: localStorage.getItem("language"),
      });

      const result = await axiosAPI.post("/updateEmpProfile", employerData);
      const { success, message } = result.data;
      if (success) {
        succesNotify(message);
        // getEmployerData();
        getCompanyDescription();
      } else {
        setButtonDisabled3(false);
        errorNotify(message);
      }
    }
  };


  // --------------------Update  Miscellaneous ---------------------------------------
  const getMiscellaneousData = async () => {
    isApiUpdate.current = true;
    const result = await axiosAPI.post("/getCompanyProfile");
    const { status, data, message } = result.data;

    if (status) {
      setShowEmail(data.contactBymail);
      setShowProfile(data.showProfile);
      setExpectCoverLetter(data.receiveCoverLetter);
      setJobPostDuration(data.JobPostDuration ? data.JobPostDuration : 4);
      setcompanyinfobackup({
        companyName: data?.companyName,
        businessCategory: data?.businessCategory,
        companySize: data?.companySize,
        headQuarters: {
          id: data?.headQuarterCountry,
          name: data?.headOfCountryName,
        },
        stateId: { id: data?.headQuarterState, name: data?.headOfStateName },
        headQuarterCity: data?.headQuarterCity,
        website: data?.website,

        businessCategory: data?.businessCategory,

        showProfile: data?.showProfile,
        contactBymail: data?.contactBymail,
        receiveCoverLetter: data?.receiveCoverLetter,
        JobPostDuration: data?.JobPostDuration,
        companyDescription: data?.companyDescription,
      });
      setButtonDisabled5(true);
    }
  };

  const profanityCheckerMiscellaneous = () => {
    let profanityDetected;
    for (const key in employerData) {
      if (profanitySend(ProfanityList, employerData[key])) {
        profanityDetected = true;
        break;
      } else {
        profanityDetected = false;
      }
    }

    if (profanityDetected == false) {
      updateMiscellaneous();
    } else {
      warningNotify(Labels.ProfanityLabel);
    }
  };

  const updateMiscellaneous = async () => {
    setButtonDisabled5(true);
    try {
      if (businessCategories.length !== 0) {
        employerData.businessCategory = businessCategories
          .toString()
          .replace(/(^,|,$|,,+)/g, "");
      } else {
        employerData.businessCategory = null;
      }

      if (showProfile) {
        employerData.showProfile = showProfile;
      }

      if (contactBymail) {
        employerData.contactBymail = contactBymail;
      }

      if (receiveCoverLetter) {
        employerData.receiveCoverLetter = receiveCoverLetter;
      }

      if (JobPostDuration) {
        employerData.JobPostDuration = JobPostDuration;
      }

      setEmployerData({
        ...employerData,
        language: localStorage.getItem("language"),
      });
      const result = await axiosAPI.post("/updateEmpProfile", employerData);
      const { success, message } = result.data;
      if (success) {
        succesNotify(message);
        // getEmployerData();
        getMiscellaneousData();
      } else {
        errorNotify(message);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  // --------------------Update Location ---------------------------------------------
  const getCompanyBranchData = async () => {
    isApiUpdate.current = true;
    const result = await axiosAPI.post("/getCompanyProfile");
    const { status, data, message } = result.data;

    if (status) {
      setEmployerData({
        ...employerData,
        companyBranches: data?.companyBranches,
      });
      setcompanyinfobackup({
        companyName: data?.companyName,
        businessCategory: data?.businessCategory,
        companySize: data?.companySize,
        headQuarters: {
          id: data?.headQuarterCountry,
          name: data?.headOfCountryName,
        },
        stateId: { id: data?.headQuarterState, name: data?.headOfStateName },
        headQuarterCity: data?.headQuarterCity,
        website: data?.website,

        businessCategory: data?.businessCategory,

        showProfile: data?.showProfile,
        contactBymail: data?.contactBymail,
        receiveCoverLetter: data?.receiveCoverLetter,
        JobPostDuration: data?.JobPostDuration,
        companyDescription: data?.companyDescription,
      });
    }
  };

  const updateLocation = async (e) => {
    setButtonDisabled2(true);
    employerData.countryid = countryId?.id;
    employerData.stateid = stateId?.id;
    if (businessCategories.length !== 0) {
      employerData.businessCategory = businessCategories
        .toString()
        .replace(/(^,|,$|,,+)/g, "");
    } else {
      employerData.businessCategory = null;
    }
    if (!countryId?.id) {
      errorNotify(Labels.countryRequired);
      setButtonDisabled2(false);
    } else if (!stateId?.id) {
      errorNotify(Labels.stateRequired);
      setButtonDisabled2(false);
    } else {
      const result = await axiosAPI.post("/updateEmpProfile", employerData);
      const { success, message } = result.data;
      if (success) {
        succesNotify(message);
        getEmployerData();
        setButtonDisabled2(false);
      } else {
        setButtonDisabled2(false);
      }
    }
  };

  // --------------------Update Additional Information----------------------
  const updateCompanyAdditionalInformations = async () => {
    setButtonDisabled4(true);
    employerData.headQuarterCountry = headQuarters?.id;
    if (businessCategories.length !== 0) {
      employerData.businessCategory = businessCategories
        .toString()
        .replace(/(^,|,$|,,+)/g, "");
    } else {
      employerData.businessCategory = null;
    }
    if (!headQuarters) {
      errorNotify(Labels.Headquartersisrequired);
      setButtonDisabled4(false);
    } else {
      const result = await axiosAPI.post("/updateEmpProfile", employerData);
      const { success, message } = result.data;
      if (success) {
        succesNotify(message);
        getEmployerData();
        setButtonDisabled4(false);
      } else {
        setButtonDisabled4(false);
      }
    }
  };

  // --------------------Update Slogan-------------------
  const allowedTypes = ["image/jpeg", "image/png", "image/JPG"];
  const maxFileSize = 3145728;

  const [selectSlogan, setSelectSolgan] = useState(false);
  const [sloganname, setSloganname] = useState(null);

  const [openSlogan, setOpenSlogan] = useState(false);

  const onChange1 = (e) => {
    const datatypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/jpg",
      "image/webp",
    ];
    if (!datatypes.includes(e.target.files[0].type)) {
      warningNotify(Labels.invaildFile);
    } else {
      e.preventDefault();
      setOpenSlogan(false);
      setSelectSolgan(true);
      let files;
      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files[0];
      }
      setSloganname(files);
      const reader = new FileReader();
      reader.onload = () => {
        setSlogan(reader.result);
      };
      reader.readAsDataURL(files);
    }
  };

  const handleUploadSlogan = async () => {
    setimgbtndisabled(true);
    const croppedDataURL = cropperRef.current.cropper
      .getCroppedCanvas()
      .toDataURL();
    updateSlogan(croppedDataURL, sloganname, usertype);
  };

  const updateSlogan = async (manipulatedDataURL, sloganname, usertype) => {
    const formData1 = new FormData();
    const blob1 = await fetch(manipulatedDataURL).then((r) => r.blob());
    formData1.append("slogan", blob1);
    formData1.append("usertype", usertype);

    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/jpg",
      "image/webp",
    ];
    // const maxFileSize = 3145728;

    if (!allowedTypes.includes(blob1.type)) {
      return errorNotify(Labels.invaildFile);
    }

    const blob3 = formData1.get("slogan"); // Replace 'yourBlobField' with the actual field name

    // Create a proper File from the Blob
    const fileName = sloganname.name; // Replace with your desired file name
    const mimeType = sloganname.type; // Replace with your desired MIME type

    const file = new File([blob3], fileName, { type: mimeType });

    const formData3 = new FormData();
    formData3.append("slogan", file);
    formData3.append("userType", usertype);
    formData3.append("language", localStorage.getItem("language"));
    try {
      const response = await axiosupload.post(
        "/updateEmpProfile",
        // employerData,
        formData3,
        tokens
      );
      const { success, message } = response.data;
      if (success === true) {
        succesNotify(message);
        setSelectSolgan(false);
        setOpenSlogan(false);
        setimgbtndisabled(false);
        getEmployerData();
        // setApiCall(Math.random());
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const [Ischecked, setIsChecked] = useState(false);

  const handleToggle = () => {
    setIsChecked((prevChecked) => {
      sendValueToAPI(!prevChecked);
      return !prevChecked;
    });
  };

  const sendValueToAPI = (value) => {
    axiosAPI
      .post("/enableDisableTFA", { twoFactorPreferred: value ? 1 : 0 }, tokens)
      .then((res) => {
      })
      .catch((err) => {
      });
  };

  //  =-------------------------------------delete Company Branch-------------------------------
  const deleteCompanyBranch = async (branchid) => {
    try {
      // Call your editCompanyBranch API here to delete the branch
      const result = await axiosAPI.post("/deleteCompanyBranch", { branchid });
      const { status, message } = result.data;

      if (status) {
        // Update employerData after successful deletion
        const updatedBranches = employerData.companyBranches.filter(
          (branch) => branch.branchid !== branchid
        );

        setEmployerData((prevData) => ({
          ...prevData,
          companyBranches: updatedBranches,
        }));
      } else {
        console.error("Error deleting branch:", message);
        // Handle error as needed
      }
    } catch (error) {
      console.error("Error deleting branch:", error);
      // Handle error as needed
    }
  };

  const closeBranchesModal = () => {
    setopenBranchesModel(false);
    getCompanyBranchData();
   
  };

  const closeEditcompanyBranchmodal = () => {
    setopenEditBranchmodal(false);
    getCompanyBranchData();
    
  };

  const EditcomapnyBranch = (data) => {
    setupdatecompany(data);
    setopenEditBranchmodal(usertype === "Recruiter" ? false : true);
  };

  const [image, setImage] = useState("");
  const [selectImage, setSelectImage] = useState(false);
  const [imagename, setImagename] = useState(null);
  const cropperRef = createRef();
  const [openImage, setOpenImage] = useState(false);

  const onChange = (e) => {
    const datatypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/jpg",
      "image/webp",
    ];
    if (!datatypes.includes(e.target.files[0].type)) {
      warningNotify(Labels.invaildFile);
    } else {
      e.preventDefault();
      setOpenImage(false);
      setSelectImage(true);
      let files;
      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files[0];
      }

      setImagename(files);
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(files);
    }
  };

  const handleUpload = async () => {
    setimgbtndisabled(true);
    const croppedDataURL = cropperRef.current.cropper
      .getCroppedCanvas()
      .toDataURL();
    uploadProfilePicture(croppedDataURL, imagename, usertype);
  };

  const [imgbtndisabled, setimgbtndisabled] = useState(false);

  const uploadProfilePicture = async (
    manipulatedDataURL,
    imagename,
    usertype
  ) => {
    const formData = new FormData();
    const blob = await fetch(manipulatedDataURL).then((r) => r.blob());
    formData.append("images", blob);
    formData.append("usertype", usertype);

    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/jpg",
      "image/webp",
    ];

    const maxFileSize = 3145728;

    if (!allowedTypes.includes(blob.type)) {
      // throw new Error(Labels.invaildFile);
      return errorNotify(Labels.invaildFile);
    }
    if (blob.size > maxFileSize) {
      // throw new Error(Labels.imageValidation);
      return errorNotify(Labels.imageValidation);
    } else {
      const blob2 = formData.get("images"); // Replace 'yourBlobField' with the actual field name

      // Create a proper File from the Blob
      const fileName = imagename.name; // Replace with your desired file name
      const mimeType = imagename.type; // Replace with your desired MIME type

      const file = new File([blob2], fileName, { type: mimeType });

      const formData2 = new FormData();

      formData2.append("images", file);
      formData2.append("userType", usertype);
      formData2.append("language", localStorage.getItem("language"));

      try {
        const response = await axiosupload.post(
          "/updateEmpProfile",
          // employerData,
          formData2,
          tokens
        );
        const { success, message } = response.data;
        if (success === true) {
          succesNotify(message);
          setSelectImage(false);
          setimgbtndisabled(false);
          setOpenImage(false);
          getEmployerData();
          // setApiCall(Math.random());
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const [userchangedcountry, setuserchangedcountry] = useState(null);


  return (
    <>
      {/* ------------------------Modals---------------------- */}
      <>
        {openBranchesAddModel === true ? (
          <CompanyBranches
            open={openBranchesAddModel}
            handleClose={closeBranchesModal}
            data={CompanyBranch}
          />
        ) : null}
        {openEditBranchmodal === true ? (
          <EditBranches
            open={openEditBranchmodal}
            handleClose={closeEditcompanyBranchmodal}
            data={updatecompany}
          />
        ) : null}
      </>

      <Grid container spacing={2}>
        <SimpleBackdrop open={backdropOpen} />

        <Grid item xs={12} lg={7}>
          {/* --------------------------------------------------------------------------------------------------------------------------- Company Information-------------------- */}
          <Paper elevation={0}>
            <Typography
              variant="subtitle1"
              sx={{ color: "#2B4947", p: 2, fontWeight: 600 }}
            >
              {Labels?.companyInformation}
            </Typography>

            <Divider />

            <Box sx={{ p: 2 }} className="jobseekerProfile">
              <Grid item xs={12} lg={2.5}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 400, color: "#2B4947" }}
                >
                  {Labels.CompanyLogo}
                </Typography>

                <Box
                  sx={{ position: "relative", display: "inline-block", mt: 2 }}
                >
                  <Avatar
                    sx={{
                      width: 130,
                      height: 120,
                      borderRadius: 1,
                      cursor: usertype !== "Recruiter" ? "cursor" : "cursor",
                      marginRight: "20px", // Adjust margin as needed
                    }}
                    src={`${profileimg}`}
                    onClick={() => setOpenImage(true)}
                  />
                  {usertype !== "Recruiter" && (
                    <IconButton
                      component="label"
                      sx={{
                        position: "absolute",
                        top: "90%",
                        right: "10px", // Adjust this value as needed
                        transform: "translateY(-50%)",
                        padding: 0.5,
                        background: "white",
                        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                        "&:hover": {
                          background: "white",
                        },
                      }}
                    >
                      <CameraAltIcon
                        sx={{
                          color: "black",
                        }}
                      />

                      <InputBase
                        type="file"
                        style={{ display: "none" }}
                        accept="image/jpeg, image/png, image/gif"
                        onChange={onChange}
                      />
                    </IconButton>
                  )}
                </Box>

                <Dialog
                  open={selectImage}
                  onClose={() => setSelectImage(false)}
                >
                  <DialogTitle>
                    <Stack direction="row" justifyContent="flex-end">
                      <Close
                        sx={{ cursor: "pointer" }}
                        onClick={() => setSelectImage(false)}
                      />
                    </Stack>
                  </DialogTitle>

                  <DialogContent>
                    <Box
                      sx={{
                        width: { lg: "100%" },
                        height: { lg: "80%" },
                      }}
                    >
                      <Cropper
                        ref={cropperRef}
                        style={{ height: "100%", width: "100%" }}
                        zoomTo={0.5}
                        initialAspectRatio={1}
                        preview=".img-preview"
                        src={image}
                        viewMode={1}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false}
                        guides={true}
                      />
                      <p>
                        <b>{Labels.maxsize}</b>
                        <br />
                        {Labels.canscroll}
                      </p>
                      <SimpleBackdrop open={imgbtndisabled} />
                    </Box>
                  </DialogContent>

                  <DialogActions sx={{ padding: "25px" }}>
                    <CustomButton
                      onClick={handleUpload}
                      label={Labels.Upload}
                      style={{ padding: "5px 18px" }}
                      disabled={imgbtndisabled}
                    />
                  </DialogActions>
                </Dialog>
              </Grid>

              {/* ------------------------------------------------------------------------------------------------------------------------ Company Name------------------ */}

              <Grid
                container
                spacing={2}
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  lg={5}
                  xl={3}
                  sx={{ position: "relative", top: { xs: "15px", sm: "0" } }}
                >
                  <Typography variant="body2" sx={{ color: "#413E3E" }}>
                    <BusinessOutlinedIcon
                      sx={{
                        color: "#65AC98",
                        margin: "0px 10px",
                        position: "relative",
                        top: "5px",
                      }}
                    />
                    {Labels?.companyName}
                    <span style={{ color: "red" }}> *</span>
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={9} lg={7} xl={9}>
                  <TextField
                    value={employerData?.companyName}
                    onChange={(e) => handleChange(e)}
                    name="companyName"
                    disabled={usertype === "Recruiter"}
                    variant="outlined"
                    fullWidth
                    InputProps={{ style: { height: "45px" } }}
                    sx={{
                      position: "relative",
                      width: "100%",
                      padding: "10px 15px",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "4px", // Adjust border radius if needed
                        border: `2px solid ${
                          employerData?.companyName ==
                          companyinfobackup?.companyName
                            ? "black"
                            : "green"
                        }`,
                        "& fieldset": {
                          border: "none", // Remove the default border
                        },
                        "&:hover": {
                          border: `2px solid ${
                            employerData?.companyName ==
                            companyinfobackup?.companyName
                              ? "black"
                              : "green"
                          }`,
                        },
                        "&.Mui-focused": {
                          border: `2px solid ${
                            employerData?.companyName ==
                            companyinfobackup?.companyName
                              ? "black"
                              : "green"
                          }`,
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>

              {/* ------------------------------------------------------------------------------------------------------------------------ Business category / Company Sector ------------------ */}

              <Grid
                container
                spacing={2}
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  lg={5}
                  xl={3}
                  sx={{ position: "relative", top: { xs: "10px", sm: "0" } }}
                >
                  <Typography variant="body2" sx={{ color: "#413E3E" }}>
                    <CategoryOutlinedIcon
                      sx={{
                        color: "#65AC98",
                        margin: "0px 10px",
                        position: "relative",
                        top: "5px",
                      }}
                    />
                    {Labels?.BusinessIndustry}
                    <span style={{ color: "red" }}> *</span>
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={9} lg={7} xl={9}>
                  {employerData?.userType === "Company" ? (
                    <BusinessCategory
                      companyinfobackup={companyinfobackup?.businessCategory}
                      value={businessCategories}
                      setValue={setBusinessCategories}
                      displayEmpty
                      style={{ width: "100%", fontSize: "14px" }}
                    />
                  ) : (
                    <BusinessCategory
                      value={businessCategories}
                      setValue={setBusinessCategories}
                      style={{ width: "100%", fontSize: "14px" }}
                    />
                  )}
                </Grid>
              </Grid>

              {/* ------------------------------------------------------------------------------------------------------------------------ Company Size------------------ */}
              <Grid
                container
                spacing={2}
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  lg={5}
                  xl={3}
                  sx={{ position: "relative", top: { xs: "20px", sm: "0" } }}
                >
                  <Typography variant="body2" sx={{ color: "#413E3E" }}>
                    <AssessmentIcon
                      sx={{
                        color: "#65AC98",
                        margin: "0px 10px",
                        position: "relative",
                        top: "5px",
                      }}
                    />
                    {Labels?.CompanySize}
                    <span style={{ color: "red" }}> *</span>
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={9} lg={7} xl={9}>
                  <CompanySize
                    companyinfobackup={companyinfobackup?.companySize}
                    value={companySize}
                    setValue={setCompanySize}
                    disabled={usertype === "Recruiter" ? true : false}
                    style={{ width: "100%", fontSize: "14px" }}
                  />
                </Grid>
              </Grid>

              {/* ------------------------------------------------------------------------------------------------------------------------ Headquarters Section --------------- */}
              <Grid
                container
                spacing={2}
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  lg={5}
                  xl={3}
                  sx={{ position: "relative", top: { xs: "12px", sm: "0" } }}
                >
                  <Typography variant="body2" sx={{ color: "#413E3E" }}>
                    <PublicOutlinedIcon
                      sx={{
                        color: "#65AC98",
                        margin: "0px 10px",
                        position: "relative",
                        top: "5px",
                      }}
                    />
                    {Labels?.Headquarters}
                    <span style={{ color: "red" }}> *</span>
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={9} lg={7} xl={9}>
                  <Country
                    companyinfobackup={companyinfobackup?.headQuarters?.id}
                    value={headQuarters}
                    setValue={setHeadQuarters}
                    disabled={usertype === "Recruiter" ? true : false}
                    userchangedcountry={userchangedcountry}
                    setuserchangedcountry={setuserchangedcountry}
                  />
                </Grid>
              </Grid>

              {/* ------------------------------------------------------------------------------------------------------------------------ State Section  ---------------*/}
              <Grid
                container
                spacing={2}
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  lg={5}
                  xl={3}
                  sx={{ position: "relative", top: { xs: "20px", sm: "0" } }}
                >
                  <Typography variant="body2" sx={{ color: "#413E3E" }}>
                    <TourOutlinedIcon
                      sx={{
                        color: "#65AC98",
                        margin: "0px 10px",
                        position: "relative",
                        top: "5px",
                      }}
                    />
                    {Labels?.State}
                    <span style={{ color: "red" }}> *</span>
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={9} lg={7} xl={9}>
                  <State
                    companyinfobackup={companyinfobackup?.stateId?.id}
                    value={stateId}
                    setValue={setStateId}
                    country={headQuarters}
                    userchangedcountry={userchangedcountry}
                    setuserchangedcountry={setuserchangedcountry}
                  />
                </Grid>
              </Grid>

              {/* ------------------------------------------------------------------------------------------------------------------------ City Section  ---------------*/}
              <Grid
                container
                spacing={2}
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  lg={5}
                  xl={3}
                  sx={{ position: "relative", top: { xs: "10px", sm: "0" } }}
                >
                  <Typography variant="body2" sx={{ color: "#413E3E" }}>
                    <ApartmentIcon
                      sx={{
                        color: "#65AC98",
                        margin: "0px 10px",
                        position: "relative",
                        top: "5px",
                      }}
                    />
                    {Labels?.City}
                    {/* <span style={{ color: "red" }}> *</span> */}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={9} lg={7} xl={9}>
                  <TextField
                    placeholder={Labels?.City}
                    value={employerData?.headQuarterCity || ""}
                    onChange={(e) => handleChange(e)}
                    name="headQuarterCity"
                    readOnly={usertype == "Recruiter" ? true : false}
                    disabled={usertype == "Recruiter" ? true : false}
                    variant="outlined"
                    InputProps={{ style: { height: "45px" } }}
                    sx={{
                      position: "relative",
                      left: "15px",
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "4px", // Adjust border radius if needed
                        border: `2px solid ${
                          (companyinfobackup?.headQuarterCity || "") ==
                          (employerData?.headQuarterCity || "")
                            ? "black"
                            : "green"
                        }`,
                        "& fieldset": {
                          border: "none", // Remove the default border
                        },
                        "&:hover": {
                          border: `2px solid ${
                            (companyinfobackup?.headQuarterCity || "") ==
                            (employerData?.headQuarterCity || "")
                              ? "black"
                              : "green"
                          }`,
                        },
                        "&.Mui-focused": {
                          border: `2px solid ${
                            (companyinfobackup?.headQuarterCity || "") ==
                            (employerData?.headQuarterCity || "")
                              ? "black"
                              : "green"
                          }`,
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>

              {/* ------------------------------------------------------------------------------------------------------------------------ Pincode --------------- */}

              <Grid
                container
                spacing={2}
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  lg={5}
                  xl={3}
                  sx={{ position: "relative", top: { xs: "20px", sm: "0" } }}
                >
                  <Typography variant="body2" sx={{ color: "#413E3E" }}>
                    <LocationOnIcon
                      sx={{
                        color: "#65AC98",
                        margin: "0px 10px",
                        position: "relative",
                        top: "5px",
                      }}
                    />
                    {Labels?.Zipcode}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={9} lg={7} xl={9}>
                  <TextField
                    placeholder={Labels?.Zipcode}
                    name="zip"
                    value={employerData?.zip}
                    onChange={(e) => handleChange(e)}
                    inputProps={{
                      maxLength: 10, // Set maximum character length
                    }}
                    InputProps={{ style: { height: "45px" } }}
                    onKeyDown={(e) => {
                      if (
                        !/[0-9]/.test(e.key) && // Only allow numeric keys
                        e.key !== "Backspace" &&
                        e.key !== "Delete" &&
                        e.key !== "ArrowLeft" &&
                        e.key !== "ArrowRight" &&
                        e.key !== "ArrowUp" && // Allow ArrowUp for increment
                        e.key !== "ArrowDown" && // Allow ArrowDown for decrement
                        e.key !== "Tab" &&
                        e.key !== "+"
                      ) {
                        e.preventDefault();
                      }
                    }}
                    variant="outlined"
                    sx={{
                      margin: "10px 0px",
                      position: "relative",
                      left: "15px",
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "4px", // Adjust border radius if needed
                        border: `2px solid ${
                          (companyinfobackup?.zip || "") ==
                          (employerData?.zip || "")
                            ? "black"
                            : "green"
                        }`,
                        "& fieldset": {
                          border: "none", // Remove the default border
                        },
                        "&:hover": {
                          border: `2px solid ${
                            (companyinfobackup?.zip || "") ==
                            (employerData?.zip || "")
                              ? "black"
                              : "green"
                          }`,
                        },
                        "&.Mui-focused": {
                          border: `2px solid ${
                            (companyinfobackup?.zip || "") ==
                            (employerData?.zip || "")
                              ? "black"
                              : "green"
                          }`,
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>

              {/* ------------------------------------------------------------------------------------------------------------------------ Website --------------- */}

              <Grid
                container
                spacing={2}
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  lg={5}
                  xl={3}
                  sx={{ position: "relative", top: { xs: "12px", sm: "0" } }}
                >
                  <Typography variant="body2" sx={{ color: "#413E3E" }}>
                    <LanguageIcon
                      sx={{
                        color: "#65AC98",
                        margin: "0px 10px",
                        position: "relative",
                        top: "5px",
                      }}
                    />
                    {Labels?.Website}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={9} lg={7} xl={9}>
                  <TextField
                    placeholder={Labels?.Website}
                    name="website"
                    value={employerData?.website}
                    onChange={(e) => handleChange(e)}
                    disabled={usertype === "Recruiter" ? true : false}
                    variant="outlined"
                    InputProps={{ style: { height: "45px" } }}
                    sx={{
                      position: "relative",
                      margin: "0px 0px 10px",
                      left: "15px",
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "4px", // Adjust border radius if needed
                        border: `2px solid ${
                          (companyinfobackup?.website || "") ==
                          (employerData?.website || "")
                            ? "black"
                            : "green"
                        }`,
                        "& fieldset": { border: "none" },
                        "&:hover": {
                          border: `2px solid ${
                            (companyinfobackup?.website || "") ==
                            (employerData?.website || "")
                              ? "black"
                              : "green"
                          }`,
                        },
                        "&.Mui-focused": {
                          border: `2px solid ${
                            (companyinfobackup?.website || "") ==
                            (employerData?.website || "")
                              ? "black"
                              : "green"
                          }`,
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>

              {/* ----------------------------------------------------------------------------------------------------------------------- Save Button ------------------ */}

              {usertype !== "Recruiter" && (
                <div style={{ display: "flex", justifyContent: "end" }} >
                  <CustomButton
                    onClick={profanityCheckerProfile}
                    label={Labels?.Save}
                    style={{ padding: "5px 25px" }}
                    disabled={isButtonDisabled1}
                  />
                </div>
              )}
            </Box>
          </Paper>
          {/* --------------------Slogan-------------------- */}
          <Paper elevation={0} sx={{ my: 2, width: "100%" }}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 600, color: "#2B4947", p: 1.5 }}
            >
              {Labels?.Slogan}
            </Typography>
            <Divider />
            <Grid container justifyContent="start">
              <Grid item xs={12} md={8} lg={6}>
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      position: "relative",
                      display: "inline-block",
                      mt: 2,
                    }}
                  >
                    <Avatar
                      sx={{
                        width: "100%",
                        height: 120,
                        borderRadius: `5px`,
                        cursor: usertype !== "Recruiter" ? "pointer" : "cursor",
                        margin: "20px", // Adjust margin as needed
                      }}
                      src={`${sloganimg}`}
                      onClick={() => setOpenSlogan(true)}
                    />
                 
                    {usertype !== "Recruiter" && (
                      <IconButton
                        component="label"
                        sx={{
                          position: "absolute",
                          top: "90%",
                          right: "10px", // Adjust this value as needed
                          transform: "translateY(-50%)",
                          padding: 0.5,
                          background: "white",
                          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                          "&:hover": {
                            background: "white",
                          },
                          "&:active": {
                            backgroundColor: "#1C614E",
                          },
                          "&:disabled": {
                            color: "white",
                            backgroundColor: "#1C614E",
                          },
                        }}
                      >
                        <CameraAltIcon
                          sx={{
                            color: "black",
                          }}
                        />

                        <InputBase
                          type="file"
                          style={{ display: "none" }}
                          accept="image/jpeg, image/png, image/gif"
                          onChange={onChange1}
                        />
                      </IconButton>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Dialog open={selectSlogan} onClose={() => setSelectSolgan(false)}>
              <DialogTitle>
                <Stack direction="row" justifyContent="flex-end">
                  <Close
                    sx={{ cursor: "pointer" }}
                    onClick={() => setSelectSolgan(false)}
                  />
                </Stack>
              </DialogTitle>
              <DialogContent>
                <Box
                  sx={{
                    width: { lg: "100%" },
                    height: { lg: "80%" },
                  }}
                >
                  <Cropper
                    ref={cropperRef}
                    style={{ height: "100%", width: "100%" }}
                    zoomTo={0.5}
                    initialAspectRatio={1}
                    preview=".img-preview"
                    src={slogan}
                    viewMode={3}
                    minCropBoxHeight={10}
                    minCropBoxWidth={20}
                    background={false}
                    responsive={true}
                    autoCropArea={3}
                    checkOrientation={false}
                    guides={true}
                  />

                  <p>
                    <b>{Labels.maxsize}</b>
                    <br />
                    {Labels.canscroll}
                  </p>
                  <SimpleBackdrop open={imgbtndisabled} />
                </Box>
              </DialogContent>
              <DialogActions sx={{ padding: "25px" }}>
                <CustomButton
                  onClick={handleUploadSlogan}
                  label={Labels.Upload}
                  style={{ padding: "5px 18px" }}
                  disabled={imgbtndisabled}
                />
              </DialogActions>
            </Dialog>
          </Paper>

          {/* --------------------About Company-------------------- */}

          <Paper elevation={0} sx={{ my: 2 }}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 600, color: "#2B4947", p: 1.5 }}
            >
              {Labels?.AboutCompany}
            </Typography>
            <Divider />

            <Box sx={{ p: 1.5 }} className="jobseekerProfile">
              <ReactQuill
                theme="snow"
                value={
                  employerData?.companyDescription
                    ? employerData?.companyDescription
                    : ""
                }
                onChange={(e) => {
                  setEmployerData({
                    ...employerData,
                    companyDescription: profanityfn(ProfanityList, e),
                  });
                }}
                style={{ width: "100%", margin: "0 auto" }}

                modules={{
                  toolbar: [
                    [{ header: [1, 2, 3, 4, 5, 6, false] }], // Header styles
                    [{font: [ ] }],
                    [{ size: [] }], // Font size dropdown
                    ["bold", "italic", "underline", "strike"], // Basic text formatting
                    [{ script: "sub" }, { script: "super" }], // Subscript/superscript
                    [{ color: [] }, { background: [] }], // Text and background color
                    [{ list: "ordered" }, { list: "bullet" }], // Ordered/unordered lists
                    [{ indent: "-1" }, { indent: "+1" }], // Indentation
                    [{ align: [] }], // Text alignment 
                  ],
                }}

                formats={[
                  "header",
                  "font",
                  "size",
                  "bold",
                  "italic",
                  "underline",
                  "strike",
                  "script",
                  "color",
                  "background",
                  "list",
                  "indent",
                  "align",
                  "blockquote",
                  "code-block"
                ]}
              />

              {usertype !== "Recruiter" && (
                <div
                  style={{
                    display: "flex",
                    margin: "10px auto",
                    justifyContent: "end",
                  }}
                >
                  <CustomButton
                    onClick={profanityCheckerCompanyDesc}
                    label={Labels?.Save}
                    style={{ padding: "5px 25px" }}
                    disabled={isButtonDisabled3}
                  />
                </div>
              )}
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} lg={5}>
          <Stack
            sx={{
              // height: "120vh",
              overflowY: "auto",
            }}
            className={classes.customColoredScrollbar}
          >
            <Paper elevation={0} sx={{ mb: 2, width: "100%" }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ p: 1.5 }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 600, color: "#2B4947" }}
                >
                  {Labels?.EnableTwoFactor}
                </Typography>
                <Switch
                  checked={Ischecked}
                  color="success"
                  onChange={handleToggle}
                />
              </Stack>
            </Paper>
            {/* --------------Company branch--------------------- */}
            {employerData.companyTypeKey === "Consultancy" ? null : (
              <Paper elevation={0}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ p: 1.5 }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: 600, color: "#2B4947" }}
                  >
                    {Labels?.CompanyLocation}
                  </Typography>
                  {usertype !== "Recruiter" && (
                    <CustomButton
                      label={Labels?.Add}
                      disabled={isButtonDisabled2}
                      sx={{
                        color: "rgba(113, 109, 109, 0.9)",
                        cursor: "pointer",
                      }}
                      onClick={() => setopenBranchesModel(true)}
                    />
                  )}
                </Stack>
                <Divider />
                <Box
                  sx={{ p: 1.5, overflow: "auto" }}
                  className="jobseekerProfile"
                >
                  <Box sx={{ p: 1 }} className="jobseekerProfile">
                    <Grid container spacing={2}>
                      {Array.isArray(employerData.companyBranches) &&
                        employerData.companyBranches.map((branch, index) => (
                          <Grid item xs={12} sm={6} md={6} lg={12} xl={6}>
                            <Box
                              key={index}
                              sx={{
                                px: 1,
                                py: 1,
                                border: "1px solid grey",
                                borderRadius: "7px",
                                minHeight: "130px",
                              }}
                            >
                              <Stack
                                direction="row"
                                alignItems="end"
                                justifyContent="flex-end"
                              >
                                <EditIcon
                                  onClick={() => EditcomapnyBranch(branch)}
                                  sx={{
                                    cursor: "pointer",
                                    fontsize: "1.2rem",
                                    color: "rgba(0, 0, 0, 0.6)",
                                    transition: "0.2s ease",
                                    "&:hover": {
                                      color: "rgba(0, 0, 0, 0.9)", // Change color on hover
                                      transform: "scale(1.1)",
                                    },
                                  }}
                                />
                                <HighlightOffTwoToneIcon
                                  sx={{
                                    cursor:
                                      usertype !== "Recruiter"
                                        ? "pointer"
                                        : "default",
                                    fontsize: "1.2rem",
                                    color: "rgba(0, 0, 0, 0.6)",
                                    transition: "0.2s ease",
                                    display:
                                      usertype !== "Recruiter"
                                        ? `block`
                                        : "none",
                                    "&:hover": {
                                      color: "rgba(0, 0, 0, 0.9)", // Change color on hover
                                      transform: "scale(1.1)",
                                    },
                                  }}
                                  onClick={(event) => {
                                    event.stopPropagation(); // Prevent event propagationsearch-groupListsearch-groupList
                                    deleteCompanyBranch(branch.branchid);
                                  }}
                                />
                              </Stack>

                              <Stack
                                sx={{ position: "relative", bottom: "10px" }}
                              >
                                <Stack direction="row" spacing={1}>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{
                                      color: "rgba(0, 0, 0, 0.59)",
                                      flexBasis: "100px", // Adjust label width
                                      textAlign: "right", // Align label to right
                                    }}
                                  >
                                    {Labels?.Country}:
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color: "rgba(0, 0, 0, 0.59)",
                                      mx: 2,
                                      flexBasis: "300px", // Adjust value width
                                    }}
                                  >
                                    {branch.countryName}
                                  </Typography>
                                </Stack>

                                <Stack direction="row" spacing={1}>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{
                                      color: "rgba(0, 0, 0, 0.59)",
                                      flexBasis: "100px", // Adjust label width
                                      textAlign: "right",
                                    }}
                                  >
                                    {Labels?.State}:
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color: "rgba(0, 0, 0, 0.59)",
                                      mx: 2,
                                      flexBasis: "300px",
                                    }}
                                  >
                                    {branch.stateName}
                                  </Typography>
                                </Stack>

                                <Stack direction="row" spacing={1}>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{
                                      color: "rgba(0, 0, 0, 0.59)",
                                      flexBasis: "100px",
                                      textAlign: "right",
                                    }}
                                  >
                                    {Labels?.City}:
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color: "rgba(0, 0, 0, 0.59)",
                                      mx: 2,
                                      flexBasis: "300px",
                                    }}
                                  >
                                    {branch.city}
                                  </Typography>
                                </Stack>

                                <Stack direction="row" spacing={1}>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{
                                      color: "rgba(0, 0, 0, 0.59)",
                                      flexBasis: "100px",
                                      textAlign: "right",
                                    }}
                                  >
                                    {Labels?.Address}:
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color: "rgba(0, 0, 0, 0.59)",
                                      mx: 2,
                                      flexBasis: "300px",
                                    }}
                                  >
                                    {branch?.address}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </Box>
                          </Grid>
                        ))}
                    </Grid>
                  </Box>
                </Box>
              </Paper>
            )}



            {/* -------------------Miscellaneous----------------------------------------- */}
            <Paper elevation={0} sx={{ mb: 2, my: 2 }}>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 600, color: "#2B4947", p: 1.5 }}
              >
                {Labels?.Miscellaneous}
              </Typography>
              <Divider />
              <Box sx={{ px: 1.5 }} className="jobseekerProfile">
                <label>{Labels?.Canpeoplecontactyoubymail}</label>
                <YesNo
                  placeHolder={Labels.yesOrNo}
                  value={contactBymail}
                  setValue={setShowEmail}
                  setButtonDisabled5={setButtonDisabled5}
                  backup={companyinfobackup?.contactBymail}
                />
              </Box>
              <Box sx={{ px: 1.5 }} className="jobseekerProfile">
                <label>{Labels?.Wouldyouliketoshowyourprofile}</label>
                <YesNo
                  placeHolder={Labels.yesOrNo}
                  value={showProfile}
                  setValue={setShowProfile}
                  setButtonDisabled5={setButtonDisabled5}
                  backup={companyinfobackup?.showProfile}
                />
              </Box>
              <Box sx={{ px: 1.5 }} className="jobseekerProfile">
                <label>{Labels?.DoyouexpecttoreceiveaCoverletter}</label>
                <YesNo
                  placeHolder={Labels.yesOrNo}
                  value={receiveCoverLetter}
                  setValue={setExpectCoverLetter}
                  setButtonDisabled5={setButtonDisabled5}
                  backup={companyinfobackup?.receiveCoverLetter}
                />
              </Box>
              <Box sx={{ px: 1.5}} className="jobseekerProfile">
                <label>{Labels.JobPostDuration}</label>
                <JobPostingDuration
                  placeholder={Labels.JobPostDuration}
                  value={JobPostDuration}
                  setValue={setJobPostDuration}
                  setButtonDisabled5={setButtonDisabled5}
                  backup={companyinfobackup?.JobPostDuration}
                  isProfile={true}
                />
                {/* {usertype !== "Recruiter" && ( */}
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <CustomButton
                    onClick={profanityCheckerMiscellaneous}
                    label={Labels?.Save}
                    style={{ padding: "5px 25px", mt: 2 }}
                    disabled={isButtonDisabled5}
                  />
                </div>
                {/* )} */}
              </Box>
            </Paper>

            {/* --------------Subscription --------------------- */}

            <Paper elevation={0}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ p: 1.5 }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 600, color: "#2B4947" }}
                >
                  {Labels?.Subscriptions}
                </Typography>
              </Stack>
              <Divider />
              <Box sx={{ p: 1.5, opacity: 0.5, pointerEvents: "none" }}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Box
                    sx={{
                      p: 6,
                      border: "1px solid rgba(28, 97, 78, 0.4)",
                      borderRadius: 2,
                    }}
                  />
                  <Box
                    sx={{
                      p: 6,
                      border: "1px solid rgba(28, 97, 78, 0.4)",
                      borderRadius: 2,
                    }}
                  />
                  <Box
                    sx={{
                      p: 6,
                      border: "1px solid rgba(28, 97, 78, 0.4)",
                      borderRadius: 2,
                    }}
                  />
                </Stack>
              </Box>
            </Paper>
            {/* <Paper elevation={0}> */}
            {/* <Grid
                container
                spacing={2}
                justifyContent="space-between"
                flexDirection="row"
                p={2}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ color: "#2B4947", p: 2, fontWeight: 600 }}
                >
                  {Labels?.CompanyLocation}
                </Typography>
              </Grid> */}

            {/* <Divider />
              <Box sx={{ p: 1.5 }} className="jobseekerProfile"> */}
            {/* ------------Country--------------- */}

            {/* <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ my: 1.5 }}
                >
                  <PublicOutlinedIcon
                    sx={{ color: "#65AC98", mr: 1, height: 25, width: 25 }}
                  />
                  <label style={{ color: "#413E3E", width: 230 }}>
                    {Labels?.Country}
                  </label>
                  <RecruiterCountry
                    value={countryId}
                    setValue={setCountryId}
                    setStateId={setStateId}
                    style={{
                      width: "100%",
                      fontSize: "14px",
                    }}
                  />
                </Stack> */}

            {/* ------------State--------------- */}
            {/* <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ my: 1.5 }}
                >
                  <TourOutlinedIcon
                    sx={{ color: "#65AC98", mr: 1, height: 25, width: 25 }}
                  />
                  <label style={{ color: "#413E3E", width: 230 }}>
                    {Labels?.State}
                  </label>
                  <State
                    value={stateId}
                    setValue={setStateId}
                    country={countryId}
                    style={{
                      width: "100%",
                      fontSize: "14px",
                    }}
                  />
                </Stack> */}
            {/* ------------City--------------- */}
            {/* <IconLabelTextField
                  icon={
                    <LocationCityIcon
                      sx={{
                        color: "#65AC98",
                      }}
                    />
                  }
                  InputLabel={Labels?.City}
                  label={Labels?.City}
                  name="city"
                  value={employerData?.city || ""}
                  style={{ width: "100%" }}
                /> */}
            {/* ------------Address--------------- */}
            {/* <IconLabelTextField
                  icon={
                    <HomeOutlinedIcon
                      sx={{
                        color: "#65AC98",
                      }}
                    />
                  }
                  InputLabel={Labels?.Address}
                  label={Labels?.Address}
                  name="address1"
                  value={employerData?.address1 || ""}
                  style={{ width: "100%" }}
                  multiline
                  rows={3}
                /> */}
            {/* ------------Save Button--------------- */}
            {/* {employerData?.userType === "Recruiter" ? null : (
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <CustomButton
                      onClick={updateLocation}
                      label={Labels?.Save}
                      style={{ padding: "5px 25px" }}
                      disabled={isButtonDisabled2}
                    />
                  </div>
                )} */}
            {/* </Box>
            </Paper> */}

            {/* --------------Company additional information --------------------- */}

            {/* <Paper elevation={0} sx={{ my: 2 }}> */}
            {/* <Typography
                variant="subtitle1"
                sx={{ color: "#2B4947", p: 2, fontWeight: 600 }}
              >
                {Labels?.additionalInformation}
              </Typography>
              <Divider /> */}
            {/* <Box sx={{ p: 1.5 }} className="jobseekerProfile"> */}
            {/* ------------Headquarters--------------- */}
            {/* {employerData?.userType === "Recruiter" ? (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ my: 1.5 }}
                  >
                    <PublicOutlinedIcon
                      sx={{ color: "#65AC98", mr: 1, height: 25, width: 25 }}
                    />
                    <label style={{ color: "#413E3E", width: 230 }}>
                      {Labels?.Headquarters}
                    </label>
                    <RecruiterCountry
                      value={headQuarters}
                      setValue={setHeadQuarters}
                      setStateId={setHeadQuarterState}
                      readOnly={usertype === "Recruiter" ? true : false}
                      style={{
                        width: "100%",
                        fontSize: "14px",
                      }}
                    />
                  </Stack>
                ) : (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ my: 1.5 }}
                  >
                    <PublicOutlinedIcon
                      sx={{ color: "#65AC98", mr: 1, height: 25, width: 25 }}
                    />
                    <label style={{ color: "#413E3E", width: 230 }}>
                      {Labels?.Headquarters}
                    </label>
                    <Country
                      value={headQuarters}
                      setValue={setHeadQuarters}
                      setStateId={setHeadQuarterState}
                      readOnly={usertype === "Recruiter" ? true : false}
                      style={{
                        width: "100%",
                        fontSize: "14px",
                      }}
                    />
                  </Stack>
                )} */}
            {/* ------------Headquarters City--------------- */}
            {/* <IconLabelTextField
                  icon={
                    <ApartmentIcon
                      sx={{
                        color: "#65AC98",
                      }}
                    />
                  }
                  InputLabel={Labels?.CompanyHeadquarters}
                  label={Labels?.CompanyHeadquarters}
                  name="headQuarterCity"
                  value={employerData?.headQuarterCity || ""}
                  onChange={(e) => handleChange(e)}
                  readOnly={usertype === "Recruiter" ? true : false}
                  style={{ width: "100%" }}
                /> */}
            {/* ------------Website--------------- */}
            {/* <IconLabelTextField
                  icon={
                    <LanguageIcon
                      sx={{
                        color: "#65AC98",
                      }}
                    />
                  }
                  InputLabel={Labels?.Website}
                  label={Labels?.Website}
                  name="website"
                  value={employerData?.website || ""}
                  onChange={(e) => handleChange(e)}
                  readOnly={usertype === "Recruiter" ? true : false}
                  style={{ width: "100%" }}
                /> */}
            {/* ------------Founded--------------- */}
            {/* <IconLabelTextField
                  icon={
                    <FlagIcon
                      sx={{
                        color: "#65AC98",
                      }}
                    />
                  }
                  InputLabel={Labels?.Founded}
                  label={Labels?.Founded}
                  name="founded"
                  value={employerData?.founded || ""}
                  onChange={(e) => handleChange(e)}
                  readOnly={usertype === "Recruiter" ? true : false}
                  style={{ width: "100%" }}
                /> */}
            {/* ------------companySize--------------- */}
            {/* <IconLabelTextField
                  icon={
                    <AssessmentIcon
                      sx={{
                        color: "#65AC98",
                      }}
                    />
                  }
                  InputLabel={Labels?.CompanySize}
                  label={Labels?.CompanySize}
                  name="companySize"
                  value={employerData?.companySize || ""}
                  onChange={(e) => handleChange(e)}
                  readOnly={usertype === "Recruiter" ? true : false}
                  style={{ width: "100%" }}
                /> */}
            {/* ------------Save Button--------------- */}
            {/* {employerData?.userType === "Recruiter" ? null : (
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <CustomButton
                      onClick={updateCompanyAdditionalInformations}
                      label={Labels?.Save}
                      style={{ padding: "5px 25px" }}
                      disabled={isButtonDisabled4}
                    />
                  </div>
                )} */}
            {/* </Box>
            </Paper> */}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
