import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loginDetails: {},
};
//  localStorage.setItem("resettedByAdmin",resettedByAdmin);

const createJobSeekerloginDetailSlice = createSlice({
  name: "jobSeekerloginDetails",
  initialState,
  reducers: {
    updateLoginDetails: (state, action) => {
      state.loginDetails = action.payload;
    },
  },
}); 

export const { updateLoginDetails } = createJobSeekerloginDetailSlice.actions;

export default createJobSeekerloginDetailSlice.reducer;
