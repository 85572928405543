import React, { Fragment, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";
import { axiosAPI } from "../Axios/Axios";
import {profanityfn}  from "../Profanity/Profanity";

const Gender = ({ value, setValue, style, label }) => {
  const [gender, setgender] = useState([]);
  useEffect(() => {
    const getgender = async () => {
      const result = await axiosAPI.get("/getGender");
      const { success, data } = result.data;
      if (success === true) {
        setgender(data);
      } else {
        setgender([]);
      }
    };
    getgender();
  }, []);
  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)

  const handleChange = (event) => {
    setValue(profanityfn(ProfanityList,event.target.value));
  };
  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <FormControl sx={{ width: "100%" }}>
      <Select
        value={value}
        onChange={handleChange}
        placeholder={label}
        displayEmpty
        variant="outlined"
        sx={{ padding: "10px 12px", fontFamily: "Poppins", ...style }}
        InputProps={{ style: { height: "45px" } }}
      >
        <MenuItem value="" disabled>
          <span style={{ color: "#bcbcbc" }}> {Labels?.Gender}</span>
        </MenuItem>
        {gender &&
          gender.map((val, index) => {
            return (
              <MenuItem key={index} value={val.id}>
                {val.name}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};

export default Gender;
