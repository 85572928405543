import React, { useEffect, useState } from "react";
import InboxIcon from "@mui/icons-material/Inbox";
import ReplyRoundedIcon from "@mui/icons-material/ReplyRounded";
import SearchIcon from "@mui/icons-material/Search";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import {
  Avatar,
  Box,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Skeleton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { axiosAPI } from "../../Axios/Axios";
import { useSelector } from "react-redux";
import { PUBLIC_IMAGE_FOLDER } from "../../Axios/Constant";
import ReplayMessageModal from "./ReplayMessageModal";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import EmailIcon from "@mui/icons-material/Email";
import SendIcon from '@mui/icons-material/Send';
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import { parseISO } from "date-fns";
import ReplayEnquiryModal from "./ReplayEnquireyModal";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "left",
  color: "black",
  boxShadow: "none",
}));

function Messages() {
  const [isApiCall, setApiCall] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(2);
  const [messageDetail, setMessageDetail] = useState({});
  const [sentMessageDetail, setSentMessageDetail] = useState({});
  const [enquiryMessageDetail, setEnquiryMessageDetail] = useState({});
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  // ------------------get inbox message list--------------
  const [messageList, setMessageList] = useState([]);
  const getInboxList = async () => {
    const result = await axiosAPI.get("/inboxMessageList");
    const { success, data } = result.data;
    if (success) {
      setMessageList(data);
    }
  };
  // // ------------------get send message list--------------
  const [sentMessageList, setSentMessageList] = useState([]);
  const [loading, setloading] = useState(true);
  const getSentMessageList = async () => {
    const result = await axiosAPI.get("/sentMessageList");
    const { success, data } = result.data;
    if (success) {
      setSentMessageList(data);
      setloading(false);
    } else {
      setloading(false);
    }
  };

  // -----------get SentEnquiry Messages ---------------
  const [sentEnquiryMessages, setSentEnquiryMessages] = useState([]);
  const getSentEnquiryMessagesList = async () => {
    const result = await axiosAPI.get("/sentEnquiryMessage");
    const { status, data } = result.data;
    if (status) {
      setSentEnquiryMessages(data);
    }
  };

  //---------------sent inbox-message replay--------------
  const [openReplayModal, setReplayModal] = useState(false);
  const [ReplayType,setReplayType] = useState('')
  const [messageData, setMessageData] = useState({});
  const closeReplayModal = () => {
    setReplayModal(false);
  };

  const replayMesssage = (e, data, userType) => {
    e.stopPropagation();
    setMessageData(data);
    setReplayModal(true);
    setReplayType(userType)
  };

  const composeMessage = (e) => {
    const data = {
      fromUser: "1",
      fromUserName: " Admin",
    };
    setMessageData(data);
    setReplayModal(true);
    setReplayType('Admin')
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //  -------------------- Time Difference --------------------
  const getTimeDifference = (createdOn) => {
    const dateNow = new Date();
    const createdDate = parseISO(createdOn);

    // Check if the date is today
    if (
      dateNow.getDate() === createdDate.getDate() &&
      dateNow.getMonth() === createdDate.getMonth() &&
      dateNow.getFullYear() === createdDate.getFullYear()
    ) {
      const hours = createdDate.getHours();
      const minutes = createdDate.getMinutes();
      return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
    } else if (dateNow.getFullYear() === createdDate.getFullYear()) {
      const options = { day: "numeric", month: "short" };
      return createdDate.toLocaleDateString(undefined, options);
    } else {
      const options = { day: "numeric", month: "short", year: "numeric" };
      return createdDate.toLocaleDateString(undefined, options);
    }
  };

  useEffect(() => {
    getInboxList();
    getSentEnquiryMessagesList();
    getSentMessageList();
  }, [isApiCall]);

  const [enquiryReplayModal, setEnquiryReplayModal] = useState(false);
  const [enquiryMessage, setEnquiryMessage] = useState({});
  const enquiryReplay = (e, data) => {
    e.stopPropagation();
    setEnquiryMessage(data);
    setEnquiryReplayModal(true);
  };
  const jobseekerLoginData = useSelector(
    (state) => state.jobSeekerloginDetails.loginDetails
  );

  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <Paper elevation={0} sx={{ my: 3 }}>
      <ReplayMessageModal
        open={openReplayModal}
        handleClose={setReplayModal}
        data={messageData}
        setApiCall={setApiCall}
        ReplayType={ReplayType}
      />
      <ReplayEnquiryModal
        open={enquiryReplayModal}
        handleClose={setEnquiryReplayModal}
        data={enquiryMessage}
        EmployerId={jobseekerLoginData?.uld_id}
        setApiCall={setApiCall}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} lg={3} xl={2}>
          <List component="nav" sx={{ my: 1 }}>
            <ListItemButton
              sx={{
                border: "1px solid #F0E5E5",
                borderRadius: 1,
                my: 1,
                "&:hover": {
                  background: "#6DA09C1F",
                },

                background:
                  selectedIndex === 2 ? "#6DA09C1F !important" : "transparent",
              }}
              selected={selectedIndex === 2}
              onClick={(event) => handleListItemClick(event, 2)}
            >
              <ListItemIcon>
                <EmailIcon />
              </ListItemIcon>
              <ListItemText primary={Labels.Inbox} />
            </ListItemButton>
            <ListItemButton
              sx={{
                border: "1px solid #F0E5E5",
                borderRadius: 1,
                my: 1,
                "&:hover": {
                  background: "#6DA09C1F",
                },

                background:
                  selectedIndex === 0 ? "#6DA09C1F !important" : "transparent",
              }}
              selected={selectedIndex === 0}
              onClick={(event) => handleListItemClick(event, 0)}
            >
              <ListItemIcon>
                <SendIcon />
              </ListItemIcon>
              <ListItemText primary={Labels?.Message} />
            </ListItemButton>

            <ListItemButton
              sx={{
                border: "1px solid #F0E5E5",
                borderRadius: 1,
                my: 1,
                "&:hover": {
                  background: "#6DA09C1F",
                },

                background:
                  selectedIndex === 1 ? "#6DA09C1F !important" : "transparent",
              }}
              selected={selectedIndex === 1}
              onClick={(event) => handleListItemClick(event, 1)}
            >
              <ListItemIcon>
                <HelpCenterIcon />
              </ListItemIcon>
              <ListItemText primary={Labels?.Enquiries} />
            </ListItemButton>

            <ListItemButton
              sx={{
                border: "1px solid #F0E5E5",
                borderRadius: 1,
                my: 1,
                "&:hover": {
                  background: "#6DA09C1F",
                },

                background: "transparent",
              }}
              onClick={(event) => composeMessage()}
            >
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText primary={Labels?.Compose} />
            </ListItemButton>
          </List>
        </Grid>
        {/* ----------------Inbox Messages---------------------- */}
        <Grid
          item
          sm={8}
          lg={9}
          xl={10}
          sx={{ minHeight: "80vh", overflowY: "auto" }}
        >
          {selectedIndex === 0 ? (
            <Box sx={{ p: 1 }}>
              {loading ? (
                <>
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={100}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={100}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={100}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={100}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={100}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={100}
                    style={{ margin: "10px auto" }}
                  />
                </>
              ) : sentMessageList.length === 0 ? (
                <>
                  <Typography>{Labels?.Messagesempty}</Typography>
                </>
              ) : (
                sentMessageList.map((data, index) => (
                  <Accordion
                    sx={{ boxShadow: "none", border: "1px solid #F0E5E5" }}
                    key={index}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Stack
                        direction="row"
                        spacing={4}
                        alignItems="center"
                        width="100%"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item lg={2} md={3} sm={12} xs={12}>
                            <Item>
                              <Avatar
                                src={`${data.fromUserLogo}`}
                                sx={{ mr: 1, height: 40, width: 40 }}
                              />
                              <Typography
                                sx={{ fontSize: "13px", mr: 1, mb: 1 }}
                              >
                                {data?.fromUserName}
                              </Typography>

                              {Array.isArray(data.replies) &&
                                data.replies.length !== 0 && (
                                  <Typography sx={{ fontSize: "13px" }}>
                                    ({data.replies.length})
                                  </Typography>
                                )}
                            </Item>
                          </Grid>
                          <Grid item lg={8} md={7} sm={12} xs={12}>
                            <Item>
                              <Typography sx={{ textAlign: "justify" }}>
                                {data?.message}
                              </Typography>
                            </Item>
                          </Grid>
                          <Grid item lg={2} md={2} sm={12} xs={12}>
                            <Item>
                              <Chip
                                icon={<ReplyRoundedIcon />}
                                label={Labels.reply}
                                variant="outlined"
                                sx={{
                                  cursor: "pointer!important",
                                  mr: 3,
                                }}
                                onClick={(e) =>
                                  replayMesssage(e, data, "sendmsg")
                                }
                              />
                            </Item>
                          </Grid>
                        </Grid>
                      </Stack>
                    </AccordionSummary>

                    {data.replies
                      ? Array.isArray(data.replies) &&
                        data.replies.map((data, index) => (
                          <AccordionDetails
                            sx={{
                              border: "1px solid #F0E5E5",
                              width: "90%",
                              margin: "10px auto",
                            }}
                          >
                            {/* {data.createdOn} */}
                            <Grid container spacing={2}>
                              <Grid item xs={1}>
                                <Item>{Labels.From}</Item>
                              </Grid>
                              <Grid item xs={10}>
                                <Item>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    : &nbsp;
                                    {data?.fromUserLogo?.endsWith(
                                      "/"
                                    ) ? null : (
                                      <Avatar
                                        src={`${data?.fromUserLogo}`}
                                        sx={{
                                          maxWidth: 50,
                                          maxHeight: 50,
                                          borderRadius: "100%%",
                                        }}
                                      />
                                    )}
                                    <Box>
                                      <Typography
                                        variant="subtitle1"
                                        sx={{ fontWeight: 500 }}
                                      >
                                        {data?.fromUserName}
                                      </Typography>
                                    </Box>
                                  </Stack>
                                </Item>
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={1}>
                                <Item>{Labels.To}</Item>
                              </Grid>
                              <Grid item xs={10}>
                                <Item>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    : &nbsp;
                                    {data?.toUserLogo?.endsWith("/") ? null : (
                                      <Avatar
                                        src={`${data?.toUserLogo}`}
                                        sx={{
                                          maxWidth: 50,
                                          maxHeight: 50,
                                          borderRadius: "100%%",
                                        }}
                                      />
                                    )}
                                    <Box>
                                      <Typography
                                        variant="subtitle1"
                                        sx={{ fontWeight: 500 }}
                                      >
                                        {data?.toUserName}
                                      </Typography>
                                    </Box>
                                  </Stack>
                                </Item>
                              </Grid>
                            </Grid>
                            <Grid container spacing={0.5}>
                              <Grid item xs={1}>
                                <Item>{Labels.Message}</Item>
                              </Grid>
                              <Grid item xs={0}>
                                <Item> :</Item>
                              </Grid>
                              <Grid item xs={10}>
                                <Item> {data?.message}</Item>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        ))
                      : ""}
                  </Accordion>
                ))
              )}
            </Box>
          ) : selectedIndex === 1 ? (
            <Grid
              item
              sm={8}
              lg={9}
              xl={10}
              sx={{ minHeight: "80vh", overflowY: "auto" }}
            >
              <Box sx={{ p: 2, mt: 1 }}>
                {loading ? (
                  <>
                    <Skeleton
                      variant="rectangular"
                      width={`100%`}
                      height={100}
                      style={{ margin: "10px auto" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={`100%`}
                      height={100}
                      style={{ margin: "10px auto" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={`100%`}
                      height={100}
                      style={{ margin: "10px auto" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={`100%`}
                      height={100}
                      style={{ margin: "10px auto" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={`100%`}
                      height={100}
                      style={{ margin: "10px auto" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={`100%`}
                      height={100}
                      style={{ margin: "10px auto" }}
                    />
                  </>
                ) : sentEnquiryMessages.length === 0 ? (
                  <>
                    <Typography>{Labels?.Messagesempty}</Typography>
                  </>
                ) : (
                  sentEnquiryMessages.map((data, index) => (
                    <Accordion
                      sx={{ boxShadow: "none", border: "1px solid #F0E5E5" }}
                      key={index}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Stack
                          direction="row"
                          spacing={4}
                          alignItems="center"
                          width="100%"
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid item lg={3} md={6} sm={12} xs={12}>
                              <Item>
                                <Avatar src={`${data.logo}`} sx={{ mr: 1 }} />
                                <Typography>{data?.companyName}</Typography>
                              </Item>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                              <Item>
                                <Typography>
                                  {data?.message.length > 50
                                    ? data?.message.slice(0, 50) + "..."
                                    : data?.message}
                                </Typography>
                              </Item>
                            </Grid>
                            <Grid item lg={3} md={6} sm={12} xs={12}>
                              <Item>
                                <Chip
                                  icon={<ReplyRoundedIcon />}
                                  label={Labels.reply}
                                  variant="outlined"
                                  sx={{
                                    cursor: "pointer!important",
                                    mr: 3,
                                  }}
                                  ay
                                  onClick={(e) => enquiryReplay(e, data)}
                                />
                                <Typography variant="caption">
                                  {data.createdOn}
                                </Typography>
                              </Item>
                            </Grid>
                          </Grid>
                        </Stack>
                      </AccordionSummary>

                      {data?.message.length > 50 ? (
                        <AccordionDetails
                          sx={{
                            border: "1px solid #F0E5E5",
                            color: "#84827D",
                          }}
                        >
                          {data.message}
                        </AccordionDetails>
                      ) : (
                        ""
                      )}

                      {data.replies
                        ? Array.isArray(data.replies) &&
                          data.replies.map((data, index) => (
                            <AccordionDetails
                              sx={{
                                border: "1px solid #F0E5E5",
                                width: "90%",
                                margin: "10px auto",
                              }}
                            >
                              {/* {data.createdOn} */}
                              <Grid container spacing={2}>
                                <Grid item xs={1}>
                                  <Item>{Labels.From}</Item>
                                </Grid>
                                <Grid item xs={10}>
                                  <Item>
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={1}
                                    >
                                      : &nbsp;
                                      {data?.fromUserLogo?.endsWith(
                                        "/"
                                      ) ? null : (
                                        <Avatar
                                          src={`${data?.fromUserLogo}`}
                                          sx={{
                                            maxWidth: 50,
                                            maxHeight: 50,
                                            borderRadius: "100%%",
                                          }}
                                        />
                                      )}
                                      <Box>
                                        <Typography
                                          variant="subtitle1"
                                          sx={{ fontWeight: 500 }}
                                        >
                                          {data?.fromUserName}
                                        </Typography>
                                      </Box>
                                    </Stack>
                                  </Item>
                                </Grid>
                              </Grid>
                              <Grid container spacing={2}>
                                <Grid item xs={1}>
                                  <Item>{Labels.To}</Item>
                                </Grid>
                                <Grid item xs={10}>
                                  <Item>
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={1}
                                    >
                                      : &nbsp;
                                      {data?.toUserLogo?.endsWith(
                                        "/"
                                      ) ? null : (
                                        <Avatar
                                          src={`${data?.toUserLogo}`}
                                          sx={{
                                            maxWidth: 50,
                                            maxHeight: 50,
                                            borderRadius: "100%%",
                                          }}
                                        />
                                      )}
                                      <Box>
                                        <Typography
                                          variant="subtitle1"
                                          sx={{ fontWeight: 500 }}
                                        >
                                          {data?.toUserName}
                                        </Typography>
                                      </Box>
                                    </Stack>
                                  </Item>
                                </Grid>
                              </Grid>
                              <Grid container spacing={0.5}>
                                <Grid item xs={1}>
                                  <Item>{Labels.Message}</Item>
                                </Grid>
                                <Grid item xs={0}>
                                  <Item> :</Item>
                                </Grid>
                                <Grid item xs={10}>
                                  <Item> {data?.message}</Item>
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          ))
                        : ""}
                    </Accordion>
                  ))
                )}
              </Box>
            </Grid>
          ) : selectedIndex === 2 ? (
            <Box sx={{ p: 1 }}>
              {loading ? (
                <>
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={100}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={100}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={100}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={100}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={100}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={100}
                    style={{ margin: "10px auto" }}
                  />
                </>
              ) : messageList.length === 0 ? (
                <>
                  <Typography>{Labels?.Messagesempty}</Typography>
                </>
              ) : (
                messageList.map((data, index) => (
                  <Accordion
                    sx={{ boxShadow: "none", border: "1px solid #F0E5E5" }}
                    key={index}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Stack
                        direction="row"
                        spacing={4}
                        alignItems="center"
                        width="100%"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item lg={2} md={3} sm={12} xs={12}>
                            <Item>
                              <Avatar
                                src={`${data.fromUserLogo}`}
                                sx={{ mr: 1, height: 40, width: 40 }}
                              />
                              <Typography
                                sx={{ fontSize: "13px", mr: 1, mb: 1 }}
                              >
                                {data?.fromUserName}
                              </Typography>

                              {Array.isArray(data.replies) &&
                                data.replies.length !== 0 && (
                                  <Typography sx={{ fontSize: "13px" }}>
                                    ({data.replies.length})
                                  </Typography>
                                )}
                            </Item>
                          </Grid>
                          <Grid item lg={8} md={7} sm={12} xs={12}>
                            <Item>
                              <Typography sx={{ textAlign: "justify" }}>
                                {data?.message}
                              </Typography>
                            </Item>
                          </Grid>
                          <Grid item lg={2} md={2} sm={12} xs={12}>
                            <Item>
                              <Chip
                                icon={<ReplyRoundedIcon />}
                                label={Labels.reply}
                                variant="outlined"
                                sx={{
                                  cursor: "pointer!important",
                                  mr: 3,
                                }}
                                onClick={(e) =>
                                  replayMesssage(e, data, "inbox")
                                }
                              />
                            </Item>
                          </Grid>
                        </Grid>
                      </Stack>
                    </AccordionSummary>

                    {data.replies
                      ? Array.isArray(data.replies) &&
                        data.replies.map((data, index) => (
                          <AccordionDetails
                            sx={{
                              border: "1px solid #F0E5E5",
                              width: "90%",
                              margin: "10px auto",
                            }}
                          >
                            {/* {data.createdOn} */}
                            <Grid container spacing={2}>
                              <Grid item xs={1}>
                                <Item>{Labels.From}</Item>
                              </Grid>
                              <Grid item xs={10}>
                                <Item>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    : &nbsp;
                                    {data?.fromUserLogo?.endsWith(
                                      "/"
                                    ) ? null : (
                                      <Avatar
                                        src={`${data?.fromUserLogo}`}
                                        sx={{
                                          maxWidth: 50,
                                          maxHeight: 50,
                                          borderRadius: "100%%",
                                        }}
                                      />
                                    )}
                                    <Box>
                                      <Typography
                                        variant="subtitle1"
                                        sx={{ fontWeight: 500 }}
                                      >
                                        {data?.fromUserName}
                                      </Typography>
                                    </Box>
                                  </Stack>
                                </Item>
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={1}>
                                <Item>{Labels.To}</Item>
                              </Grid>
                              <Grid item xs={10}>
                                <Item>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    : &nbsp;
                                    {data?.toUserLogo?.endsWith("/") ? null : (
                                      <Avatar
                                        src={`${data?.toUserLogo}`}
                                        sx={{
                                          maxWidth: 50,
                                          maxHeight: 50,
                                          borderRadius: "100%%",
                                        }}
                                      />
                                    )}
                                    <Box>
                                      <Typography
                                        variant="subtitle1"
                                        sx={{ fontWeight: 500 }}
                                      >
                                        {data?.toUserName}
                                      </Typography>
                                    </Box>
                                  </Stack>
                                </Item>
                              </Grid>
                            </Grid>
                            <Grid container spacing={0.5}>
                              <Grid item xs={1}>
                                <Item>{Labels.Message}</Item>
                              </Grid>
                              <Grid item xs={0}>
                                <Item> :</Item>
                              </Grid>
                              <Grid item xs={10}>
                                <Item> {data?.message}</Item>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        ))
                      : ""}
                  </Accordion>
                ))
              )}
            </Box>
          ) : null}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default Messages;
