import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { axiosAPI } from "../Axios/Axios";
import { useSelector } from "react-redux";
import { InputLabel } from "@mui/material";
import { profanityfn } from "../Profanity/Profanity";
import { ElectricalServicesSharp } from "@mui/icons-material";

const SelectJobType = ({
  value,
  setValue,
  style,
  placeholder,
  setButtonDisable = () => {},
  setbackupdata = {},
  backupdata = {},
  backup = "",
  isEdit=true
}) => {
  const [Types, setTypes] = useState([]);

  useEffect(() => {
    const getJobType = async () => {
      const result = await axiosAPI.get("/getTypes");
      const { success, data } = await result.data;
      if (success === true) {
        setTypes(data);
      } else {
        setTypes([]);
      }
    };
    getJobType();
  }, []);

  useEffect(() => {
    if (isNaN(value)) {
      const selectedType = Types?.find((item) => item.name == value);
      setValue(selectedType?.id);
      setbackupdata({ ...backupdata, jobtype: selectedType?.id });
    } else {
      setValue(value);
    }
  }, [value]);

  const ProfanityList = useSelector((state) => state.Profanity.wordlist);

  const handleChange = (event) => {
    setButtonDisable(false);
    setValue(profanityfn(ProfanityList, event.target.value));
  };

  const Labels = useSelector((state) => state.allLabels.labels);

  return (
    <FormControl sx={{ width: "100%" }}>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        onChange={handleChange}
        // placeholder={label}
        fullWidth
        variant="outlined"
        sx={{
          padding: "10px 12px",
          fontSize: "14px", // Apply fontSize style here
          fontFamily: "Poppins",

          border: isEdit?`2px solid ${(backup?backup:"" == value )? "black" : "green"}`:`2px solid black`,
          "& fieldset": {
            border: "none", // Remove the default border
          },
          "&:hover": {
            border: isEdit?`2px solid ${(backup?backup:"" == value) ? "black" : "green"}`:`2px solid black`,
          },
          "&.Mui-focused": {
            border: isEdit?`2px solid ${(backup?backup:"" == value) ? "black" : "green"}`:`2px solid black`,
          },
          ...style,
        }}
        // renderValue={(selected) => {
        //   const selectedType = Types.find((item) => item.id === selected);
        //   return selectedType ? selectedType.name : "contract";
        // }}
        displayEmpty
      >
        <MenuItem value="" disabled>
          <span style={{ color: "#bcbcbc" }}>
            {placeholder || Labels.ContractType}
          </span>
        </MenuItem>
        {Types &&
          Types.map((val, index) => (
            <MenuItem key={index} value={val.id}>
              {val.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default SelectJobType;
