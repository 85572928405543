import React, { Fragment, useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import { axiosAPI } from "../../Axios/Axios";
import { useSelector } from "react-redux";
import { Autocomplete, Skeleton, TextField } from "@mui/material";

const CustomState = ({
  value,
  setValue,
  country,
  style,
  userchangedcountry,
  setuserchangedcountry,
  setButtonDisabled1,
}) => {
  const [States, setStates] = useState([]);
  const usertype = localStorage.getItem("userType");

  useEffect(() => {
    const getStates = async () => {
      const result = await axiosAPI.get(`/getStates?countryID=${country?.id}`);
      const { success, data } = await result.data;
      if (success === true) {
        setStates(data);
        if (userchangedcountry) {
          setValue(null);
          setuserchangedcountry(false);
        }
      } else {
        setStates([]);
      }
    };
    getStates();
  }, [country]);

  const handleChange = (event, newValue) => {
    setButtonDisabled1(false);
    setValue(newValue);
  };

  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <FormControl sx={{ width: "65.5%", position: "relative", right: "5px" }}>
      <Autocomplete
        value={value}
        onChange={handleChange}
        options={States}
        readOnly={usertype === "Recruiter"}
        disableClearable
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={Labels?.SelectState}
            sx={{
              height: "25px",
              input: { height: "6px", fontSize: "14px" }, // Adjust font size here
              ".MuiInputBase-root": {
                fontSize: "13px", // For consistency in Autocomplete text
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "4px", // Adjust border radius if needed
                border: `2px solid black`,
                "& fieldset": {
                  border: "none", // Remove the default border
                },
                "&:hover": {
                  border: `2px solid black`,
                },
                "&.Mui-focused": {
                  border: `2px solid black`,
                },
              },
            }}
          />
        )}
        noOptionsText={Labels?.notAvailable}
        sx={{
          ".MuiAutocomplete-input": {
            fontSize: "13px", // Font size for dropdown input text
          },
          ".MuiAutocomplete-option": {
            fontSize: "13px", // Font size for dropdown options
          },
        }}
      />
    </FormControl>
  );
};

export default CustomState;
