import React, { Fragment } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#1C614E"),
  backgroundColor: "#1C614E",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#1C614E",
  },
  "&:active": {
    backgroundColor: "#1C614E",
  },
  "&:disabled": { color:"white",
    backgroundColor: "grey",
  }
}));

const CustomButton = ({ label, style, onClick, disabled }) => {
  const textStyle = {
    textTransform: "capitalize",
    fontFamily: "Poppins",
    borderRadius: "10px",
  };
  return (
    <ColorButton
      variant="contained"
      sx={{ ...textStyle, ...style }}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </ColorButton>
  );
};

export default CustomButton;
