import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { axiosAPI } from "../Axios/Axios";
import { useSelector } from "react-redux";
import {profanityfn}  from "../Profanity/Profanity";

const SalaryPeriod = ({ value, setValue, style,setButtonDisable = () => {}, width=`70%`,backup="",salaryPeriod=""}) => {
  const [WorkHour, setWorkHour] = useState([]);
  const Labels = useSelector((state) => state.allLabels.labels);

  useEffect(() => {
    const getWorkHour = async () => {
      const result = await axiosAPI.get("/getWorkHour");
      const { success, data } = await result.data;
      if (success === true) {
        setWorkHour(data);
      } else {
        setWorkHour([]);
      }
    };
    getWorkHour();
  }, []);

  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)

  const handleChange = (event) => {
    setButtonDisable(false)
    const value = event.target.value;
    if (!isNaN(value) && Number(value) >= 0) {
      setValue(profanityfn(ProfanityList,event.target.value))
    }
  };

  return (
    <FormControl sx={{ width:width, mt: 1 }}>
      <span style={{ fontSize: "14px", fontFamily: "Poppins" }}>
        {Labels.SalaryPeriod}
      </span>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        onChange={handleChange}
        fullWidth
        displayEmpty
        variant="outlined"
        sx={{
          padding: "10px 12px",
          fontSize: "14px",
          fontFamily: "Poppins",
          border: salaryPeriod === "salaryPeriod2"
          ? `2px solid black`
          : `2px solid black`,
          '& fieldset': {
            border: 'none', // Remove the default border
          },
          '&:hover': {
            border: salaryPeriod === "salaryPeriod2"
            ? `2px solid ${(backup?.salaryPeriod2 ?? 5) === value || backup?.salaryPeriod2 === undefined? "black": "green"}`
            : `2px solid ${(backup?.salaryPeriod ?? 5) === value || backup?.salaryPeriod === undefined? "black": "green"}`,
          },
          '&.Mui-focused': {
            border: salaryPeriod === "salaryPeriod2"
            ? `2px solid ${(backup?.salaryPeriod2 ?? 5) === value || backup?.salaryPeriod2 === undefined? "black": "green"}`
            : `2px solid ${(backup?.salaryPeriod ?? 5) === value || backup?.salaryPeriod === undefined? "black": "green"}`,
          },
          ...style,
        }}
      >
        <MenuItem value="" >
          <span style={{ color: "#bcbcbc", fontSize: "14px" }}>
          {Labels.SalaryPeriod}
          </span>
        </MenuItem>
        
        {WorkHour &&
          WorkHour.map((val, index) => {
            return (
              <MenuItem key={index} value={val.id}>
                {val.name}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};

export default SalaryPeriod;
