import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  Chip,
  Paper,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Skeleton,
  Pagination,
  TablePagination,
} from "@mui/material";

import { IconButton } from "@mui/material";
import { Facebook, LinkedIn, Instagram } from "@mui/icons-material";
import EmailIcon from "@mui/icons-material/Email";

import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import React from "react";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import Grid from "@mui/material/Grid";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import ShareIcon from "@mui/icons-material/Share";
import { useEffect, useRef } from "react";
import { axiosAPI } from "../../Axios/Axios";
import { useState } from "react";

import {
  errorNotify,
  infoNotify,
  succesNotify,
} from "../../CommonCode/Commonfunc";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PUBLIC_IMAGE_FOLDER } from "../../Axios/Constant";
import { useStyles } from "../../CommonCode/Commonfunc";
import { useSelector } from "react-redux";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import UndoIcon from "@mui/icons-material/Undo";
import PlaceIcon from "@mui/icons-material/Place";
import TextfieldCustom from "../../Components/TextfieldCustom";
import CustomButton from "../../Components/CustomButton";
import JobDetailedViewPostLogin from "./JobDetailedViewPostLogin";
import { makeStyles } from "@mui/styles";
import { profanityfn } from "../../Profanity/Profanity";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: "15px",
  },
}));

const NewJobSearchPostLogin = () => {
  const location = useLocation();
  const postData = location.state;
  const [jobList, setJobList] = useState([]);
  const [jobId, setjobId] = useState("");
  const [view, setView] = useState("module");
  const [jobDetail, setJobDetail] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [searchResult, setSearchResult] = useState("");
  const [refreshState, setRefreshState] = useState(false);
  const cardRefs = useRef([]);

  useEffect(() => {
    let jobString = "";
    if (postData?.jobsearch?.length) jobString += ` ${postData.jobsearch}`;
    if (postData?.city?.length) jobString += `, ${postData.city}`;
    if (postData?.radius?.length)
      jobString += `, ${postData.radius}km ${Labels.Radius}`;

    setSearchResult(jobString);
  }, [postData]);

  const handleCardClick = (val, index) => {
    setSelectedJobId(val.eja_id);
    if (cardRefs.current[index]) {
      cardRefs.current[index].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    setExpanded(false);
  };

  const savefn = async (data) => {
    const postdata1 = {
      language: "en",
      jobid: data.eja_id,
      type: data.save_status ? "unsave" : "save",
      language: localStorage.getItem("language"),
    };

    try {
      const result = await axiosAPI.post("/saveJob", postdata1);
      const { status, message } = result.data; // Destructure status and message
      if (status === true) {
        succesNotify(message); // Notify success
        getJobList(); // Refresh job list
        setRefreshState(!refreshState);
      }
    } catch (error) {}
  };

  const handleLinkClick = (jobid) => {
    const email = ""; // Replace with the recipient's email address
    const subject = "Job Application";
    const body = `Job Link : https://50plus.zone/detailedJobView/${jobid}`;

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  const [open, setOpen] = useState(false);

  const jobLink = "https://example.com/job-posting"; // Replace with your actual job link
  const jobMessage = "Check out this amazing job opportunity!";

  const handleShare = (platform) => {
    switch (platform) {
      case "facebook":
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            jobLink
          )}&quote=${encodeURIComponent(jobMessage)}`,
          "_blank"
        );
        break;

      case "linkedin":
        window.open(
          `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
            jobLink
          )}&title=${encodeURIComponent(
            jobMessage
          )}&summary=${encodeURIComponent(
            "Apply now for this exciting role."
          )}&source=${encodeURIComponent("YourCompany")}`,
          "_blank"
        );
        break;

      case "instagram":
        window.open(`https://www.instagram.com/`, "_blank");
        alert(
          "Instagram does not support direct posting from web. Open Instagram to post manually."
        );
        break;

      case "email":
        handleLinkClick();
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const handleChange = (event, nextView) => {
    if (nextView !== null) {
      setView(nextView);
    }
    if (jobDetail) {
      setSelectedJobId(null);
    }

    if (jobDetail == true) {
      setJobDetail(false);
      setjobId("");
    }
  };

  const [loading, setloading] = useState(true);
  const Labels = useSelector((state) => state.allLabels.labels);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(3); // derived from pagination.last_page
  const [rowsPerPage, setrowsPerPage] = useState(9);

  const handlePageChange = (event, value) => {
    setPage(value); // Update page
  };

  const getJobList = async () => {
    if (postData?.jobsearch === undefined) {
      try {
        const result = await axiosAPI.post(`/searchjobs?page=${page}`, {
          jobsearch: "",
          pageItem_count: rowsPerPage,
        });
        const { data, success } = result.data;
        if (success === true) {
          setJobList(data);
          setloading(false);
          setTotalPages(result?.data?.pagination?.last_page);
        } else {
          setJobList([]);
          setloading(false);
        }
      } catch (error) {}
    } else {
      try {
        const postDataWithPageCount = {
          ...postData, // spread the existing postData
          pageItem_count: rowsPerPage, // add the pageItem_count
        };

        const result = await axiosAPI.post(
          `/searchjobs?page=${page}`,
          postDataWithPageCount
        );
        const { data, success, message } = result.data;

        if (success === true) {
          setJobList(data);
          setTotalPages(result?.data?.last_page);
          if (data.length == 0) {
            infoNotify(message ? message : "");
          }
          setloading(false);
        } else {
          setloading(false);
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    getJobList();
  }, [postData, Labels, page, rowsPerPage]);

  const viewDetailedJob = (id) => {
    setJobDetail(true);
    setjobId(id);
  };

  const containerRef = useRef(null);
  const selectedCardRef = useRef(null);

  useEffect(() => {
    if (selectedCardRef.current && containerRef.current) {
      const containerTop = containerRef.current.getBoundingClientRect().top;
      const cardTop = selectedCardRef.current.getBoundingClientRect().top;
      containerRef.current.scrollTo({
        top: selectedCardRef.current.offsetTop - containerTop,
        behavior: "smooth",
      });
    }
  }, [selectedJobId]);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <Typography variant="h6">Share Job Posting</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Help us spread the word about this job opening! Share on:
          </Typography>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "20px" }}
          >
            <IconButton
              onClick={() => handleShare("facebook")}
              color="primary"
              aria-label="Share on Facebook"
            >
              <Facebook fontSize="large" />
            </IconButton>

            <IconButton
              onClick={() => handleShare("linkedin")}
              color="primary"
              aria-label="Share on LinkedIn"
            >
              <LinkedIn fontSize="large" />
            </IconButton>
            {/* <IconButton
              onClick={() => handleShare("instagram")}
              color="secondary"
              aria-label="Share on Instagram"
            >
              <Instagram fontSize="large" />
            </IconButton> */}
            <IconButton
              onClick={() => handleShare("email")}
              color="primary"
              aria-label="Share on Facebook"
            >
              <EmailIcon fontSize="large" />
            </IconButton>
          </div>
        </DialogContent>
      </Dialog>
      {/* Top job length display + toggle view button stack */}
      <Stack
        direction="row"
        spacing={3}
        justifyContent="space-between"
        sx={{ my: 1 }}
      >
        <Stack direction="row" spacing={4} justifyContent="space-between">
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography variant="body1" sx={{ color: "#2B4947" }}>
              {Labels?.Showing}
            </Typography>
            <Typography variant="body1" sx={{ color: "#BA8C04" }}>
              {jobList.length}
            </Typography>
            <Typography variant="body1" sx={{ color: "#2B4947" }}>
              {jobList.length == 1 ? Labels?.Job : Labels?.Jobs}
            </Typography>
            <Typography variant="body1" sx={{ color: "#BA8C04" }}>
              {searchResult}
            </Typography>
          </Stack>
        </Stack>
        <ToggleButtonGroup
          value={view}
          exclusive
          onChange={handleChange}
          sx={{ display: { xs: "none", md: "block" } }}
        >
          {jobDetail ? null : (
            <ToggleButton value="module" aria-label="module">
              <ViewModuleIcon />
            </ToggleButton>
          )}

          {jobDetail ? null : (
            <ToggleButton value="list" aria-label="list">
              <ViewListIcon />
            </ToggleButton>
          )}

          {jobDetail ? (
            <ToggleButton value="module" aria-label="list">
              <UndoIcon />
            </ToggleButton>
          ) : null}
        </ToggleButtonGroup>
      </Stack>
      {/* Top job length display + toggle view button stack */}

      {/* ________________________________________________________________________________________________________________________________________ */}

      <Grid container spacing={2} sx={{ padding: "12px" }}>
        <Grid
          item
          xs={12}
          sx={{
            display: {
              xs: "block",
              sm: "block",
              md: "none",
              lg: "none",
              xl: "none",
            },
          }}
        >
          <Accordion expanded={expanded}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              onClick={() => {
                setExpanded(!expanded);
              }}
            >
              {Labels.viewAll} {Labels.JobsList}
            </AccordionSummary>
            <AccordionDetails sx={{ background: "rgb(234,239,241)" }}>
              <Grid
                item
                xs={12}
                sm={jobDetail ? 12 : 12}
                md={jobDetail ? 6 : 12}
                lg={jobDetail ? 6 : 12}
                xl={jobDetail ? 4 : 12}
              >
                <div style={{ position: "relative" }}>
                  <Grid
                    container
                    spacing={2}
                    className="scrollable-container-Jobsearchpostlogin"
                    ref={containerRef}
                    style={{ height: jobDetail ? "85vh" : `100%` }}
                  >
                    {loading ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                          md={jobDetail ? 12 : view == "module" ? 6 : 12}
                          lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                        >
                          <Skeleton
                            variant="rectangular"
                            width={`100%`}
                            style={{
                              minHeight: view == "module" ? "350px" : "100px",
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                          md={jobDetail ? 12 : view == "module" ? 6 : 12}
                          lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                        >
                          <Skeleton
                            variant="rectangular"
                            width={`100%`}
                            style={{
                              minHeight: view == "module" ? "350px" : "100px",
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                          md={jobDetail ? 12 : view == "module" ? 6 : 12}
                          lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                        >
                          <Skeleton
                            variant="rectangular"
                            width={`100%`}
                            style={{
                              minHeight: view == "module" ? "350px" : "100px",
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                          md={jobDetail ? 12 : view == "module" ? 6 : 12}
                          lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                        >
                          <Skeleton
                            variant="rectangular"
                            width={`100%`}
                            style={{
                              minHeight: view == "module" ? "350px" : "100px",
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                          md={jobDetail ? 12 : view == "module" ? 6 : 12}
                          lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                        >
                          <Skeleton
                            variant="rectangular"
                            width={`100%`}
                            style={{
                              minHeight: view == "module" ? "350px" : "100px",
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                          md={jobDetail ? 12 : view == "module" ? 6 : 12}
                          lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                        >
                          <Skeleton
                            variant="rectangular"
                            width={`100%`}
                            style={{
                              minHeight: view == "module" ? "350px" : "100px",
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                          md={jobDetail ? 12 : view == "module" ? 6 : 12}
                          lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                        >
                          <Skeleton
                            variant="rectangular"
                            width={`100%`}
                            style={{
                              minHeight: view == "module" ? "350px" : "100px",
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                          md={jobDetail ? 12 : view == "module" ? 6 : 12}
                          lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                        >
                          <Skeleton
                            variant="rectangular"
                            width={`100%`}
                            style={{
                              minHeight: view == "module" ? "350px" : "100px",
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                          md={jobDetail ? 12 : view == "module" ? 6 : 12}
                          lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                        >
                          <Skeleton
                            variant="rectangular"
                            width={`100%`}
                            style={{
                              minHeight: view == "module" ? "350px" : "100px",
                            }}
                          />
                        </Grid>
                      </>
                    ) : (
                      jobList &&
                      jobList.map((val, i) => (
                        <Grid
                          item
                          xs={12}
                          sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                          md={jobDetail ? 12 : view == "module" ? 6 : 12}
                          lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                          key={i}
                          ref={
                            selectedJobId === val.eja_id
                              ? selectedCardRef
                              : null
                          }
                        >
                          <Card
                            sx={{
                              backgroundColor:
                                selectedJobId === val.eja_id
                                  ? "#1C614E" // Green background if selected
                                  : val.status === "Applied" || val.status == 4
                                  ? "#E0FDE3" // Light green for "Applied" status
                                  : "#FFFFFF", // White otherwise
                              borderRadius: "17px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "5px",
                              position: "relative",
                              bottom:
                                i === 1 && jobList.length === 2 && jobDetail
                                  ? "100px"
                                  : null,
                              minHeight: view === "module" ? "270px" : "150px",
                              overflow: "none",
                              transition:
                                "box-shadow 0.3s ease, transform 0.3s ease", // Smooth animation
                              margin: "5px 10px",
                              cursor: "pointer",
                            }}
                            // onClick={() => {
                            //   handleCardClick(val);
                            // }}
                          >
                            {/* {(val.status == "Applied" || val.status == 4) && (
                              <span class={`stamp-${view} is-applied`}>
                                {Labels.Applied}
                              </span>
                            )}
                            {val.status == "Shortlisted" && (
                              <span class={`stamp-${view} is-shortlisted`}>
                                {Labels.Shortlisted}
                              </span>
                            )} */}
                            {/* {val.status == "Open"  && 
                                  <Button
                                  variant="text"
                                  onClick={() => redirect(`/detailedJobView/${val.eja_id}`)}
                                  sx={{
                                    textTransform: "capitalize",
                                    color: "black",
                                    padding: "5px 10px",
                                    borderRadius: "10px",
                                    border:"2px solid grey",
                                    position:"absolute",
                                    right:"20px",
                                    top:"50px",
                                    zIndex:"99999"
                                  }}
                                >
                                    {Labels?.View}
                                  </Button>
                                  } */}

                            <Grid container spacing={2} direction="row">
                              <Grid
                                sx={{
                                  width: "90%",
                                  margin: "auto",
                                  position: "absolute",
                                  top: "10px",
                                  left: "25px",
                                }}
                                container
                                direction="row"
                                justifyContent="space-between"
                              >
                                <Grid item direction="row">
                                  <ShareIcon
                                    // onClick={() => {handleLinkClick(val.eja_id)}}
                                    onClick={() => setOpen(true)}
                                    sx={{
                                      color:
                                        selectedJobId === val.eja_id
                                          ? "white"
                                          : "#2B4947",
                                      cursor: "pointer",
                                    }}
                                  />
                                </Grid>
                                <Grid item direction="row">
                                  {val.status === "Applied" && (
                                    <DoneIcon
                                      sx={{
                                        color:
                                          selectedJobId === val.eja_id
                                            ? "white"
                                            : "#1C614E",
                                        fontSize: "25px",
                                      }}
                                    />
                                  )}
                                  {val.status === "Shortlisted" && (
                                    <DoneAllIcon
                                      sx={{
                                        color:
                                          selectedJobId === val.eja_id
                                            ? "white"
                                            : "#34B7F1",
                                        fontSize: "25px",
                                      }}
                                    />
                                  )}

                                  {val.save_status === 0 ? (
                                    <BookmarkBorderOutlinedIcon
                                      onClick={() => {
                                        savefn(val);
                                      }}
                                      sx={{
                                        color:
                                          selectedJobId === val.eja_id
                                            ? "white"
                                            : "#2B4947",
                                        cursor: "pointer",
                                      }}
                                    />
                                  ) : null}

                                  {val.save_status === 1 ? (
                                    <BookmarkAddedIcon
                                      onClick={() => {
                                        savefn(val);
                                      }}
                                      sx={{
                                        color:
                                          selectedJobId === val.eja_id
                                            ? "white"
                                            : "#2B4947",
                                        cursor: "pointer",
                                      }}
                                    />
                                  ) : null}
                                </Grid>
                              </Grid>

                              {/* Avatar */}
                              <Grid
                                item
                                xs={3}
                                onClick={() => {
                                  viewDetailedJob(`${val.eja_id}`);
                                  handleCardClick(val);
                                }}
                                sm={jobDetail ? 3 : view == "module" ? 3 : 2}
                                md={jobDetail ? 3 : view == "module" ? 3 : 2}
                                lg={jobDetail ? 3 : view == "module" ? 3 : 2}
                                sx={{ position: "relative", top: "30px" }}
                                direction="row"
                              >
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  height="100%"
                                >
                                  <Avatar
                                    src={
                                      val.companyLogo
                                        ? `${val.companyLogo}`
                                        : null
                                    }
                                    alt=" "
                                    style={{
                                      minWidth: 60,
                                      minHeight: 60,
                                      margin: "25px",
                                    }}
                                  >
                                    {val.companyLogo ? null : (
                                      <>
                                        {val?.jobName
                                          ? val.jobName
                                              .split(" ")
                                              .map((word) =>
                                                word[0].toUpperCase()
                                              )
                                              .join("")
                                          : null}
                                      </>
                                    )}
                                  </Avatar>
                                </Box>
                              </Grid>

                              {/* Job details */}
                              <Grid
                                item
                                xs={9}
                                onClick={() => {
                                  viewDetailedJob(`${val.eja_id}`);
                                  handleCardClick(val);
                                }}
                                sm={jobDetail ? 9 : view == "module" ? 9 : 4}
                                md={jobDetail ? 9 : view == "module" ? 9 : 4}
                                lg={jobDetail ? 9 : view == "module" ? 9 : 4}
                                sx={{ position: "relative", top: "30px" }}
                                //   onClick={() =>
                                //     redirect(`/detailedJobView/${val.eja_id}`)
                                //   }
                              >
                                <Box
                                  sx={{
                                    direction: "row",
                                    Width: 100,
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    sx={{
                                      color:
                                        selectedJobId === val.eja_id
                                          ? "white"
                                          : "#2B4947",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {val.jobName}
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      color:
                                        selectedJobId === val.eja_id
                                          ? "white"
                                          : "#2B4947",
                                    }}
                                  >
                                    {val.employer}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color:
                                        selectedJobId === val.eja_id
                                          ? "white"
                                          : "#2B4947",
                                    }}
                                  >
                                    {val?.companyAddress}
                                    {val?.companyAddress &&
                                    !val.companyAddress.endsWith(",")
                                      ? ", "
                                      : " "}
                                    {val?.country}
                                  </Typography>

                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color:
                                        selectedJobId === val.eja_id
                                          ? "white"
                                          : "#2B4947",
                                    }}
                                  >
                                    {val.days}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color:
                                        selectedJobId === val.eja_id
                                          ? "white"
                                          : "#2B4947",
                                    }}
                                  >
                                    {val.jobseekers}{" "}
                                    {val.jobseekers?.length == 1
                                      ? Labels?.Applicant
                                      : Labels?.Applicants}
                                  </Typography>
                                </Box>
                              </Grid>

                              {/* Job Box */}
                              <Grid
                                item
                                xs={12}
                                onClick={() => {
                                  viewDetailedJob(`${val.eja_id}`);
                                  handleCardClick(val);
                                }}
                                sm={jobDetail ? 12 : view == "module" ? 12 : 6}
                                md={jobDetail ? 12 : view == "module" ? 12 : 5}
                                lg={jobDetail ? 12 : view == "module" ? 12 : 5}
                                mt={2}
                                //   onClick={() =>redirect(`/detailedJobView/${val.eja_id}`)}
                              >
                                <Box
                                  sx={{
                                    position: "relative", // Ensure the parent container is a positioning context
                                    display: "flex",
                                    paddingRight: 4,
                                    minHeight: "0px", // Set a minimum height to create space for other content
                                  }}
                                >
                                  <Grid item xs={4} direction="column">
                                    {val?.officePresence != null ? (
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={1}
                                        mx={1}
                                        my={2}
                                        // style={{ border: '1px solid red' }}
                                      >
                                        <Chip
                                          // key={index}
                                          label={val?.officePresence}
                                          size="medium"
                                          sx={{
                                            fontWeight:
                                              selectedJobId === val.eja_id
                                                ? 600
                                                : 400,
                                            backgroundColor: "transparent",
                                            color:
                                              selectedJobId === val.eja_id
                                                ? "white"
                                                : "#2B4947",
                                            // background: "#FFFFFF",
                                            fontSize: "1rem",
                                            borderRadius: 1,
                                            border: "1px solid #BA8C04",
                                            width: "150px", // Set your desired fixed width
                                            whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                            wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                          }}
                                        />
                                      </Stack>
                                    ) : null}

                                    {val.officePercentage != null ? (
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={1}
                                        mx={1}
                                        my={2}
                                      >
                                        <Chip
                                          key={""}
                                          label={
                                            val.officePercentage == 100
                                              ? `${val.officePercentage}%`
                                              : val.officePercentage == 0
                                              ? `${val.remotePercentage}%`
                                              : val.officePercentage > 0 &&
                                                val.officePercentage < 100
                                              ? `${val.officePercentage}% (${Labels.Office})`
                                              : null
                                          }
                                          size="medium"
                                          sx={{
                                            fontWeight:
                                              selectedJobId === val.eja_id
                                                ? 600
                                                : 400,
                                            backgroundColor: "transparent",
                                            color:
                                              selectedJobId === val.eja_id
                                                ? "white"
                                                : "#2B4947",
                                            // background: "#FFFFFF",
                                            fontSize: "1rem",
                                            borderRadius: 1,
                                            border: "1px solid #BA8C04",
                                            width: "150px", // Set your desired fixed width
                                            whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                            wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                          }}
                                        />
                                      </Stack>
                                    ) : null}
                                  </Grid>

                                  <Grid item xs={4} direction="column">
                                    {val?.workingHours != null &&
                                    val?.hourPeriod != null ? (
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={1}
                                        mx={1}
                                        my={2}
                                      >
                                        <Chip
                                          key={""}
                                          label={`${
                                            val?.salaryCalucateitem
                                              ? JSON.parse(
                                                  val.salaryCalucateitem
                                                )?.hoursPerDay
                                              : val?.workingHours || ""
                                          } hrs/${val?.hourPeriod || ""}`}
                                          size="medium"
                                          sx={{
                                            fontWeight:
                                              selectedJobId === val.eja_id
                                                ? 600
                                                : 400,
                                            backgroundColor: "transparent",
                                            color:
                                              selectedJobId === val.eja_id
                                                ? "white"
                                                : "#2B4947",
                                            // background: "#FFFFFF",
                                            fontSize: "1rem",
                                            borderRadius: 1,
                                            border: "1px solid #BA8C04",
                                            width: "150px", // Set your desired fixed width
                                            whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                            wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                          }}
                                        />
                                      </Stack>
                                    ) : null}

                                    {val?.salaryMax || val?.salary ? (
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={1}
                                        mx={1}
                                        my={2}
                                      >
                                        <Chip
                                          key={""}
                                          label={`
                                            ${
                                              val?.salaryMax
                                                ? val?.salaryMax
                                                    ?.toString()
                                                    .replace(/[^0-9]/g, "")
                                                    .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )
                                                : val?.salary
                                                ? val?.salary
                                                    ?.toString()
                                                    .replace(/[^0-9]/g, "")
                                                    .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )
                                                : ""
                                            } ${
                                            val?.salaryMax || val?.salary
                                              ? val?.currencySymbol +
                                                (val?.salaryPeriod
                                                  ? ` /${val?.salaryPeriod}`
                                                  : "")
                                              : ""
                                          }`}
                                          size="medium"
                                          sx={{
                                            fontWeight:
                                              selectedJobId === val.eja_id
                                                ? 600
                                                : 400,
                                            backgroundColor: "transparent",
                                            color:
                                              selectedJobId === val.eja_id
                                                ? "white"
                                                : "#2B4947",
                                            // background: "#FFFFFF",
                                            fontSize: "1rem",
                                            borderRadius: 1,
                                            border: "1px solid #BA8C04",
                                            width: "150px", // Set your desired fixed width
                                            whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                            wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                          }}
                                        />
                                      </Stack>
                                    ) : null}
                                  </Grid>

                                  <Grid item xs={4} direction="column">
                                    {val.jobTypes ? (
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={1}
                                        mx={1}
                                        my={2}
                                      >
                                        {val.jobTypeName
                                          ?.split(",")
                                          ?.map((jobType, i) => (
                                            <Chip
                                              key={jobType}
                                              label={jobType}
                                              size="medium"
                                              sx={{
                                                fontWeight:
                                                  selectedJobId === val.eja_id
                                                    ? 600
                                                    : 400,
                                                backgroundColor: "transparent",
                                                color:
                                                  selectedJobId === val.eja_id
                                                    ? "white"
                                                    : "#2B4947",
                                                // background: "#FFFFFF",
                                                fontSize: "1rem",
                                                borderRadius: 1,
                                                border: "1px solid #BA8C04",
                                                width: "150px", // Set your desired fixed width
                                                whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                                wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                              }}
                                            />
                                          ))
                                          .slice(0, 2)}
                                      </Stack>
                                    ) : null}
                                    {val?.typePercentage != null &&
                                    val.jobTypes ? (
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={1}
                                        mx={1}
                                        my={2}
                                      >
                                        <Chip
                                          key={""}
                                          label={`${val?.typePercentage}0%`}
                                          size="medium"
                                          sx={{
                                            fontWeight:
                                              selectedJobId === val.eja_id
                                                ? 600
                                                : 400,
                                            backgroundColor: "transparent",
                                            color:
                                              selectedJobId === val.eja_id
                                                ? "white"
                                                : "#2B4947",
                                            // background: "#FFFFFF",
                                            fontSize: "1rem",
                                            borderRadius: 1,
                                            border: "1px solid #BA8C04",
                                            width: "150px", // Set your desired fixed width
                                            whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                            wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                          }}
                                        />
                                      </Stack>
                                    ) : null}
                                  </Grid>
                                </Box>
                              </Grid>
                            </Grid>
                          </Card>
                        </Grid>
                      ))
                    )}
                  </Grid>
                </div>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={2}>
            <Grid
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                  xl: "block",
                },
              }}
              item
              xs={12}
              sm={jobDetail ? 12 : 12}
              md={jobDetail ? 6 : 12}
              lg={jobDetail ? 6 : 12}
              xl={jobDetail ? 4 : 12}
            >
              <div style={{ position: "relative" }}>
                <Grid
                  container
                  spacing={2}
                  className="scrollable-container-Jobsearchpostlogin"
                  ref={containerRef}
                  style={{ height: jobDetail ? "85vh" : `100%` }}
                >
                  {loading ? (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                        md={jobDetail ? 12 : view == "module" ? 6 : 12}
                        lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                      >
                        <Skeleton
                          variant="rectangular"
                          width={`100%`}
                          style={{
                            minHeight: view == "module" ? "350px" : "100px",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                        md={jobDetail ? 12 : view == "module" ? 6 : 12}
                        lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                      >
                        <Skeleton
                          variant="rectangular"
                          width={`100%`}
                          style={{
                            minHeight: view == "module" ? "350px" : "100px",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                        md={jobDetail ? 12 : view == "module" ? 6 : 12}
                        lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                      >
                        <Skeleton
                          variant="rectangular"
                          width={`100%`}
                          style={{
                            minHeight: view == "module" ? "350px" : "100px",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                        md={jobDetail ? 12 : view == "module" ? 6 : 12}
                        lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                      >
                        <Skeleton
                          variant="rectangular"
                          width={`100%`}
                          style={{
                            minHeight: view == "module" ? "350px" : "100px",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                        md={jobDetail ? 12 : view == "module" ? 6 : 12}
                        lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                      >
                        <Skeleton
                          variant="rectangular"
                          width={`100%`}
                          style={{
                            minHeight: view == "module" ? "350px" : "100px",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                        md={jobDetail ? 12 : view == "module" ? 6 : 12}
                        lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                      >
                        <Skeleton
                          variant="rectangular"
                          width={`100%`}
                          style={{
                            minHeight: view == "module" ? "350px" : "100px",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                        md={jobDetail ? 12 : view == "module" ? 6 : 12}
                        lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                      >
                        <Skeleton
                          variant="rectangular"
                          width={`100%`}
                          style={{
                            minHeight: view == "module" ? "350px" : "100px",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                        md={jobDetail ? 12 : view == "module" ? 6 : 12}
                        lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                      >
                        <Skeleton
                          variant="rectangular"
                          width={`100%`}
                          style={{
                            minHeight: view == "module" ? "350px" : "100px",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                        md={jobDetail ? 12 : view == "module" ? 6 : 12}
                        lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                      >
                        <Skeleton
                          variant="rectangular"
                          width={`100%`}
                          style={{
                            minHeight: view == "module" ? "350px" : "100px",
                          }}
                        />
                      </Grid>
                    </>
                  ) : (
                    jobList &&
                    jobList.map((val, i) => (
                      <Grid
                        item
                        xs={12}
                        sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                        md={jobDetail ? 12 : view == "module" ? 6 : 12}
                        lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                        key={i}
                        ref={
                          selectedJobId === val.eja_id ? selectedCardRef : null
                        }
                      >
                        <Card
                          sx={{
                            // backgroundColor: val.status == "Applied" || val.status == 4 ?`#E0FDE3`:
                            // val.status == "Shortlisted"?`#0073ba11`:'',
                            // border: selectedJobId === val.eja_id ? `2px solid #1C614E` : "0px",
                            // borderRadius: "17px",

                            backgroundColor:
                              selectedJobId === val.eja_id
                                ? "#1C614E" // Green background if selected
                                : val.status == "Applied" ||
                                  val.status == "Shortlisted" ||
                                  val.status == 4
                                ? "#E0FDE3" // Light green for "Applied" status
                                : "#FFFFFF", // White otherwise
                            borderRadius: "17px", // Keep the border-radius, but remove the border
                            color: selectedJobId === val.eja_id ? "white" : "",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "5px",
                            position: "relative",
                            bottom:
                              i === 1 && jobList.length === 2 && jobDetail
                                ? "100px"
                                : null,
                            minHeight: view === "module" ? "270px" : "150px",
                            overflow: "none",
                            transition:
                              "box-shadow 0.3s ease, transform 0.3s ease", // Smooth animation
                            margin: "5px 7px",
                          }}
                        >
                          {/* {(val.status == "Applied" || val.status == 4) && (
                            <span class={`stamp-${view} is-applied`}>
                              {Labels.Applied}
                            </span>
                          )} */}
                          {/* {val.status == "Shortlisted" && (
                            <span class={`stamp-${view} is-shortlisted`}>
                              {Labels.Shortlisted}
                            </span>
                          )} */}

                          <Grid container spacing={2} direction="row">
                            <Grid
                              sx={{
                                width: "90%",
                                margin: "auto",
                                position: "absolute",
                                top: "20px",
                                left: "25px",
                                zIndex: "100",
                              }}
                              container
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Grid item direction="row">
                                <ShareIcon
                                  // onClick={() => {handleLinkClick(val.eja_id)}}
                                  onClick={() => setOpen(true)}
                                  sx={{
                                    color:
                                      selectedJobId === val.eja_id
                                        ? "white"
                                        : "#2B4947",
                                    cursor: "pointer",
                                  }}
                                />
                              </Grid>
                              <Grid item direction="row">
                                {val.status === "Applied" && (
                                  <DoneIcon
                                    sx={{
                                      color:
                                        selectedJobId === val.eja_id
                                          ? "white"
                                          : "#1C614E",
                                      fontSize: "25px",
                                    }}
                                  />
                                )}
                                {val.status === "Shortlisted" && (
                                  <DoneAllIcon
                                    sx={{
                                      color:
                                        selectedJobId === val.eja_id
                                          ? "white"
                                          : "#34B7F1",
                                      fontSize: "25px",
                                    }}
                                  />
                                )}

                                {val.save_status === 0 ? (
                                  <BookmarkBorderOutlinedIcon
                                    onClick={() => {
                                      savefn(val);
                                    }}
                                    sx={{
                                      color:
                                        selectedJobId === val.eja_id
                                          ? "white"
                                          : "#2B4947",
                                      cursor: "pointer",
                                    }}
                                  />
                                ) : null}

                                {val.save_status === 1 ? (
                                  <BookmarkAddedIcon
                                    onClick={() => {
                                      savefn(val);
                                    }}
                                    sx={{
                                      color:
                                        selectedJobId === val.eja_id
                                          ? "white"
                                          : "#2B4947",
                                      cursor: "pointer",
                                    }}
                                  />
                                ) : null}
                              </Grid>
                            </Grid>

                            {/* Avatar */}
                            <Grid
                              item
                              xs={3}
                              onClick={() => {
                                viewDetailedJob(`${val.eja_id}`);
                                handleCardClick(val);
                              }}
                              sm={jobDetail ? 3 : view == "module" ? 3 : 2}
                              md={jobDetail ? 3 : view == "module" ? 3 : 2}
                              lg={jobDetail ? 3 : view == "module" ? 3 : 2}
                              sx={{
                                position: "relative",
                                zIndex: "1",
                                cursor: "pointer",
                                top:
                                  view == "module"
                                    ? "30px"
                                    : jobDetail
                                    ? "20px"
                                    : "0px",
                              }}
                              direction="row"
                            >
                              <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                height="100%"
                              >
                                <Avatar
                                  src={
                                    val.companyLogo
                                      ? `${val.companyLogo}`
                                      : null
                                  }
                                  alt=" "
                                  style={{
                                    minWidth: 60,
                                    minHeight: 60,
                                    margin: "25px",
                                  }}
                                >
                                  {val.companyLogo ? null : (
                                    <>
                                      {val?.jobName
                                        ? val.jobName
                                            .split(" ")
                                            .map((word) =>
                                              word[0].toUpperCase()
                                            )
                                            .join("")
                                        : null}
                                    </>
                                  )}
                                </Avatar>
                              </Box>
                            </Grid>

                            {/* Job details */}
                            <Grid
                              item
                              xs={9}
                              onClick={() => {
                                viewDetailedJob(`${val.eja_id}`);
                                handleCardClick(val);
                              }}
                              sm={jobDetail ? 9 : view == "module" ? 9 : 4}
                              md={jobDetail ? 9 : view == "module" ? 9 : 4}
                              lg={jobDetail ? 9 : view == "module" ? 9 : 4}
                              sx={{
                                cursor: "pointer",
                                position: "relative",
                                zIndex: "1",
                                top:
                                  view == "module"
                                    ? "30px"
                                    : jobDetail
                                    ? "20px"
                                    : "0px",
                              }}
                              //   onClick={() =>
                              //     redirect(`/detailedJobView/${val.eja_id}`)
                              //   }
                            >
                              <Box
                                sx={{
                                  direction: "row",
                                  Width: 100,
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  sx={{
                                    color:
                                      selectedJobId === val.eja_id
                                        ? "white"
                                        : "#2B4947",
                                    fontWeight: 600,
                                  }}
                                >
                                  {val.jobName}
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    color:
                                      selectedJobId === val.eja_id
                                        ? "white"
                                        : "#2B4947",
                                  }}
                                >
                                  {val.employer}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color:
                                      selectedJobId === val.eja_id
                                        ? "white"
                                        : "#2B4947",
                                  }}
                                >
                                  {val?.companyAddress}
                                  {val?.companyAddress &&
                                  !val.companyAddress.endsWith(",")
                                    ? ", "
                                    : " "}
                                  {val?.country}
                                </Typography>

                                <Typography
                                  variant="body2"
                                  sx={{
                                    color:
                                      selectedJobId === val.eja_id
                                        ? "white"
                                        : "#2B4947",
                                  }}
                                >
                                  {val.days}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color:
                                      selectedJobId === val.eja_id
                                        ? "white"
                                        : "#2B4947",
                                  }}
                                >
                                  {val.jobseekers}{" "}
                                  {val.jobseekers == 1
                                    ? Labels?.Applicant
                                    : Labels?.Applicants}
                                </Typography>
                              </Box>
                            </Grid>

                            {/* Job Box */}
                            <Grid
                              item
                              xs={12}
                              onClick={() => {
                                viewDetailedJob(`${val.eja_id}`);
                                handleCardClick(val);
                              }}
                              sm={jobDetail ? 12 : view == "module" ? 12 : 6}
                              md={jobDetail ? 12 : view == "module" ? 12 : 5}
                              lg={jobDetail ? 12 : view == "module" ? 12 : 5}
                              sx={{ zIndex: "1", cursor: "pointer" }}
                              mt={2}
                              //   onClick={() =>redirect(`/detailedJobView/${val.eja_id}`)}
                            >
                              <Box
                                sx={{
                                  position: "relative", // Ensure the parent container is a positioning context
                                  display: "flex",
                                  paddingRight: 4,
                                  minHeight: "0px", // Set a minimum height to create space for other content
                                }}
                              >
                                <Grid item xs={4} direction="column">
                                  {val?.officePresence && (
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={1}
                                      mx={1}
                                      my={2}
                                      // style={{ border: '1px solid red' }}
                                    >
                                      <LightTooltip
                                        title={Labels.officePresencetooltip}
                                        arrow
                                      >
                                        <Chip
                                          // key={index}
                                          label={val?.officePresence}
                                          size="medium"
                                          sx={{
                                            fontWeight:
                                              selectedJobId === val.eja_id
                                                ? 600
                                                : 400,
                                            backgroundColor: "transparent",
                                            color:
                                              selectedJobId === val.eja_id
                                                ? "white"
                                                : "#BA8C04",
                                            // background: "#FFFFFF",
                                            fontSize: "1rem",
                                            borderRadius: 1,
                                            border: "1px solid #BA8C04",
                                            width: "150px", // Set your desired fixed width
                                            whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                            wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                          }}
                                        />
                                      </LightTooltip>
                                    </Stack>
                                  )}

                                  {val.officePercentage != null && (
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={1}
                                      mx={1}
                                      my={2}
                                    >
                                      <LightTooltip
                                        title={Labels.officePercentagetooltip}
                                        arrow
                                      >
                                        <Chip
                                          key={""}
                                          // label={`${val.officePercentage}%`}
                                          label={
                                            val.officePercentage == 100
                                              ? `${val.officePercentage}%`
                                              : val.officePercentage == 0
                                              ? `${val.remotePercentage}%`
                                              : val.officePercentage > 0 &&
                                                val.officePercentage < 100
                                              ? `${val.officePercentage}% (${Labels.Office})`
                                              : null
                                          }
                                          size="medium"
                                          sx={{
                                            fontWeight:
                                              selectedJobId === val.eja_id
                                                ? 600
                                                : 400,
                                            backgroundColor: "transparent",
                                            color:
                                              selectedJobId === val.eja_id
                                                ? "white"
                                                : "#BA8C04",
                                            // background: "#FFFFFF",
                                            fontSize: "1rem",
                                            borderRadius: 1,
                                            border: "1px solid #BA8C04",
                                            width: "150px", // Set your desired fixed width
                                            whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                            wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                          }}
                                        />
                                      </LightTooltip>
                                    </Stack>
                                  )}
                                </Grid>
                                <Grid item xs={4} direction="column">
                                  {val?.workingHours != null &&
                                  val?.hourPeriod != null ? (
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={1}
                                      mx={1}
                                      my={2}
                                    >
                                      <LightTooltip
                                        title={Labels.workingHourstooltip}
                                        arrow
                                      >
                                        <Chip
                                          key={""}
                                          label={`${
                                            val?.salaryCalucateitem
                                              ? JSON.parse(
                                                  val.salaryCalucateitem
                                                )?.hoursPerDay
                                              : val?.workingHours || ""
                                          } ${
                                            (val?.salaryCalucateitem
                                              ? JSON.parse(
                                                  val.salaryCalucateitem
                                                )?.hoursPerDay
                                              : val?.workingHours || "") === 1
                                              ? "hr"
                                              : "hrs"
                                          }/${val?.hourPeriod || ""}`}
                                          size="medium"
                                          sx={{
                                            fontWeight:
                                              selectedJobId === val.eja_id
                                                ? 600
                                                : 400,
                                            backgroundColor: "transparent",
                                            color:
                                              selectedJobId === val.eja_id
                                                ? "white"
                                                : "#BA8C04",
                                            // background: "#FFFFFF",
                                            fontSize: "1rem",
                                            borderRadius: 1,
                                            border: "1px solid #BA8C04",
                                            width: "150px", // Set your desired fixed width
                                            whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                            wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                          }}
                                        />
                                      </LightTooltip>
                                    </Stack>
                                  ) : null}

                                  {val?.salaryMax || val?.salary ? (
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={1}
                                      mx={1}
                                      my={2}
                                    >
                                      <LightTooltip
                                        title={`
                                          ${
                                            val?.salaryMax
                                              ? val?.salaryMax
                                                  ?.toString()
                                                  .replace(/[^0-9]/g, "")
                                                  .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )
                                              : val?.salary
                                              ? val?.salary
                                                  ?.toString()
                                                  .replace(/[^0-9]/g, "")
                                                  .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )
                                              : ""
                                          } ${
                                          val?.salaryMax || val?.salary
                                            ? val?.currencySymbol +
                                              (val?.salaryPeriod
                                                ? ` /${val?.salaryPeriod}`
                                                : "")
                                            : ""
                                        }`}
                                        arrow
                                      >
                                        <Chip
                                          key={""}
                                          label={`
                                            ${
                                              val?.salaryMax
                                                ? val?.salaryMax
                                                    ?.toString()
                                                    .replace(/[^0-9]/g, "")
                                                    .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )
                                                : val?.salary
                                                ? val?.salary
                                                    ?.toString()
                                                    .replace(/[^0-9]/g, "")
                                                    .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )
                                                : ""
                                            } ${
                                            val?.salaryMax || val?.salary
                                              ? val?.currencySymbol +
                                                (val?.salaryPeriod
                                                  ? ` /${val?.salaryPeriod}`
                                                  : "")
                                              : ""
                                          }`}
                                          size="medium"
                                          sx={{
                                            fontWeight:
                                              selectedJobId === val.eja_id
                                                ? 600
                                                : 400,
                                            backgroundColor: "transparent",
                                            color:
                                              selectedJobId === val.eja_id
                                                ? "white"
                                                : "#BA8C04",
                                            // background: "#FFFFFF",
                                            fontSize: "1rem",
                                            borderRadius: 1,
                                            border: "1px solid #BA8C04",
                                            width: "150px", // Set your desired fixed width
                                            whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                            wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                          }}
                                        />
                                      </LightTooltip>
                                    </Stack>
                                  ) : null}
                                </Grid>
                                <Grid item xs={4} direction="column">
                                  {val.jobTypes && val.jobTypes != null ? (
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={1}
                                      mx={1}
                                      my={2}
                                    >
                                      {val?.jobTypeName
                                        ?.split(",")
                                        ?.map((jobType, i) => (
                                          <LightTooltip
                                            title={Labels.jobtitletooltip}
                                            arrow
                                            key={jobType}
                                          >
                                            <Chip
                                              label={jobType}
                                              size="medium"
                                              sx={{
                                                fontWeight:
                                                  selectedJobId === val.eja_id
                                                    ? 600
                                                    : 400,
                                                backgroundColor: "transparent",
                                                color:
                                                  selectedJobId === val.eja_id
                                                    ? "white"
                                                    : "#BA8C04",
                                                // background: "#FFFFFF",
                                                fontSize: "1rem",
                                                borderRadius: 1,
                                                border: "1px solid #BA8C04",
                                                width: "150px", // Set your desired fixed width
                                                whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                                wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                              }}
                                            />
                                          </LightTooltip>
                                        ))
                                        .slice(0, 2)}
                                    </Stack>
                                  ) : null}

                                  {val?.typePercentage && val.jobTypes ? (
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={1}
                                      mx={1}
                                      my={2}
                                    >
                                      <LightTooltip
                                        title={Labels.typepercentagetooltip}
                                        arrow
                                      >
                                        <Chip
                                          key={""}
                                          label={`${val?.typePercentage}0%`}
                                          size="medium"
                                          sx={{
                                            fontWeight:
                                              selectedJobId === val.eja_id
                                                ? 600
                                                : 400,
                                            backgroundColor: "transparent",
                                            color:
                                              selectedJobId === val.eja_id
                                                ? "white"
                                                : "#BA8C04",
                                            // background: "#FFFFFF",
                                            fontSize: "1rem",
                                            borderRadius: 1,
                                            border: "1px solid #BA8C04",
                                            width: "150px", // Set your desired fixed width
                                            whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                            wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                          }}
                                        />
                                      </LightTooltip>
                                    </Stack>
                                  ) : null}
                                </Grid>
                              </Box>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    ))
                  )}
                </Grid>
              </div>
            </Grid>

            {jobDetail && jobList.length ? (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={8}>
                <JobDetailedViewPostLogin
                  jobid={jobId}
                  refreshState={refreshState}
                  savefn={savefn}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>

        <Divider sx={{ width: "100%", margin: "10px" }} />
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} sm={12} container justifyContent="center">
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              size="large"
              sx={{
                "& .MuiPaginationItem-root": {
                  color: "#1C614E",
                  "&:hover": {
                    backgroundColor: "rgba(0, 128, 0, 0.1)",
                    color: "green",
                  },
                },
                "& .MuiPaginationItem-root.Mui-selected": {
                  backgroundColor: "#1C614E",
                  color: "white",
                  "&:hover": { backgroundColor: "#1C614E" },
                },
                "& .MuiPaginationItem-previousNext": {
                  color: "#1C614E",
                  "&:hover": { backgroundColor: "rgba(0, 128, 0, 0.1)" },
                },
                "& .MuiPaginationItem-ellipsis": {
                  color: "#1C614E", // Text color for ellipsis
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} container justifyContent="flex-start">
            <p> {Labels.Rowsperpage} </p>
            <select
              value={rowsPerPage}
              onChange={(e) => {
                setrowsPerPage(e.target.value);
                setPage(1);
              }}
              style={{
                padding: "8px",
                fontSize: "16px",
                margin: "10px",
              }}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default NewJobSearchPostLogin;
