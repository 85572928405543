import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import CreateLoginDetailsSlice from "./redux/CreateLoginDetailSlice";
import getLanguageSlice from "./redux/getLanguageSlice";
import jobseekerDetailsSlice from "./redux/JobSeekerDetailSlice";
import JobSeekerloginDetailSlice from "./redux/JobSeekerLoginDetailSlice";
import getAllLabelSlice from "./redux/AllLabels";
import LoadingSlice from "./redux/Loader";
import getListSlice from "./redux/getListSlice";
import CompanyDetailSlice from "./redux/CompanyDetailSlice";
import ProfanitySlice from "./redux/Profanity";

const persistConfig = {
  key: "root",
  storage,
}

const reducers = combineReducers({
  createLoginDetails: CreateLoginDetailsSlice,
  getAllLanguage: getLanguageSlice,
  getJobseekerProfileData: jobseekerDetailsSlice,
  getEmployerProfileData: CompanyDetailSlice,
  jobSeekerloginDetails: JobSeekerloginDetailSlice,
  allLabels: getAllLabelSlice,
  Loading: LoadingSlice,
  allList: getListSlice,
  Profanity:ProfanitySlice
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export default store;
