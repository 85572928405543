import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Avatar,
  Button,
  Chip,
  Grid,
  Paper,
  Stack,
  Typography,
  Divider,
  Box,
  Badge,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { PUBLIC_IMAGE_FOLDER } from "../../../Axios/Constant";
import { errorNotify, useStyles } from "../../../CommonCode/Commonfunc";
import { axiosAPI } from "../../../Axios/Axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import BackBtn from "../../../../Images/left-arrow.png";
import JobDetailedViewPostLogin from "../../JobSearchPostLogin/JobDetailedViewPostLogin";

const CardComp = ({ card, onClick, onclick2,selectedCardId }) => {
    const Labels = useSelector((state) => state.allLabels.labels);
    const redirect = useNavigate();
  return (
    <div
      className="card"
      onClick={() => {
        onClick(card.eja_id);
        onclick2(card.eja_id);
      }}
    >
      <Card
        sx={{
          backgroundColor:selectedCardId === card.eja_id
            ? // ? "#9ed8ff"
              "#E0FDE3"
            : "",
          // borderRadius: "17px",
          boxShadow: "none",
          cursor: "pointer",
          display: { xs: "block", md: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 5px",
          minHeight:'100px',
          my: 1,
          position:"relative"
         
        }}
        // onClick={() => redirect(`/detailedJobView/${item.eja_id}`)}
      >
        {/* {(card?.status == "Applied" || card.status == 4 )&& <div class="ribbon-stamp"><span> {Labels.Applied}</span></div>} */}
       
        <Grid container spacing={2} > 
          <Grid item xs={3} sm={3} md={3} lg={2} >
              <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
            <Avatar
              src={
                card.companyLogo === null || card.companyLogo?.endsWith("/")
                  ? `${card?.jobName.charAt(0, 1)}`
                  : `${card?.companyLogo}`
              }
              alt={card?.jobName.charAt(0, 1)}
              style={{
                maxWidth: 80,
                minWidth: 60,
                maxHeight: 80,
                minHeight: 60,
              }}
            />
               </Box>
          </Grid>
          <Grid item xs={9} sm={9} md={9} lg={8} >
            <Typography variant="h6" sx={{ color: "#2B4947", fontWeight: 600}}>
              {card?.jobName}
            </Typography>
            <Typography variant="subtitle1" sx={{ color: "#2B4947" }}>
              {card?.employer}
            </Typography>
            <Typography variant="body2" sx={{ color: "#000000A1" }}>
              {card?.city}
            </Typography>
            <Typography variant="body2" sx={{ color: "#000000A1" }}>
              {card?.jobseekers}{" "}
              {card?.jobseekers == 1 ? Labels.Applicant : Labels.Applicants}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={2} >
            <Stack
              direction={{ xs: "column", sm: "row" }}
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              // sx={{ borderTop:  "1px solid #eaeaea" }}
            >
              <Stack
                spacing={1}
                justifyContent="flex-start"
                alignItems="center"
                sx={{ display: { xs: "none", sm: "flex" } }}
              >
                {card?.jobSkills
                  ? card?.jobSkills
                      .split(",")
                      .map((skill) => (
                        <Chip
                          label={skill.trim()}
                          size="small"
                          sx={{
                            color: "#BA8C04",
                            background: "#BA8C040F",
                            borderRadius: 1,
                            width: 130,
                            margin: "20px",
                            border: "1px solid rgba(0, 0, 0, 0.10)",
                          }}
                        />
                      ))
                      .slice(0, 2)
                  : null}
              </Stack>

              <Box style={{ margin: "5px" }}>
                {card?.status == "Applied" || card?.status == 4 ? (
                  // <Typography
                  //   variant="caption"
                  //   sx={{
                  //     color: "white",
                  //     backgroundColor: "#1C614E",
                  //     padding: "5px 10px",
                  //     borderRadius: "10px",
                  //   }}
                  // >
                  //   {Labels.Applied}
                  // </Typography>
                  // Commented for Ribbon implementation - 18-06-24 - vis
                  null
                ) : null}
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default function RecommendedJobs() {
  const classes = useStyles();
  const redirect = useNavigate();
  const [recommendedJobs, setRecommendedJobs] = useState([]);
  const [appliedJobs, setAppliedJobs] = useState([]);
  const uid = localStorage.getItem("uid");
  const [waiting, setwaiting] = useState(false);

  const [jobid, setjobid] = useState();

  const getRecommendedJobs = async () => {
    try {
      const result = await axiosAPI.post("/recommended");
      const { success, data, message } = result.data;
      if (success) {
        setRecommendedJobs(data);
        setwaiting(true);
      } else {
        errorNotify(message); 
        setwaiting(true);
      }
    } catch (error) {
    }
  };
  const getAppliedJobs = async () => {
    const postdata = {
      jobseekerid: uid,
      sort_direction:'DESC'
    };
    try {
      if (uid) {
        const result = await axiosAPI.post("/getAppliedJobs", postdata);
        const { success, data, message } = result.data;
        if (success) {
          setAppliedJobs(data?.applied_Jobs?.reverse());
        } else {
          errorNotify(Labels.somethingWrong);
        }
      }
    } catch (error) {
    }
  };
  useEffect(() => {
    getRecommendedJobs();
    getAppliedJobs();
  }, []);
  const Labels = useSelector((state) => state.allLabels.labels);

  const goToPreviousRoute = () => {
    if (window.history.length <= 2) {
      window.location.href = "/jobseekerDashboard";
    } else {
      window.history.back();
    }
  };

  const [selectedCardId, setSelectedCardId] = useState(null);
  const cardRefs = useRef([]);

  const handleCardClick = (id) => {
    setSelectedCardId(id);
  };

  useEffect(() => {
    if (selectedCardId !== null) {
      const selectedCardIndex = recommendedJobs.findIndex(
        (card) => card.eja_id === selectedCardId
      );
      if (selectedCardIndex !== -1 && cardRefs.current[selectedCardIndex]) {
        cardRefs.current[selectedCardIndex].scrollIntoView({
          behavior: "smooth",
          // block: "start",
        });
      }
    }
  }, [selectedCardId, recommendedJobs]);

  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={jobid ? 6 : 8} lg={jobid ? 5 : 8} xl={jobid?4:8}
          sx={{maxHeight:"85vh", overflowY:"auto", margin:jobid?'20px auto':'0px'}}
        >
          <Paper
            elevation={0}
            sx={{
              borderRadius: 2,
              backgroundColor: "rgba(109, 160, 156, 0.12)",
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ p: 1.5 }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 600, color: "#1C614E" }}
              >
                {Labels?.recommendedJobs}
              </Typography>
              <Typography variant="body1" sx={{ color: "#1C614E" }}>
                {Labels?.Total} : {recommendedJobs.length}
              </Typography>
            </Stack>
          </Paper>
          {waiting ? (
            recommendedJobs.length > 0 ? (
              recommendedJobs.map((item, index) => (
                <div
                  ref={(el) => (cardRefs.current[index] = el)}
                  className={`card-container ${ selectedCardId === item.eja_id ? "selected" : ""}`}
                  onClick={() => {
                    isSmUp
                      ? handleCardClick(item.eja_id)
                      : redirect(`/detailedJobView/${item.eja_id}`);
                  }}
                >
                  <CardComp
                    card={item}
                    onClick={handleCardClick}
                    onclick2={setjobid}
                    selectedCardId={selectedCardId}
                  />
                </div>
              ))
            ) : (
               <h3 style={{textAlign:"center"}}>
              {Labels?.NoRecommendedJobs}
             </h3>
            )
            
          ) : (
            <>
              <Skeleton
                variant="rounded"
                width={`100%`}
                height={120}
                style={{ margin: "10px auto" }}
              />
              <Skeleton
                variant="rounded"
                width={`100%`}
                height={120}
                style={{ margin: "10px auto" }}
              />
              <Skeleton
                variant="rounded"
                width={`100%`}
                height={120}
                style={{ margin: "10px auto" }}
              />
              <Skeleton
                variant="rounded"
                width={`100%`}
                height={120}
                style={{ margin: "10px auto" }}
              />
              <Skeleton
                variant="rounded"
                width={`100%`}
                height={120}
                style={{ margin: "10px auto" }}
              />
              <Skeleton
                variant="rounded"
                width={`100%`}
                height={120}
                style={{ margin: "10px auto" }}
              />
              <Skeleton
                variant="rounded"
                width={`100%`}
                height={120}
                style={{ margin: "10px auto" }}
              />
              <Skeleton
                variant="rounded"
                width={`100%`}
                height={120}
                style={{ margin: "10px auto" }}
              />
              <Skeleton
                variant="rounded"
                width={`100%`}
                height={120}
                style={{ margin: "10px auto" }}
              />
              <Skeleton
                variant="rounded"
                width={`100%`}
                height={120}
                style={{ margin: "10px auto" }}
              />
            </>
          )}
          
        </Grid>
        {jobid && (
          <Grid item xs={12} sm={12} md={6} lg={7} xl={8}>
            <JobDetailedViewPostLogin jobid={jobid} />
          </Grid>
        )}
        <Grid  item  xs={12}  sm={5}  md={4}  lg={4}
          sx={{ display: jobid ? "none" : { xs: "none", md: "block" }}}
        >
          <Card
            sx={{
              borderRadius: 3,
              padding: 0,
              boxShadow: "none",
              height:"85vh",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 10,
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ color: "#1C614E", fontWeight: 500 }}
              >
                {Labels?.appliedJobs}
              </Typography>
              <Button
                variant="contained"
                sx={{
                  background: "rgba(109, 160, 156, 0.15)",
                  color: "rgb(43, 73, 71)",
                  textTransform: "capitalize",
                  "&:hover": {
                    background: "rgba(109, 160, 156, 0.15)",
                  },
                  "&:active": {
                    backgroundColor: "#1C614E",
                  },
                  "&:disabled": { color: "white", backgroundColor: "#1C614E" },
                  boxShadow: "none",
                }}
                onClick={() => redirect("/appliedJobs")}
              >
                {Labels?.viewAll}
              </Button>
            </div>
            <Divider />
            <Box
              sx={{ height: { xs: "50vh", md: "76vh" }, overflow: "auto" }}
              className={classes.customColoredScrollbar}
            >
              {waiting ? (
                appliedJobs?.length === 0 ? (
                  <Box sx={{ marginTop: "10px", marginLeft: 2 }}>
                    <Typography variant="body2">
                      {Labels?.NoJobsApplied}
                    </Typography>
                  </Box>
                ) : (
                  appliedJobs?.map((data) => (
                    <Card
                      key={data.eja_id}
                      sx={{
                        borderRadius: 1,
                        boxShadow: "none",
                        borderBottom: "1px solid #1C614E33",
                        cursor: "pointer",
                        p: 0.5,
                        "&:hover": {
                          backgroundColor: "#ffffffe5",
                        },
                      }}
                      onClick={() =>
                        redirect(`/detailedJobView/${data.eja_id}`)
                      }
                    >
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                      >
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Avatar
                            src={
                              data.companyLogo === null ||
                              data.companyLogo?.endsWith("/")
                                ? `${data.jobName.charAt(0, 1)}`
                                : `${data.companyLogo}`
                            }
                            alt={data?.jobName.charAt(0, 1)}
                            style={{
                              maxWidth: 30,
                              maxHeight: 30,
                            }}
                          />
                          <Stack>
                            <Typography
                              variant="subtitle1"
                              sx={{ color: "#2B4947", fontWeight: 600 }}
                            >
                              {data.jobName}
                            </Typography>
                            <Stack>
                              {/* <Typography variant="body2">
                                {data.city}
                              </Typography> */}
                              <Typography variant="caption">
                                {data.jobseekers}&nbsp;
                                {data.jobseekers == 1
                                  ? Labels?.Applicant
                                  : Labels?.Applicants}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Card>
                  ))
                )
              ) : (
                <>
                  <Skeleton
                    variant="rounded"
                    width={`98%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rounded"
                    width={`98%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rounded"
                    width={`98%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rounded"
                    width={`98%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rounded"
                    width={`98%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rounded"
                    width={`98%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rounded"
                    width={`98%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rounded"
                    width={`98%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rounded"
                    width={`98%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rounded"
                    width={`98%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                </>
              )}
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
