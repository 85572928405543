import React, { Fragment, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { axiosAPI } from "../Axios/Axios";
import { useSelector } from "react-redux";
import { Autocomplete, Skeleton, TextField } from "@mui/material";

const State = ({
  value,
  setValue,
  country,
  style,
  userchangedcountry,
  setuserchangedcountry,
  companyinfobackup="",
  isEdit=true
}) => {
  const [States, setStates] = useState([]);
  const usertype = localStorage.getItem("userType");

  useEffect(() => {
    const getStates = async () => {
      const result = await axiosAPI.get(`/getStates?countryID=${country?.id}`);
      const { success, data } = await result.data;
      if (success === true) {
        setStates(data);
        if (userchangedcountry) {
          setValue(null);
          setuserchangedcountry(false);
        }
      } else {
        setStates([]);
      }
    };
    getStates();
  }, [country]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <FormControl sx={{ width: "100%", margin:"10px 0px" }}>
      <Autocomplete
        value={value}
        onChange={handleChange}
        options={States}
        disabled={usertype === "Recruiter" ? true : false}
        getOptionLabel={(option) => option.name}
        InputProps={{ style: { height: "45px" } }}
        renderInput={(params) => (
          <TextField  {...params} placeholder={Labels?.SelectState}
            sx={{
              position: "relative",
              width: "100%",
              padding: "0px 15px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "4px", // Adjust border radius if needed
                border: isEdit?`2px solid ${companyinfobackup == value?.id?'black':'green'}`:`2px solid black`,
                "& fieldset": {
                  border: "none", // Remove the default border
                },
                "&:hover": {
                  border: isEdit?`2px solid ${companyinfobackup == value?.id?'black':'green'}`:`2px solid black`,
                },
                "&.Mui-focused": {
                  border: isEdit?`2px solid ${companyinfobackup == value?.id?'black':'green'}`:`2px solid black`,
                },
              },
              ...style
            }}
          />
        )}
        noOptionsText={Labels?.notAvailable}
      />
    </FormControl>
  );
};

export default State;
