import React, { useEffect, useState } from "react";
import "./App.css";
import './views/Employer/Quill.css'
import "react-quill/dist/quill.snow.css";

import { atom, RecoilRoot } from "recoil";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import JobSeekerRoutes from "./Routes/JobSeekerRoutes";
import EmployerRoutes from "./Routes/EmployerRoutes";
import CommonRoutes from "./Routes/CommonRoutes";
import RouteValidator from "./RouteValidator";
import { ToastContainer } from "react-toastify";
import { AuthProvider } from "./authContext/index";
import { useDispatch, useSelector } from "react-redux";
import { getProfanityWord } from "./redux/Profanity";
import { axiosAPI } from "./views/Axios/Axios";
import { getAllLabels } from "./redux/AllLabels";

export const updateStateAtom = atom({
  key: "updateState",
  default: false
})

function App() {
  const dispatch = useDispatch()
  const language = localStorage.getItem("language") != null ? localStorage.getItem("language") : "en"

  const getLabels = (language) => {
    dispatch(getAllLabels(language))
    dispatch(getProfanityWord())
  };

  useEffect(() => {
    getLabels(language);
  }, [])

  return (
    <RecoilRoot>
      <Router>
        <RouteValidator />
        <AuthProvider>
          <CommonRoutes />
          <JobSeekerRoutes />
          <EmployerRoutes />
          <ToastContainer />
        </AuthProvider>
      </Router>
    </RecoilRoot>
  )
}

export default App;
