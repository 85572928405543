import React, { useState, Fragment } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextfieldCustom from "./Components/TextfieldCustom";
import CustomButton from "./Components/CustomButton";
import { useNavigate } from "react-router-dom";
import { warningNotify } from "./CommonCode/Commonfunc";

import "../views/Landinpage1/Style.css";
import { useSelector } from "react-redux";
import {profanityfn} from "./Profanity/Profanity";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Home = () => {
  const Labels = useSelector((state) => state.allLabels.labels);
  const [open, setOpen] = useState(true);
  // const handleClose = () => setOpen(false);
  const [passwords, setPasswords] = useState({password: ""});
  const { password } = passwords;
  const getPassword = (e) => {
    const value = e.target.value;
    setPasswords({ ...passwords, [e.target.name]:value});
  };
  const redirect = useNavigate();

  const submitPassword = (e) => {
    // e.preventDefault();

    if (password === "admin123") {
      redirect("/landingpage");
    } else {
      warningNotify(Labels.unAuthorizedAccess);
    }
  };

  return (
    <Fragment>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {Labels.EnterPasswordToContinue}
          </Typography>
          <div className="flex">
            <TextfieldCustom
              style={{
                width: 300,
              }}
              type="password"
              name="password"
              value={password}
              onChange={(e) => getPassword(e)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  submitPassword();
                }
              }}
            />

            <CustomButton
              label={Labels.Submit}
              style={{ ml: 2 }}
              onClick={submitPassword}
            />
          </div>
        </Box>
      </Modal>
    </Fragment>
  );
};

export default Home;
