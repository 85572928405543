import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import { axiosAPI } from "../Axios/Axios";
import { TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const CompanyCountry = ({ value, setValue, setAdressId, setAddressData ,style,ref,setButtonDisable = () => {}, backup={}}) => {
  const location = useLocation();
  const isCreateJobRoute = location.pathname.includes("/Createjob");

  const [companyCountries, setCompanyCountries] = useState([]);
  const Labels = useSelector((state) => state.allLabels.labels);

  useEffect(() => {
    const getCountries = async () => {
      const result = await axiosAPI.get("/getCompanyCountries");
      const { status, data } = await result.data;
      if (status === true) {
        setCompanyCountries(data);
      } else {
        setCompanyCountries([]);
      }
    };
    getCountries();
  }, []);


  useEffect(()=>{
    if(companyCountries.length){
      setValue({id: companyCountries[0].id, name: companyCountries[0].name })
    }
  },[companyCountries])


  const handleChange = async (event, newValue) => {
    setButtonDisable(false)
    setValue(newValue);
    setAdressId(newValue.name);
  };
  
  return (
  
     <FormControl sx={{ width: "100%" }}>

      {companyCountries.length == 1 ? (
        <TextField
          ref={ref}
          value={companyCountries[0].name}
          onChange={(e) => setValue({ name: e.target.value, id: companyCountries[0].id })} // Include countryId
          placeholder={Labels?.SelectCountry}
          {...style}
          InputProps={{ style: { height: "45px" } }}
          sx={{
            ...style,
            borderRadius: '4px',
            '& .MuiOutlinedInput-root': {
              borderRadius: '4px', // Adjust border radius if needed
              border: `2px solid black`,
              '& fieldset': {
                border: 'none', // Remove the default border
              },
              '&:hover': {
                border: `2px solid black`,
              },
              '&.Mui-focused': {
                border: `2px solid black`,
              },
            },
          }}
        />
      ): (
        <Autocomplete
        value={value}
        onChange={handleChange}
        options={companyCountries}
        autoHighlight
        disableClearable
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            ref={ref}
            {...params}
            placeholder={Labels?.SelectCountry}
            sx={{
              ...style,
              '& .MuiOutlinedInput-root': {
                borderRadius: '4px', // Adjust border radius if needed
                border: `2px solid ${JSON.stringify(backup) == JSON.stringify(value) ? 'black' : isCreateJobRoute?"black":'green'}`,
                '& fieldset': {
                  border: 'none', // Remove the default border
                },
                '&:hover': {
                  border: `2px solid ${JSON.stringify(backup) == JSON.stringify(value) ? 'black' : isCreateJobRoute?"black":'green'}`,
                },
                '&.Mui-focused': {
                  border: `2px solid ${JSON.stringify(backup) == JSON.stringify(value) ? 'black' : isCreateJobRoute?"black":'green'}`,
                },
              },
            }}
          />
        )}
        noOptionsText={Labels?.notAvailable}
        inputValue={value && value.name ? value.name : ""}
      />
      )}
    </FormControl>
  );
};

export default CompanyCountry;
