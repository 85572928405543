import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosAPI } from "../views/Axios/Axios";

const initialState = {
  loading: false,
  error: "",
  wordlist: [],
};

const getProfanityWord = createAsyncThunk(
  "api/profanity",
  async () => {
    return await axiosAPI
      .get(`/getProfanityWords`)
      .then((response) => {
        return response.data.data;
      });
  }
);

const getProfanitySlice = createSlice({
  name: "Profanity",
  initialState,
  extraReducers: {
    [getProfanityWord.pending]: (state) => {
      state.loading = true;
    },
    [getProfanityWord.fulfilled]: (state, { payload }) => {
      state.wordlist = payload;
      state.loading = false;
    },
    [getProfanityWord.rejected]: (state) => {
      state.error = "something went wrong";
      state.loading = false;
    },
  },
});

export { getProfanityWord };

export default getProfanitySlice.reducer;
