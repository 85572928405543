import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
};

const createLoadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    updateLoadingState: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { updateLoadingState } = createLoadingSlice.actions;

export default createLoadingSlice.reducer;
