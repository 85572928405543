import {
  Box,
  Button,
  Stack,
  Divider,
  Typography,
  ButtonBase,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { useSelector } from "react-redux";
import { Fragment, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import React from "react";
import fifyPluslogo from "../../Images/fifyPluslogo.png";
import { axiosAPI } from "../Axios/Axios";
import { ImFacebook } from "react-icons/im";
import {
  AiOutlineYoutube,
  AiOutlineTwitter,
  AiOutlineInstagram,
} from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import "./Style.css";

const Footer = () => {
  const allList = useSelector((state) => state.allList.list);
  const navigate = useNavigate();
  const gotoLandingPage = (e) => {
    e.preventDefault();
    const searchData = {
      language: "en",
      search: "",
    };
    navigate("/jobSearchPrelogin", { state: searchData });
  };
  const year = new Date().getFullYear();
  const postData = {
    language: "en",
    search: "",
    skills: "",
    city: "",
    category: "",
    type: "",
  };
  // -----------------Search Jobs----------------------
  const [formdata, setformData] = useState({
    PrivacyPolicy: "",
  });
  const { PrivacyPolicy } = formdata;
  const [showprivacy, setShowprivacy] = useState(false);
  // const [open, setOpen] = useState(false);
  const [showterms, setShowterms] = useState(false);
  const handleOpenterms = () => {
    setShowterms(true);
  };
  const handleCloseterms = () => {
    setShowterms(false);
  };

  const handleOpenDialog = () => {
    setShowprivacy(true);
  };

  const handleCloseDialog = () => {
    setShowprivacy(false);
  };
  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <Stack sx={{ background: "#1C614E1C", padding: { sm: 0, md: 3 } }}>
      {/* <Box
        sx={{
          width: { sm: "100%", md: "90%" },
          marginTop: { sm: 10, md: -20 },
          padding: 5,
          display: { sx: "block", md: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
          background: "#1C614E",
          borderRadius: 4,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div>
          <Typography
            component="h4"
            variant="h4"
            color="inherit"
            sx={{ color: "#fff" }}
          >
            Never want to miss
          </Typography>
          <Stack direction="row" sx={{ marginBottom: 2 }}>
            <Typography
              component="h4"
              variant="h4"
              color="inherit"
              sx={{ color: "#fff" }}
            >
              any&nbsp;
            </Typography>
            <Typography
              component="h4"
              variant="h4"
              color="inherit"
              sx={{ color: "#BA8C04" }}
            >
              job news
            </Typography>
          </Stack>
        </div>
        <div className="subscribe-input">
          <input placeholder="Enter your Email" type="email" />
          <button>Subscribe</button>
        </div>
      </Box> */}

      <div className="flex" style={{ margin: "35px 0" }}>
        <img src={fifyPluslogo} alt={"logo"} />

        <div className="flex">
          <Typography
            component="h6"
            variant="h6"
            color="inherit"
            sx={{ color: "#2B4947", fontWeight: 600, margin: "10px" }}
          >
            {Labels?.Writeus}
          </Typography>
          <Typography
            variant="subtitle1"
            color="inherit"
            sx={{ color: "#2B4947", fontWeight: 400, margin: "10px" }}
          >
            email@domain.com
          </Typography>
        </div>
      </div>
      <div className="flex">
        <Stack
          direction="row"
          sx={{
            color: "#fff",
            marginBottom: "35px",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              background: "#2B4947",
              fontSize: 20,
              width: 30,
              height: 30,
              borderRadius: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: 5,
            }}
          >
            <ImFacebook />
          </div>
          <div
            style={{
              background: "#2B4947",
              fontSize: 20,
              width: 30,
              height: 30,
              borderRadius: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: 5,
            }}
          >
            <AiOutlineYoutube />
          </div>
          <div
            style={{
              background: "#2B4947",
              fontSize: 20,
              width: 30,
              height: 30,
              borderRadius: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: 5,
            }}
          >
            <AiOutlineTwitter />
          </div>
          <div
            style={{
              background: "#2B4947",
              fontSize: 20,
              width: 30,
              height: 30,
              borderRadius: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: 5,
            }}
          >
            <AiOutlineInstagram />
          </div>
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} sx={{ color: "#fff" }}>
          <Button
            variant="text"
            sx={{
              textTransform: "capitalize",
              color: "#2B4947",
              // boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              // background: "#1C614E1C",
              margin: 1,
            }}
            onClick={() => navigate("/jobSearchPrelogin", { state: postData })}
          >
            {Labels?.Jobs}
          </Button>
          <Button
            variant="text"
            sx={{
              textTransform: "capitalize",
              color: "#2B4947",
              // boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              // background: "#1C614E1C",
              margin: 1,
            }}
            onClick={() => navigate("/about")}
          >
            {Labels?.AboutUs}
          </Button>
          <Button
            variant="text"
            sx={{
              textTransform: "capitalize",
              color: "#2B4947",
              // boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              // background: "#1C614E1C",
              margin: 1,
            }}
            onClick={() => navigate("/contact")}
          >
            {Labels?.Contactus}
          </Button>
          <Button
            variant="text"
            sx={{
              textTransform: "capitalize",
              color: "#2B4947",
              // boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              // background: "#1C614E1C",
              margin: 1,
            }}
            onClick={() => {
              handleOpenterms();
            }}
          >
            {Labels?.TandC}
          </Button>

          <Dialog open={showterms} onClose={handleCloseterms}>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ p: 0.5, mt: 0.5 }}
            >
              <DialogTitle
                sx={{
                  color: "#1C614E",
                }}
              >
               {Labels?.TermsandConditions}
              </DialogTitle>
              <HighlightOffIcon
                sx={{
                  color: "#1C614E",
                  cursor: "pointer",
                  m: 2,
                }}
                onClick={handleCloseterms}
              />
            </Stack>
            <DialogContent sx={{ pt: 0, mb: 1 }}>
              {allList?.privacyPolicy?.terms && (
                <Typography>{allList?.privacyPolicy?.terms}</Typography>
              )}
            </DialogContent>
          </Dialog>

          <Button
            variant="text"
            sx={{
              textTransform: "capitalize",
              color: "#2B4947",
              // boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              // background: "#1C614E1C",
              margin: 1,
            }}
            onClick={() => {
              handleOpenDialog();
            }}
          >
            {Labels?.PrivacyPolicy}
          </Button>
          <Stack>
            <Dialog open={showprivacy} onClose={handleCloseDialog}>
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ p: 0.5, mt: 0.5 }}
              >
                <DialogTitle
                  sx={{
                    color: "#1C614E",
                  }}
                >
                  {Labels?.PrivacyPolicy}
                </DialogTitle>
                <HighlightOffIcon
                  sx={{
                    color: "#1C614E",
                    cursor: "pointer",
                    m: 2,
                  }}
                  onClick={handleCloseDialog}
                />
              </Stack>

              <DialogContent sx={{ pt: 0, mb: 1 }}>
                {allList?.privacyPolicy?.privacy && (
                  <Typography>{allList?.privacyPolicy?.privacy}</Typography>
                )}
              </DialogContent>
            </Dialog>
          </Stack>
        </Stack>
      </div>
      <Stack sx={{ borderTop: "1px solid rgba(0, 0, 0, 0.07)" }}>
        <div className="flex">
          <p>
            {Labels?.AllRightsReserved} &copy; {year} &nbsp;
          </p>
          <p>{Labels?.DevelopedbyM2india}</p>
        </div>
      </Stack>
    </Stack>
  );
};

export default Footer;
