import React, { Fragment, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { axiosAPI } from "../Axios/Axios";
import { useSelector } from "react-redux";
import { profanityfn } from "../Profanity/Profanity";
import { Tooltip } from "@mui/material";

export default function JobPostingDuration({
  value,
  setValue,
  placeHolder,
  style,
  setButtonDisabled5 = () => {},
  backup = "",
  isEdit = true,
  jobDetails = {},
  isProfile = false,
}) {
  const ProfanityList = useSelector((state) => state.Profanity.wordlist);

  const handleChange = (event) => {
    setButtonDisabled5(false);
    setValue(profanityfn(ProfanityList, event.target.value));
  };
  const usertype = localStorage.getItem("userType");
  const Labels = useSelector((state) => state.allLabels.labels);

  const [isWithinTwoDays, setisWithinTwoDays] = useState(true);

  useEffect(() => {
    if (!isProfile) {
      const createdDate = new Date(jobDetails?.created_on);
      const now = new Date();

      const twoDaysLater = new Date(createdDate);
      twoDaysLater.setDate(createdDate.getDate() + 2);

      setisWithinTwoDays(now <= twoDaysLater);
    } 
  }, [jobDetails?.created_on]);

  return (
    <Fragment>
      <FormControl sx={{ width: "100%" }}>
        {isWithinTwoDays ? (
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            value={value ? value : ""}
            onChange={handleChange}
            fullWidth
            displayEmpty
            readOnly={!isWithinTwoDays}
            variant="outlined"
            sx={{
              padding: "10px 12px",
              fontSize: "14px",
              fontFamily: "Poppins",
              border: isEdit
                ? `2px solid ${backup == value ? "black" : "green"}`
                : `2px solid black`,
              "& fieldset": {
                border: "none", // Remove the default border
              },
              "&:hover": {
                border: isEdit
                  ? `2px solid ${backup == value ? "black" : "green"}`
                  : `2px solid black`,
              },
              "&.Mui-focused": {
                border: isEdit
                  ? `2px solid ${backup == value ? "black" : "green"}`
                  : `2px solid black`,
              },
              ...style,
            }}
          >
            <MenuItem disabled value="">
              <span style={{ color: "#bcbcbc" }}>
                {Labels.JobPostDuration}{" "}
              </span>
            </MenuItem>
            <MenuItem value="1">1 {Labels.Week}</MenuItem>
            <MenuItem value="2">2 {Labels.Week}</MenuItem>
            <MenuItem value="3">3 {Labels.Week}</MenuItem>
            <MenuItem value="4">4 {Labels.Week}</MenuItem>
            <MenuItem value="5">5 {Labels.Week}</MenuItem>
            <MenuItem value="6">6 {Labels.Week}</MenuItem>
            <MenuItem value="7">7 {Labels.Week}</MenuItem>
            <MenuItem value="8">8 {Labels.Week}</MenuItem>
          </Select>
        ) : (
          <Tooltip title={Labels.JobDurationChange}>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              value={value ? value : ""}
              onChange={handleChange}
              fullWidth
              displayEmpty
              readOnly={!isWithinTwoDays}
              variant="outlined"
              sx={{
                padding: "10px 12px",
                fontSize: "14px",
                fontFamily: "Poppins",
                border: isEdit
                  ? `2px solid ${backup == value ? "black" : "green"}`
                  : `2px solid black`,
                "& fieldset": {
                  border: "none", // Remove the default border
                },
                "&:hover": {
                  border: isEdit
                    ? `2px solid ${backup == value ? "black" : "green"}`
                    : `2px solid black`,
                },
                "&.Mui-focused": {
                  border: isEdit
                    ? `2px solid ${backup == value ? "black" : "green"}`
                    : `2px solid black`,
                },
                ...style,
              }}
            >
              <MenuItem disabled value="">
                <span style={{ color: "#bcbcbc" }}>
                  {Labels.JobPostDuration}{" "}
                </span>
              </MenuItem>
              <MenuItem value="1">1 {Labels.Week}</MenuItem>
              <MenuItem value="2">2 {Labels.Week}</MenuItem>
              <MenuItem value="3">3 {Labels.Week}</MenuItem>
              <MenuItem value="4">4 {Labels.Week}</MenuItem>
              <MenuItem value="5">5 {Labels.Week}</MenuItem>
              <MenuItem value="6">6 {Labels.Week}</MenuItem>
              <MenuItem value="7">7 {Labels.Week}</MenuItem>
              <MenuItem value="8">8 {Labels.Week}</MenuItem>
            </Select>
          </Tooltip>
        )}
      </FormControl>
    </Fragment>
  );
}
