import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import { axiosAPI } from "../Axios/Axios";
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import { Clear } from "@mui/icons-material";

const PaperComponent = (props) => {
  return <Paper {...props} style={{ maxHeight: 200, overflow: "auto" }} />;
};
const Skills = ({ style, value, setValue }) => {
  const [skill, setSkill] = useState([]);

  useEffect(() => {
    const getSkills = async () => {
      const result = await axiosAPI.get("/getSkills");
      const { success, data } = await result.data;
      if (success === true) {
        setSkill(data);
      } else {
        setSkill([]);
      }
    };
    getSkills();
  }, []);
  const [svalues, setsvalues] = useState([]);

  const handleChange = (event, newValue) => {
    const selectedValues = Array.isArray(newValue) ? newValue : [];
    setsvalues(selectedValues);
    setValue(selectedValues.map((option) => option.id));
  };

  const Labels = useSelector((state) => state.allLabels.labels);

  return (
    <FormControl sx={{ width: "100%" }}>
      <Autocomplete
        onChange={handleChange}
        options={skill}
        multiple
        sx={{ ...style }}
        clearIcon={
          <IconButton
            sx={{
              // border: "2px solid red",
              position: "absolute",
              left: "50px",
              visibility: "visible",
            }}
          >
            <Clear />
          </IconButton>
        }
        getOptionLabel={(option) => option.name}
        PaperComponent={PaperComponent}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={Labels?.skills}
            sx={{
              width: svalues.length ? "90%" : "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "4px", // Adjust border radius if needed
                border: `2px solid black`,
                "& fieldset": {
                  border: "none", // Remove the default border
                },
                "&:hover": {
                  border: `2px solid black`,
                },
                "&.Mui-focused": {
                  border: `2px solid black`,
                },
              },
            }}
          />
        )}
        noOptionsText={Labels?.notAvailable}
      />
    </FormControl>
  );
};

export default Skills;
