import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import { axiosAPI } from "../../Axios/Axios";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";

const CustomCountry = ({
  value,
  setValue,
  style,
  countryId = "",
  userchangedcountry,
  setuserchangedcountry,
  setButtonDisabled1,
}) => {
  const dispatch = useDispatch();
  const [Countries, setCountries] = useState([]);
  const usertype = localStorage.getItem("userType");

  useEffect(() => {
    const getCountries = async () => {
      const result = await axiosAPI.get("/getCountries");
      const { success, data } = await result.data;
      if (success === true) {
        setCountries(data);
        if (countryId) {
          const selectedCountry = data.find(
            (country) => country.id == countryId
          );
          if (selectedCountry) {
            setValue(selectedCountry);
          }
        }
      } else {
        setCountries([]);
      }
    };
    getCountries();
  }, [countryId]);

  const Labels = useSelector((state) => state.allLabels.labels);

  const handleChange = (event, newValue) => {
    setButtonDisabled1(false);
    setValue(newValue);
    setuserchangedcountry(true);
  };

  return (
    <FormControl sx={{ width: "65.5%", position: "relative", right: "5px" }}>
      <Autocomplete
        value={value}
        onChange={handleChange}
        options={Countries}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        readOnly={usertype === "Recruiter"}
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={Labels?.SelectCountry}
            sx={{
              height: "25px",
              input: { height: "6px", fontSize: "14px" }, // Adjust font size here
              ".MuiInputBase-root": {
                fontSize: "13px", // For consistency in Autocomplete text
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "4px", // Adjust border radius if needed
                border: `2px solid black`,
                "& fieldset": {
                  border: "none", // Remove the default border
                },
                "&:hover": {
                  border: `2px solid black`,
                },
                "&.Mui-focused": {
                  border: `2px solid black`,
                },
              },
            }}
          />
        )}
        noOptionsText={Labels?.notAvailable}
        sx={{
          ".MuiAutocomplete-input": {
            fontSize: "13px", // Font size for dropdown input text
          },
          ".MuiAutocomplete-option": {
            fontSize: "13px", // Font size for dropdown options
          },
        }}
      />
    </FormControl>
  );
};

export default CustomCountry;
