import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import Header from "./Header";
import Advertisement2 from "./Advertisement2";
import Companylogo from "./Companylogo";
import AboutCompany from "./AboutCompany";
import Footer from "./Footer";
import PageHeading from "./PageHead";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { Chip, Paper } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const Labels = useSelector((state) => state.allLabels.labels);
  const defaultTheme = createTheme();
  return (
    <ThemeProvider theme={defaultTheme}>
    <Container
      maxWidth="100%"
      sx={{ margin: "0 auto", padding: "0 !important" }}
    >
      <PageHeading pageHeading={Labels?.AboutUs} />
      <Container maxWidth="xl">
      <Paper elevation={0}>
      <Box sx={{ p: 5 }}>
        <AboutCompany />
        <Advertisement2 />
        <Companylogo />
       
      </Box>
      </Paper>
      </Container>
      <Footer />
    </Container>
    </ThemeProvider>
  );
}

export default AboutUs;
