import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  InputBase,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AssessmentIcon from "@mui/icons-material/Assessment";
import TimelineIcon from "@mui/icons-material/Timeline";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import WorkRoundedIcon from "@mui/icons-material/WorkRounded";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import advertisements from "../../../Images/advertisements.png";
import { PUBLIC_IMAGE_FOLDER } from "../../Axios/Constant";
import { useNavigate } from "react-router-dom";
import { axiosAPI, axiosupload } from "../../Axios/Axios";
import { useDispatch, useSelector } from "react-redux";
import Cropper from "react-cropper";
import {
  createJobseekerDetails,
  updateJobSeekerDetails,
} from "../../../redux/JobSeekerDetailSlice";
import {
  errorNotify,
  infoNotify,
  succesNotify,
  useStyles,
} from "../../CommonCode/Commonfunc";
import { updateLoginDetails } from "../../../redux/JobSeekerLoginDetailSlice";
import { VscLayoutSidebarLeftOff } from "react-icons/vsc";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Close } from "@mui/icons-material";
import { createRef } from "react";
import CustomButton from "../../Components/CustomButton";
import { useScreenWidth } from "../../CommonCode/ScreenWidth/useScreenWidth";
import { useLocation } from "react-router-dom";

export default function JobSeekerSidebar() {
  const redirect = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isApiCalled, setApiCall] = useState(0);
  const [open, setOpen] = useState(false);
  const [openGroup, setOpenGroup] = useState(false);
  const [openForum, setOpenForum] = useState(false);
  const [openMessages, setOpenMessages] = useState(false);

  const tokens = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };
  const handleClick = () => {
    localStorage.setItem("JobPath", true);
    setOpen(!open);
  };
  const handleClickGroups = () => {
    setOpenGroup(!openGroup);
  };
  const handleClickForums = () => {
    setOpenForum(!openForum);
  };

  const handleClickMessages = () => {
    setOpenMessages(!openMessages);
  };
  const postData = {
    search: "",
    city: "",
  };
  const jobSeekerLogout = async (e) => {
    e.preventDefault();
    try {
      const result = await axiosAPI.post("/logout", {
        language: localStorage.getItem("language"),
      });
      const { success, message } = result.data;
      if (success) {
        localStorage.clear();
        dispatch(updateLoginDetails({}));
        dispatch(updateJobSeekerDetails({}));
        succesNotify(message);
        redirect("/landingpage");
      } else {
        localStorage.clear();
        redirect("/landingpage");
      }
    } catch (error) {
      localStorage.clear();
      redirect("/landingpage");
    }
  };

  const gotoProfilePage = () => {
    redirect("/jobseekerProfile");
    setMobileOpen(false);
  };

  const redirecttoDashboard = () => {
    redirect("/jobseekerDashboard");
    setMobileOpen(false);
  };
  const redirectToJobList = () => {
    redirect("/jobList", { state: postData });
    setMobileOpen(false);
  };
  const redirectToRecomendedJobs = () => {
    redirect("/recommendedJobs");
    setMobileOpen(false);
  };

  const redirectToAppliedJobs = () => {
    redirect("/appliedJobs");
    setMobileOpen(false);
  };
  const redirectSavedJobs = () => {
    redirect("/savedJobs");
    setMobileOpen(false);
  };

  const redirectToSearchGroupList = () => {
    redirect("/searchGroupList");
    setMobileOpen(false);
  };

  const redirectToGroupList = () => {
    redirect("/myGroupList");
    setMobileOpen(false);
  };
  const redirectToForum = () => {
    redirect("/forum");
    setMobileOpen(false);
  };

  const redirectToMyForum = () => {
    redirect("/myForum");
    setMobileOpen(false);
  };
  const redirectToChat = () => {
    redirect("/chat");
    setMobileOpen(false);
  };

  const redirectToMessage = () => {
    redirect("/message");
    setMobileOpen(false);
  };
  const redirectToNotifications = () => {
    redirect("/notifications");
    setMobileOpen(false);
  };

  const redirectToDetailedJobView = (sourceRoute) => {
    localStorage.setItem("sourceRoute", sourceRoute);
    redirect(sourceRoute);
  };

  useEffect(() => {
    dispatch(createJobseekerDetails());
  }, [isApiCalled]);
  const Labels = useSelector((state) => state.allLabels.labels);
  const data = useSelector(
    (state) => state.getJobseekerProfileData.jobseekerDetails
  );

  // -------------------- Drawer --------------------
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(true);
  };
  const handleCloseDrawerToggle = () => {
    setMobileOpen(false);
  };
  const [openProfileImage, setOpenProfileImage] = useState(false);

  // ----------------Delete Profile pic ------------------

  const deletePrifilePic = async () => {
    if (!data.profilePicPath) {
      return infoNotify("Upload profile picture");
    }
    const result = await axiosAPI.post("/deleteProfilePic", {
      language: localStorage.getItem("language"),
    });
    const { status, message } = result.data;
    if (status) {
      succesNotify(message);
      setOpenProfileImage(false);
      setApiCall(Math.random());
    }
  };

  // ----------------Profile pic upload ------------------
  const [image, setImage] = useState(null);
  const [imagename, setImagename] = useState(null);

  const [croppedImage, setCroppedImage] = useState(null);
  const [selectImage, setSelectImage] = useState(false);
  const cropperRef = React.createRef();

  const onChange = (e) => {
    e.preventDefault();
    setOpenProfileImage(false);
    setSelectImage(true);
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files[0];
    }
    // setImage(files)
    setImagename(files);
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files);
  };
  const handleUpload = async () => {
    const croppedDataURL = cropperRef.current.cropper
      .getCroppedCanvas()
      .toDataURL();

    uploadProfilePicture(croppedDataURL, imagename);
  };

  const uploadProfilePicture = async (manipulatedDataURL, imagename) => {
    const blob = await fetch(manipulatedDataURL).then((res) => res.blob());
    const formData = new FormData();
    // formData.append("images", blob);
    formData.append("images", blob);

    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/jpg",
      "image/webp",
    ];
    const maxFileSize = 3145728;

    if (!allowedTypes.includes(blob.type)) {
      return errorNotify(Labels.invaildFile);
    }

    if (blob.size > maxFileSize) {
      return errorNotify(Labels.imageValidation);
    }

    const blob2 = formData.get("images"); // Replace 'yourBlobField' with the actual field name

    // Create a proper File from the Blob
    const fileName = imagename.name; // Replace with your desired file name
    const mimeType = imagename.type; // Replace with your desired MIME type

    const file = new File([blob2], fileName, { type: mimeType });

    const formData2 = new FormData();
    formData2.append("images", file);
    formData2.append("language", localStorage.getItem("language"));

    try {
      const response = await axiosupload.post(
        "/uploadProfilePic",
        formData2,
        tokens
      );
      const { status, message } = response.data;
      if (status === "successs") {
        succesNotify(message);
        setSelectImage(false);
        setOpenProfileImage(false);
        setApiCall(Math.random());
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  const CurrentWidth = useScreenWidth();

  const location = useLocation();

  const isActiveRoute = (route) => {
    return location?.pathname?.includes(route);
  };

  const activeRoute = window.location.pathname;
  const storedSourceRoute = localStorage.getItem("sourceRoute");
  const detailedJobViewActive = activeRoute.includes("/detailedJobView");
  const bgcolor = "lightgrey";

  return (
    <div
      style={{
        position: CurrentWidth > 1199 ? "fixed" : "relative",
        width: CurrentWidth > 1534 ? "15.7%" : `23.7%`,
      }}
    >
      <Stack sx={{ display: { xs: "block", lg: "none" } }}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          onClick={handleDrawerToggle}
          sx={{ cursor: "pointer" }}
        >
          <VscLayoutSidebarLeftOff style={{ fontSize: 23 }} />
          <ChevronRightIcon />
          <Typography variant="caption" style={{ fontSize: 20 }}>
            {Labels.Menu}{" "}
          </Typography>
        </Stack>
      </Stack>
      {/* -------------------- Drawer -------------------- */}
      <Drawer anchor="left" open={mobileOpen} onClose={handleCloseDrawerToggle}>
        <Stack sx={{ width: 250 }}>
          <Card
            sx={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              boxShadow: "none",
            }}
          >
            <CardContent>
              <Avatar
                src={`${data?.profilePicPath}`}
                sx={{
                  width: 100,
                  height: 100,
                  borderRadius: "100%",
                  objectFit: "cover",
                  mx: "auto",
                  cursor: "pointer",
                }}
                onClick={() => setOpenProfileImage(true)}
              />
              <Typography variant="subtitle1" sx={{ color: "#2B4947" }}>
                {data?.displayName}
              </Typography>
              <Typography variant="body2" sx={{ color: "#84827D" }}>
                {data?.designationName}
              </Typography>
              <Typography variant="caption" sx={{ color: "#84827D" }}>
                {data?.currentEmployer}
              </Typography>
            </CardContent>
          </Card>
          <Divider />
          <List
            sx={{
              bgcolor: "background.paper",
              borderRadius: 1,
              margin: "10px 0",
            }}
            component="nav"
          >
            {/*-------------------- Dashboard --------------------*/}
            <ListItemButton onClick={redirecttoDashboard}>
              <ListItemIcon>
                <DashboardIcon sx={{ color: "#050505BF" }} />
              </ListItemIcon>
              <ListItemText primary={Labels?.Dashboard} />
            </ListItemButton>
            {/*-------------------- My Profile --------------------*/}
            <ListItemButton onClick={gotoProfilePage}>
              <ListItemIcon>
                <AccountBoxIcon sx={{ color: "#050505BF" }} />
              </ListItemIcon>
              <ListItemText primary={Labels?.myProfile} />
            </ListItemButton>
            {/*-------------------- Jobs --------------------*/}
            <ListItemButton onClick={handleClick}>
              <ListItemIcon>
                <WorkRoundedIcon sx={{ color: "#050505BF" }} />
              </ListItemIcon>
              <ListItemText primary={Labels?.Jobs} />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 8 }} onClick={redirectToJobList}>
                  <ListItemText secondary={Labels?.AllJobs} />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 8 }}
                  onClick={redirectToRecomendedJobs}
                >
                  <ListItemText secondary={Labels?.recommendedJobs} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 8 }} onClick={redirectToAppliedJobs}>
                  <ListItemText secondary={Labels?.appliedJobs} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 8 }} onClick={redirectSavedJobs}>
                  <ListItemText secondary={Labels?.savedjobs} />
                </ListItemButton>
              </List>
            </Collapse>
            {/*-------------------- Groups --------------------*/}
            <ListItemButton onClick={handleClickGroups}>
              <ListItemIcon>
                <AssessmentIcon sx={{ color: "#050505BF" }} />
              </ListItemIcon>
              <ListItemText primary={Labels?.Groups} />
              {openGroup ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openGroup} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 8 }}
                  onClick={redirectToSearchGroupList}
                >
                  <ListItemText secondary={Labels?.DiscoverGroups} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 8 }} onClick={redirectToGroupList}>
                  <ListItemText secondary={Labels?.MyGroups} />
                </ListItemButton>
              </List>
            </Collapse>
            {/*-------------------- Forums --------------------*/}
            <ListItemButton onClick={handleClickForums}>
              <ListItemIcon>
                <TimelineIcon sx={{ color: "#050505BF" }} />
              </ListItemIcon>
              <ListItemText primary={Labels?.Forums} />
              {openForum ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openForum} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 8 }} onClick={redirectToForum}>
                  <ListItemText secondary={Labels?.discoverForum} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 8 }} onClick={redirectToMyForum}>
                  <ListItemText secondary={Labels?.MyForums} />
                </ListItemButton>
              </List>
            </Collapse>
            {/*-------------------- Messages --------------------*/}
            <ListItemButton onClick={handleClickMessages}>
              <ListItemIcon>
                <ChatBubbleOutlineIcon sx={{ color: "#050505BF" }} />
              </ListItemIcon>
              <ListItemText primary={Labels?.Messages} />
              {openMessages ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openMessages} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 8 }} onClick={redirectToChat}>
                  <ListItemText secondary={Labels?.Onlinechat} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 8 }} onClick={redirectToMessage}>
                  <ListItemText secondary={Labels?.Messages} />
                </ListItemButton>
              </List>
            </Collapse>
            {/*-------------------- Notifications --------------------*/}
            <ListItemButton onClick={redirectToNotifications}>
              <ListItemIcon>
                <NotificationsIcon sx={{ color: "#050505BF" }} />
              </ListItemIcon>
              <ListItemText primary={Labels?.Notifications} />
            </ListItemButton>
            {/*-------------------- Logout --------------------*/}
            <ListItemButton onClick={jobSeekerLogout}>
              <ListItemIcon>
                <LogoutIcon sx={{ color: "#050505BF" }} />
              </ListItemIcon>
              <ListItemText primary={Labels?.Logout} />
            </ListItemButton>
            <ListItem>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: 75,
                  borderRadius: 4,
                  justifyContent: "center",
                  alignItems: "center",
                  background:
                    "linear-gradient(97.72deg, rgba(28, 97, 78, 0) 2.18%, rgba(28, 97, 78, 0.58) 86.2%)",
                  backgroundImage: `url(${advertisements})`,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    textTransform: "uppercase",
                    fontStyle: "italic",
                    color: "#FFFFFF",
                  }}
                >
                  {Labels.advertisementArea}
                </Typography>
              </Box>
            </ListItem>
          </List>
        </Stack>
      </Drawer>

      <Stack
        sx={{
          display: { xs: "none", lg: "block" },
          position: { md: "sticky" },
          top: "85px",
        }}
        className={classes.customScrollbar}
      >
        <Card
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            boxShadow: "none",
          }}
        >
          <CardContent
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <Box sx={{ position: "relative" }}>
              <Avatar
                src={`${data?.profilePicPath}`}
                sx={{
                  width: 100,
                  height: 100,
                  borderRadius: "100%",
                  cursor: "pointer",
                }}
                onClick={() => setOpenProfileImage(true)}
              />
              <IconButton
                component="label"
                sx={{
                  position: "absolute",
                  zIndex: 2,
                  bottom: "7%",
                  right: "0%",
                  padding: 0.5,
                  background: "white",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",

                  "&:hover": {
                    background: "white",
                  },
                }}
              >
                <CameraAltIcon
                  sx={{
                    color: "black",
                  }}
                />
                <InputBase
                  type="file"
                  style={{ display: "none" }}
                  accept="image/jpeg, image/png, image/gif"
                  onChange={onChange}
                />
              </IconButton>
            </Box>

            <Typography variant="subtitle1" sx={{ color: "#2B4947" }}>
              {data?.displayName}
            </Typography>
            <Typography variant="body2" sx={{ color: "#84827D" }}>
              {data?.designationName}
            </Typography>
            <Typography variant="caption" sx={{ color: "#84827D" }}>
              {data?.currentEmployer}
            </Typography>
          </CardContent>
        </Card>

        <List
          sx={{
            bgcolor: "background.paper",
            borderRadius: 1,
            margin: "10px 0",
            height: "64vh",
            overflow: "auto",
          }}
          component="nav"
        >
          {/*-------------------- Dashboard --------------------*/}
          <ListItemButton
            onClick={() => redirect("/jobseekerDashboard")}
            sx={
              isActiveRoute("/jobseekerDashboard")
                ? { backgroundColor: bgcolor }
                : {}
            }
          >
            <ListItemIcon>
              <DashboardIcon sx={{ color: "#050505BF" }} />
            </ListItemIcon>
            <ListItemText primary={Labels?.Dashboard} />
          </ListItemButton>
          {/*-------------------- My Profile --------------------*/}
          <ListItemButton
            onClick={gotoProfilePage}
            sx={
              isActiveRoute("/jobseekerProfile")
                ? { backgroundColor: bgcolor }
                : {}
            }
          >
            <ListItemIcon>
              <AccountBoxIcon sx={{ color: "#050505BF" }} />
            </ListItemIcon>
            <ListItemText primary={Labels?.myProfile} />
          </ListItemButton>
          {/*-------------------- Jobs --------------------*/}
          <ListItemButton onClick={handleClick}>
            <ListItemIcon>
              <WorkRoundedIcon sx={{ color: "#050505BF" }} />
            </ListItemIcon>
            <ListItemText primary={Labels?.Jobs} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                onClick={() => {
                  redirectToDetailedJobView("/jobList");
                }}
                sx={
                  activeRoute === "/jobList" ||
                  (detailedJobViewActive && storedSourceRoute === "/jobList")
                    ? { backgroundColor: bgcolor, pl: 8 }
                    : { pl: 8 }
                }
              >
                <ListItemText secondary={Labels?.AllJobs} />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  redirectToDetailedJobView("/recommendedJobs");
                }}
                sx={
                  activeRoute === "/recommendedJobs" ||
                  (detailedJobViewActive &&
                    storedSourceRoute === "/recommendedJobs")
                    ? { backgroundColor: bgcolor, pl: 8 }
                    : { pl: 8 }
                }
              >
                <ListItemText secondary={Labels?.recommendedJobs} />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  redirectToDetailedJobView("/appliedJobs");
                }}
                sx={
                  activeRoute === "/appliedJobs" ||
                  (detailedJobViewActive &&
                    storedSourceRoute === "/appliedJobs")
                    ? { backgroundColor: bgcolor, pl: 8 }
                    : { pl: 8 }
                }
              >
                <ListItemText secondary={Labels?.appliedJobs} />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  redirectToDetailedJobView("/savedJobs");
                }}
                sx={
                  activeRoute === "/savedJobs" ||
                  (detailedJobViewActive && storedSourceRoute === "/savedJobs")
                    ? { backgroundColor: bgcolor, pl: 8 }
                    : { pl: 8 }
                }
              >
                <ListItemText secondary={Labels?.savedjobs} />
              </ListItemButton>
            </List>
          </Collapse>
          {/*-------------------- Groups --------------------*/}
          <ListItemButton onClick={handleClickGroups}>
            <ListItemIcon>
              <AssessmentIcon sx={{ color: "#050505BF" }} />
            </ListItemIcon>
            <ListItemText primary={Labels?.Groups} />
            {openGroup ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openGroup} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={
                  isActiveRoute("/searchGroupList") ||
                  isActiveRoute("/groupDetail")
                    ? { backgroundColor: bgcolor, pl: 8 }
                    : { pl: 8 }
                }
                onClick={() => redirect("/searchGroupList")}
              >
                <ListItemText secondary={Labels?.DiscoverGroups} />
              </ListItemButton>
              <ListItemButton
                sx={
                  isActiveRoute("/myGroupList") ||
                  isActiveRoute("/myGroupDetail")
                    ? { backgroundColor: bgcolor, pl: 8 }
                    : { pl: 8 }
                }
                onClick={() => redirect("/myGroupList")}
              >
                <ListItemText secondary={Labels?.MyGroups} />
              </ListItemButton>
            </List>
          </Collapse>
          {/*-------------------- Forums --------------------*/}
          <ListItemButton onClick={handleClickForums}>
            <ListItemIcon>
              <TimelineIcon sx={{ color: "#050505BF" }} />
            </ListItemIcon>
            <ListItemText primary={Labels?.Forums} />
            {openForum ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openForum} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={
                  isActiveRoute("/forum")
                    ? { backgroundColor: bgcolor, pl: 8 }
                    : { pl: 8 }
                }
                onClick={() => redirect("/forum")}
              >
                <ListItemText secondary={Labels?.discoverForum} />
              </ListItemButton>
              <ListItemButton
                sx={
                  isActiveRoute("/myForum")
                    ? { backgroundColor: bgcolor, pl: 8 }
                    : { pl: 8 }
                }
                onClick={() => redirect("/myForum")}
              >
                <ListItemText secondary={Labels?.MyForums} />
              </ListItemButton>
            </List>
          </Collapse>
          {/*-------------------- Messages --------------------*/}
          <ListItemButton onClick={handleClickMessages}>
            <ListItemIcon>
              <ChatBubbleOutlineIcon sx={{ color: "#050505BF" }} />
            </ListItemIcon>
            <ListItemText primary={Labels?.Messages} />
            {openMessages ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openMessages} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={
                  isActiveRoute("/chat")
                    ? { backgroundColor: bgcolor, pl: 8 }
                    : { pl: 8 }
                }
                onClick={() => redirect("/chat")}
              >
                <ListItemText secondary={Labels?.Onlinechat} />
              </ListItemButton>
              <ListItemButton
                sx={
                  isActiveRoute("/message")
                    ? { backgroundColor: bgcolor, pl: 8 }
                    : { pl: 8 }
                }
                onClick={() => redirect("/message")}
              >
                <ListItemText secondary={Labels?.Messages} />
              </ListItemButton>
            </List>
          </Collapse>
          {/*-------------------- Notifications --------------------*/}
          <ListItemButton
            onClick={() => redirect("/notifications")}
            sx={
              isActiveRoute("/notifications")
                ? { backgroundColor: bgcolor }
                : {}
            }
          >
            <ListItemIcon>
              <NotificationsIcon sx={{ color: "#050505BF" }} />
            </ListItemIcon>
            <ListItemText primary={Labels?.Notifications} />
          </ListItemButton>
          <ListItemButton
            onClick={jobSeekerLogout}
            sx={isActiveRoute("/logout") ? { backgroundColor: bgcolor } : {}}
          >
            <ListItemIcon>
              <LogoutIcon sx={{ color: "#050505BF" }} />
            </ListItemIcon>
            <ListItemText primary={Labels?.Logout} />
          </ListItemButton>
          <ListItem>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: 75,
                borderRadius: 4,
                justifyContent: "center",
                alignItems: "center",
                background:
                  "linear-gradient(97.72deg, rgba(28, 97, 78, 0) 2.18%, rgba(28, 97, 78, 0.58) 86.2%)",
                backgroundImage: `url(${advertisements})`,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  textTransform: "uppercase",
                  fontStyle: "italic",
                  color: "#FFFFFF",
                }}
              >
                {Labels.advertisementArea}
              </Typography>
            </Box>
          </ListItem>
        </List>
      </Stack>
      {/* -------------------- Profile view dialog box -------------------- */}
      <Dialog
        open={openProfileImage}
        onClose={() => setOpenProfileImage(false)}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="flex-end">
            <Close
              sx={{ cursor: "pointer" }}
              onClick={() => setOpenProfileImage(false)}
            />
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ position: "relative" }}>
            <Avatar
              sx={{
                width: 300,
                height: 300,
                borderRadius: "100%",
                cursor: "pointer",
                padding: "2px",
              }}
              onClick={() => setOpenProfileImage(true)}
            >
              <img
                src={`${data?.profilePicPath}`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
            </Avatar>
            <IconButton
              component="label"
              sx={{
                position: "absolute",
                zIndex: 2,
                bottom: 0,
                right: 0,
                padding: "20px",
                background: "white",
                "&:hover": {
                  background: "white",
                },
              }}
            >
              <CameraAltIcon
                sx={{
                  color: "black",
                }}
              />
              <InputBase
                type="file"
                style={{ display: "none" }}
                accept="image/jpeg, image/png, image/gif"
                onChange={onChange}
              />
            </IconButton>
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: "25px" }}>
          <CustomButton
            onClick={deletePrifilePic}
            label={Labels.delete}
            style={{ padding: "5px 18px" }}
          />
        </DialogActions>
      </Dialog>
      {/* ---------------Dialog box for image crop------------------ */}
      <Dialog open={selectImage} onClose={() => setSelectImage(false)}>
        <DialogTitle>
          <Stack direction="row" justifyContent="flex-end">
            <Close
              sx={{ cursor: "pointer" }}
              onClick={() => setSelectImage(false)}
            />
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: { xs: "60%", sm: "50%", md: "60%", lg: "80%" },
              height: { xs: "60%", sm: "50%", md: "60%", lg: "80%" },
            }}
          >
            <Cropper
              ref={cropperRef}
              style={{ height: "100%", width: "100%" }}
              zoomTo={0.5}
              initialAspectRatio={1}
              preview=".img-preview"
              src={image}
              viewMode={1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false}
              guides={true}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: "25px" }}>
          <CustomButton
            onClick={handleUpload}
            label={Labels.Upload}
            style={{ padding: "5px 18px" }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
