import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import JobSearchPostLogin from "../views/Jobseeker/JobSearchPostLogin/JobSearchPostLogin";
import JobseekerDashboard from "../views/Jobseeker/JobseekerProfile/JobseekerDashboard/JobseekerDashboard";
import JobseekerProfile from "../views/Jobseeker/JobseekerProfile/JobseekerProfile";
import JobDetailedViewPostLogin from "../views/Jobseeker/JobSearchPostLogin/JobDetailedViewPostLogin";
import AppliedJobs from "../views/Jobseeker/JobseekerProfile/JobseekerDashboard/AppliedJobs";
import JobApplySuccessPage from "../views/Jobseeker/JobseekerProfile/JobseekerDashboard/JobApplySuccessPage";
import RecommendedJobs from "../views/Jobseeker/JobseekerProfile/JobseekerDashboard/RecommendedJobs";
import OnlineChat from "../views/Jobseeker/messages/onlineChat";
import Messages from "../views/Jobseeker/messages/messages";
import Notification from "../views/Jobseeker/notification/notification";
import { useStyles } from "../views/CommonCode/Commonfunc";
import React, { useEffect } from "react";
import { Container, Grid } from "@mui/material";
import JobSeekerNavbar from "../views/Jobseeker/JobseekerProfile/JobSeekerNavbar";
import JobSeekerSidebar from "../views/Jobseeker/JobseekerProfile/JobSeekerSidebar";
import CreateGroup from "../views/Jobseeker/jobseekerGroup/createGroup";
import SearchGroupList from "../views/Jobseeker/jobseekerGroup/searchGroupList";
import GroupDetails from "../views/Jobseeker/jobseekerGroup/searchGroupDetails";
import MyGroupList from "../views/Jobseeker/jobseekerGroup/myGroupList";
import ForumPage from "../views/Jobseeker/jobseekerforum/forumList";
import CreateForm from "../views/Jobseeker/jobseekerforum/createForum";
import ForumDetails from "../views/Jobseeker/jobseekerforum/forumDetails";
import { useSelector } from "react-redux";
import { useState } from "react";
import JobSeekerActivationModal from "../views/Jobseeker/JobseekerProfile/JobseekerDashboard/JobSeekerActivationModal";
import EditGroupModal from "../views/Jobseeker/jobseekerGroup/EditGroupModal";
import EditForumModal from "../views/Jobseeker/jobseekerforum/editForumModal";
import JobSearchPostLoginNavbar from "../views/Jobseeker/JobSearchPostLogin/JobSearchPostLoginNavbar";
import MyForumPage from "../views/Jobseeker/jobseekerforum/myForumList";
import MyForumDetails from "../views/Jobseeker/jobseekerforum/myForumDetails";
import DetailedGroup from "../views/Jobseeker/jobseekerGroup/detailedgroup";
import CompanyDetailView from "../views/Jobseeker/JobSearchPostLogin/CompayDetailVeiw";
import CustomJobseekerSignUp from "../views/Login/CustomSignup/CustomJobseeker";
import { axiosAPI } from "../views/Axios/Axios";
import MFAPopup from "../views/CommonCode/MFA/MFAPopup";
import MyDetailedGroup from "../views/Jobseeker/jobseekerGroup/MyDetailedGroup";
import SavedJobs from "../views/Jobseeker/JobseekerProfile/JobseekerDashboard/SavedJobs";

function ProtectedRoute({ value }) {
  const classes = useStyles();
  const isAuth = localStorage.getItem("token");
  const [openActivationModal, setActivationModal] = useState(false);
  const [NavHead,setNavHead] = useState("Hello") // Nav Heading text
  const jobSeekerLoginDetails = useSelector(
    (state) => state.jobSeekerloginDetails.loginDetails
  );
  const [isValid, setIsValid] = useState(true);

  const isAccountActive = () => {   
    if (jobSeekerLoginDetails?.activatedOn) {
      setActivationModal(false);
    }
    else{
      setActivationModal(true);
    }
  };

  useEffect(() => {
    isAccountActive();
  }, [value]);

  const uid = localStorage.getItem("uid");
  const postdata = {
    jobseekerid: uid,
  };

  const [openpopup, setopenpopup] = useState(false);
  useEffect(() => {
    axiosAPI
      .post("/profile", postdata)
      .then((res) => {    
        if (res.data.data.twoFactorPreferred) {
          localStorage.getItem("VerifiedMFA") == "true"
            ? setopenpopup(false)
            : setopenpopup(true);
        }
       
      })
      .catch((err) => {
      });
  }, []);

  return isAuth ? (
    <>
     <MFAPopup openpopup={openpopup} setopenpopup={setopenpopup} />
      <Container
        maxWidth="100%"
        sx={{
          margin: "10px auto",
          padding: "0 !important",
          background: "#EAEFF1",
          minHeight: "100vh",
        }}
      >
        <JobSeekerNavbar NavHead={NavHead} />
        {isValid ? (
          <Grid
            container
            spacing={2}
            sx={{
              px: 2,
              pb: 2,
              pt: {
                xs: "65px",
                sm: "75px",
              },
            }}
          >
            <Grid item lg={3} xl={2}>
              <JobSeekerSidebar />
            </Grid>
            <Grid
              item
              xs={12}
              lg={9}
              xl={10}
              sx={{ py: 1 }}
              className={classes.customScrollbar}
            >
              <JobSeekerActivationModal
                open={openActivationModal}
                handleClose={setActivationModal}
              />
              <Outlet />
            </Grid>
          </Grid>
        ) : (
          <CustomJobseekerSignUp />
        )}
      </Container>
    </>
  ) : (
    <Navigate to="/landingpage" />
  );
}

function ActivatedRoute() {
  const jobSeekerLoginDetails = useSelector(
    (state) => state.jobSeekerloginDetails.loginDetails
  );
  const isActivated = jobSeekerLoginDetails.activatedOn;
  return isActivated ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to="/jobseekerDashboard" />
  );
}

export default function JobSeekerRoutes() {
  const [isRouteChange, setRouteChange] = useState(0);
  return (
    <Routes>
      <Route element={<ProtectedRoute value={isRouteChange} />}>
        {/* --------------- Jobs --------------- */}
        <Route path="/jobList" element={<JobSearchPostLogin />} />
        
        <Route path="/detailedJobView/:id"element={<JobDetailedViewPostLogin />}/>
        
        <Route path="/recommendedJobs" element={<RecommendedJobs />} />
        {/* <Route path="/recommendedJobs" element={<RecommendedJobsNew />} /> */}
        
        <Route path="/similarJobs" element={<JobApplySuccessPage />} />
        <Route path="/appliedJobs" element={<AppliedJobs />} />
        <Route path="/savedJobs" element={<SavedJobs />} />
        <Route element={<ActivatedRoute />}>
          <Route path="/jobseekerProfile" element={<JobseekerProfile routeChange={setRouteChange} />} />
        </Route>
        <Route path="/jobseekerDashboard" element={<JobseekerDashboard routeChange={setRouteChange} />}/>
        <Route path="/Companydetailview/:id" element={<CompanyDetailView />} />
        {/* --------------- Groups --------------- */}
        <Route path="/createGroup" element={<CreateGroup />} />
        <Route path="/searchGroupList" element={<SearchGroupList />} />
        {/* <Route path="/searchGroupDetail/:id" element={<GroupDetails />} /> */}
        <Route path="/myGroupList" element={<MyGroupList />} />
        <Route path="/groupDetail/:id" element={<DetailedGroup />} />
        <Route path="/myGroupDetail/:id" element={<MyDetailedGroup />} />
        <Route path="/editGroup/:id" element={<EditGroupModal />} />

        {/* --------------- Forums --------------- */}
        <Route path="/forum" element={<ForumPage />} />
        <Route path="/myForum" element={<MyForumPage />} />
        <Route path="/createForum" element={<CreateForm />} />
        <Route path="/forumDetail/:id" element={<ForumDetails />} />
        <Route path="/myForumDetail/:id" element={<MyForumDetails />} />
        <Route path="/editForum/:id" element={<EditForumModal />} />

        {/* --------------- Messages --------------- */}
        <Route path="/chat" element={<OnlineChat />} />
        <Route path="/message" element={<Messages />} />

        {/* --------------- Notification --------------- */}
        <Route path="/notifications" element={<Notification />} />
      </Route>
    </Routes>
  );
}
