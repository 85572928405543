import {
  Box,
  Chip,
  IconButton,
  InputAdornment,
  Dialog,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Button, Textarea } from "@mui/joy";
import { errorNotify, succesNotify,warningNotify } from "../../CommonCode/Commonfunc";
import { axiosAPI, axiosupload } from "../../Axios/Axios";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import TextfieldCustom from "../../Components/TextfieldCustom";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {profanityfn,profanitySend}  from "../../Profanity/Profanity";

const useStyles = makeStyles({
  textField: {
    "& .MuiInputBase-input": {
      textAlign: "start",
      textIndent: "10px", // Adjust the textIndent value as needed
    },
  },
});

export default function ReplayMessageModal({
  open,
  handleClose,
  data,
  setApiCall,
  ReplayType
}) {
  const [message, setMessage] = useState("");
  const [isButtonDisable, setButtonDisable] = useState(false);

  useEffect(()=>{
    if(!open){
      setMessage('')
    }
  },[open])

  const sendChatProfanity = (e) => {
    const data = {
      "message": message,
  };

    let profanityDetected;
    for (const key in data) {
      if (profanitySend(ProfanityList, data[key])) {
        profanityDetected = true;
        break;
      } else {
        profanityDetected = false;
      }
    }

    if (profanityDetected == false) {
      sendMessage(e);
    } else {
      warningNotify(Labels.ProfanityLabel);
    }
  };


  const sendMessage = async () => {
    // setButtonDisable(true);
    if (!message || message.trim().length==0) {
      errorNotify(Labels.writeMessage);
      return;
    } else if (message.length > 1000) {
      errorNotify(Labels.messageValidation);
      return;
    } else {
      const result = await axiosAPI.post("/message", {
        parentID: data.id,
        toUser: data.fromUser,
        message: message,
      });
      const { success, message: responseMessage } = result.data;
      setMessage("");
      if (success) {
        succesNotify(responseMessage);
        setApiCall(Math.random());
        setButtonDisable(false);
        handleClose(false);
      } else {
        setButtonDisable(false);
        errorNotify(responseMessage);
      }
    }
  };

  const classes = useStyles();
  const Labels = useSelector((state) => state.allLabels.labels);
  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      onClose={() => handleClose(false)}
      PaperProps={{
        sx: {
          width: "80%",
          padding: 3,
        },
      }}
    >
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        sx={{ p: 2 }}
      >
        <HighlightOffIcon
          sx={{
            color: "#1C614E",
            cursor: "pointer",
          }}
          onClick={() => handleClose(false)}
        />
      </Stack>

      <Typography variant="body1" sx={{ fontWeight: 500 }}>
        {Labels?.To} :&nbsp;
        <Chip label={data?.fromUserName} />
      </Typography>
      <Box
        sx={{
          margin: "10px 0px",
          maxHeight: "80vh",
          overflowY: "auto",
          overflowX: "hidden",
          p: 1.5,
        }}
      >
        <br />
        <Textarea
          minRows={5}
          placeholder={Labels.Typehere}
          value={message}
          onChange={(e) => setMessage(profanityfn(ProfanityList,e.target.value))}
        />
      </Box>
      <Stack direction="row" justifyContent="center" sx={{ p: 1.5 }}>
        <Button
          variant="solid"
          fullWidth
          sx={{
            background: "#1C614E",
            color: "white",
            borderRadius: 3,
            "&:hover": {
              backgroundColor: "#1C614E",
            },
            "&:active": {
              backgroundColor: "#1C614E",
            },
            "&:disabled": { color:"white",
              backgroundColor: "#1C614E",
            },
          }}
          onClick={sendChatProfanity}
          disabled={isButtonDisable}
        >
          {Labels?.Send}
        </Button>
      </Stack>
    </Dialog>
  );
}
