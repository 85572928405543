import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  errorNotify,
  isValidEmail,
  succesNotify,
} from "../CommonCode/Commonfunc";
import { axiosAPI } from "../Axios/Axios";
import { updateLoginDetails } from "../../redux/JobSeekerLoginDetailSlice";
import {
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import CustomButton from "../Components/CustomButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import InputWithLabel from "../Components/InputWithLabel";
import ForgotPasswordModel from "../Jobseeker/Login/ForgotPasswordModel";
import {profanityfn}  from "../Profanity/Profanity";
import {
  doSignInWithFacebook,
  doSignInWithGoogle,
} from "../../AuthConfig/Auth";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Popup from "./CustomSignup/Popup";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
export default function JobseekerLogin() {
  const [isSigningIn, setIsSigningIn] = useState(false);
  const redirect = useNavigate();
  const dispatch = useDispatch();
  const Labels = useSelector((state) => state.allLabels.labels);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [forgetPasswordModal, setforgetPasswordModal] = useState(false);
  const [userDetl, setuserDetl] = useState({
    emailid: "",
    password: "",
  });
  const { emailid, password } = userDetl;
  const defaultstate = {
    emailid: "",
    password: "",
  };
  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)

  const getUserdetl = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    if (e.target.name == "password") {
      setuserDetl({ ...userDetl, [e.target.name]: value });
    } else {
      setuserDetl({ ...userDetl, [e.target.name]: profanityfn(ProfanityList,value) });
    }
  };
  const postdata = {
    username: emailid,
    password: password,
    userRole: "3",
  };

  const submitFormData = async (e) => {
    e?.preventDefault();
    setIsButtonDisabled(true);
    if (!isValidEmail(emailid)) {
      errorNotify(Labels.emailValidation);
      setIsButtonDisabled(false);
    } else if (password === "") {
      errorNotify(Labels.passwordValidation);
      setIsButtonDisabled(false);
    } else {

      if(!isButtonDisabled){
        await axiosAPI
        .post("/login", postdata)
        .then((response) => {
          const { success, data, message } = response.data;
          if (success === true) {
            dispatch(updateLoginDetails(data));
            localStorage.setItem("language", data.preferredLanguage);
            localStorage.setItem("token", data.token);
            localStorage.setItem("uid", data.uld_id);
            localStorage.setItem("resettedByAdmin", data.resettedByAdmin);
            setuserDetl(defaultstate);
            if (data.resettedByAdmin === 1) {
              redirect("/emppwdchange");
            } else {
              redirect("/jobseekerDashboard");
              succesNotify(Labels.loginSuccessfully);
              setIsButtonDisabled(true);
            }  
          } else {
            errorNotify(message);
            setIsButtonDisabled(false);
          }
        })
        .catch((e) => {
          setIsButtonDisabled(false);
        });
      }
      
    }
  };


  const [showPassword1, setShowPassword1] = useState(false);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };
  const RedirectToSignUp = () => {
    redirect("/signup", { state: 1 });
  };

  const [opengooglepopup, setopengooglepopup] = useState(false);
  const [openfacebookpopup, setopenfacebookpopup] = useState(false);

  const onGoogleSignIn = (e) => {
    if (!isSigningIn) {
      setIsSigningIn(true);
      doSignInWithGoogle()
        .then((res) => {
          axiosAPI
            .post("/login", {
              username: res.providerData[0].email,
              password: res.providerData[0].uid,
              userRole: "3",
            })
            .then((response) => {
              const { success, data, message } = response.data;
              if (success) {
                dispatch(updateLoginDetails(data));
                if (data?.preferredLanguage) {
                  localStorage.setItem("language", data?.preferredLanguage);
                } else {
                  localStorage.setItem("language", "en");
                }
                localStorage.setItem("token", data.token);
                localStorage.setItem("uid", data.uld_id);
                localStorage.setItem("resettedByAdmin", data.resettedByAdmin);
                setuserDetl(defaultstate);
                if (data.resettedByAdmin === 1) {
                  redirect("/emppwdchange");
                } else {
                  redirect("/jobseekerDashboard");
                  succesNotify(Labels.loginSuccessfully);
                }
                setIsButtonDisabled(false);
              } else {
                setIsButtonDisabled(false);
                setopengooglepopup(true);
              }
            })
            .catch((e) => {
              setIsButtonDisabled(false);
            });
        })
        .catch((err) => {
          setIsSigningIn(false);
        });
    }
  };
  
  const onFacebookSignIn = (e) => {
    e.preventDefault();
    if (!isSigningIn) {
      setIsSigningIn(true);
      doSignInWithFacebook()
        .then((res) => {})
        .catch((err) => {
          setIsSigningIn(false);
        });
    }
  };

  return (
    <>
      <Popup
        openpopup={opengooglepopup}
        setopenpopup={setopengooglepopup}
        user={"Jobseeker"}
        loginType={"Google"}
      />
      <Popup
        openpopup={openfacebookpopup}
        setopenpopup={setopenfacebookpopup}
        user={"Jobseeker"}
        loginType={"Facebook"}
      />
      <ForgotPasswordModel
        open={forgetPasswordModal}
        handleClose={setforgetPasswordModal}
        userEmail={emailid}
      />
      {/* ----------------------Email Address---------------- */}
      <InputWithLabel
        style={{ width: "100%", mb: 2 }}
        InputLabel={Labels?.emailAddress}
        label={Labels?.emailAddress}
        type="text"
        name="emailid"
        value={emailid}
        onChange={(e) => getUserdetl(e)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            submitFormData();
          }
        }}
      />
      {/* ----------------------Password---------------- */}
      <div style={{ margin: "10px 5px" }}>
        <style>
          {`
          input[type=password]::-ms-reveal,
          input[type=password]::-ms-clear {
            display: none;
          }
        `}
        </style>
        <label>{Labels?.password}</label>
        <br />
        <OutlinedInput
          type={showPassword1 ? "text" : "password"}
          placeholder={Labels?.password}
          sx={{
            width: "100%",
            mb: 2,
          }}
          name="password"
          value={password}
          inputMode="none"
          onChange={(e) => getUserdetl(e)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              submitFormData();
            }
          }}
       
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword1}
                onMouseDown={handleMouseDownPassword1}
                edge="end"
              >
                {showPassword1 ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </div>
      <div
        style={{
          margin: "10px 0",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack direction="row" sx={{ alignItems: "center" }}>
          <Checkbox
            sx={{
              color: " #1C614E",
              "&.Mui-checked": {
                color: "#1C614E",
              },
            }}
          />
          
          <Typography
            sx={{
              fontWeight: 300,
              fontSize: "15px",
              color: "rgba(0, 0, 0, 0.85)",
            }}
          >
            {Labels?.rememberMe}
          </Typography>
        </Stack>
        {/* ----------------------Forget Password---------------- */}
        <Button
          variant="Text"
          sx={{
            textTransform: "capitalize",
            color: "rgba(0, 0, 0, 0.85)",
            fontSize: "15px",
            fontWeight: 300,
          }}
          onClick={() => setforgetPasswordModal(true)}
        >
          {Labels?.forgotPassword}
        </Button>
      </div>
      {/* ----------------------Signin button ---------------- */}
      <CustomButton
        label={Labels?.signIn}
        style={{
          fontSize: "16px",
          lineHeight: "24px",
          color: "#FFFFFF",
          width: "100%",
          margin: "10px auto",
          borderRadius: "4px",
        }}
        onClick={submitFormData}
        disabled={isButtonDisabled}
      />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Item>
            <button
              disabled={isSigningIn}
              className="social-google-btn"
              onClick={() => {
                onGoogleSignIn();
              }}
            >
              <i class="fa fa-google fa-fw"> </i> {Labels.LoginwithGoogle}
            </button>
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
            <button
              disabled={isSigningIn}
              className="social-facebook-btn"
              onClick={(e) => {
                setopenfacebookpopup(true);
              }}
            >
              <i class="fa fa-facebook fa-fw"></i> {Labels.LoginwithFacebook}
            </button>
          </Item>
        </Grid>
      </Grid>

      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: "rgba(0, 0, 0, 0.85)",
            fontSize: "15px",
            fontWeight: 300,
          }}
        >
          {Labels.Donthaveanaccount}
        </Typography>
        <Button
          variant="text"
          sx={{
            textTransform: "capitalize",
            color: "rgba(0, 0, 0, 0.85)",
            fontSize: "15px",
            fontWeight: 600,
          }}
          onClick={RedirectToSignUp}
        >
          {Labels?.signUp}
        </Button>
      </div>
    </>
  );
}
