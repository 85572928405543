import React from "react";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { Icon, InputAdornment, Stack, TextField } from "@mui/material";
import { useSelector } from "react-redux";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === "light" ? "white" : "white",
    border: "0.5px solid #1C614E",
    borderColor: "#C9C9C9",
    padding: "10px 12px",
    fontSize: "14px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    fontFamily: ['"Poppins"'].join(","),
    "&:focus": {
      border: "0.5px solid #1C614E",
      boxShadow: "#1C614E",
      borderColor: "#C9C9C9",
    },
  },
}));

function IconLabelTextMUI({
  InputLabel,
  value,
  name,
  getCareerProfile,
  required,
  errorMsg,
  icon,
}) {
  const Labels = useSelector((state) => state.allLabels.labels);

  const handleChange = (e) => {
    const newValue = parseFloat(e.target.value);
    if (!isNaN(newValue) && newValue >= 0) {
      getCareerProfile(e);
    }
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ my: 1.5 }}
    >
      {icon ? (
        <Icon sx={{ color: "grey", mr: 1, height: 25, width: 25 }}>{icon}</Icon>
      ) : null}
      <label style={{ color: "#413E3E", width: 230 }}>{InputLabel}</label>
      {required ? <span style={{ color: "red" }}>*</span> : null}

      <TextField
        type="number"
        value={value}
        name={name}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{Labels.InYears}</InputAdornment>
          ),
        }}
        inputProps={{ min: 0 }} // This ensures that the number input cannot be less than 0
      />
      <br />
      {errorMsg
        ? errorMsg && (
            <span style={{ color: "#ff0000", fontSize: "13px" }}>
              {errorMsg}
            </span>
          )
        : null}
    </Stack>
  );
}

export default IconLabelTextMUI;
