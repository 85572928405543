import React, { useEffect, useState } from "react";
import {
  FormControl,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Divider,
  Skeleton,
  Box,
  ListItemIcon
} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check'; // Import the Check icon
import useMediaQuery from "@mui/material/useMediaQuery";
import { axiosAPI } from "../Axios/Axios";
import { useSelector } from "react-redux";

const ContractTypeNew = ({ style, value, setValue, contractname }) => {
  const isLargeScreen = useMediaQuery("(min-width:600px)");
  const [Skill, setSkill] = useState([]);

  useEffect(() => {
    const getSkills = async () => {
      const result = await axiosAPI.get("/getTypes");
      const { success, data } = await result.data;
      if (success === true) {
        setSkill(data);
      } else {
        setSkill([]);
      }
    };
    getSkills();
  }, []);

  useEffect(() => {
    const idArray = contractname?.map((contract) => contract.id);
    if (idArray?.length) {
      setValue(idArray);
    }
  }, [contractname]);

  const handleToggle = (id) => {
    const currentIndex = value.indexOf(id);
    const newChecked = [...value];

    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setValue(newChecked);
  };

  const Labels = useSelector((state) => state.allLabels.labels);

  return (
    <FormControl
    sx={{ width: isLargeScreen ? `100%` : `100%`, margin: "auto" }}
    >
      <List sx={{ ...style }}>
        {Skill.length ? (
          Skill.map((val, index) => (
            <React.Fragment key={index}>
              <ListItem
                dense
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor:"white",
                  margin:"4px 0px",
                  border: value.indexOf(val.id) !== -1 ?"3px solid #1C614E":"1px solid #1c614d57",
                  borderRadius: "4px",
                  "&:hover": {
                    cursor: "pointer",
                  }
                }}
                onClick={() => handleToggle(val.id)}
              >
                <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                  <ListItemText
                    id={`checkbox-list-label-${val.id}`}
                    primary={val.name}
                    sx={{ flexGrow: 1 }}
                  />
                  {value.indexOf(val.id) !== -1 && (
                    <ListItemIcon>
                      <CheckIcon sx={{ color: "green" }} />
                    </ListItemIcon>
                  )}
                </Box>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))
        ) : (
          <>
            {[...Array(5)].map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                width={`100%`}
                height={60}
                sx={{ margin: "5px 0px" }}
              />
            ))}
          </>
        )}
      </List>
    </FormControl>
  );
};

export default ContractTypeNew;
