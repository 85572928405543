import React from "react";
import { Stack } from "@mui/material";
import Company from "../../Images/Company.png";
const Companylogo = () => {
  return (
    <Stack>
      <img
        src={Company}
        alt={"background image"}
        style={{ margin: "0% auto 0%" }}
        // style={{mt:0 ,mx:3}}
        width="90%"
      />
    </Stack>
  );
};

export default Companylogo;
