import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  errorNotify,
  isValidEmail,
  succesNotify,
  validatePassword,
  warningNotify,
} from "../CommonCode/Commonfunc";
import { axiosAPI } from "../Axios/Axios";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import InputWithLabel from "../Components/InputWithLabel";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CompanyType from "../CommonCode/CompanyType";
import PhoneInput from "react-phone-input-2";
import Languages from "../CommonCode/Languages";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CustomButton from "../Components/CustomButton";
import "../../App.css";
import "react-phone-input-2/lib/style.css";
import { profanityfn } from "../Profanity/Profanity";
import { Label } from "semantic-ui-react";

export default function EmployerSignup() {
  const redirect = useNavigate();

  const [showPassword1, setShowPassword1] = useState(false);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };
  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };
  const [Language, setLanguages] = useState(
    localStorage.getItem("language") ? localStorage.getItem("language") : ""
  );
  const [companyTypes, setCompanyTypes] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [countryCode, setCountryCode] = useState("1");
  let handleOnChange = (value) => {
    setCountryCode(value);
  };

  const allList = useSelector((state) => state.allList.list);
  const [showterms, setShowterms] = useState(false);
  const handleOpenterms = () => {
    setShowterms(true);
  };
  const handleCloseterms = () => {
    setShowterms(false);
  };

  const [formData, setFormData] = useState({
    companyname: "",
    companyregNo: "",
    contactperson: "",
    contactnumber: "",
    emailid: "",
    password: "",
    confirmpassword: "",
    termsandconditions: false,
  });
  const defaultState = {
    companyname: "",
    companyregNo: "",
    contactperson: "",
    contactnumber: "",
    emailid: "",
    password: "",
    confirmpassword: "",
    termsandconditions: false,
  };
  const {
    companyname,
    companyregNo,
    contactperson,
    contactnumber,
    emailid,
    password,
    confirmpassword,
    termsandconditions,
  } = formData;

  const ProfanityList = useSelector((state) => state.Profanity.wordlist);

  const employerRegister = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    if (e.target.name == "password" || e.target.name == "confirmpassword") {
      setFormData({ ...formData, [e.target.name]: value });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: profanityfn(ProfanityList, value),
      });
    }
  };

  const postData = {
    contactName: contactperson,
    username: emailid,
    password: confirmpassword,
    confirmPassword: confirmpassword,
    companyName: companyname,
    countrycode: countryCode,
    contactNumber: contactnumber,
    companyType: companyTypes,
    language: Language,
  };
  const Labels = useSelector((state) => state.allLabels.labels);

  const submitFormData = async (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    const minLength = 8;
    const maxLength = 30;
    const uppercaseRegex = /[A-Z]/;
    const numericRegex = /[0-9]/;
    const specialCharRegex = /[!@#$%^&*]/;


      if (!companyname) {
        errorNotify(Labels.companyNameRequired);
         setIsButtonDisabled(false);
      } else if (companyname.length > 200) {
        errorNotify(Labels.companyNameValidation);
         setIsButtonDisabled(false);
      } else if (!companyTypes) {
        errorNotify(Labels.CompanyTypeValidation);
         setIsButtonDisabled(false);
      } else if (!contactperson) {
        errorNotify(Labels.contactPersonRequired);
         setIsButtonDisabled(false);
      } else if (contactperson.length > 200) {
        errorNotify(Labels.contactPersonValidation);
         setIsButtonDisabled(false);
      } else if (emailid.length > 100) {
        errorNotify(Labels.emailLengthValidation);
         setIsButtonDisabled(false);
      } else if (!isValidEmail(emailid)) {
        errorNotify(Labels.emailValidation);
         setIsButtonDisabled(false);
      } else if (!countryCode) {
        errorNotify(Labels.countryCodeRequired);
         setIsButtonDisabled(false);
      } else if (!contactnumber) {
        errorNotify(Labels.contactNumberRequired);
         setIsButtonDisabled(false);
      } else if (!/^\d*$/.test(contactnumber)) {
        errorNotify(Labels.phoneNumberValidation);
         setIsButtonDisabled(false);
      } else if (contactnumber.length < 7) {
        errorNotify(Labels.contactMinValidation);
         setIsButtonDisabled(false);
      } else if (contactnumber.length > 14) {
        errorNotify(Labels.contactMaxValidation);
         setIsButtonDisabled(false);
      } else if (!Language) {
        errorNotify(Labels.languageRequired);
         setIsButtonDisabled(false);
      } else if (password === "") {
        errorNotify(Labels.passwordValidation);
         setIsButtonDisabled(false);
      } else if (password.length < minLength || password.length > maxLength) {
        errorNotify(Labels.Passwordmustbebetween8and30characters);
         setIsButtonDisabled(false);
      } else if (!uppercaseRegex.test(password)) {
        errorNotify(Labels.Passwordmustcontainatleastoneuppercaseletter);
         setIsButtonDisabled(false);
      } else if (!numericRegex.test(password)) {
        errorNotify(Labels.Passwordmustcontainatleastonenumericdigit);
         setIsButtonDisabled(false);
      } else if (!specialCharRegex.test(password)) {
        errorNotify(Labels.Passwordmustcontainatleastonespecialcharacter);
        setIsButtonDisabled(false);
      } else if (password !== confirmpassword) {
        errorNotify(Labels.passwordNotMatched);
         setIsButtonDisabled(false);
      } else if (termsandconditions === false) {
        errorNotify(Labels.acceptTermsAndConditions);
         setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
        await axiosAPI
        .post("/registerEmployer", {
          ...postData,
        })
        .then((response) => {
          const { success, message } = response.data;
          if (success === true) {
            setFormData(defaultState);
            succesNotify(Labels.accountCreated);
            redirect("/login", { state: 2 });
          } else {
            if (response?.data?.data?.username?.length) {
              errorNotify(Labels.EmailTaken);
            }
            errorNotify(message);
            setIsButtonDisabled(false);
          }
        })
        .catch((res) => {
          setIsButtonDisabled(false);
          const { data } = res.response.data;
          const arr = Object.values(data);
          arr.map((val) => {
            return errorNotify(arr[0]);
          });
        });
    }
  }

  const toLogin = () => {
    redirect("/login", { state: 2 });
  };
  return (
    <>
      <Typography variant="body1" sx={{ mb: 2, textAlign: "center" }}>
        {Labels.Createanaccountandstartrecruiting}
      </Typography>
      <Box>
        <Grid container spacing={2}>
          {/* -------------------company name------------------------ */}
          <Grid item xs={12} sm={6}>
            <InputWithLabel
              style={{ width: "100%" }}
              InputLabel={Labels.CompanyConsultancyName}
              required
              label={Labels.CompanyConsultancyName}
              type="text"
              name="companyname"
              value={companyname}
              onChange={(e) => employerRegister(e)}
            />
          </Grid>
          {/* -------------------company Type------------------------ */}
          <Grid item xs={12} sm={6}>
            <div style={{ margin: "10px 5px" }}>
              <label>{Labels?.CompanyType}</label>
              <span style={{ color: "red" }}>*</span>
              <br />
              <CompanyType
                value={companyTypes}
                setValue={setCompanyTypes}
                style={{ width: "100%" }}
              />
            </div>
          </Grid>
          {/* -------------------contact person name------------------------ */}
          <Grid item xs={12} sm={6}>
            <InputWithLabel
              style={{ width: "100%" }}
              InputLabel={Labels.ContactPersonName}
              required
              label={Labels.ContactPersonName}
              type="text"
              name="contactperson"
              value={contactperson}
              onChange={(e) => employerRegister(e)}
            />
          </Grid>
          {/* -------------------Email Address------------------------ */}
          <Grid item xs={12} sm={6}>
            <InputWithLabel
              style={{ width: "100%" }}
              InputLabel={Labels?.emailAddress}
              required
              label={Labels?.emailAddress}
              type="text"
              name="emailid"
              value={emailid}
              onChange={(e) => employerRegister(e)}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
                        <div style={{ margin: "10px 5px" }}>
                            <label>Contact Number</label>
                            <span style={{ color: "red" }}>*</span>
                            <br />
                            <Stack direction="row">
                                <PhoneInput
                                    country={"us"}
                                    style={{ width: "110px" }}
                                    inputProps={{ required: true, readOnly: true }}
                                    value={countryCode}
                                    onChange={handleOnChange}
                                    readOnly
                                />
                                <InputWithLabel
                                    style={{ width: "100%" }}
                                    label="Contact Number"
                                    type="text"
                                    name="contactnumber"
                                    display="true"
                                    value={contactnumber}
                                    onChange={(e) => employerRegister(e)}
                                />
                            </Stack>
                        </div>
                    </Grid> */}
          <Grid item xs={12} sm={6}>
            <div style={{ margin: "10px 5px" }}>
              <label>{Labels?.ContactNumber}</label>
              <span style={{ color: "red" }}>*</span>
              <br />
              <Stack direction="row">
                <PhoneInput
                  country={"us"}
                  style={{ width: "110px" }}
                  inputProps={{ required: true, readOnly: true }}
                  value={countryCode}
                  onChange={handleOnChange}
                  readOnly
                />
                <InputWithLabel
                  style={{ width: "100%" }}
                  label={Labels.ContactNumber}
                  type="text"
                  name="contactnumber"
                  display="true"
                  value={contactnumber}
                  onChange={(e) => employerRegister(e)}
                />
              </Stack>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div style={{ margin: "10px 5px" }}>
              <label>{Labels?.language}</label>
              <span style={{ color: "red" }}>*</span>
              <br />
              <Languages
                style={{
                  width: "100%",
                }}
                selectLabel={Labels.PreferredLanguages}
                value={Language}
                setValue={setLanguages}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div style={{ margin: "10px 5px" }}>
              <label>{Labels?.password}</label>
              <span style={{ color: "red" }}>*</span>
              <br />
              <OutlinedInput
                type={showPassword1 ? "text" : "password"}
                placeholder={Labels?.password}
                name="password"
                value={password}
                onChange={(e) => employerRegister(e)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword1}
                      onMouseDown={handleMouseDownPassword1}
                      edge="end"
                    >
                      {showPassword1 ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                sx={{ width: "100%" }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div style={{ margin: "10px 5px" }}>
              <label>{Labels?.confirmPassword}</label>
              <span style={{ color: "red" }}>*</span>
              <br />
              <OutlinedInput
                type={showPassword2 ? "text" : "password"}
                placeholder={Labels?.confirmPassword}
                name="confirmpassword"
                value={confirmpassword}
                onChange={(e) => employerRegister(e)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword2}
                      onMouseDown={handleMouseDownPassword2}
                      edge="end"
                    >
                      {showPassword2 ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                sx={{ width: "100%" }}
              />
            </div>
          </Grid>
        </Grid>
        <Stack direction="row">
          <Checkbox
            sx={{
              color: " #1C614E",
              "&.Mui-checked": {
                color: "#1C614E",
              },
            }}
            name="termsandconditions"
            value={termsandconditions}
            checked={termsandconditions}
            onChange={(e) => employerRegister(e)}
          />
          <p
            sx={{
              fontWeight: 300,
              fontSize: "15px",
              color: "rgba(0, 0, 0, 0.85)",
              display: "flex", // Add flex display
              alignItems: "center", // Center vertically
            }}
          >
            {Labels.agree}
            {/* <a style={{ color: "#1976d2", textDecoration: "none" }}>
                terms & conditions */}
            <Button
              variant="text"
              sx={{
                textTransform: "capitalize",
                color: "#1976d2",
                // boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                // background: "#1C614E1C",
                // margin: 1,
              }}
              onClick={() => {
                handleOpenterms();
              }}
            >
              {Labels?.TermsandConditions}
            </Button>
            <Stack>
              <Dialog open={showterms} onClose={handleCloseterms}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ p: 0.5, mt: 0.5 }}
                >
                  <DialogTitle
                    sx={{
                      color: "#1C614E",
                    }}
                  >
                    {Labels.TermsandConditions}
                  </DialogTitle>
                  <HighlightOffIcon
                    sx={{
                      color: "#1C614E",
                      cursor: "pointer",
                      m: 2,
                    }}
                    onClick={handleCloseterms}
                  />
                </Stack>

                <DialogContent sx={{ pt: 0, mb: 1 }}>
                  {allList?.privacyPolicy?.terms && (
                    <Typography>{allList?.privacyPolicy?.terms}</Typography>
                  )}
                </DialogContent>
              </Dialog>
            </Stack>
            {/* </a> */}
          </p>
        </Stack>
        <Stack textAlign="center">
          <CustomButton
            label={Labels?.signUp}
            style={{
              fontSize: "16px",
              lineHeight: "24px",
              color: "#FFFFFF",
              width: "40%",
              margin: "auto",
            }}
            onClick={submitFormData}
            disabled={isButtonDisabled}
          />
        </Stack>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "15px",
          }}
        >
          <Typography
            sx={{
              color: "rgba(0, 0, 0, 0.85)",
              fontSize: "15px",
              fontWeight: 300,
            }}
          >
            {Labels.alreadyuser}
          </Typography>
          <Button
            variant="text"
            sx={{
              textTransform: "capitalize",
              color: "rgba(0, 0, 0, 0.85)",
              fontSize: "15px",
              fontWeight: 600,
            }}
            onClick={toLogin}
          >
            {Labels?.signIn}
          </Button>
        </div>
      </Box>
    </>
  );
}
