import React, { Fragment, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { axiosAPI } from "../Axios/Axios";
import { useSelector } from "react-redux";
import { Autocomplete, Skeleton, TextField } from "@mui/material";

const BranchState = ({ value, setValue, country,style }) => {
  const [States, setStates] = useState([]);
  const usertype = localStorage.getItem("userType");
  const [newvalue,setnewvalue] = useState({})

  useEffect(() => {
    const getStates = async () => {
      const result = await axiosAPI.get(`/getStates?countryID=${country?.id}`);
      const { success, data } = await result.data;
      if (success === true) {
        setStates(data);
      } else {
        setStates([]);
      }
    };
    getStates();

    if(value!=undefined){
      setnewvalue({id:value.stateID, abbrName:"", name:value.stateName})
      setValue({id:value.stateID, abbrName:"", name:value.stateName})
    }
  }, [country]);
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setnewvalue(newValue)
  };
  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    // <FormControl sx={{ width: "100%" }}>
    //   <Select
    //     value={value}
    //     onChange={handleChange}
    //     placeholder={label}
    //     displayEmpty
    //     variant="outlined"
    //     sx={{
    //       padding: "10px 12px",
    //       fontFamily: "Poppins",
    //       ...style,
    //     }}
    //   >
    //     <MenuItem value="" disabled sx={{ color: "#c4c4c4" }}>
    //       <span style={{ color: "#bcbcbc" }}> {Labels?.SelectState}</span>
    //     </MenuItem>
    //     {States &&
    //       States.map((val, index) => {
    //         return (
    //           <MenuItem key={index} value={val.id}>
    //             {val.name}
    //           </MenuItem>
    //         );
    //       })}
    //   </Select>
    // </FormControl>
    <FormControl sx={{ width: "100%" }}>
      <Autocomplete
        value={newvalue}
        onChange={handleChange}
        options={States}
        sx={{ ...style }}
        readOnly={usertype === "Recruiter" ? true : false}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField {...params} placeholder={Labels?.SelectState}/>
        )}
        noOptionsText={Labels?.notAvailable}
      />
    </FormControl>
  );
};

export default BranchState;
