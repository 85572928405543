import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import CustomButton from "../../Components/CustomButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  errorNotify,
  isValidEmail,
  succesNotify,
  validatePassword,
} from "../../CommonCode/Commonfunc";
import { axiosAPI } from "../../Axios/Axios";
import { useSelector } from "react-redux";
import InputWithLabel from "../../Components/InputWithLabel";

const ForgotPasswordModel = ({ open, handleClose, userEmail, empEmail }) => {
  const [email, setEmail] = useState("");
  const [resetCode, setResetCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetPassword, setResetPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };
  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };

  const forgetPasswordAPI = async (e) => {
    e.preventDefault();
    if (!isValidEmail(email)) {
      return errorNotify(Labels.emailValidation);
    } else {
      try {
        const postData = {
          email: email,
        };
        const result = await axiosAPI.post("/forgotPassword", postData);
        const { success, message } = result.data;
        if (success) {
          setResetPassword(success);
          succesNotify(Labels.passwordResetMail);
        } else {
          errorNotify(message);
        }
      } catch (error) {
      }
    }
  };

  const restPasswordAPI = async (e) => {
    e.preventDefault();
    const minLength = 8;
    const maxLength = 30;
    const uppercaseRegex = /[A-Z]/;
    const numericRegex = /[0-9]/;
    const specialCharRegex = /[!@#$%^&*]/;
    if (!isValidEmail(email)) {
      return errorNotify(Labels.emailValidation);
    } else if (!resetCode) {
      return errorNotify(Labels.resetCodeValidation);
    } else if (!password) {
      return errorNotify(Labels.passwordValidation);
    }

    if (password.length < minLength || password.length > maxLength) {
      errorNotify(Labels.Passwordmustbebetween8and30characters);
    } else if (!uppercaseRegex.test(password)) {
      errorNotify(Labels.Passwordmustcontainatleastoneuppercaseletter);
    } else if (!numericRegex.test(password)) {
      errorNotify(Labels.Passwordmustcontainatleastonenumericdigit);
    } else if (!specialCharRegex.test(password)) {
      errorNotify(Labels.Passwordmustcontainatleastonespecialcharacter);
    } 
    if (password !== confirmPassword) {
      return errorNotify(Labels.passwordMisMatch);
    } else {
      try {
        const postData = {
          email: email,
          code: resetCode,
          password: password,
        };
        const result = await axiosAPI.post("/resetPassword", postData);
        const { success, data, message } = result.data;
        if (success) {
          succesNotify(Labels.passwordResetMail);
          setResetPassword(false);
          handleClose(false);
        } else {
          errorNotify(data.error);
        }
      } catch (error) {
      }
    }
  };
  const Labels = useSelector((state) => state.allLabels.labels);
  useEffect(() => {
    if (userEmail) {
      setEmail(userEmail);
    } else {
      setEmail(empEmail);
    }
  }, [userEmail, empEmail]);

  return (
    <>
      <Dialog open={open}>
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
              {Labels?.passwordRecovery}
            </Typography>
            <HighlightOffIcon
              sx={{ color: "#1C614E", cursor: "pointer" }}
              onClick={() => handleClose(false)}
            />
          </Stack>
        </DialogTitle>
        <DialogContent>
          {/* ------------------ emailAddress ------------------ */}
          <InputWithLabel
            label={Labels?.emailAddress}
            name="emailid"
            value={email}
            style={{ width: "100%", margin: "5px 0px" }}
            onChange={(e) => setEmail(e.target.value)}
          />
          {/* ------------------ Reset Password ------------------ */}
          {!resetPassword && (
            <Stack direction="row" justifyContent="center">
              <CustomButton
                label={Labels?.Submit}
                onClick={(e) => forgetPasswordAPI(e)}
              />
            </Stack>
          )}
          {/* ------------------ Reset Code ------------------ */}
          {resetPassword ? (
            <>
              <InputWithLabel
                label={Labels?.resetCode}
                style={{
                  width: "100%",
                  margin: "5px 0px",
                }}
                onChange={(e) => setResetCode(e.target.value)}
              />
              {/* ------------------ Password ------------------ */}
              <FormControl
                sx={{ margin: "10px 5px", width: "98%" }}
                variant="outlined"
              >
                <OutlinedInput
                  type={showPassword1 ? "text" : "password"}
                  placeholder={Labels?.password}
                  sx={{ padding: "10px 12px", fontFamily: "Poppins" }}
                  onChange={(e) => setPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword1}
                        onMouseDown={handleMouseDownPassword1}
                        edge="end"
                      >
                        {showPassword1 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              {/* ------------------ Confirm Password ------------------ */}
              <FormControl
                sx={{ margin: "10px 5px", width: "98%" }}
                variant="outlined"
              >
                <OutlinedInput
                  type={showPassword2 ? "text" : "password"}
                  placeholder={Labels?.confirmPassword}
                  sx={{
                    padding: "10px 12px",
                    fontFamily: "Poppins",
                  }}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword2}
                        onMouseDown={handleMouseDownPassword2}
                        edge="end"
                      >
                        {showPassword2 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              {/* ------------------ Reset Password ------------------ */}
              <Stack direction="row" justifyContent="center">
                <CustomButton
                  label={Labels?.Submit}
                  onClick={(e) => restPasswordAPI(e)}
                />
              </Stack>
            </>
          ) : null}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ForgotPasswordModel;
