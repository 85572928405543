import React from "react";
import "./Style.css";
import jobseeker from "../../Images/jobseeker.png";
import { Paper, Typography, Grid, Stack } from "@mui/material";
import CustomButton from "../Components/CustomButton";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Advertisement2 = () => {
  const redirect = useNavigate();
  const redirecttosignUp = () => {
    redirect("/signup");
  };
  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <Stack mb={5}>
      <Paper
        sx={{
          color: "#fff",
          position: "relative",
          boxShadow: "none",
          mt:2,
        }}
      >
        <Grid container sx={{ background: "rgba(178, 207, 207, 0.3)" }}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: { xs: "25px", md: "0px" },
            }}
          >
            <div className="circle1"></div>
            <div className="circle2"></div>
            <Stack
              direction="column"
              justifyContent="center"
              sx={{
                mx: { xs: "0px", md: "auto" },
                width: { sx: "100%", md: "75%" },
              }}
            >
              <Typography
                sx={{
                  color: "rgba(43, 73, 71, 1)",
                  fontWeight: "600",
                  fontSize: { xs: "1.5rem", lg: "2.5rem" },
                }}
              >
                {Labels?.YourUltimateJob}
              </Typography>

              <Typography
                sx={{
                  color: "#BA8C04",
                  fontWeight: "600",
                  fontSize: { xs: "1.5rem", lg: "2.5rem" },
                  mb: 2,
                }}
              >
                {Labels?.Destination}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="center"
              sx={{ mx: "auto", width: { sx: "100%", md: "75%" } }}
            >
              <Typography
                sx={{
                  fontWeight: 400,
                  color: "rgba(43, 73, 71, 1)",
                  fontSize: { md: "16px", lg: "18px" },
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                justo est, cursus auctor nisl lobortis, tempus venenatis lectus.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                justo est, cursus auctor nisl lobortis, tempus venenatis lectus.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-start"
              sx={{ mx: "auto", width: { sx: "100%", md: "75%" } }}
            >
              <CustomButton
                label={Labels?.CreateAccount}
                onClick={redirecttosignUp}
                style={{
                  background: "white",
                  marginTop: { xs: 4, lg: 7 },
                  color: "#2B4947",
                  "&:hover": {
                    color: "white",
                  },
                  "&:active": {
                    backgroundColor: "#1C614E",
                  },
                  "&:disabled": { color:"white",
                    backgroundColor: "#1C614E",
                  },
                  minWidth: "160px",
                }}
              />
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              background: "#1C614E",
              display: " flex",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <img
              src={jobseeker}
              className="mikeImg"
              alt={"background image"}
              width="100%"
            />
          </Grid>
        </Grid>
      </Paper>
    </Stack>
  );
};

export default Advertisement2;
