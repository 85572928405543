import { useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { axiosAPI } from "../../Axios/Axios";
import { useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Stack,
  Typography,
  Grid,
  Popover,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { useSelector } from "react-redux";
import { profanityfn } from "../../Profanity/Profanity";
import MatchingJobseekers from "../EmployerDashboard/MatchingJobseekers";
import MarkedJobSeekers from "../EmployerDashboard/MarkedJobSeekers";
import AppliedJobSeekers from "../CreateJob/AppliedJobSeekers";
import ShortListedJobSeekers from "../EmployerDashboard/ShortListedJobSeekers";
import JobEnquires from "../EmployerDashboard/JobEnquires";
import JobInfo from "../CreateJob/JobInfo";

export default function JobSeekersList({
  jobSeekerListPage,
  isJobDetailsCalled,
}) {

  const redirect = useNavigate()
  const jobId = localStorage.getItem("jobId")
  const { svalue } = useParams()

  // --------------Get jobDetails------------------

  const [jobDetails, setJobDetails] = useState({})
  const [changevalue,setchangevalue] = useState('')

  const getjobdetails = async () => {    
    const result = await axiosAPI.post(`/getJobInfo?jobid=${jobId}`)

    const { success, data, message } = result.data

    if (success === true) {
      setJobDetails(data);
    } 
  };

  useEffect(() => {
    getjobdetails()
  }, [changevalue])

  const [selectedOption, setSelectedOption] = useState("jobInfo");
  const ProfanityList = useSelector((state) => state.Profanity.wordlist);

  useEffect(() => {
    if (svalue) {
      switch (svalue) {
        case '1':
          setSelectedOption("jobInfo");
          break;
        case '2':
          setSelectedOption("appliedJobSeekers");
          break;
        case '3':
          setSelectedOption("shortListedJobSeekers");
          break;
        case '4':
          setSelectedOption("markedJobSeekers");
          break;
        case '5':
          setSelectedOption("matchingJobSeekers");
          break;
        case '6':
          setSelectedOption("enquiryMessages");
          break;
        default:
          setSelectedOption('jobInfo')
          break;
      }
    }
  }, [svalue]);

  const handleSelectChange = (event) => {
    setSelectedOption(profanityfn(ProfanityList, event.target.value));
  };

  const Labels = useSelector((state) => state.allLabels.labels);
  const location = useLocation();

  const [prevbtn, setprevbtn] = useState(false);

  useEffect(() => {
    setprevbtn(location.pathname.includes("jobInfo"));
  }, [location]);


  const handleCallback = (e)=>{
    setchangevalue(e)
  }

  return (
    <>
      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack
          alignItems="center"
          spacing={1}
          direction="row"
          sx={{
            my: 2,
            cursor: "pointer",
          }}
          onClick={() => redirect(`/jobInfo/${jobId}`)}
        ></Stack>

        <FormControl sx={{ display: { xs: "block", xl: "none" }, my: 1 }}>
          <Select
            value={selectedOption}
            onChange={handleSelectChange}
            sx={{ minWidth: 200, background: "#fff", border: "none" }}
          >
            <MenuItem value="jobInfo">{Labels?.Details}</MenuItem>
            <MenuItem value="appliedJobSeekers">
              {Labels?.Applied}({jobDetails?.applied})
            </MenuItem>
            <MenuItem value="shortListedJobSeekers">
              {Labels?.Shortlisted}({jobDetails?.shortlist})
            </MenuItem>
            <MenuItem value="markedJobSeekers">
              {Labels?.Marked}({jobDetails?.marked})
            </MenuItem>
            <MenuItem value="matchingJobSeekers">
              {Labels?.Recommended}({jobDetails?.matching})
            </MenuItem>
            <MenuItem value="enquiryMessages">
              {Labels?.Enquiries} ({jobDetails?.enquiry})
            </MenuItem>
          </Select>
        </FormControl>

        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
          alignItems="center"
          sx={{
            display: {
              xs: "none",
              xl: "flex",
              position: "relative",
              bottom: prevbtn ? "25px" : "0px",
            },
          }}
        >
          {/* Job Info Button */}
          <Button
            variant="contained"
            sx={{
              background: selectedOption === "jobInfo" ? "#1C614E" : "white",
              color: selectedOption === "jobInfo" ? "white" : "black",
              boxShadow: "none",
              border: "1px solid #00000040",
              textTransform: "capitalize",
              my: 0.5,
              "&:hover": {
                backgroundColor:
                  selectedOption === "jobInfo" ? "#1C614E" : "white",
                color: selectedOption === "jobInfo" ? "white" : "black",
              },
              "&:active": {
                backgroundColor: "#1C614E",
              },
              "&:disabled": { color: "white", backgroundColor: "#1C614E" },
            }}
            onClick={() => setSelectedOption("jobInfo")}
          >
            {Labels?.Details}
          </Button>

          {/* Applied Job Seekers Button */}
          <Button
            variant="contained"
            sx={{
              background:
                selectedOption === "appliedJobSeekers" ? "#1C614E" : "white",
              color: selectedOption === "appliedJobSeekers" ? "white" : "black",
              boxShadow: "none",
              border: "1px solid #00000040",
              textTransform: "capitalize",
              my: 0.5,
              "&:hover": {
                backgroundColor:
                  selectedOption === "appliedJobSeekers" ? "#1C614E" : "white",
                color:
                  selectedOption === "appliedJobSeekers" ? "white" : "black",
              },
              "&:active": {
                backgroundColor: "#1C614E",
              },
              "&:disabled": { color: "white", backgroundColor: "#1C614E" },
            }}
            onClick={() => setSelectedOption("appliedJobSeekers")}
          >
            {Labels?.Applied} ({jobDetails?.applied ? jobDetails?.applied : 0})
          </Button>

          {/* Shortlisted Job Seekers Button */}
          <Button
            variant="contained"
            sx={{
              background:
                selectedOption === "shortListedJobSeekers"
                  ? "#1C614E"
                  : "white",
              color:
                selectedOption === "shortListedJobSeekers" ? "white" : "black",
              boxShadow: "none",
              border: "1px solid #00000040",
              textTransform: "capitalize",
              my: 0.5,
              "&:hover": {
                backgroundColor:
                  selectedOption === "shortListedJobSeekers"
                    ? "#1C614E"
                    : "white",
                color:
                  selectedOption === "shortListedJobSeekers"
                    ? "white"
                    : "black",
              },
              "&:active": {
                backgroundColor: "#1C614E",
              },
              "&:disabled": { color: "white", backgroundColor: "#1C614E" },
            }}
            onClick={() => setSelectedOption("shortListedJobSeekers")}
          >
            {Labels?.Shortlisted} (
            {jobDetails?.shortlist ? jobDetails?.shortlist : 0})
          </Button>

          {/* Marked Job Seekers Button */}
          <Button
            variant="contained"
            sx={{
              background:
                selectedOption === "markedJobSeekers" ? "#1C614E" : "white",
              color: selectedOption === "markedJobSeekers" ? "white" : "black",
              boxShadow: "none",
              border: "1px solid #00000040",
              textTransform: "capitalize",
              my: 0.5,
              "&:hover": {
                backgroundColor:
                  selectedOption === "markedJobSeekers" ? "#1C614E" : "white",
                color:
                  selectedOption === "markedJobSeekers" ? "white" : "black",
              },
              "&:active": {
                backgroundColor: "#1C614E",
              },
              "&:disabled": { color: "white", backgroundColor: "#1C614E" },
            }}
            onClick={() => setSelectedOption("markedJobSeekers")}
          >
            {Labels?.Marked} ({jobDetails?.marked ? jobDetails?.marked : 0})
          </Button>

          {/* Matching Job Seekers Button */}
          <Button
            variant="contained"
            sx={{
              background:
                selectedOption === "matchingJobSeekers" ? "#1C614E" : "white",
              color:
                selectedOption === "matchingJobSeekers" ? "white" : "black",
              boxShadow: "none",
              border: "1px solid #00000040",
              textTransform: "capitalize",
              my: 0.5,
              "&:hover": {
                backgroundColor:
                  selectedOption === "matchingJobSeekers" ? "#1C614E" : "white",
                color:
                  selectedOption === "matchingJobSeekers" ? "white" : "black",
              },
              "&:active": {
                backgroundColor: "#1C614E",
              },
              "&:disabled": { color: "white", backgroundColor: "#1C614E" },
            }}
            onClick={() => setSelectedOption("matchingJobSeekers")}
          >
            {Labels?.Recommended} (
            {jobDetails?.matching ? jobDetails?.matching : 0})
          </Button>

          {/* Enquiry Messages Button */}
          <Button
            variant="contained"
            sx={{
              background:
                selectedOption === "enquiryMessages" ? "#1C614E" : "white",
              color: selectedOption === "enquiryMessages" ? "white" : "black",
              boxShadow: "none",
              border: "1px solid #00000040",
              textTransform: "capitalize",
              my: 0.5,
              "&:hover": {
                backgroundColor:
                  selectedOption === "enquiryMessages" ? "#1C614E" : "white",
                color: selectedOption === "enquiryMessages" ? "white" : "black",
              },
              "&:active": {
                backgroundColor: "#1C614E",
              },
              "&:disabled": { color: "white", backgroundColor: "#1C614E" },
            }}
            onClick={() => setSelectedOption("enquiryMessages")}
          >
            {Labels?.Enquiries} ({jobDetails?.enquiry ? jobDetails?.enquiry : 0}
            )
          </Button>
        </Stack>
      </Stack>

      {/* <Outlet /> */}

      {selectedOption == "jobInfo" && <JobInfo id={jobId} onCallback={handleCallback}/>}
      {selectedOption == "appliedJobSeekers" && (<AppliedJobSeekers id={jobId} onCallback={handleCallback}/>)}
      {selectedOption == "shortListedJobSeekers" && (<ShortListedJobSeekers id={jobId} onCallback={handleCallback}/>)}
      {selectedOption == "markedJobSeekers" && <MarkedJobSeekers id={jobId} onCallback={handleCallback}/>}
      {selectedOption == "matchingJobSeekers" && (<MatchingJobseekers id={jobId} onCallback={handleCallback}/>)}
      {selectedOption == "enquiryMessages" && <JobEnquires id={jobId} onCallback={handleCallback}/>}
    </>
  );
}
