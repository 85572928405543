import React from "react";
import {
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Message } from "@mui/icons-material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LanguageIcon from "@mui/icons-material/Language";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export default function EmployerDrawer() {
  const redirect = useNavigate();
  const [open, setOpen] = useState(false);
  const language = localStorage.getItem("language");
  const toggleDrawer = (isOpen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(isOpen);
  };
  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <div>
      <Button onClick={toggleDrawer(true)}>
        {<MenuIcon sx={{ color: "#2B4947", fontSize: "40px" }} />}
      </Button>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        <div
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
          style={{ marginTop: "50px" }}
        >
          <ListItem disablePadding>
            <ListItemButton
              sx={{
                ":hover": {
                  backgroundColor: "#1b765d17",
                },
                cursor: "pointer",
                width: "250px",
              }}
            >
              <ListItemIcon sx={{ color: "#2B4947" }}>
                <LanguageIcon />
              </ListItemIcon>
              <ListItemText
                sx={{
                  color: "#2B4947",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                }}
                primary={
                  language === "pl"
                    ? "Polish"
                    : language === "de"
                    ? "German"
                    : "English"
                }
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
          <ListItemButton onClick={()=>{redirect('/messages')}}>
              <ListItemIcon sx={{ color: "#2B4947" }}>
                <Message />
              </ListItemIcon>
              <ListItemText
                sx={{
                  color: "#2B4947",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                }}
                primary={Labels?.Messages}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => redirect("/notification")}>
              <ListItemIcon sx={{ color: "#2B4947" }}>
                <NotificationsIcon />
              </ListItemIcon>
              <ListItemText
                sx={{
                  color: "#2B4947",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                }}
                primary={Labels?.Notifications}
              />
            </ListItemButton>
          </ListItem>
        </div>
      </Drawer>
    </div>
  );
}
