import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import fifyPluslogo from "../../Images/fifyPluslogo.png";
import "./Style.css";
import { useNavigate, NavLink } from "react-router-dom";
import { ListItemIcon, Select, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Drawer from "./Drawer";
import LanguageIcon from "@mui/icons-material/Language";
import { useDispatch, useSelector } from "react-redux";
import { getAllLanguage } from "../../redux/getLanguageSlice";
import { getAllLabels } from "../../redux/AllLabels";

const Header = () => {
  const dispatch = useDispatch();
  const redirect = useNavigate();
  const [value, setValue] = useState("");
  const [languageCode, setLanguageCode] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const Language = useSelector((state) => state.getAllLanguage.languages);
  const Labels = useSelector((state) => state.allLabels.labels);
  const postData = {
    search: "",
    skills: "",
    city: "",
    category: "",
    type: "",
  };

  const setLanguage = () => {
    const language = localStorage.getItem("language");
    if (language) {
      setLanguageCode(language);
      if (Language) {
        const selectedLanguage = Language.find(
          (data) => data.languagecode === language
        );
        setValue(selectedLanguage.language);
      }
    } else {
      setValue("English");
      setLanguageCode("en");
    }
  };
  useEffect(() => {
    setLanguage();
    dispatch(getAllLabels(languageCode));
  }, [languageCode]);

  useEffect(() => {
    dispatch(getAllLanguage());
  }, []);

  const switchLanguage = (e) => {
    setValue(e.target.value);
    const selectedLanguage = Language.find(
      (data) => data.language === e.target.value
    );
    localStorage.setItem("language", selectedLanguage.languagecode);
    setLanguageCode(selectedLanguage.languagecode);
  };
  
  // const handleClick = () => {
  //   // Reload the page when the NavLink is clicked
  //   window.location.reload();
  // };

  return (
    <AppBar position="fixed" sx={{ backgroundColor: "white" }}>
      <Toolbar disableGutters sx={{ px: 3 }}>
        {/* ------------------------- Logo --------------------- */}
        <img
          src={fifyPluslogo}
          alt=" "
          width="50px"
          height="50px"
          style={{ margin: "0 10px", cursor: "pointer" }}
          onClick={() => redirect("/landingpage")}
        />
        {isMobile ? (
          <Stack
            sx={{
              mx: { xs: "12px", lg: "70px" },
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
            }}
            direction="row"
          >
            <Drawer />
          </Stack>
        ) : (
          <Stack
            sx={{
              flexWrap: "wrap",
              display: { xs: "block", sm: "flex", md: "flex" },
              alignItems: "center",
              justifyContent: "space-between",
              width: { sm: "85%", lg: "100%" },
            }}
            direction={{
              xs: "column",
              sm: "row",
            }}
          >
            {/* -------------------------Home AboutUs Jobs--------------------- */}
            <Box
              sx={{
                flexGrow: 1,
                alignItems: "center !important",
                justifyContent: "center !important",
                textAlign: {
                  xs: "right !important",
                  md: "center !important",
                },
              }}
            >
              <nav id="header">
                <NavLink
                  to="/landingpage"
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                >
                  {Labels?.Home}
                </NavLink>
                <NavLink
                  to="/about"
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                >
                  {Labels?.AboutUs}
                </NavLink>
                <NavLink
                  to="/jobSearchPrelogin"
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                  state={postData}
                  // onClick={handleClick}
                >
                  {Labels?.Jobs}
                </NavLink>
              </nav>
            </Box>
            {/* -------------------------SignUp SignIn--------------------- */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <LanguageIcon sx={{ color: "#2B4947" }} />
              <Select
                value={value}
                onChange={(e) => switchLanguage(e)}
                sx={{
                  color: "#2B4947",
                  padding: "10px 12px",
                  minWidth: 120,
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
              >
                {Language &&
                  Language.map((val, index) => {
                    return (
                      <MenuItem key={index} value={val.language}>
                        {val.language}
                      </MenuItem>
                    );
                  })}
              </Select>
              <>
                <Button
                  sx={{
                    my: 2,
                    marginRight: "20px",
                    color: "#2B4947",
                    textTransform: "capitalize",
                    fontSize: "18px",
                    fontWeight: 600,
                  }}
                  onClick={() => redirect("/login")}
                >
                  {Labels?.signIn}
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    my: 2,
                    backgroundColor: "#1C614E",
                    color: "#FFFFFF",
                    fontWeight: 600,
                    textTransform: "capitalize",
                    fontSize: "16px",
                    // width: 100,
                    "&:hover": {
                      backgroundColor: "#1C614E",
                    },
                  }}
                  onClick={() => redirect("/signup")}
                >
                  {Labels?.signUp}
                </Button>
              </>
            </Box>
          </Stack>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
