import { HighlightOffOutlined } from "@mui/icons-material";
import { Grid, Box, Modal, Stack, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import { axiosAPI } from "../Axios/Axios";
import { useNavigate } from "react-router-dom";
import {
  errorNotify,
  succesNotify,
  validatePassword,
} from "../CommonCode/Commonfunc";
import CustomButton from "../Components/CustomButton";
import TextfieldCustom from "../Components/TextfieldCustom";
import { useSelector } from "react-redux";
import { profanityfn } from "../Profanity/Profanity";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#FFFFFF",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};
const EmployerPasswordChange = ({
  openPasswordReset,
  setopenPasswordReset,
  ForgotPasswordModelClose,
}) => {
  const [formData, setFormData] = useState({
    currentpassword: "",
    newpassword: "",
    confirmpassword: "",
  });

  const { currentpassword, newpassword, confirmpassword } = formData;
  const getNewPassword = (e) => {
    const value = e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  };
  const postData = {
    oldPassword: currentpassword,
    newPassword: newpassword,
    confirmPassword: confirmpassword,
    language:localStorage.getItem("language")
  };
  const navigate = useNavigate();
  const PasswordReset = async (e) => {
    e.preventDefault();
    const minLength = 8;
    const maxLength = 30;
    const uppercaseRegex = /[A-Z]/;
    const numericRegex = /[0-9]/;
    const specialCharRegex = /[!@#$%^&*]/;
    if (!currentpassword) {
      errorNotify(Labels.currentPasswordEmpty);
    }

    // else if (validatePassword(newpassword) !== "strong") {
    //   const msg = validatePassword(newpassword);
    //   errorNotify(msg);
    // }

    if (newpassword.length < minLength || newpassword.length > maxLength) {
      errorNotify(Labels.Passwordmustbebetween8and30characters);
    } else if (!uppercaseRegex.test(newpassword)) {
      errorNotify(Labels.Passwordmustcontainatleastoneuppercaseletter);
    } else if (!numericRegex.test(newpassword)) {
      errorNotify(Labels.Passwordmustcontainatleastonenumericdigit);
    } else if (!specialCharRegex.test(newpassword)) {
      errorNotify(Labels.Passwordmustcontainatleastonespecialcharacter);
    } 
    if (newpassword !== confirmpassword) {
      errorNotify(Labels.passwordNotMatched);
    } else {
      const result = await axiosAPI.post("/changePassword", postData);
      const { success, message, data } = result.data;
      if (success === true) {
        succesNotify(message);
        // ForgotPasswordModelClose();
        navigate("/login");
      } else {
        errorNotify(data.error);
      }
    }
  };
  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <Fragment>
      {/* <Modal
        open={openPasswordReset}
        onClose={ForgotPasswordModelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      > */}
      <Grid container item xs={12} lg={12}>
        <Box sx={style}>
          <Stack
            direction="row"
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: 600,
                fontStyle: "normal",
                fontSize: "20px",
                lineHeight: "30px",
              }}
            >
              {Labels?.ChangePassword}
            </Typography>
          </Stack>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: 400,
              fontStyle: "normal",
              fontSize: "15px",
              lineHeight: "24px",
              pt: 5,
            }}
          >
            {Labels?.CurrentPassword}
          </Typography>
          <TextfieldCustom
            style={{
              width: 400,
              pt: 1,
            }}
            label={Labels?.CurrentPassword}
            type="password"
            name="currentpassword"
            value={currentpassword}
            onChange={(e) => getNewPassword(e)}
          />
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: 400,
              fontStyle: "normal",
              fontSize: "15px",
              lineHeight: "24px",
              pt: 3,
            }}
          >
            {Labels?.NewPassword}
          </Typography>
          <TextfieldCustom
            style={{
              width: 400,
              pt: 1,
            }}
            label={Labels?.NewPassword}
            name="newpassword"
            value={newpassword}
            onChange={(e) => getNewPassword(e)}
          />
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: 400,
              fontStyle: "normal",
              fontSize: "15px",
              lineHeight: "24px",
              pt: 3,
            }}
          >
            {Labels?.confirmPassword}
          </Typography>
          <TextfieldCustom
            style={{
              width: 400,
              pt: 1,
            }}
            label={Labels?.confirmPassword}
            type="password"
            name="confirmpassword"
            value={confirmpassword}
            onChange={(e) => getNewPassword(e)}
          />
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: 400,
              fontStyle: "normal",
              fontSize: "12px",
              lineHeight: "18px",
              color: "#949191",
              pl: 2,
              pt: 3,
            }}
          >
            {Labels?.Atleast8Characters}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: 400,
              fontStyle: "normal",
              fontSize: "12px",
              lineHeight: "18px",
              color: "#949191",
              pl: 2,
              pt: 1,
            }}
          >
            {Labels?.UpperandLower} (A-Z a-z)
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: 400,
              fontStyle: "normal",
              fontSize: "12px",
              lineHeight: "18px",
              color: "#949191",
              pl: 2,
              pt: 1,
            }}
          >
            {Labels?.Numbers0to9}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: 400,
              fontStyle: "normal",
              fontSize: "12px",
              lineHeight: "15px",
              color: "#949191",
              pt: 1,
              pl: 2,
            }}
          >
            !@#$%^&* {Labels?.SpecialCharactersarevalid}
          </Typography>
          <CustomButton
            label={Labels?.ChangePassword}
            style={{
              mt: 3,
              fontFamily: "Poppins",
              width: 400,
              fontStyle: "normal",
              fontSize: "16px",
              mb: 2,
            }}
            onClick={PasswordReset}
          />
        </Box>
        {/* </Modal> */}
      </Grid>
    </Fragment>
  );
};

export default EmployerPasswordChange;
