import HomeIcon from "@mui/icons-material/Home";
import { Box, IconButton, Stack } from "@mui/material";
import LoginImage from "../../Images/LoginImage.png";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../Images/fifyPluslogo.png";
import { styled } from "@mui/system";
import { Tabs } from "@mui/base/Tabs";
import { TabsList as BaseTabsList } from "@mui/base/TabsList";
import { TabPanel as BaseTabPanel } from "@mui/base/TabPanel";
import { buttonClasses } from "@mui/base/Button";
import { Tab as BaseTab, tabClasses } from "@mui/base/Tab";
import JobseekerSignUp from "../SignUp/JobseekerSignUp";
import EmployerSignup from "../SignUp/EmployerSignup.jsx";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function Signup() {
  const redirect = useNavigate()
  const Labels = useSelector((state) => state.allLabels.labels);

  return (
    <Stack direction="row">
      <img
        src={LoginImage}
        className="loginImg"
        alt="loginImage"
        style={{
          height: "100vh",
        }}
      />
      <Stack width="100%">
        <Stack direction="row" justifyContent="flex-end">
          <IconButton
            sx={{
              color: "#1C614E",
              height: "100%",
              background: "rgba(0, 0, 0, 0.04)"
            }}
            aria-label="delete"
            onClick={() => redirect("/landingpage")}
          >
            <HomeIcon />
          </IconButton>
        </Stack>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          height="90%"
          spacing={5}
        >
          <img
            src={logo}
            alt=" "
            width="85px"
            style={{
              cursor: "pointer",
            }}
            onClick={() => redirect("/landingpage")}
          />
          <Box sx={{ minWidth: "45%" ,p:2}}>
            <Tabs defaultValue={1}>
              <TabsList>
              <Tab value={1}>{Labels?.JobSeeker}</Tab>
                <Tab value={2}>{Labels?.EmployerRecruiter}</Tab>
              </TabsList>
              <TabPanel value={1}>
                <JobseekerSignUp />
              </TabPanel>
              <TabPanel value={2}>
                <EmployerSignup />
              </TabPanel>
            </Tabs>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
}


const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Tab = styled(BaseTab)`
  font-family: "IBM Plex Sans", sans-serif;
  color: #fff;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: transparent;
  width: 100%;
  padding: 10px 12px;
  margin: 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  &:focus {
    color: #fff;
  }

  &.${tabClasses.selected} {
    background-color: #fff;
    color: rgb(28, 97, 78);
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(BaseTabPanel)(
  ({ theme }) => `
  width: auto;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  padding: 20px 12px;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid rgba(0, 0, 0, 0.54);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  `
);

const TabsList = styled(BaseTabsList)(
  ({ theme }) => `
  width: 100%;
  background-color: rgba(0, 0, 0, 0.54);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  box-shadow: 0px 4px 30px ${theme.palette.mode === "dark" ? grey[900] : grey[200]
    };
  `
);
