import { warningNotify } from "../CommonCode/Commonfunc";

const profanityfn = (list, word) => {
  const loopfn = (word) => {
    return "";
  };

  let newValue = word;
  let alertword;

  for (let i in list) {
    const regex = new RegExp(`${list[i]} `, "gi");
    if (regex.test(newValue)) {
      alertword = true;
      newValue = newValue.replace(regex, loopfn(list[i]));
    }
  }

  if (alertword) {
    const language = localStorage.getItem("language");

    if (language == "en") {
      warningNotify("Please refrain from using inappropriate language.");
    } else if (language == "pl") {
      warningNotify("Prosimy o powstrzymanie się od używania nieodpowiedniego języka.");
    } else if (language == "de") {
      warningNotify(" Bitte verwenden Sie keine unangemessene Sprache.");
    } else{
      warningNotify("Please refrain from using inappropriate language.");
    }
  }
  return newValue;
};

const profanitySend = (list, word) => {
  let newValue = word;
  let alertword;
  if (typeof newValue === "string") {
    const words = newValue.split(/\s+/); // split the string into words based on whitespace
    for (let word of words) {
      // if (list.includes(word)) {
      //   alertword = true;
      //   break; // stop checking if a match is found
      // }
      if (list.map((item) => item.toLowerCase()).includes(word.toLowerCase())) {
        alertword = true;
        break; // Stop checking if a match is found
      }
    }
  }

  return alertword;
};

export { profanityfn, profanitySend };
