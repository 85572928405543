import * as React from "react";
import Box from "@mui/material/Box";
import { Button, Dialog, Stack, TextField, Typography } from "@mui/material";
import { axiosAPI } from "../../Axios/Axios";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { errorNotify, succesNotify } from "../../CommonCode/Commonfunc";
import Country from "../../CommonCode/Country";
import State from "../../CommonCode/State";
import TextfieldCustom from "../../Components/TextfieldCustom";
import { useSelector } from "react-redux";
import { useState } from "react";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "60%",
    sm: "50%",
    md: "40%",
    lg: "30%",
  },
  bgcolor: "#FFFFFF",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

export default function PreferedLocationModal({
  open,
  handleClose,
  data,
  countryid = "",
}) {
  const [isButtonDisable, setButtonDisable] = useState(false);
  const [place, setLocations] = useState(data);
  const [country, setCountry] = useState(countryid ? countryid : null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState("");

  const submitLocations = async () => {
    if (!country || !state || !city) {
      return errorNotify(Labels.allFieldsMandatory);
    } else if (city.length > 200) {
      errorNotify(Labels.cityValidation);
    } else {
      setButtonDisable(true);
      const newData = {
        countryid: country.id,
        stateid: state.id,
        city: city,
      };
      place.push(newData);
      const postData = {
        locations: JSON.stringify(place),
        language: localStorage.getItem("language"),
      };
      const result = await axiosAPI.post("/updateUserProfile", postData);
      const { success, message } = result.data;
      if (success === true) {
        succesNotify(message);
        handleClose();
        setButtonDisable(false);
      } else {
        errorNotify(message);
        setButtonDisable(false);
      }
    }
  };
  const Labels = useSelector((state) => state.allLabels.labels);
  const [userchangedcountry, setuserchangedcountry] = useState(null);
  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 1.5 }}
      >
        <Typography variant="h6" sx={{ color: "#1C614E" }}>
          {Labels?.PreferredLocations}
        </Typography>
        <HighlightOffIcon
          sx={{
            color: "#1C614E",
            cursor: "pointer",
          }}
          onClick={handleClose}
        />
      </Stack>
      <Box sx={{ p: 1.5 }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          {Labels?.Country}
        </Typography>
        <Country
          value={country}
          countryId={countryid}
          setValue={setCountry}
          setStateId={setState}
          userchangedcountry={userchangedcountry}
          setuserchangedcountry={setuserchangedcountry}
          style={{ position: "relative", right: "15px" }}
          isEdit={false}
        />
        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          {Labels?.State}
        </Typography>
        <State
          value={state}
          setValue={setState}
          userchangedcountry={userchangedcountry}
          setuserchangedcountry={setuserchangedcountry}
          country={country}
          isEdit={false}
          style={{ position: "relative", right: "15px" }}
        />
        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          {Labels?.City}
        </Typography>

        <TextField
          name="institution"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          fullWidth
          placeholder={Labels?.City}
          InputProps={{ style: { height: "45px" } }}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "4px", // Adjust border radius if needed
              border: `2px solid black`,
              "& fieldset": {
                border: "none", // Remove the default border
              },
              "&:hover": {
                border: `2px solid black`,
              },
              "&.Mui-focused": {
                border: `2px solid black`,
              },
            },
          }}
        />

        <Stack direction="row" justifyContent="center" sx={{ p: 1.5 }}>
          <Button
            variant="solid"
            fullWidth
            sx={{
              background: "#1C614E",
              borderRadius: 3,
              color: "#fff",
              "&:hover": {
                backgroundColor: "#1C614E",
              },
              "&:active": {
                backgroundColor: "#1C614E",
              },
              "&:disabled": { color: "white", backgroundColor: "#1C614E" },
            }}
            disabled={isButtonDisable}
            onClick={submitLocations}
          >
            {Labels?.Save}
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
}
