import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
} from "@mui/material";

import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';

import { useSelector } from "react-redux";

const SalaryCalculationTable = ({ jobDetails }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const salaryDetails = jobDetails?.salaryCalucateitem
    ? JSON.parse(jobDetails?.salaryCalucateitem)
    : {};

  const salary = jobDetails?.salary
    ? parseInt(jobDetails.salary.toString().replace(/[^0-9]/g, ""), 10)
    : "";
  const salaryMax =
    jobDetails?.salaryMax && jobDetails.salaryMax !== 0
      ? parseInt(jobDetails.salaryMax.toString().replace(/[^0-9]/g, ""), 10)
      : salaryDetails?.salaryMax
      ? parseInt(salaryDetails.salaryMax.toString().replace(/[^0-9]/g, ""), 10)
      : "";
  const salaryPeriodName = jobDetails?.salaryPeriodName || "";

  const annualSalaryMin = jobDetails?.anualSalaryMin
    ? parseInt(jobDetails.anualSalaryMin.toString().replace(/[^0-9]/g, ""), 10)
    : "";
  const annualSalaryMax = jobDetails?.anualsalaryMax
    ? parseInt(jobDetails.anualsalaryMax.toString().replace(/[^0-9]/g, ""), 10)
    : "";

  const formattedAnnualSalaryMin =
    annualSalaryMin > 0
      ? `${annualSalaryMin.toLocaleString()} ${jobDetails?.currencySymbol}`
      : "";
  const formattedAnnualSalaryMax =
    annualSalaryMax > 0
      ? `${annualSalaryMax.toLocaleString()} ${jobDetails?.currencySymbol}`
      : "";

  const hoursPerDay = salaryDetails?.hoursPerDay || "";
  const daysPerWeek = salaryDetails?.daysPerWeek || "";

  const hourlyRate =
    salary && hoursPerDay && daysPerWeek
      ? Math.floor(salary / (hoursPerDay * daysPerWeek * 4.33))
          .toString()
          .replace(/[^0-9]/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
        "." +
        (salary / (hoursPerDay * daysPerWeek * 4.33)).toFixed(2).split(".")[1]
      : "";

  const weeklySalary =
    salary && hoursPerDay && daysPerWeek
      ? Math.floor(salary / 4.33)
          .toString()
          .replace(/[^0-9]/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
        "." +
        (salary / 4.33).toFixed(2).split(".")[1]
      : "";

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  const Labels = useSelector((state) => state.allLabels.labels);

  return (
    <>
      {(formattedAnnualSalaryMax || formattedAnnualSalaryMin) && (
        <>
          <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
            <Typography sx={{ fontWeight: "600" }}>
              {Labels.AnnualSalary}
            </Typography>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <Typography>:</Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7} lg={8} xl={9}>
            <Typography sx={{ position: "relative", bottom: "10px" }}>
              {formattedAnnualSalaryMax || formattedAnnualSalaryMin}
              {isExpanded ? (
               
                <MoreHorizRoundedIcon
                  onClick={toggleExpand}
                  sx={{
                    border: "1px solid black",
                    borderRadius: "50%",
                    cursor: "pointer",
                  color:'grey',
                    position: "relative",
                    top: "7px",
                    left: "10px",
                  }}
                />
              ) : (
                <MoreHorizRoundedIcon
                  onClick={toggleExpand}
                  sx={{
                    border: "1px solid #0288d1",
                    borderRadius: "50%",
                    cursor: "pointer",
                    position: "relative",
                    color:"#0288d1",
                    top: "7px",
                    left: "10px",
                  }}
                />
              )}
            </Typography>
          </Grid>
        </>
      )}

      {(formattedAnnualSalaryMax || formattedAnnualSalaryMin) && isExpanded && (
        <Grid item xs={12}>
          <TableContainer component={Paper} sx={{ maxWidth: "500px" }}>
            <Table aria-label="salary calculation table" size="small">
              <TableHead>
               
              </TableHead>
              <TableBody>
                {hoursPerDay && (
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{ fontSize: "12px", padding: "4px" }}
                    >
                      {Labels.HoursperDay}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ fontSize: "12px", padding: "4px" }}
                    >
                      {hoursPerDay}
                    </TableCell>
                  </TableRow>
                )}
                {daysPerWeek && (
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{ fontSize: "12px", padding: "4px" }}
                    >
                      {Labels.DaysperWeek}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ fontSize: "12px", padding: "4px" }}
                    >
                      {daysPerWeek}
                    </TableCell>
                  </TableRow>
                )}
                {hourlyRate && (
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{ fontSize: "12px", padding: "4px" }}
                    >
                      {Labels.HourlyRate}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ fontSize: "12px", padding: "4px" }}
                    >
                      ~ {`${hourlyRate} ${jobDetails?.currencySymbol}`}
                    </TableCell>
                  </TableRow>
                )}
                {weeklySalary && (
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{ fontSize: "12px", padding: "4px" }}
                    >
                      {Labels.WeeklySalary}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ fontSize: "12px", padding: "4px" }}
                    >
                     ~ {`${weeklySalary} ${jobDetails?.currencySymbol}`}
                    </TableCell>
                  </TableRow>
                )}
                {salary && salaryPeriodName && (
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{ fontSize: "12px", padding: "4px" }}
                    >
                      {Labels.Salary} (Min) / {salaryPeriodName}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ fontSize: "12px", padding: "4px" }}
                    >
                      {`${salary.toLocaleString()} ${
                        jobDetails?.currencySymbol
                      }`}
                    </TableCell>
                  </TableRow>
                )}
                {salaryMax > 0 && (
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{ fontSize: "12px", padding: "4px" }}
                    >
                      {Labels.Salary} (Max) / {salaryPeriodName}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ fontSize: "12px", padding: "4px" }}
                    >
                      {`${salaryMax.toLocaleString()} ${
                        jobDetails?.currencySymbol
                      }`}
                    </TableCell>
                  </TableRow>
                )}
                {formattedAnnualSalaryMin && (
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{ fontSize: "12px", padding: "4px" }}
                    >
                      {Labels.AnnualSalary} (Min)
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ fontSize: "12px", padding: "4px" }}
                    >
                      {formattedAnnualSalaryMin}
                    </TableCell>
                  </TableRow>
                )}
                {formattedAnnualSalaryMax && (
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{ fontSize: "12px", padding: "4px" }}
                    >
                      {Labels.AnnualSalary} (Max)
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ fontSize: "12px", padding: "4px" }}
                    >
                      {formattedAnnualSalaryMax}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </>
  );
};

export default SalaryCalculationTable;
