// import React, { Fragment, useEffect, useState } from "react";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// import { axiosAPI } from "../Axios/Axios";
// import { Box } from "@mui/material";

// export default function AllLanguage({
//   value,
//   setValue,
//   label,
//   style,
//   languageName,
//   setLanguageName,
// }) {
//   const [languages, setLanguages] = useState([]);
//   const [selectedLanguages, setSelectedLanguages] = useState([]);
//   useEffect(() => {
//     const getAllLanguages = async () => {
//       const result = await axiosAPI.get("/getAllLanguages");
//       const { success, data } = result.data;
//       if (success === true) {
//         setLanguages(data);
//       } else {
//         setLanguages([]);
//       }
//     };
//     getAllLanguages();
//   }, []);

//   const handleChange = (e) => {
//     const selectedValue = e.target.value;

//     // Check if the language is already selected
//     const isLanguageSelected = selectedLanguages.some(
//       (language) => language.l_id === selectedValue.l_id
//     );

//     if (!isLanguageSelected) {
//       // If the language is not already selected, update the state
//       setSelectedLanguages((prevSelectedLanguages) => [
//         ...prevSelectedLanguages,
//         selectedValue
//       ]);

//       setValue(selectedValue);

//       // Assuming languages is an array of objects
//       const selectedLanguage = languages.find(
//         (language) => language.l_id === selectedValue.l_id
//       );

//       if (selectedLanguage) {
//         setLanguageName(selectedLanguage.language);
//       } else {
//         // Handle the case when the selected language is not found
//         setLanguageName("");
//       }
//     }
//   };

//   return (
//     <FormControl sx={{ width: "100%" }}>
//       <Select
//         value={value}
//         // label={label}
//         placeholder={label}
//         displayEmpty
//         variant="outlined"
//         onChange={handleChange}
//         sx={{
//           padding: "10px 12px",
//           fontFamily: "Poppins",
//           ...style,
//         }}
//       >
//         <MenuItem value="" disabled>
//           <span style={{ color: "#bcbcbc" }}>{label}</span>
//         </MenuItem>
//         {languages &&
//           languages.map((val, index) => {
//             return (
//               <MenuItem key={index} value={val}>
//                 {val.language}
//               </MenuItem>
//             );
//           })}
//       </Select>
//     </FormControl>
//   );
// }

import React, { Fragment, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { axiosAPI } from "../Axios/Axios";
import { Box, Skeleton } from "@mui/material";
import { useSelector } from "react-redux";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 200, // Adjust the maximum height as needed
      width: 250,
    },
  },
};

export default function AllLanguageFilter({ value, setValue, label, style }) {
  const [languages, setLanguages] = useState([]);
  useEffect(() => {
    const getAllLanguages = async () => {
      const result = await axiosAPI.get("/getAllLanguages");
      const { success, data } = result.data;
      if (success === true) {
        setLanguages(data);
      } else {
        setLanguages([]);
      }
    };
    getAllLanguages();
  }, []);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <FormControl sx={{ width: "100%" }}>
      <Select
        MenuProps={MenuProps}
        value={value ? value : []}
        onChange={handleChange}
        placeholder={label}
        displayEmpty
        multiple
        variant="outlined"
        sx={{
          padding: "10px 12px",
          fontSize: "14px",
          fontFamily: "Poppins",
          border: `2px solid black`,
          "& fieldset": {
            border: "none", // Remove the default border
          },
          "&:hover": {
            border: `2px solid black`,
          },
          "&.Mui-focused": {
            border: `2px solid black`,
          },
          ...style,
        }}
        renderValue={(selected) => {
          const selectedArray = Array.isArray(selected) ? selected : [selected];

          if (selectedArray[0] === undefined || selectedArray[0] === null) {
            return (
              <>
                <span style={{ color: "rgb(188, 188, 188)" }}>
                {label}
                </span>
              </>
            );
          }
          const selectedNames = selectedArray.map((val) => languages.find((item) => item.l_id === val)?.language).join(", ");
          return languages?.length?languages?.length<4?selectedNames:selectedNames.slice(0,40)+" ...": <Skeleton variant="rectangular" width={`90%`} height={20} />;
        }}
      >
        {languages &&
          languages.map((val, index) => {
            return (
              <MenuItem key={index} value={val?.l_id}>
                {val.language}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
}
