import React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  errorNotify,
  isValidEmail,
  succesNotify,
  validatePassword,
  warningNotify,
} from "../CommonCode/Commonfunc";
import { useSelector } from "react-redux";
import { axiosAPI } from "../Axios/Axios";
import dayjs from "dayjs";
import { Box, Button, Stack, Typography } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import InputWithLabel from "../Components/InputWithLabel";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import Languages from "../CommonCode/Languages";
import CustomButton from "../Components/CustomButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { profanityfn } from "../Profanity/Profanity";

export default function JobseekerSignUp() {
  const redirect = useNavigate();
  const location = useLocation();
  const [Language, setLanguages] = useState(
    localStorage.getItem("language") ? localStorage.getItem("language") : ""
  );
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const currentDate = new Date();
  const maxDate = new Date(currentDate);
  maxDate.setFullYear(currentDate.getFullYear() - 50);
  const maxDateDayjs = dayjs(maxDate);
  const [dob, setdob] = useState(null);
  const Labels = useSelector((state) => state.allLabels.labels);

  const shouldDisableDate = (date) => {
    return date.isAfter(maxDateDayjs);
  };
  const [FormData, setFormData] = useState({
    name: "",
    email: "",
    date: "",
    password: "",
    confirmpassword: "",
    termsandconditions: false,
  });
  const [showPassword1, setShowPassword1] = useState(false);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };
  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };

  const { name, email, password, confirmpassword, termsandconditions } =
    FormData;
  const ProfanityList = useSelector((state) => state.Profanity.wordlist);

  const jobSekkerRegister = async (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    if (e.target.name == "password" || e.target.name == "confirmpassword") {
      setFormData({ ...FormData, [e.target.name]: value });
    } else {
      setFormData({
        ...FormData,
        [e.target.name]: profanityfn(ProfanityList, value),
      });
    }
  };
  const postdata = {
    name: name,
    username: email,
    dob: dob,
    socialMediaLogin: 0,
    language: Language,
    password: confirmpassword,
  };

  const allList = useSelector((state) => state.allList.list);
  const [showterms, setShowterms] = useState(false);
  const handleOpenterms = () => {
    setShowterms(true);
  };
  const handleCloseterms = () => {
    setShowterms(false);
  };

  const submitFormData = async (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    const minLength = 8;
    const maxLength = 30;
    const uppercaseRegex = /[A-Z]/;
    const numericRegex = /[0-9]/;
    const specialCharRegex = /[!@#$%^&*]/;

    if (name === "") {
      errorNotify(Labels.nameRequired);
      setIsButtonDisabled(false);
    } else if (name.length > 200) {
      errorNotify(Labels.nameValidation);
      setIsButtonDisabled(false);
    } else if (!isValidEmail(email)) {
      errorNotify(Labels.emailValidation);
      setIsButtonDisabled(false);
    } else if (email.length > 100) {
      errorNotify(Labels.emailLengthValidation);
      setIsButtonDisabled(false);
    } else if (!dob) {
      errorNotify(Labels.DOBselect);
      setIsButtonDisabled(false);
    } else if (Language === "") {
      errorNotify(Labels.selectPreferredLanguage);
      setIsButtonDisabled(false);
    } else if (password === "") {
      errorNotify(Labels.passwordBlank);
      setIsButtonDisabled(false);
    } else if (password.length < minLength || password.length > maxLength) {
      errorNotify(Labels.Passwordmustbebetween8and30characters);
      setIsButtonDisabled(false);
    } else if (!uppercaseRegex.test(password)) {
      errorNotify(Labels.Passwordmustcontainatleastoneuppercaseletter);
      setIsButtonDisabled(false);
    } else if (!numericRegex.test(password)) {
      errorNotify(Labels.Passwordmustcontainatleastonenumericdigit);
      setIsButtonDisabled(false);
    } else if (!specialCharRegex.test(password)) {
      errorNotify(Labels.Passwordmustcontainatleastonespecialcharacter);
      setIsButtonDisabled(false);
    } else if (confirmpassword === "") {
      errorNotify(Labels.confirmPasswordBlank);
      setIsButtonDisabled(false);
    } else if (password !== confirmpassword) {
      errorNotify(Labels.passwordMisMatch);
      setIsButtonDisabled(false);
    } else if (termsandconditions === false) {
      errorNotify(Labels.acceptTermsAndConditions);
      setIsButtonDisabled(false);
    } else {
      if (dob < maxDateDayjs.$d) {
        setIsButtonDisabled(true);
        await axiosAPI
          .post("/registerJobSeeker", postdata)
          .then((response) => {
            const { success, message } = response.data;
            if (success) {
              redirect("/login", { state: 1 });
              succesNotify(Labels.accountCreated);
              setIsButtonDisabled(false);
            } else {
              if (response?.data?.data?.username?.length) {
                errorNotify(Labels.EmailTaken);
              }
              setIsButtonDisabled(false);
            }
          })
          .catch((res) => {
            warningNotify(Labels.credentialValidation);
          });
      } else {
        errorNotify(Labels.DOBvalidation);
        setIsButtonDisabled(false);
      }
    }
  };

  const RedirectToLogin = () => {
    redirect("/login", { state: 1 });
  };

  return (
    <>
      <Typography variant="body1" sx={{ mb: 2, textAlign: "center" }}>
        {Labels.createAnAccountandstart}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <InputWithLabel
            style={{ width: "100%" }}
            InputLabel={Labels?.name}
            label={Labels?.name}
            type="text"
            name="name"
            value={name}
            required
            onChange={(e) => jobSekkerRegister(e)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputWithLabel
            style={{ width: "100%" }}
            InputLabel={Labels?.emailAddress}
            label={Labels?.emailAddress}
            type="email"
            name="email"
            value={email}
            required
            onChange={(e) => jobSekkerRegister(e)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div style={{ margin: "10px 5px" }}>
            <label>{Labels?.dateofBirth}</label>
            <span style={{ color: "red" }}>*</span>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]} sx={{ paddingTop: 0 }}>
                <DatePicker
                  fullWidth
                  value={dob}
                  onChange={(date) => setdob(date.$d)}
                  shouldDisableDate={shouldDisableDate}
                  renderInput={(params) => <TextField {...params} />}
                  format="DD/MM/YYYY" // specify the desired format
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div style={{ margin: "10px 5px" }}>
            <label>{Labels?.language}</label>
            <span style={{ color: "red" }}>*</span>
            <br />
            <Languages
              style={{
                width: "100%",
              }}
              selectLabel={Labels.PreferredLanguages}
              value={Language}
              setValue={setLanguages}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div style={{ margin: "10px 5px" }}>
            <label>{Labels?.password}</label>
            <span style={{ color: "red" }}>*</span>
            <br />
            <OutlinedInput
              type={showPassword1 ? "text" : "password"}
              placeholder={Labels?.password}
              name="password"
              value={password}
              onChange={(e) => jobSekkerRegister(e)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword1}
                    onMouseDown={handleMouseDownPassword1}
                    edge="end"
                  >
                    {showPassword1 ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              sx={{ width: "100%" }}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div style={{ margin: "10px 5px" }}>
            <label>{Labels?.confirmPassword}</label>
            <span style={{ color: "red" }}>*</span>
            <br />
            <OutlinedInput
              type={showPassword2 ? "text" : "password"}
              placeholder={Labels?.confirmPassword}
              name="confirmpassword"
              value={confirmpassword}
              onChange={(e) => jobSekkerRegister(e)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword2}
                    onMouseDown={handleMouseDownPassword2}
                    edge="end"
                  >
                    {showPassword2 ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              sx={{ width: "100%" }}
            />
          </div>
        </Grid>
      </Grid>
      <Stack direction="row" mb={4}>
        <Checkbox
          sx={{
            color: " #1C614E",
            "&.Mui-checked": {
              color: "#1C614E",
            },
          }}
          name="termsandconditions"
          value={termsandconditions}
          checked={termsandconditions}
          onChange={(e) => jobSekkerRegister(e)}
        />
        <p
          sx={{
            fontWeight: 300,
            fontSize: "15px",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {Labels.agree}
          {/* <a style={{ color: "#1976d2", textDecoration: "none" }}>
                        terms & conditions
                    </a> */}
          <Button
            variant="text"
            sx={{
              textTransform: "capitalize",
              color: "#1976d2",
              // boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              // background: "#1C614E1C",
              margin: 1,
            }}
            onClick={() => {
              handleOpenterms();
            }}
          >
            {Labels?.TermsandConditions}
          </Button>
          <Stack>
            <Dialog open={showterms} onClose={handleCloseterms}>
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ p: 0.5, mt: 0.5 }}
              >
                <DialogTitle
                  sx={{
                    color: "#1C614E",
                  }}
                >
                  {Labels?.TermsandConditions}
                </DialogTitle>
                <HighlightOffIcon
                  sx={{
                    color: "#1C614E",
                    cursor: "pointer",
                    m: 2,
                  }}
                  onClick={handleCloseterms}
                />
              </Stack>

              <DialogContent sx={{ pt: 0, mb: 1 }}>
                {allList?.privacyPolicy?.terms && (
                  <Typography>{allList?.privacyPolicy?.terms}</Typography>
                )}
              </DialogContent>
            </Dialog>
          </Stack>
        </p>
      </Stack>
      <Stack textAlign="center">
        <CustomButton
          label={Labels?.signUp}
          style={{
            fontSize: "16px",
            lineHeight: "24px",
            color: "#FFFFFF",
            width: "30%",
            margin: "auto",
          }}
          onClick={submitFormData}
          disabled={isButtonDisabled}
        />
      </Stack>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "15px",
        }}
      >
        <Typography
          sx={{
            color: "rgba(0, 0, 0, 0.85)",
            fontSize: "15px",
            fontWeight: 300,
          }}
        >
          {Labels.alreadyuser}
        </Typography>
        <Button
          variant="text"
          sx={{
            textTransform: "capitalize",
            color: "rgba(0, 0, 0, 0.85)",
            fontSize: "15px",
            fontWeight: 600,
          }}
          onClick={RedirectToLogin}
        >
          {Labels?.signIn}
        </Button>
      </div>
    </>
  );
}
