import React from "react";
import { Stack, Typography } from "@mui/material";
import jobadvertise from "../../Images/jobadvertise.png";
import CustomButton from "../Components/CustomButton";
import { useNavigate } from "react-router-dom";
import "./Style.css";
import { useSelector } from "react-redux";

const Advertisement = () => {
  const redirect = useNavigate();
  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <Stack
      sx={{
        backgroundImage: `url(${jobadvertise})`,
        backgroundSize: "cover",
        borderRadius: 5,
        width: "100%",
        marginBottom: 5,
        paddingTop: 3,
        paddingBottom: 3,
        paddingRight: 3,
        paddingLeft: { xs: 3, lg: 6 },
        color: "#fff",
      }}
    >
      <div className="advertisement">
        <div>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: { xs: "35px", md: "45px" },
              color: "#fff",
            }}
          >
            {Labels?.Advertiseyourbrand}
          </Typography>
          <p
            style={{
              fontWeight: 300,
              fontSize: "18px",
              color: "#fff",
            }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            <br />
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CustomButton
            label={Labels?.CallUsNow}
            style={{
              background: "white",
              color: "#2B4947",
              "&:hover": {
                color: "white",
              },
              "&:active": {
                backgroundColor: "#1C614E",
              },
              "&:disabled": { color:"white",
                backgroundColor: "#1C614E",
              },
            }}
            onClick={() => redirect("/contact")}
          />
        </div>
      </div>
    </Stack>
  );
};

export default Advertisement;
