import React, { useEffect, useState, Fragment, createRef } from "react";
import {
  Box,
  Divider,
  Avatar,
  Button,
  Grid,
  Chip,
  Paper,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
  DialogTitle,
  DialogActions,
  Dialog,
  DialogContent,
  IconButton,
  InputBase,
  InputLabel,
} from "@mui/material";

import { Close, TokenSharp } from "@mui/icons-material";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import EditIcon from "@mui/icons-material/Edit";
import Select from "@mui/material/Select";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { axiosupload } from "../../Axios/Axios";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomButton from "../../Components/CustomButton";
import TextfieldCustom from "../../Components/TextfieldCustom";
import JobCategory from "../../CommonCode/JobCategory";
import Country from "../../CommonCode/Country";
import State from "../../CommonCode/State";
import Skills from "../../CommonCode/Skills";
import SkillsEdit from "../../CommonCode/SkillsEditJob";
import SimpleBackdrop from "../../CommonCode/SimpleBackdrop";
import {
  errorNotify,
  succesNotify,
  useStyles,
  warningNotify,
} from "../../CommonCode/Commonfunc";

import Gender from "../../CommonCode/Gender";
import MenuItem from "@mui/material/MenuItem";
import EmployerSideMenu from "../EmployerComponents/EmployerSideMenu";
import OfficePresence from "../../CommonCode/OfficePresence";
import { axiosAPI } from "../../Axios/Axios";
import EmployerNavbar from "../EmployerComponents/EmployerNavbar";
import { redirect, useNavigate, useParams } from "react-router-dom";
import { format, addDays } from "date-fns";
import SelectJobType from "../../CommonCode/SelectJobType";
import Education from "../../CommonCode/Education";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import YesNo from "../../CommonCode/YesNo";
import FormControl from "@mui/material/FormControl";
import { useDispatch, useSelector } from "react-redux";
import InputWithLabel from "../../Components/InputWithLabel";
import AllLanguage from "../../CommonCode/AllLanguage";
import LanguageLevel from "../../CommonCode/LanguageLevel";
import Weeks from "../../CommonCode/Weeks";
import JobCategoryEditJob from "../../CommonCode/JobcategoryEditJob";
import EducationEditJob from "../../CommonCode/EducationEditJob";
import { ElectricScooterSharp, Label } from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import JobInfo from "../../CommonCode/JobInformation";
import WorkHour from "../../CommonCode/WorkingHour";
import Currency from "../../CommonCode/Currency";
import CompanyCountry from "../../CommonCode/CompanyCountries";
import JobPostingDuration from "../../CommonCode/JobDuration";
import SalaryPeriod from "../../CommonCode/SalaryPeriod";
import Percentage from "../../CommonCode/Percentage";
import CompanyBranchAddress from "../../CommonCode/CompanyBranchs";
import AddedCompanies from "../../CommonCode/AddedCompanies";
import ExperienceLevel from "../../CommonCode/ExperienceLevel";
import { profanityfn, profanitySend } from "../../Profanity/Profanity";
import { createNewLoginDetails } from "../../../redux/CreateLoginDetailSlice";
// import SalaryPeriod from" ../../CommonCode/SalaryPeriod";

import ReactQuill from "react-quill";

import SalaryCalculatorModal from "./SalaryCalculator";

export default function EditJob({
  value,
  api,
  setValue,
  placeHolder,
  selectedValue,
  style,
  newValue,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const Labels = useSelector((state) => state.allLabels.labels);

  const Userdata = useSelector((state) => state.createLoginDetails);

  const classes = useStyles();

  const { id } = useParams();

  const [isButtonDisable, setButtonDisable] = useState(true);
  const [country, setcountry] = useState(null);
  const [state, setState] = useState(null);

  const [gender, setgender] = useState("");
  const [addressData, setAddressData] = useState(null);
  const [qualification, setqualification] = useState({ id: "", name: "" });

  const [jobacate, setjobcate] = useState({
    id: "",
    categoryName: "",
    categoryIcon: "",
    jobs: "",
  });

  const [jobtype, setjobtype] = useState("");

  const [jobRequiredLanguages, setjobRequiredLanguages] = useState([]);
  const [btntext, setbtntext] = useState(Labels.Add);
  const [editindex, seteditindex] = useState(null);

  const [FilterLanguage1, setFilterLanguage1] = useState("");

  const [jobRequiredLanguages1, setjobRequiredLanguages1] = useState([]);
  const [languageName1, setLanguageName1] = useState("1"); // English
  const [language1, setLanguage1] = useState(""); // id

  const [spoken, setLanguageReadLevel] = useState("");
  const [written, setLanguageWriteLevel] = useState("");
  const [languageName, setLanguageName] = useState("1"); // English
  const [Rlevel, setRLevel] = useState("");
  const [Wlevel, setWLevel] = useState("");
  const [skills, setskills] = useState([{ id: "", name: "", type: "" }]);

  const [language, setLanguage] = useState("");

  const [isRemote, setisRemote] = useState(false);
  const [remote, setRemote] = useState("International");
  const [isHybrid, setisHybrid] = useState(false);
  const [hybrid, setHybrid] = useState("National");

  const [radius, setRadius] = useState("");
  const [jobDetails, setJobDetails] = useState({});

  const [jobRequireTravel, setJobRequireTravel] = useState(1);
  const [travelPercentage, setTravelPercentage] = useState("");
  const [willingToRelocate, setRelocate] = useState("");

  const [receiveCoverLetter, setExpectCoverLetter] = useState("");
  const [showProfile, setShowProfile] = useState("");
  const [contactBymail, setShowEmail] = useState("");

  const [companyName, setcompayname] = useState("");

  const [jobinfo, setjobinfo] = useState([]);
  const [jobinformation, setjobinformation] = useState([]);
  const [AdressId, setAdressId] = useState(null);
  const [Adress, setAdress] = useState(null);
  const [salaryPeriod, setsalaryPeriod] = useState(null);
  const [officePercent, setofficePercent] = useState(null);
  const [CompanycountryID, setCompanycountryID] = useState(null);

  const [JobPostDuration, setJobPostDuration] = useState("");
  const [currency, setCurrency] = useState([]);

  // const [travel, setTravel] = useState([]);

  const [image, setImage] = useState("");

  const maxFileSize = 3145728;

  const [allCompanies, setAllCompanies] = useState([]);
  const [FilterLanguage, setFilterLanguage] = useState("");
  // const [Qualification2, setQualification2] = useState([]);
  const [userExperienceLevel, setUserExperienceLevel] = useState("");
  // const [travel, setTravel] = useState([]);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [Workinghour, setWorkinghour] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const [typePercentage, settypepercentage] = useState(null);

  // For new client change - 14-06-24 - vis
  const [officePresence, setOfficePresence] = useState(8);
  const [officePercentage, setofficePercentage] = useState(100); // Old Value
  const [remotePercentage, setremotePercentage] = useState(0); // New Value
  const [countryValue, setcountryValue] = useState(1); // New Value
  const [continentValue, setcontinentValue] = useState(1); // New Value
  const [radiusValue, setradiusValue] = useState(0); // New Value
  const [stateID, setstateID] = useState(""); // New Value
  const [cityvalue, setcityvalue] = useState(""); // New Value
  // For new client change - 14-06-24 - vis

  const [logoPath, setlogoPath] = useState("");
  const employerTypeName = localStorage.getItem("employerType");

  const tokens = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("employerToken"),
    },
  };

  const [errMsg, setErrMsg] = useState({
    spokenErr: false,
    writtenErr: false,
    languageErr: false,
  });
  const [errMsg1, setErrMsg1] = useState({
    languageErr1: false,
  });

  useEffect(() => {
    dispatch(createNewLoginDetails());
  }, []);

  const setView = (e) => {};

  const [salaryMax, setSalaryMax] = useState("");

  const [yearlysalary, setyearlysalary] = useState("");
  const [yearlysalary2, setyearlysalary2] = useState("");

  const [salarycalcuvalue, setsalarycalcuvalue] = useState({});

  const [jobdescription, setjobdescription] = useState("<p><br></p>");

  const {
    jobName,
    description,
    noOfVacancies,
    applicationLastDate,
    city,
    travel,
    salary,
    benefits,
    experianceYears,
    whatYouWillDo,
    workingHours,
    companyLink,
    jobtitle,
    jobdesc,
    // typePercentage,
    certifications,
    additionalQualification,
  } = jobDetails;

  const currentDate = new Date();
  const ProfanityList = useSelector((state) => state.Profanity.wordlist);

  // ---------Get Job Details---------

  const [backupdata, setbackupdata] = useState({});

  const getjobdetails = async () => {
    setBackdrop(true);
    if (id) {
      const result = await axiosAPI.post(`/getJobInfo?&&jobid=${id}`);
      const { success, data, message } = result.data;
      if (success === true) {
        setBackdrop(false);
        setjobcate({
          id: data.jobCategory,
          categoryName: "",
          categoryIcon: "",
          jobs: 0,
        });

        setsalarycalcuvalue(data?.salaryCalucateitem);

        setyearlysalary(data?.anualSalaryMin ? data?.anualSalaryMin : "");
        setyearlysalary2(data?.anualsalaryMax ? data?.anualsalaryMax : "");

        setskills(
          data.jobSkills.map((skill) => ({
            id: skill.id,
            name: skill.skillName,
            type: "skill",
          }))
        );

        setQuestiondata(data?.Jobinformations);

        data?.Jobinformations.forEach((info) => {
          if (info.key === "others") {
            try {
              const dynamicFields = JSON.parse(info.value); // Parse the stringified JSON
              setDynamicFields(dynamicFields); // Store the parsed value in setDynamicFields
            } catch (error) {
              console.error("Error parsing 'others' value: ", error);
            }
          }
        });
        setSalaryMax(data?.salaryMax);
        setofficePercentage(data?.officePercentage);
        setremotePercentage(data?.remotePercentage);
        setcountryValue(data?.withinCountry);
        setcontinentValue(data?.withinContinents);
        setradiusValue(data?.jobRadious);
        setOfficePresence(data?.officePresence);
        setUserExperienceLevel(data?.experianceYears);
        setJobDetails(data);
        setgender(data?.genderId);
        setcountry(
          data?.countryID ? { id: data.countryID, name: data.country } : null
        );
        setState(data?.stateID ? { id: data.stateID, name: data.state } : null);
        setAdress({ name: data?.companyAddress });
        setqualification({
          id: data?.qualifications[0]?.educationID,
          name: data?.qualifications[0]?.educationName,
        });
        setjobtype(data?.jobTypes);
        settypepercentage(
          data?.jobTypes == 2
            ? 10
            : data?.typePercentage
            ? data?.typePercentage
            : 10
        );
        setjobRequiredLanguages(data?.languages);
        setjobRequiredLanguages1(data?.additionalLanguage);
        setShowProfile(data ? data.showProfile : "");
        setExpectCoverLetter(data ? data.receiveCoverLetter : "");
        setShowEmail(data ? data.contactBymail : "");
        setlogoPath(data?.companyLogo);
        setsalaryPeriod(data?.salaryPeriod);
        setCurrency(data?.currency);
        setJobPostDuration(data?.JobPostDuration ? data?.JobPostDuration : "");
        setOfficePresence(data?.officePresence);
        setWorkinghour(data?.workingDuration);
        setJobRequireTravel(
          data?.jobRequireTravel ? data?.jobRequireTravel : ""
        );
        setTravelPercentage(
          data?.travelPercentage ? data?.travelPercentage : 0
        );
        setcompayname(data?.companyId);

        setRelocate(
          data?.willingToRelocate
            ? data?.willingToRelocate
            : data?.willingToRelocate === 0
            ? 0
            : ""
        );

        // salary
        // salaryMax
        // currency
        // salaryPeriod
        // Workinghour

        // salarycalcuvalue

        // yearlysalary
        // yearlysalary2
        setjobdescription(
          data?.jobdescription ? data?.jobdescription : "<p><br></p>"
        );
        setbackupdata({
          country: data?.countryID
            ? { id: data.countryID, name: data.country }
            : null,
          Adress: { name: data?.companyAddress },
          companyName: data?.companyId,
          jobName: data?.jobName,
          jobacate: {
            id: data.jobCategory,
            categoryName: "",
            categoryIcon: "",
            jobs: 0,
          },
          officePercentage: data?.officePercentage,
          remotePercentage: data?.remotePercentage,

          countryValue: data?.withinCountry,
          continentValue: data?.withinContinents,
          jobRadious:data?.jobRadious,

          jobtype: data?.jobTypes,
          typePercentage:
            data?.jobTypes == 2
              ? 10
              : data?.typePercentage
              ? data?.typePercentage
              : 10,
          Workinghour: data?.workingDuration,
          workingHours: data?.workingHours,
          salary: data?.salary ? data?.salary : 0,
          salaryMax: data?.salaryMax ? data?.salaryMax : 0,
          currency: data?.currency,
          salaryPeriod: data?.salaryPeriod,
          showProfile: data?.showProfile,
          contactBymail: data.contactBymail,
          receiveCoverLetter: data.receiveCoverLetter,
          JobPostDuration: data?.JobPostDuration,
          jobRequiredLanguages: data?.languages,
          jobRequiredLanguages1: data?.additionalLanguage,
          userExperienceLevel: data?.experianceYears,
          certifications: data?.certifications,
          jobRequireTravel:
            data?.jobRequireTravel != null ? data?.jobRequireTravel : "",
          travelPercentage: data?.travelPercentage,
          skills: data.jobSkills.map((skill) => ({
            id: skill.id,
            name: skill.skillName,
            type: "skill",
          })),

          isRemote:
            data?.officePercentage != null && data?.remotePercentage != null
              ? data.officePercentage > 0 &&
                data.officePercentage < 100 &&
                data.remotePercentage > 0 &&
                data.remotePercentage < 100
                ? false
                : data.officePercentage === 0 && data.remotePercentage === 100
                ? true
                : data.officePercentage === 100 && data.remotePercentage === 0
                ? false
                : data.officePercentage === ""
                ? true
                : data.remotePercentage === ""
                ? false
                : false
              : false,

          additionalQualification: data?.additionalQualification,
          Questiondata: data?.Jobinformations,
          dynamicFields: data?.Jobinformations?.find(
            (item) => item.key === "others"
          )?.value
            ? JSON.parse(
                data.Jobinformations.find((item) => item.key === "others").value
              )
            : [],
          jobdescription: data?.jobdescription
            ? data?.jobdescription
            : `<p><br></p>`,

          // dynamicFields: data?.Jobinformations.forEach((info) => {
          //   if (info.key == "others") {
          //    return JSON.parse(info.value)
          //   }
          // }),
        });
      } else {
        setBackdrop(false);
        navigate("/employerJobList");
      }
    } else {
      setBackdrop(false);
      navigate("/employerJobList");
    }
  };

  useEffect(() => {
    getjobdetails();
  }, []);

  useEffect(() => {
    setJobDetails({
      ...jobDetails,
      salaryMax: salaryMax,
    });
  }, [salaryMax]);

  const Createjobads = (e) => {
    if (e.target.name == "workingHours") {
      const numericValue = e.target.value;

      if (Workinghour == 5 && numericValue <= 744) {
        setJobDetails({
          ...jobDetails,
          [e.target.name]: profanityfn(ProfanityList, numericValue),
        });
      } else if (Workinghour == 6 && numericValue <= 168) {
        setJobDetails({
          ...jobDetails,
          [e.target.name]: profanityfn(ProfanityList, numericValue),
        });
      } else if (Workinghour == 7 && numericValue <= 8760) {
        setJobDetails({
          ...jobDetails,
          [e.target.name]: profanityfn(ProfanityList, numericValue),
        });
      } else if (Workinghour == 8 && numericValue <= 24) {
        setJobDetails({
          ...jobDetails,
          [e.target.name]: profanityfn(ProfanityList, numericValue),
        });
      }
    } else {
      setJobDetails({
        ...jobDetails,
        [e.target.name]: profanityfn(ProfanityList, e.target.value),
      });
    }

    setError(false);
  };

  const [viewSelect, setviewSelect] = useState(false);
  const [backdropOpen, setBackdrop] = useState(false);

  useEffect(() => {
    // change item.name to item.namekey later after api change - vis
    if (officePercentage != null && remotePercentage != null) {
      if (
        officePercentage > 0 &&
        officePercentage < 100 &&
        remotePercentage > 0 &&
        remotePercentage < 100
      ) {
        setisHybrid(true);
        setisRemote(false);
        setOfficePresence(6);
        // Hybrid
      } else if (officePercentage == 0 && remotePercentage == 100) {
        setisHybrid(false);
        setisRemote(true);
        // Remote
        setOfficePresence(7);
      } else if (officePercentage == 100 && remotePercentage == 0) {
        setisHybrid(false);
        setisRemote(false);
        // Office
        setcountryValue(1);
        setcontinentValue(1);
        setradiusValue(0);
        setOfficePresence(8);
      }
    }
    if (officePercentage == "") {
      setremotePercentage("100");
      setisHybrid(false);
      setisRemote(true);
      setOfficePresence(7);
    }
    if (remotePercentage == "") {
      setofficePercentage("100");
      setisHybrid(false);
      setisRemote(false);
      setOfficePresence(8);
    }
  }, [officePercentage, remotePercentage, officePresence]);

  useEffect(() => {
    if (officePercentage == 100 && remotePercentage == 0) {
      setcountryValue(1);
      setcontinentValue(1);
    } else {
      if (continentValue == 0) {
        setcountryValue(0);
      }
    }
  }, [continentValue, countryValue]);

  const setradiusfn = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && Number(value) >= 0) {
      setradiusValue(value);
    }
  };

  const setofficePercentagefn = (e) => {
    let inputValue = e.target.value;
    if (/^\d*$/.test(inputValue)) {
      if ((inputValue >= 0 && inputValue <= 100) || inputValue == "") {
        setofficePercentage(inputValue);
        setremotePercentage((100 - inputValue).toString());
      }
    } else {
      setofficePercentage("");
      // warningNotify(Labels.Officepercentagevalueshouldbeanumber);
    }
  };

  const setremotePercentagefn = (e) => {
    let inputValue = e.target.value;
    if (/^\d*$/.test(inputValue)) {
      if ((inputValue >= 0 && inputValue <= 100) || inputValue == "") {
        setremotePercentage(inputValue);
        setofficePercentage((100 - inputValue).toString());
      }
    } else {
      setremotePercentage("");
      // warningNotify(Labels.Officepercentagevalueshouldbeanumber);
    }
  };

  const profanityChecker = () => {
    const postdata = {
      jobid: id,
      jobName: jobName,
      address: Adress?.name,
      jobCategory: jobacate?.id?.toString()?.replace(/(^,|,$|,,+)/g, ""),
      workingHours: workingHours,
      experianceYears: experianceYears,
      countryID: country?.id,
      stateID: state?.id,
      city: city,
      language: "en",
      language1: "en",
      additionalLanguage: JSON.stringify(jobRequiredLanguages1),
      expectedLanguage: JSON.stringify(jobRequiredLanguages),
      applicationLastDate: applicationLastDate,
      whatYouWillDo: whatYouWillDo,
      jobRequireTravel: jobRequireTravel,
      willingToRelocate: willingToRelocate,
      travelPercentage: travelPercentage ? travelPercentage : 0,
      //  Needed values______________________________________________________________________________________________________
      officePresence: officePresence,
      officePercentage: officePercentage,
      remotePercentage: remotePercentage,
      withinCountry: countryValue,
      withinContinents: continentValue,
      jobRadious: radiusValue,
      stateID: stateID,
      city: cityvalue,
      //  Needed values______________________________________________________________________________________________________
      salary: jobDetails?.salary?.toString()?.replace(",", ""),
      salaryMax: salaryMax?.toString()?.replace(",", ""),
      receiveCoverLetter: receiveCoverLetter,
      officePercentage: officePercentage !== null ? officePercentage : "",
      showYourProfile: showProfile,
      allowContactByMail: contactBymail,
      JobPostDuration: JobPostDuration,
      officePresence: officePresence,
      companyLink: companyLink,
      companyId: companyName,
      type: "Consultancy",
      jobTypes: jobtype?.toString()?.replace(/(^,|,$|,,+)/g, ""),
      jobSkills: skills?.length
        ? skills
            .map((a) => a.id)
            .toString()
            .replace(/(^,|,$|,,+)/g, "")
        : null,
      certifications: certifications,
      remote: "all",
      currency: currency,
      workingDuration: Workinghour,
      additionalQualification: additionalQualification,
      currency: currency,
      workingHours: workingHours,
      salaryPeriod: salaryPeriod,
      Jobinformations: JSON.stringify(Questiondata),
    };
    const postDataWithOfficePercentage = {
      ...postdata, // or postdata1 depending on your condition
      language: localStorage.getItem("language"),
    };

    let profanityDetected;
    for (const key in postDataWithOfficePercentage) {
      if (profanitySend(ProfanityList, postDataWithOfficePercentage[key])) {
        profanityDetected = true;
        break;
      } else {
        profanityDetected = false;
      }
    }

    if (profanityDetected == false) {
      submitFormData();
    } else {
      warningNotify(Labels.ProfanityLabel);
    }
  };

  const submitFormData = async (e) => {
    setButtonDisable(true);
    if (!jobName) {
      setButtonDisable(false);
      errorNotify(Labels.jobTitlerequired);
      return;
    } else if (jobName.length > 200) {
      setButtonDisable(false);
      errorNotify(Labels.jobTitleValidation);
      return;
    }
    // else if (!jobacate || jobacate === "") {
    //   setButtonDisable(false);
    //   errorNotify(Labels.jobCategoryRequired);
    //   return;
    // }
    else if (!country?.id) {
      errorNotify(Labels.countryRequired);
      setButtonDisable(false);
      return;
    } else if (yearlysalary > yearlysalary2) {
      errorNotify(
        Labels.yearlysalaryValidation ||
          "Minimum Salary cannot be more than maximum salary."
      );
      setButtonDisable(false);
      return;
    }
    {
      const postdata = {
        jobid: id,
        jobName: jobName,
        address: Adress?.name,
        jobCategory: jobacate?.id?.toString()?.replace(/(^,|,$|,,+)/g, ""),
        workingHours: workingHours,
        experianceYears: experianceYears,
        countryID: country?.id,
        stateID: state?.id,
        city: city,
        language: "en",
        language1: "en",
        additionalLanguage: JSON.stringify(jobRequiredLanguages1),
        expectedLanguage: JSON.stringify(jobRequiredLanguages),
        applicationLastDate: applicationLastDate,
        whatYouWillDo: whatYouWillDo,
        jobRequireTravel: jobRequireTravel,
        willingToRelocate: willingToRelocate,
        travelPercentage: travelPercentage ? travelPercentage : 0,
        //  Needed values______________________________________________________________________________________________________
        officePresence: officePresence,
        officePercentage: officePercentage,
        remotePercentage: remotePercentage,
        withinCountry: countryValue,
        withinContinents: continentValue,
        jobRadious: radiusValue,
        stateID: stateID,
        city: cityvalue,
        //  Needed values______________________________________________________________________________________________________
        receiveCoverLetter: receiveCoverLetter,
        officePercentage: officePercentage !== null ? officePercentage : "",
        showYourProfile: showProfile,
        allowContactByMail: contactBymail,
        JobPostDuration: JobPostDuration,
        officePresence: officePresence,

        experianceYears: userExperienceLevel,
        companyLink: companyLink,
        companyId: companyName,
        type: "Consultancy",

        jobTypes: jobtype?.toString().replace(/(^,|,$|,,+)/g, ""),
        // typePercentage: typePercentage,

        typePercentage:
          jobtype == 2 ? 10 : typePercentage ? typePercentage : 10,

        jobSkills: skills?.length
          ? skills
              .map((a) => a.id)
              .toString()
              .replace(/(^,|,$|,,+)/g, "")
          : "",
        certifications: certifications,
        remote: "all",
        workingDuration: Workinghour,
        additionalQualification: additionalQualification,
        currency: currency,
        workingHours: workingHours,
        salary: jobDetails?.salary
          ? jobDetails?.salary?.toString()?.replace(",", "")
          : 0,
        salaryMax: salaryMax ? salaryMax?.toString()?.replace(",", "") : 0,
        salaryCalucateitem: salarycalcuvalue,

        anualSalaryMin: yearlysalary ? yearlysalary : "",
        anualsalaryMax: yearlysalary2 ? yearlysalary2 : "",

        salaryPeriod: salaryPeriod,
        currency: currency,
        Jobinformations: JSON.stringify(Questiondata),

        description: jobdescription,
      };

      const postDataWithOfficePercentage = {
        ...postdata, // or postdata1 depending on your condition
        language: localStorage.getItem("language"),
      };

      await axiosAPI
        .post("/updateJob", postDataWithOfficePercentage)
        .then((response) => {
          const { success, message, data } = response.data;
          if (success === true) {
            setButtonDisable(false);
            succesNotify(message);
            setTimeout(() => {
              navigate("/employerJobList");
            }, 1000);
          } else {
            if (message === "Validation Error.") {
              setButtonDisable(false);
              errorNotify(Labels.allFieldsMandatory);
            } else {
              setButtonDisable(false);
              errorNotify(message);
            }
          }
        })
        .catch((error) => {
          setButtonDisable(false);
        });
    }
  };

  const [Questiondata, setQuestiondata] = useState([]);

  const handleChange = (id, newValue) => {
    setQuestiondata((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, value: newValue } : item
      )
    );
  };

  const [dynamicFields, setDynamicFields] = useState([]);

  const handleDynamicChange = (index, field, value) => {
    if (value) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }

    const updatedFields = [...dynamicFields];
    updatedFields[index][field] = value;
    setDynamicFields(updatedFields);

    const updatedQuestionData = Questiondata.map((item) => {
      if (item.key === "others") {
        return { ...item, value: JSON.stringify(updatedFields) };
      }
      return item;
    });

    setQuestiondata(updatedQuestionData);
  };

  const addNewField = () => {
    setDynamicFields([...dynamicFields, { question: "", answer: "" }]);
    setButtonDisable(true);
  };

  const removeField = (index) => {
    setButtonDisable(false);
    const updatedFields = dynamicFields.filter((_, i) => i !== index);
    setDynamicFields(updatedFields);

    const updatedQuestionData = Questiondata.map((item) => {
      if (item.key === "others") {
        return { ...item, value: JSON.stringify(updatedFields) };
      }
      return item;
    });

    setQuestiondata(updatedQuestionData);
  };

  const setRemoteValue = (e) => {
    setRemote(e.target.value);
    if (e.target.value === "radius") {
      setRadius(0);
    } else {
      setRadius("");
    }
  };

  const setHybridValue = (e) => {
    setHybrid(e.target.value);
    if (e.target.value === "radius") {
      setRadius(0);
    } else {
      setRadius("");
    }
  };

  const Travelchange = (event) => {
    setJobRequireTravel(profanityfn(ProfanityList, event.target.value));
  };

  const addLanguage = () => {
    setTimeout(() => {
      setErrMsg({
        spokenErr: false,
        writtenErr: false,
        languageErr: false,
      });
    }, 2000);
    if (language) {
      if (!spoken) {
        return setErrMsg({ spokenErr: true });
      }
      if (!written) {
        return setErrMsg({ writtenErr: true });
      }
      const data = {
        written: Wlevel, // string w level name
        spoken: Rlevel, // string r level name
        language: languageName, // string

        languageid: language.l_id, // object (all data available)
        writtenid: written.id, // id of written
        spokenid: spoken.id, // id  of spoken
      };

      setjobRequiredLanguages((prevLanguages) => [...prevLanguages, data]);

      setLanguage("");
      setLanguageName("");
      setLanguageReadLevel("");
      setLanguageWriteLevel("");
      setWLevel("");
      setRLevel("");
    } else {
      return setErrMsg({ languageErr: true });
    }
  };

  const removeAddedLanguage = (index) => {
    const updatedLanguages = [...jobRequiredLanguages];
    updatedLanguages.splice(index, 1);
    setjobRequiredLanguages(updatedLanguages);
  };

  const removeAddedJobInfo = (index) => {
    setviewSelect(false);
    setbtntext(Labels.Add);
    const updatedLanguages = [...Questiondata];
    updatedLanguages.splice(index, 1);
    setQuestiondata(updatedLanguages);
  };

  const files = acceptedFiles.map((file) => (
    <div key={file.path}>
      <li>{file.path}</li>
      <img src={URL.createObjectURL(file)} alt={file.name} width="50px" />
    </div>
  ));

  // ----------------------------nicev to have language----------------------
  const addLanguage1 = () => {
    setTimeout(() => {
      setErrMsg1({
        languageErr1: false,
      });
    }, 2000);
    if (language1) {
      const data = {
        language1: languageName1, // string

        languageid1: language1.l_id, // object (all data available)
      };

      setjobRequiredLanguages1((prevLanguages1) => [...prevLanguages1, data]);
      setLanguage1("");
      setLanguageName1("");
    } else {
      return setErrMsg1({ languageErr1: true });
    }
  };

  const removeAddedLanguage1 = (index) => {
    const updatedLanguages1 = [...jobRequiredLanguages1];
    updatedLanguages1.splice(index, 1);
    setjobRequiredLanguages1(updatedLanguages1);
  };

  useEffect(() => {
    if (Questiondata?.length == 0) {
      setbtntext(Labels.Add);
      setJobDetails({ ...jobDetails, jobdesc: "" });
      setjobinfo("");
    }
  }, [Questiondata]);

  const [error, setError] = useState(false);

  const addinfo = () => {
    setbtntext(Labels.Add);
    if (jobinfo.length === 0 || jobdesc?.length === 0) {
      errorNotify(Labels.PleaseselectaJobInformation);
    } else {
      if (jobdesc !== undefined) {
        const data = {
          id: jobinfo.id,
          key: jobinfo.key,
          name: jobinfo.name,
          value: jobdesc,
        };

        if (editindex !== null) {
          const updatedLanguages = [...jobinformation];
          updatedLanguages.splice(editindex, 1, data);
          setjobinformation(updatedLanguages);
          seteditindex(null);
          setjobinfo("");
          setJobDetails({ ...jobDetails, jobdesc: "" });
          succesNotify(Labels.JobInformationEdited);
        } else {
          setjobinformation((prevInformation) => [...prevInformation, data]);
          setjobinfo("");
          setJobDetails({ ...jobDetails, jobdesc: "" });
          succesNotify(Labels.JobInformationAdded);
        }
      } else {
        errorNotify(Labels.PleaseselectaJobInformation);
      }
    }
  };

  const handleSelectedNameChange = (name) => {
    setSelectedName(name.name);
    if (name.id == 8) {
      setofficePercentage("100");
    }
    if (name.id == 7) {
      setofficePercentage("0");
    }
  };
  const [cropData, setCropData] = useState("");
  const [selectImage, setSelectImage] = useState(false);
  const [imagename, setImagename] = useState(null);
  const cropperRef = createRef();
  const [openImage, setOpenImage] = useState(false);

  const onChange = (e) => {
    e.preventDefault();
    setOpenImage(false);
    setSelectImage(true);
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files[0];
    }
    setImagename(files);
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files);
  };

  const handleUpload = async () => {
    // if (typeof cropperRef.current?.cropper !== "undefined") {
    const croppedDataURL = cropperRef.current.cropper
      .getCroppedCanvas()
      .toDataURL();
    // setCropData(croppedDataURL);
    // setSelectImage(false);
    // uploadProfilePicture(croppedDataURL);
    uploadProfilePicture(croppedDataURL, imagename);
    // }
  };

  const uploadProfilePicture = async (manipulatedDataURL, imagename) => {
    const formData = new FormData();
    const blob = await fetch(manipulatedDataURL).then((r) => r.blob());
    formData.append("images", blob);

    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/jpg",
      "image/webp",
    ];
    const maxFileSize = 3145728;

    if (!allowedTypes.includes(blob.type)) {
      // throw new Error(Labels.invaildFile);
      return errorNotify(Labels.invaildFile);
    }
    if (blob.size > maxFileSize) {
      // throw new Error(Labels.imageValidation);
      return errorNotify(Labels.imageValidation);
    }
    const blob2 = formData.get("images"); // Replace 'yourBlobField' with the actual field name

    // Create a proper File from the Blob
    const fileName = imagename.name; // Replace with your desired file name
    const mimeType = imagename.type; // Replace with your desired MIME type

    const file = new File([blob2], fileName, { type: mimeType });

    const formData2 = new FormData();
    formData2.append("images", file);
    formData2.append("language", localStorage.getItem("language"));

    try {
      const response = await axiosupload.post("/addjob", formData2, tokens);
      const { status, message } = response.data;
      if (status === "successs") {
        succesNotify(message);
        setSelectImage(false);
        setOpenImage(false);
        // getCompanyData();
        // setApiCall(Math.random());
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  useEffect(() => {
    if (Object.keys(backupdata).length > 0) {
      // console.log( "Matching country:",JSON.stringify(backupdata.country) === JSON.stringify(country), backupdata?.country, country);
      // console.log( "Matching Adress:",JSON.stringify(backupdata?.Adress?.name?.replace(" ", "")) ===  JSON.stringify(Adress?.name ? Adress?.name?.replace(" ", "") : ""),backupdata.Adress?.name?.replace(" ", ""),Adress?.name ? Adress?.name?.replace(" ", "") : "");
      // console.log( "Matching companyName:", backupdata.companyName === companyName, backupdata.companyName, companyName );
      // console.log( "Matching jobName:", backupdata.jobName === jobName, backupdata.jobName, jobName );
      // console.log( "Matching jobacate:", JSON.stringify(backupdata.jobacate) === JSON.stringify(jobacate), backupdata?.jobacate, jobacate );
      // console.log( "Matching officePercentage:", backupdata.officePercentage === officePercentage, backupdata?.officePercentage, officePercentage );
      // console.log( "Matching remotePercentage:", backupdata.remotePercentage === remotePercentage, backupdata?.remotePercentage, remotePercentage );
      // console.log( "Matching countryValue:", backupdata.countryValue == countryValue, backupdata?.countryValue, countryValue );
      // console.log( "Matching continentValue:",backupdata.continentValue == continentValue,backupdata.continentValue,continentValue );
      // console.log( "Matching isRemote:",backupdata.isRemote === isRemote,backupdata.isRemote,isRemote );
      // console.log( "Matching jobtype:",JSON.stringify(backupdata?.jobtype || "") === JSON.stringify(jobtype || ""),backupdata.jobtype,jobtype );
      // console.log( "Matching typePercentage:", backupdata.typePercentage == typePercentage, backupdata.typePercentage, typePercentage );
      // console.log( "Matching Workinghour:",  backupdata.Workinghour === Workinghour,  backupdata.Workinghour,  Workinghour );
      // console.log( "Matching workingHours:", backupdata.workingHours === workingHours, backupdata.workingHours, workingHours );
      // console.log( "Matching salary:", (backupdata.salary || "") == (salary || ""), backupdata.salary, salary);
      // console.log( "Matching currency:", backupdata.currency === currency, backupdata.currency, currency);
      // console.log( "Matching salaryPeriod:", backupdata.salaryPeriod === salaryPeriod, backupdata.salaryPeriod, salaryPeriod);
      // console.log( "Matching showProfile:", backupdata.showProfile === showProfile, backupdata.showProfile, showProfile);
      // console.log( "Matching contactBymail:", backupdata.contactBymail === contactBymail, backupdata.contactBymail, contactBymail);
      // console.log( "Matching receiveCoverLetter:", backupdata.receiveCoverLetter === receiveCoverLetter, backupdata.receiveCoverLetter, receiveCoverLetter);
      // console.log( "Matching JobPostDuration:", backupdata.JobPostDuration === JobPostDuration, backupdata.JobPostDuration, JobPostDuration);
      // console.log( "Matching jobRequiredLanguages:", JSON.stringify(backupdata.jobRequiredLanguages) ===JSON.stringify(jobRequiredLanguages), backupdata.jobRequiredLanguages, jobRequiredLanguages);
      // console.log( "Matching jobRequiredLanguages1:",  JSON.stringify(backupdata.jobRequiredLanguages1) ===  JSON.stringify(jobRequiredLanguages1),  backupdata.jobRequiredLanguages1,  jobRequiredLanguages1);
      // console.log( "Matching userExperienceLevel:",backupdata.userExperienceLevel === userExperienceLevel,backupdata.userExperienceLevel,userExperienceLevel);
      // console.log( "Matching certifications:",  JSON.stringify(backupdata.certifications) === JSON.stringify(certifications), backupdata.certifications, certifications);
      // console.log( "Matching jobRequireTravel:", backupdata.jobRequireTravel === jobRequireTravel, backupdata.jobRequireTravel, jobRequireTravel);
      // console.log( "Matching travelPercentage:",  backupdata.travelPercentage === travelPercentage,  backupdata.travelPercentage,  travelPercentage);
      // console.log( "Matching skills:",  JSON.stringify(backupdata.skills) === JSON.stringify(skills),  backupdata.skills,  skills);
      // console.log( "Matching Questiondata:",  JSON.stringify(backupdata.Questiondata) === JSON.stringify(Questiondata),  backupdata.Questiondata,  Questiondata);
      // console.log( "Matching dynamicFields:",  backupdata.dynamicFields != null || backupdata.dynamicFields != undefined ? JSON.stringify(backupdata.dynamicFields) ===  JSON.stringify(dynamicFields): JSON.stringify([]) === JSON.stringify(dynamicFields),  backupdata.dynamicFields,  dynamicFields );
      // console.log( "Matching additionalQualification:",backupdata.additionalQualification? backupdata.additionalQualification: 1,  additionalQualification ? additionalQualification : 1);
      // console.log( "jobdescription", jobdescription == backupdata?.jobdescription, jobdescription, backupdata?.jobdescription );

      const allMatch =
        JSON.stringify(backupdata?.country) === JSON.stringify(country) &&
        JSON.stringify(backupdata?.Adress?.name?.replace(" ", "")) ==
          JSON.stringify(Adress?.name?.replace(" ", "")) &&
        backupdata.companyName === companyName &&
        backupdata.jobName === jobName &&
        JSON.stringify(backupdata.jobacate?.id) ===
          JSON.stringify(jobacate?.id) &&
        backupdata.officePercentage == officePercentage &&
        backupdata.remotePercentage == remotePercentage &&
        backupdata.countryValue == countryValue &&
        backupdata.continentValue == continentValue &&
        backupdata.isRemote === isRemote &&
        JSON.stringify(backupdata?.jobtype || "") ===
          JSON.stringify(jobtype || "") &&
        backupdata.typePercentage == typePercentage &&
        backupdata.Workinghour == Workinghour &&
        backupdata.workingHours == workingHours &&
        (backupdata.salary || "") == (salary || "") &&
        (backupdata.salaryMax || "") == (salaryMax || "") &&
        backupdata.currency == currency &&
        backupdata.salaryPeriod == salaryPeriod &&
        backupdata.showProfile == showProfile &&
        backupdata.contactBymail == contactBymail &&
        backupdata.receiveCoverLetter == receiveCoverLetter &&
        backupdata.JobPostDuration == JobPostDuration &&
        JSON.stringify(backupdata.jobRequiredLanguages) ==
          JSON.stringify(jobRequiredLanguages) &&
        JSON.stringify(backupdata.jobRequiredLanguages1) ==
          JSON.stringify(jobRequiredLanguages1) &&
        backupdata.userExperienceLevel == userExperienceLevel &&
        JSON.stringify(backupdata.certifications) ===
          JSON.stringify(certifications) &&
        backupdata.jobRequireTravel == jobRequireTravel &&
        backupdata.travelPercentage == travelPercentage &&
        JSON.stringify(backupdata.skills) == JSON.stringify(skills) &&
        (backupdata.additionalQualification
          ? backupdata.additionalQualification
          : 1) == (additionalQualification ? additionalQualification : 1) &&
        JSON.stringify(backupdata.Questiondata) ==
          JSON.stringify(Questiondata) &&
        (backupdata.dynamicFields != null ||
        backupdata.dynamicFields != undefined
          ? JSON.stringify(backupdata.dynamicFields) ==
            JSON.stringify(dynamicFields)
          : JSON.stringify([]) === JSON.stringify(dynamicFields)) &&
        jobdescription == backupdata?.jobdescription;

      // Log the result based on the match
      if (allMatch) {
        // console.log("All fields match!");
        setButtonDisable(true);
      } else {
        // console.log("At least one field does not match.");
        setButtonDisable(false);
      }
    }
  }, [
    country,
    Adress,
    companyName,
    jobName,
    jobacate,
    officePercentage,
    remotePercentage,
    countryValue,
    continentValue,
    isRemote,
    jobtype,
    typePercentage,
    Workinghour,
    workingHours,
    salary,
    currency,
    salaryPeriod,
    showProfile,
    contactBymail,
    receiveCoverLetter,
    JobPostDuration,
    jobRequiredLanguages,
    jobRequiredLanguages1,
    userExperienceLevel,
    certifications,
    jobRequireTravel,
    travelPercentage,
    skills,
    additionalQualification,
    Questiondata,
    dynamicFields,
    jobdescription,
  ]);

  const [currencySymbol, setCurrencySymbol] = useState("");

  return (
    <Paper elevation={0} sx={{ my: 0 }}>
      <Typography variant="h6" sx={{ color: "#2B4947", p: 2 }}>
        {Labels?.EditJob}
      </Typography>
      <Divider />
      <Box
        sx={{ p: 2, height: "auto", overflow: "auto" }}
        className={classes.customColoredScrollbar}
      >
        <Grid container spacing={2}>
          <SimpleBackdrop open={backdropOpen} />
          {/* ---------------------------------basic details----------------------------   */}
          <Grid item xs={12} xl={6}>
            <Grid container spacing={1}>
              {/* Logo Commented on 27-11-24 */}

              {/* <Grid item xs={12} sm={3} md={3} lg={2} xl={3}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center", // Center horizontally
                    alignItems: "center", // Center vertically
                    height: "100%", // Ensure the parent box takes up the full height
                    minHeight: "130px", // Set minimum height as per your requirement
                  }}
                >
                  {logoPath?.endsWith("/") ? null : (
                    <Avatar
                      sx={{
                        width: 110,
                        height: 130,
                        borderRadius: 1,
                        cursor: "pointer",
                        marginRight: "25px", // Adjust margin as needed
                      }}
                      src={logoPath}
                      onClick={() => setOpenImage(true)}
                    />
                  )}
                </Box>
                <Dialog
                  open={selectImage}
                  onClose={() => setSelectImage(false)}
                >
                  <DialogTitle>
                    <Stack direction="row" justifyContent="flex-end">
                      <Close
                        sx={{ cursor: "pointer" }}
                        onClick={() => setSelectImage(false)}
                      />
                    </Stack>
                  </DialogTitle>
                  <DialogContent>
                    <Box
                      sx={{
                        width: {
                          xs: "60%",
                          sm: "50%",
                          md: "60%",
                          lg: "80%",
                        },
                        height: {
                          xs: "60%",
                          sm: "50%",
                          md: "60%",
                          lg: "80%",
                        },
                      }}
                    >
                      <Cropper
                        ref={cropperRef}
                        style={{ height: "100%", width: "100%" }}
                        zoomTo={0.5}
                        initialAspectRatio={1}
                        preview=".img-preview"
                        src={image}
                        viewMode={1}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false}
                        guides={true}
                      />
                    </Box>
                  </DialogContent>
                  <DialogActions sx={{ padding: "25px" }}>
                    <CustomButton
                      onClick={handleUpload}
                      label={Labels.Upload}
                      style={{ padding: "5px 18px" }}
                    />
                  </DialogActions>
                </Dialog>
              </Grid> */}

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {/* <Grid item xs={12} sm={9} md={9} lg={10} xl={9}> */}
                <Paper elevation={0} sx={{ my: 1 }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      p: 1.5,
                      background: "#DEF0EA",
                      borderRadius: "5px",
                      border: "1px solid #ecf4f1",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: "18px",
                        fontWeight: 500,
                      }}
                    >
                      {Labels?.Basicdetails}
                    </Typography>
                  </Stack>
                </Paper>

                {/* ------------------------------ Country ------------------------------- */}
                <Grid item xs={12} xl={12} my={2}>
                  <Box sx={{ m: "10px 3px" }}>
                    <label>
                      {Labels?.Country}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <CompanyCountry
                      backup={backupdata?.country}
                      value={country}
                      setValue={setcountry}
                      setAdressId={setAdress}
                      setAddressData={setAddressData}
                      setButtonDisable={setButtonDisable}
                    />
                  </Box>
                </Grid>
                {/* ------------------------------ Address ------------------------------   */}
                <Grid item xs={12} lg={12}>
                  <span style={{ fontSize: "14px" }}>{Labels?.Address}</span>
                  {/* <span style={{ color: "red" }}>*</span> */}
                  <CompanyBranchAddress
                    backup={backupdata?.Adress}
                    value={Adress}
                    setValue={setAdress}
                    country={country}
                    setstateID={setstateID}
                    setcityvalue={setcityvalue}
                    setButtonDisable={setButtonDisable}
                  />
                </Grid>
              </Grid>

              {/* --------------------- Company Name -------------------------- */}
              {employerTypeName == "Consultancy" ? (
                <Grid item xs={12} lg={12}>
                  <Box sx={{ m: "10px 5px 0px" }}>
                    <AddedCompanies
                      backup={backupdata.companyName}
                      value={companyName ? companyName : ""}
                      setValue={setcompayname}
                      setView={setView}
                      setButtonDisable={setButtonDisable}
                    />
                  </Box>
                </Grid>
              ) : null}

              {/* -------------------------------------- Job title --------------------------------- */}
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                sx={{ margin: "0px 0px 10px" }}
              >
                <label style={{ position: "relative", bottom: "1px" }}>
                  {Labels?.JobTitle} <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  onChange={(e) => {
                    Createjobads(e);
                    setButtonDisable(false);
                  }}
                  value={jobName}
                  placeholder={Labels?.JobTitle}
                  name="jobName"
                  fullWidth
                  sx={{
                    position: "relative",
                    left: "5px",
                    width: "98%",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "4px", // Adjust border radius if needed
                      border: `2px solid ${
                        backupdata?.jobName == jobName ? "black" : "green"
                      }`,
                      "& fieldset": {
                        border: "none", // Remove the default border
                      },
                      "&:hover": {
                        border: `2px solid ${
                          backupdata?.jobName == jobName ? "black" : "green"
                        }`,
                      },
                      "&.Mui-focused": {
                        border: `2px solid ${
                          backupdata?.jobName == jobName ? "black" : "green"
                        }`,
                      },
                    },
                  }}
                  inputProps={{
                    sx: {
                      height: "40px",
                      padding: `0px`,
                      paddingLeft: "10px",
                      backgroundColor: !jobName ? "#fadde1" : "white",
                    },
                  }}
                />
              </Grid>

              {/* --------------------- jobCategory --------------------------  */}
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box sx={{ m: "0px 5px 10px" }}>
                  <label>
                    {Labels?.JobCategory}
                    {/* <span style={{ color: "red" }}>* </span> */}
                  </label>

                  <JobCategory
                    backup={backupdata?.jobacate}
                    value={jobacate ? jobacate : ""}
                    setValue={setjobcate}
                    setButtonDisable={setButtonDisable}
                  />
                </Box>
              </Grid>

              {/* --------------------- Office presence % -------------------------- */}

              {/* In Office % */}
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box sx={{ m: "0px 5px 10px" }}>
                  <label style={{ position: "relative", bottom: "1px" }}>
                    {Labels.InOffice}
                  </label>
                  <TextField
                    onChange={(e) => {
                      setofficePercentagefn(e);
                      setButtonDisable(false);
                    }}
                    value={officePercentage}
                    placeholder={Labels.InOffice}
                    fullWidth
                    sx={{
                      position: "relative",
                      width: "101%",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "4px", // Adjust border radius if needed
                        border: `2px solid ${
                          backupdata.officePercentage == officePercentage
                            ? "black"
                            : "green"
                        }`,
                        "& fieldset": {
                          border: "none", // Remove the default border
                        },
                        "&:hover": {
                          border: `2px solid $${
                            backupdata.officePercentage == officePercentage
                              ? "black"
                              : "green"
                          }`,
                        },
                        "&.Mui-focused": {
                          border: `2px solid ${
                            backupdata.officePercentage == officePercentage
                              ? "black"
                              : "green"
                          }`,
                        },
                      },
                    }}
                    inputProps={{
                      sx: {
                        height: "40px",
                        padding: `0px`,
                        paddingLeft: "10px",
                      },
                    }}
                  />
                </Box>
              </Grid>

              {/* Remote % */}
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box sx={{ m: "0px 5px 10px" }}>
                  <label style={{ position: "relative", bottom: "1px" }}>
                    {Labels?.InRemote}
                  </label>
                  <TextField
                    onChange={(e) => {
                      setremotePercentagefn(e);
                      setButtonDisable(false);
                    }}
                    value={remotePercentage}
                    placeholder={Labels?.InRemote}
                    fullWidth
                    sx={{
                      position: "relative",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "4px", // Adjust border radius if needed
                        border: `2px solid ${
                          backupdata.remotePercentage == remotePercentage
                            ? "black"
                            : "green"
                        }`,
                        "& fieldset": {
                          border: "none", // Remove the default border
                        },
                        "&:hover": {
                          border: `2px solid $${
                            backupdata.remotePercentage == remotePercentage
                              ? "black"
                              : "green"
                          }`,
                        },
                        "&.Mui-focused": {
                          border: `2px solid ${
                            backupdata.remotePercentage == remotePercentage
                              ? "black"
                              : "green"
                          }`,
                        },
                      },
                    }}
                    inputProps={{
                      sx: {
                        height: "40px",
                        padding: `0px`,
                        paddingLeft: "10px",
                      },
                    }}
                  />
                </Box>
              </Grid>

              {/* ------------------Hybrid for Office presence---------------------------- */}

              <Grid
                container
                spacing={2}
                sx={{
                  margin: "0px auto",
                  position: "relative",
                  right: "4px",
                  opacity: isRemote ? 1 : 0.4,
                }}
              >
                {/* Within Country */}
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <label>
                      <>
                        <span> {Labels.Withinsamecountry} </span>
                      </>
                    </label>
                    <Select
                      sx={{
                        height: "40px",
                        border: `2px solid ${
                          backupdata?.countryValue == countryValue
                            ? "black"
                            : "green"
                        }`,
                        "& fieldset": {
                          border: "none", // Remove the default border
                        },
                        "&:hover": {
                          border: `2px solid ${
                            backupdata?.countryValue == countryValue
                              ? "black"
                              : "green"
                          }`,
                        },
                        "&.Mui-focused": {
                          border: `2px solid ${
                            backupdata?.countryValue == countryValue
                              ? "black"
                              : "green"
                          }`,
                        },
                      }}
                      value={
                        countryValue != null
                          ? !isRemote
                            ? 1
                            : countryValue
                          : 1
                      }
                      disabled={!isRemote}
                      onChange={(e) => {
                        setcountryValue(e.target.value);
                        setButtonDisable(false);
                      }}
                    >
                      <MenuItem value={1}>{Labels.yes}</MenuItem>
                      <MenuItem value={0}>{Labels.No}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* Within Continent */}
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <label>
                      <>
                        <span> {Labels.WithinsameContinent} </span>
                      </>
                    </label>
                    <Select
                      sx={{
                        height: "40px",
                        border: `2px solid ${
                          backupdata?.continentValue == continentValue
                            ? "black"
                            : "green"
                        }`,
                        "& fieldset": {
                          border: "none", // Remove the default border
                        },
                        "&:hover": {
                          border: `2px solid ${
                            backupdata?.continentValue == continentValue
                              ? "black"
                              : "green"
                          }`,
                        },
                        "&.Mui-focused": {
                          border: `2px solid ${
                            backupdata?.continentValue == continentValue
                              ? "black"
                              : "green"
                          }`,
                        },
                      }}
                      value={
                        continentValue != null
                          ? !isRemote
                            ? 1
                            : continentValue
                          : 1
                      }
                      disabled={!isRemote}
                      onChange={(e) => {
                        setcontinentValue(e.target.value);
                        setButtonDisable(false);
                      }}
                    >
                      <MenuItem value={1}>{Labels.yes}</MenuItem>
                      <MenuItem value={0}>{Labels.No}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* Within Radius */}
                <Grid item xs={12} sm={4}>
                  <label style={{ position: "relative", bottom: "1px" }}>
                    <>
                      <span> {Labels.Withinaradius} (km)</span>
                    </>
                  </label>
                
                  <TextField
                    sx={{
                      width: "100%",
                      height: "50px",
                      position: "relative",
                      bottom: "3px",

                      "& .MuiOutlinedInput-root": {
                        borderRadius: "4px", // Adjust border radius if needed
                        border: `2px solid ${
                          backupdata?.jobRadious == radiusValue
                            ? "black"
                            : "green"
                        }`,
                        "& fieldset": {
                          border: "none", // Remove the default border
                        },
                        "&:hover": {
                          border: `2px solid ${
                            backupdata?.jobRadious == radiusValue
                              ? "black"
                              : "green"
                          }`,
                        },
                        "&.Mui-focused": {
                          border: `2px solid ${
                            backupdata?.jobRadious == radiusValue
                              ? "black"
                              : "green"
                          }`,
                        },
                      },
                    }}
                    inputProps={{
                      sx: {
                        height: "36px",
                        padding: `0px`,
                        paddingLeft: "10px",
                      },
                    }}
                    disabled={!isRemote}
                    onChange={(e) => {
                      setradiusfn(e);
                      setButtonDisable(false);
                    }}
                    value={
                      radiusValue != null ? (!isRemote ? 0 : radiusValue) : ""
                    }
                    // id="outlined-required"
                  />
                </Grid>
              </Grid>

              {/* Contract Type */}
              <Grid item xs={12} lg={12}>
                <Box
                  sx={{
                    m: "-10px 4px 10px",
                    position: "relative",
                    right: "1px",
                  }}
                >
                  <label>{Labels.ContractType}</label>
                  <SelectJobType
                    value={jobtype ? jobtype : ""}
                    setValue={setjobtype}
                    setbackupdata={setbackupdata}
                    backupdata={backupdata}
                    setButtonDisable={setButtonDisable}
                    backup={backupdata?.jobtype}
                  />
                </Box>
              </Grid>

              {/*Contract Type Percentage*/}
              <Grid item xs={12} lg={12}>
                <label style={{ position: "relative", left: "2px" }}>
                  {Labels.FullPartTime}
                </label>

                <Percentage
                  value={
                    jobtype == 2 ? 10 : typePercentage ? typePercentage : 10
                  }
                  backup={backupdata.typePercentage}
                  name="typePercentage"
                  setValue={settypepercentage}
                  setButtonDisable={setButtonDisable}
                  disabled={jobtype == 2}
                  sx={{
                    padding: "10px 12px",
                    fontSize: "14px", // Apply fontSize style here
                    fontFamily: "Poppins",
                    ...style,
                  }}
                />
              </Grid>

              {/* ---------------------------------------------------------------- Working hours per -------------------------- */}

              {/* <Grid item xs={12} md={6} mt={1}>
                <span
                  style={{
                    potition: "relative",
                    left: "3px",
                    fontSize: "14px",
                    fontFamily: "Poppins",
                  }}
                >
                  {Labels.ExpectedHours}
                </span>
                <WorkHour
                  InputLabel={Labels.ExpectedHours}
                  label={Labels?.Workinghours}
                  name="WorkingDuration"
                  value={Workinghour ? Workinghour : ""}
                  setButtonDisable={setButtonDisable}
                  setValue={setWorkinghour}
                />
              </Grid> */}
              {/* ---------------------------------------------------------------- Hours -------------------------- */}
              {/* <Grid item xs={12} md={6}>
                <span
                  style={{
                    position: "relative",
                    top: "10px",
                    left: "5px",
                    fontSize: "14px",
                    fontFamily: "Poppins",
                  }}
                >
                  {Labels?.Workinghours}
                </span>
                <InputWithLabel
                  InputLabel={" "}
                  label={Labels?.Workinghours}
                  name="workingHours"
                  value={workingHours ? workingHours : ""}
                  type="number"
                  inputProps={{
                    sx: { height: "19px", color: "black" },
                    min: 0,
                  }}
                  style={{ width: "100%", position: "relative", bottom: "1px" }}
                  onChange={(e) => {
                    Createjobads(e);
                    setButtonDisable(false);
                  }}
                  onKeyDown={(e) => {
                    // Allow only numbers, backspace, delete, arrow keys, etc.
                    if (
                      !/[0-9]/.test(e.key) && // Only allow numeric keys
                      e.key !== "Backspace" &&
                      e.key !== "Delete" &&
                      e.key !== "ArrowLeft" &&
                      e.key !== "ArrowRight" &&
                      e.key !== "ArrowUp" && // Allow ArrowUp for increment
                      e.key !== "ArrowDown" && // Allow ArrowDown for decrement
                      e.key !== "Tab" &&
                      e.key !== "+"
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
              </Grid> */}

              {/* ------------------------------- Salary -------------------------------? */}

              {/* 
              <Grid container sx={{ position: "relative", left: "5px" }}>
                <Grid item xs={12} sm={6} md={6}>
                  <InputWithLabel
                    fullWidth
                    InputLabel={
                      <span style={{ position: "relative", bottom: "2px" }}>
                        {Labels.Salary} (Min)
                      </span>
                    }
                    label={Labels.Amount}
                    name="salary" // Unique name for each input
                    value={
                      salary
                        ? salary
                            .toString()
                            .replace(/[^0-9]/g, "")
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : ""
                    }
                    type="text"
                    style={{
                      width: "100%",
                      position: "relative",
                    }}
                    inputProps={{ sx: { height: "18px" }, min: 0 }}
                    onChange={(e) => {
                      Createjobads(e);
                      setButtonDisable(false);
                    }}
                    onKeyDown={(e) => {
                      if (
                        !/[0-9]/.test(e.key) &&
                        e.key !== "Backspace" &&
                        e.key !== "Delete" &&
                        e.key !== "ArrowLeft" &&
                        e.key !== "ArrowRight" &&
                        e.key !== "ArrowUp" &&
                        e.key !== "ArrowDown" &&
                        e.key !== "Tab" &&
                        e.key !== "+"
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <InputWithLabel
                    fullWidth
                    InputLabel={
                      <span style={{ position: "relative", bottom: "2px" }}>
                        {Labels.Salary} (Max)
                      </span>
                    }
                    label={Labels.Amount}
                    name="salary2" // Unique name for each input
                    value={
                      salary
                        ? salary
                            .toString()
                            .replace(/[^0-9]/g, "")
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : ""
                    }
                    type="text"
                    style={{
                      width: "99%",
                      position: "relative",
                    }}
                    inputProps={{ sx: { height: "18px" }, min: 0 }}
                    onChange={(e) => {
                      Createjobads(e);
                      setButtonDisable(false);
                    }}
                    onKeyDown={(e) => {
                      if (
                        !/[0-9]/.test(e.key) &&
                        e.key !== "Backspace" &&
                        e.key !== "Delete" &&
                        e.key !== "ArrowLeft" &&
                        e.key !== "ArrowRight" &&
                        e.key !== "ArrowUp" &&
                        e.key !== "ArrowDown" &&
                        e.key !== "Tab" &&
                        e.key !== "+"
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Grid>
              </Grid> */}

              {/* <Grid
                item
                xs={12}
                md={12}
                justifyContent="space-between"
                direction="row"
                display="flex"
              >
                <Currency
                  name="setCurrency"
                  value={currency}
                  setValue={setCurrency}
                  setButtonDisable={setButtonDisable}
                  style={{ width: "96%", position: "relative", left: "2px" }}
                />

                <SalaryPeriod
                  style={{ width: "99%" }}
                  InputLabel={Labels.Period}
                  label={Labels?.Workinghours}
                  name="salaryPeriod"
                  value={salaryPeriod ? salaryPeriod : ""}
                  setValue={setsalaryPeriod}
                  setButtonDisable={setButtonDisable}
                />
              </Grid> */}

              <Grid container spacing={1} sx={{ margin: "0px" }}>
                <Grid
                  item
                  xs={12}
                  xl={12}
                  style={{ position: "relative", bottom: "14px" }}
                >
                  <Paper elevation={0} sx={{ my: 3.5 }}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        p: 1.5,
                        background: "#DEF0EA",
                        borderRadius: "5px",
                        border: "1 px solid #ecf4f1",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "18px",
                          fontWeight: 500,
                        }}
                      >
                        {Labels.Salary}
                      </Typography>
                    </Stack>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <SalaryCalculatorModal
                    setyearlysalary={setyearlysalary}
                    setyearlysalary2={setyearlysalary2}
                    yearlysalary={yearlysalary}
                    yearlysalary2={yearlysalary2}
                    Createjobads={Createjobads}
                    salaryPeriod={salaryPeriod}
                    setsalaryPeriod={setsalaryPeriod}
                    currency={currency}
                    setCurrency={setCurrency}
                    Workinghour={Workinghour}
                    salaryValue={
                      salary
                        ? salary
                            .toString()
                            .replace(/[^0-9]/g, "")
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : ""
                    }
                    salaryMax={salaryMax}
                    setSalaryMax={setSalaryMax}
                    setButtonDisable={setButtonDisable}
                    setsalarycalcuvalue={setsalarycalcuvalue}
                    salarycalcuvalue={salarycalcuvalue}
                    currencySymbol={currencySymbol}
                    setCurrencySymbol={setCurrencySymbol}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* -------------------- Yes / No Questions -------------------- */}
          <Grid item xs={12} xl={6}>
            <Paper elevation={0} sx={{ my: 1 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  p: 1.5,
                  background: "#DEF0EA",
                  borderRadius: "5px",
                  border: "1 px solid #ecf4f1",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                  }}
                >
                  {Labels?.Miscellaneous}
                </Typography>
              </Stack>
            </Paper>

            <Grid container spacing={2}>
              <Grid item xs={12} md={12} my={1}>
                <Box sx={{ position: "relative", top: "2px" }}>
                  <label>{Labels?.Wouldyouliketoshowyourprofile}</label>
                  <YesNo
                    placeholder={`${Labels?.yes}/${Labels?.No}`}
                    value={showProfile == 1 ? 1 : 0}
                    backup={backupdata?.showProfile}
                    setValue={setShowProfile}
                    setButtonDisabled5={setButtonDisable}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box sx={{ position: "relative", bottom: "6px" }}>
                  <label>{Labels?.Canpeoplecontactyoubymail}</label>
                  <YesNo
                    placeholder={`${Labels?.yes}/${Labels?.No}`}
                    value={contactBymail == 1 ? 1 : 0}
                    setValue={setShowEmail}
                    backup={backupdata?.contactBymail}
                    setButtonDisabled5={setButtonDisable}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box sx={{ position: "relative", bottom: "14px" }}>
                  <label>{Labels?.DoyouexpecttoreceiveaCoverletter}</label>
                  <YesNo
                    placeholder={`${Labels?.yes}/${Labels?.No}`}
                    value={receiveCoverLetter == 1 ? 1 : 0}
                    setValue={setExpectCoverLetter}
                    backup={backupdata?.receiveCoverLetter}
                    setButtonDisabled5={setButtonDisable}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={12} my={1}>
                <Box sx={{ position: "relative", bottom: "20px" }}>
                  <label>{Labels.JobPostDuration}</label>
                  
                  <JobPostingDuration
                    placeholder={Labels.JobPostDuration}
                    value={JobPostDuration ? JobPostDuration : ""}
                    setValue={setJobPostDuration}
                    setButtonDisabled5={setButtonDisable}
                    backup={backupdata?.JobPostDuration}
                    jobDetails={jobDetails}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid container sx={{ position: "relative", bottom: "20px" }}>
              {/* </Grid> */}
              <Grid item xs={12} xl={12}>
                <Paper elevation={0} sx={{ my: 3.5 }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      p: 1.5,
                      background: "#DEF0EA",
                      borderRadius: "5px",
                      border: "1 px solid #ecf4f1",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: "18px",
                        fontWeight: 500,
                      }}
                    >
                      {Labels.JobRequirementsExpectationsInfo}
                    </Typography>
                  </Stack>
                </Paper>
              </Grid>

              <Grid
                container
                spacing={1}
                sx={{ position: "relative", bottom: "25px" }}
              >
                <Grid item xs={12} xl={12} my={2}>
                  {/* {jobRequiredLanguages.length !== 0 && (
                    <Grid container spacing={1}>
                      {jobRequiredLanguages.map((data, index) => (
                        <Grid
                          item
                          xs={8}
                          md={4}
                          lg={4}
                          key={index}
                          direction="row"
                        >
                          <Box
                            key={index}
                            sx={{
                              p: 2,
                              // border: "1px solid rgba(0, 0, 0, 0.12)",
                              border:
                              JSON.stringify(backupdata?.jobRequiredLanguages?.map(item => item.languageid)?.sort()) 
                              == JSON.stringify(jobRequiredLanguages?.map(item => item.languageid)?.sort())
                              ?'2px solid black'
                              :'2px solid green',
                              mb: 2,
                              cursor: "pointer",
                              xs: "100%",
                              width: {
                                xs: "100%", // Set width to 100% on xs screens
                                sm: "80%", // Set width to 80% on sm and larger screens
                              },
                            }}
                          >
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Typography
                                variant="subtitle2"
                                sx={{ fontWeight: 600, width: "0%" }}
                              >
                                {`${data?.language}`}
                              </Typography>

                              <Stack direction="row" alignItems="center">
                                <HighlightOffTwoToneIcon
                                  sx={{
                                    cursor: "pointer",
                                    fontsize: "1.2rem",
                                    color: "rgba(0, 0, 0, 0.59)",
                                  }}
                                  onClick={() => removeAddedLanguage(index)}
                                />
                              </Stack>
                            </Stack>
                            <Stack>
                              <Stack direction="row">
                                <Typography
                                  variant="subtitle2"
                                  sx={{
                                    color: "rgba(0, 0, 0, 0.59)",
                                  }}
                                >
                                  {Labels?.Written}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  sx={{
                                    color: "rgba(0, 0, 0, 0.59)",
                                    mx: 2,
                                    width: "200px",
                                  }}
                                >
                                  {`${data?.written}`}
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="subtitle2"
                                  sx={{
                                    color: "rgba(0, 0, 0, 0.59)",
                                  }}
                                >
                                  {Labels?.Spoken}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  sx={{
                                    color: "rgba(0, 0, 0, 0.59)",
                                    mx: 2,
                                    width: "200px",
                                  }}
                                >
                                  {`${data?.spoken}`}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  )} */}

                  {jobRequiredLanguages.length !== 0 && (
                    <Grid container spacing={1}>
                      {jobRequiredLanguages.map((data, index) => {
                        // Find new IDs not present in backupdata
                        const backupIds =
                          backupdata?.jobRequiredLanguages?.map(
                            (item) => item.languageid
                          ) || [];
                        const isNewLanguage = backupIds.includes(
                          data.languageid
                        );

                        return (
                          <Grid
                            item
                            xs={8}
                            md={4}
                            lg={4}
                            key={index}
                            direction="row"
                          >
                            <Box
                              key={index}
                              sx={{
                                p: 2,
                                border: `2px solid ${
                                  isNewLanguage ? "black" : "green"
                                }`,
                                mb: 2,
                                borderRadius: "5px",
                                cursor: "pointer",
                                xs: "100%",
                                width: {
                                  xs: "100%", // Set width to 100% on xs screens
                                  sm: "80%", // Set width to 80% on sm and larger screens
                                },
                              }}
                            >
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                              >
                                <Typography
                                  variant="subtitle2"
                                  sx={{ fontWeight: 600, width: "0%" }}
                                >
                                  {`${data?.language}`}
                                </Typography>

                                <Stack direction="row" alignItems="center">
                                  <HighlightOffTwoToneIcon
                                    sx={{
                                      cursor: "pointer",
                                      fontsize: "1.2rem",
                                      color: "rgba(0, 0, 0, 0.59)",
                                    }}
                                    onClick={() => removeAddedLanguage(index)}
                                  />
                                </Stack>
                              </Stack>
                              <Stack>
                                <Stack direction="row">
                                  <Typography
                                    variant="subtitle2"
                                    sx={{
                                      color: "rgba(0, 0, 0, 0.59)",
                                    }}
                                  >
                                    {Labels?.Written}
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color: "rgba(0, 0, 0, 0.59)",
                                      mx: 2,
                                      width: "200px",
                                    }}
                                  >
                                    {`${data?.written}`}
                                  </Typography>
                                </Stack>
                                <Stack direction="row" alignItems="center">
                                  <Typography
                                    variant="subtitle2"
                                    sx={{
                                      color: "rgba(0, 0, 0, 0.59)",
                                    }}
                                  >
                                    {Labels?.Spoken}
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color: "rgba(0, 0, 0, 0.59)",
                                      mx: 2,
                                      width: "200px",
                                    }}
                                  >
                                    {`${data?.spoken}`}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                  )}

                  <Stack
                    direction={{ xs: "column", md: "row" }}
                    justifyContent={{ xs: "block", md: "space-between" }}
                    alignItems={{ xs: "center", md: "end" }}
                    sx={{
                      position: "relative",
                      bottom: jobRequiredLanguages.length == 0 ? "12px" : "0px",
                    }}
                  >
                    <Box sx={{ m: "0px 5px", width: "100%" }}>
                      <label>{Labels?.ExpectedLanguages}</label>
                      {/* <span style={{ color: "red" }}>*</span> */}
                      <AllLanguage
                        languageName={languageName} // english
                        setLanguageName={setLanguageName}
                        value={language} // id ?
                        setValue={setLanguage}
                        label={Labels?.language}
                        setFilterLanguage={setFilterLanguage}
                        jobreqlang={jobRequiredLanguages}
                        setButtonDisable={setButtonDisable}
                        type={"expectedlanguage"}
                      />
                    </Box>

                    <Box sx={{ m: "0px 5px", width: "100%" }}>
                      <label>{Labels?.Spoken} </label>
                      {/* <span style={{ color: "red" }}>*</span> */}
                      <LanguageLevel
                        value={spoken}
                        setValue={setLanguageReadLevel}
                        setLevel={setRLevel}
                        label={Labels?.Spoken}
                        setButtonDisable={setButtonDisable}
                        jobreqlang={jobRequiredLanguages}
                        type={"expectedlanguage"}
                      />
                      <br />
                    </Box>

                    <Box sx={{ m: "0px 5px", width: "100%" }}>
                      <label>{Labels?.Written}</label>
                      {/* <span style={{ color: "red" }}>*</span> */}
                      <LanguageLevel
                        value={written}
                        setValue={setLanguageWriteLevel}
                        setLevel={setWLevel}
                        label={Labels?.Written}
                        setButtonDisable={setButtonDisable}
                        jobreqlang={jobRequiredLanguages}
                        type={"expectedlanguage"}
                      />
                    </Box>

                    <Box sx={{ m: "5px 5px" }}>
                      <Button
                        variant="contained"
                        sx={{
                          background: "rgba(109, 160, 156, 0.15)",
                          color: "rgb(43, 73, 71)",
                          textTransform: "capitalize",
                          "&:hover": {
                            background: "rgba(109, 160, 156, 0.15)",
                          },
                          "&:active": {
                            backgroundColor: "#1C614E",
                          },
                          "&:disabled": {
                            color: "white",
                            backgroundColor: "grey",
                          },
                          boxShadow: "none",
                        }}
                        onClick={() => addLanguage()}
                      >
                        {Labels?.Add}
                      </Button>
                    </Box>
                  </Stack>
                  {errMsg?.languageErr && (
                    <span style={{ color: "#ff0000", fontSize: "13px" }}>
                      {Labels?.languagefieldisrequird}
                    </span>
                  )}
                  {errMsg?.writtenErr && (
                    <span style={{ color: "#ff0000", fontSize: "13px" }}>
                      {Labels?.Writtenfieldisrequird}
                    </span>
                  )}
                  {errMsg?.spokenErr && (
                    <span style={{ color: "#ff0000", fontSize: "13px" }}>
                      {Labels?.Spokenfieldisrequird}
                    </span>
                  )}
                </Grid>

                {/* ---------------------nice to have Languages -------------------------- */}
                <Grid item xs={12} xl={12}>
                  {jobRequiredLanguages1 &&
                    jobRequiredLanguages1.length !== 0 && (
                      <Grid container spacing={1}>
                        {jobRequiredLanguages1.map((data, index) => {
                          // Identify new languages not in backupdata
                          const backupIds =
                            backupdata?.jobRequiredLanguages1?.map(
                              (item) => item.languageid1
                            ) || [];
                          const isNewLanguage = backupIds.includes(
                            data.languageid1
                          );

                          return (
                            <Grid
                              item
                              xs={9}
                              md={4}
                              lg={4}
                              key={index}
                              direction="row"
                            >
                              <Box
                                key={index}
                                sx={{
                                  p: 2,
                                  border: `2px solid ${
                                    isNewLanguage ? "black" : "green"
                                  }`,
                                  borderRadius: "5px",
                                  mb: 2,
                                  cursor: "pointer",
                                  xs: "100%",
                                  width: {
                                    xs: "100%", // Set width to 100% on xs screens
                                    sm: "80%", // Set width to 80% on sm and larger screens
                                  },
                                }}
                              >
                                <Stack
                                  direction="row"
                                  justifyContent="space-between"
                                >
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ fontWeight: 600, width: "0%" }}
                                  >
                                    {`${data?.language1}`}
                                  </Typography>

                                  <Stack direction="row" alignItems="center">
                                    <HighlightOffTwoToneIcon
                                      sx={{
                                        cursor: "pointer",
                                        fontsize: "1.2rem",
                                        color: "rgba(0, 0, 0, 0.59)",
                                      }}
                                      onClick={() =>
                                        removeAddedLanguage1(index)
                                      }
                                    />
                                  </Stack>
                                </Stack>
                              </Box>
                            </Grid>
                          );
                        })}
                      </Grid>
                    )}
                  <Stack
                    direction={{ xs: "column", md: "row" }}
                    justifyContent={{ xs: "block", md: "space-between" }}
                    alignItems={{ xs: "center", md: "end" }}
                    sx={{
                      position: "relative",
                      bottom:
                        jobRequiredLanguages1.length == 0 ? "18px" : "0px",
                    }}
                  >
                    <Box sx={{ m: "0px 5px", width: "100%" }}>
                      <label>{Labels?.Nicetohave}</label>
                      <AllLanguage
                        languageName={languageName1} // english
                        setLanguageName={setLanguageName1}
                        value={language1} // id ?
                        setValue={setLanguage1}
                        label={Labels?.language}
                        setFilterLanguage={setFilterLanguage1}
                        jobRequiredLanguages1={jobRequiredLanguages1}
                        setButtonDisable={setButtonDisable}
                      />
                    </Box>

                    <Box sx={{ m: "5px 5px" }}>
                      <Button
                        variant="contained"
                        sx={{
                          background: "rgba(109, 160, 156, 0.15)",
                          color: "rgb(43, 73, 71)",
                          textTransform: "capitalize",
                          "&:hover": {
                            background: "rgba(109, 160, 156, 0.15)",
                          },
                          "&:active": {
                            backgroundColor: "#1C614E",
                          },
                          "&:disabled": {
                            color: "white",
                            backgroundColor: "grey",
                          },
                          boxShadow: "none",
                        }}
                        onClick={() => addLanguage1()}
                      >
                        {Labels?.Add}
                      </Button>
                    </Box>
                  </Stack>
                  {errMsg?.languageErr && (
                    <span style={{ color: "#ff0000", fontSize: "13px" }}>
                      {Labels?.languagefieldisrequird}
                    </span>
                  )}
                </Grid>

                <Grid container spacing={1}>
                  {/* ------------------------------Seniority level--------------------------------- */}
                  <Grid item xs={12} lg={12}>
                    <Box sx={{ m: `${jobRequiredLanguages1.length?'25px 14px 0px':'8px 14px'} `,  position:"relative", bottom:"15px" }}>
                      <label>{Labels?.ExperienceLevel}</label>
                      <ExperienceLevel
                        value={userExperienceLevel}
                        backup={backupdata.userExperienceLevel}
                        setValue={setUserExperienceLevel}
                        setButtonDisable={setButtonDisable}
                        style={{
                          background: "white",
                        }}
                      />
                    </Box>
                  </Grid>

                  {/* --------------------- Qualifications -------------------------- */}
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{ position: "relative", bottom: "3px", left: "9px" }}
                  >
                    <label
                      style={{
                        position: "relative",
                        bottom: "1px",
                        left: "5px",
                      }}
                    >
                      {Labels.EducationCertifications}
                    </label>

                    <TextField
                      onChange={(e) => {
                        Createjobads(e);
                        setButtonDisable(false);
                      }}
                      name="certifications"
                      value={certifications}
                      placeholder={Labels?.Nicetohave}
                      // fullWidth
                      sx={{
                        position: "relative",
                        left: "5px",
                        width: "96.3%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "4px", // Adjust border radius if needed
                          border: `2px solid ${
                            backupdata?.certifications == certifications
                              ? "black"
                              : "green"
                          }`,
                          "& fieldset": {
                            border: "none", // Remove the default border
                          },
                          "&:hover": {
                            border: `2px solid ${
                              backupdata?.certifications == certifications
                                ? "black"
                                : "green"
                            }`,
                          },
                          "&.Mui-focused": {
                            border: `2px solid ${
                              backupdata?.certifications == certifications
                                ? "black"
                                : "green"
                            }`,
                          },
                        },
                      }}
                      inputProps={{
                        sx: {
                          height: "40px",
                          padding: `0px`,
                          paddingLeft: "10px",
                        },
                      }}
                    />
                  </Grid>

                  {/* -----------------------------Travel---------------------------------------- */}
                  <Grid item xs={12} md={12}>
                    <Grid container spacing={1}>
                      <Grid
                        item
                        xs={12}
                        sm={jobRequireTravel == 1 ? 6 : 12}
                        md={jobRequireTravel == 1 ? 6 : 12}
                        lg={jobRequireTravel == 1 ? 6 : 12}
                        sx={{ position: "relative", left: "15px", top: "10px" }}
                      >
                        <label>{Labels?.DoestheJobrequiretravelling}</label>
                        <FormControl sx={{ width: "96.4%" }}>
                          <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            onChange={(e) => {
                              Travelchange(e);
                              setButtonDisable(false);
                            }}
                            value={jobRequireTravel}
                            fullWidth
                            displayEmpty
                            variant="outlined"
                            sx={{
                              padding: "10px 12px",
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              border: `2px solid ${
                                backupdata.jobRequireTravel == jobRequireTravel
                                  ? "black"
                                  : "green"
                              }`,
                              "& fieldset": {
                                border: "none", // Remove the default border
                              },
                              "&:hover": {
                                border: `2px solid ${
                                  backupdata.jobRequireTravel ==
                                  jobRequireTravel
                                    ? "black"
                                    : "green"
                                }`,
                              },
                              "&.Mui-focused": {
                                border: `2px solid ${
                                  backupdata.jobRequireTravel ==
                                  jobRequireTravel
                                    ? "black"
                                    : "green"
                                }`,
                              },
                              ...style,
                            }}
                          >
                            <MenuItem disabled value="">
                              <span
                                style={{ color: "#bcbcbc", fontSize: "14px" }}
                              >
                                {`${Labels?.yes}/${Labels?.No}`}
                              </span>
                            </MenuItem>
                            <MenuItem value="1" sx={{ fontSize: "14px" }}>
                              {Labels?.yes}
                            </MenuItem>
                            <MenuItem value="0" sx={{ fontSize: "14px" }}>
                              {Labels?.No}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      {jobRequireTravel == 1 && (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          lg={6}
                          sx={{
                            position: "relative",
                            top: "10px",
                            left: "5px",
                          }}
                        >
                          <label>
                            {Labels?.Percentageoftravellingrequired}
                          </label>
                          <FormControl sx={{ width: "95%" }}>
                            <TextField
                              label={Labels?.TravelPercentage}
                              name="travelPercentage"
                              type="number"
                              value={travelPercentage}
                              onChange={(e) => {
                                if (
                                  /^\d*$/.test(e.target.value) &&
                                  Number(e.target.value) <= 100
                                ) {
                                  setTravelPercentage(e.target.value);
                                }
                                setButtonDisable(false);
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "4px", // Adjust border radius if needed
                                  border: `2px solid ${
                                    backupdata?.travelPercentage ==
                                    travelPercentage
                                      ? "black"
                                      : "green"
                                  }`,
                                  "& fieldset": {
                                    border: "none", // Remove the default border
                                  },
                                  "&:hover": {
                                    border: `2px solid ${
                                      backupdata?.travelPercentage ==
                                      travelPercentage
                                        ? "black"
                                        : "green"
                                    }`,
                                  },
                                  "&.Mui-focused": {
                                    border: `2px solid ${
                                      backupdata?.travelPercentage ==
                                      travelPercentage
                                        ? "black"
                                        : "green"
                                    }`,
                                  },
                                },
                              }}
                              InputProps={{
                                sx: { height: "44px" },
                                inputProps: {
                                  min: 0,
                                  max: 100,
                                  inputMode: "numeric", // Suggests numeric keyboard on mobile devices
                                  pattern: "\\d*",
                                }, // Restrict input to percentage range
                              }}
                              variant="outlined"
                              fullWidth
                            />
                          </FormControl>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  {/* --------------------- skills -------------------------- */}
                  <Grid item xs={12} md={12}>
                    <Box
                      sx={{
                        m: "10px 5px",
                        my: 1.5,
                        width: `96%`,
                        position: "relative",
                        left: "10px",
                        top: "6px",
                      }}
                    >
                      <label>{Labels.ExpectedSkills}</label>
                      <SkillsEdit
                        value={skills}
                        setValue={setskills}
                        setButtonDisable={setButtonDisable}
                      />
                    </Box>
                  </Grid>

                  {/* -------------------------Nice to have---------------------------------- */}
                  <Grid
                    item
                    xs={12}
                    sx={{ position: "relative", left: "11px" }}
                  >
                    <label
                      style={{
                        position: "relative",
                        bottom: "1px",
                        left: "5px",
                      }}
                    >
                      {Labels?.Nicetohave}
                    </label>

                    <TextField
                      onChange={(e) => {
                        Createjobads(e);
                        setButtonDisable(false);
                      }}
                      name="additionalQualification"
                      value={additionalQualification}
                      placeholder={Labels?.Nicetohave}
                      // fullWidth
                      sx={{
                        position: "relative",
                        left: "5px",
                        width: "96%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "4px", // Adjust border radius if needed
                          border: `2px solid ${
                            backupdata?.additionalQualification ==
                            additionalQualification
                              ? "black"
                              : "green"
                          }`,
                          "& fieldset": {
                            border: "none", // Remove the default border
                          },
                          "&:hover": {
                            border: `2px solid ${
                              backupdata?.additionalQualification ==
                              additionalQualification
                                ? "black"
                                : "green"
                            }`,
                          },
                          "&.Mui-focused": {
                            border: `2px solid ${
                              backupdata?.additionalQualification ==
                              additionalQualification
                                ? "black"
                                : "green"
                            }`,
                          },
                        },
                      }}
                      inputProps={{
                        sx: {
                          height: "40px",
                          padding: `0px`,
                          paddingLeft: "10px",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* ---------------------Added Languages -------------------------- */}
            </Grid>
          </Grid>
          <Grid item xs={12} xl={12}>
            <Paper elevation={0} sx={{ my: 1 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  p: 1.5,
                  background: "#DEF0EA",
                  borderRadius: "5px",
                  border: "1 px solid #ecf4f1",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                  }}
                >
                  {Labels.JobOverview}
                </Typography>
              </Stack>
            </Paper>
          </Grid>

          {/* ------------------------------ Job Information New --------------------- */}
          {Questiondata?.filter((item) => item.key !== "others").map((item) => {
            const backupItem = backupdata?.Questiondata?.find(
              (backup) => backup.id === item.id
            );
            const isChanged = backupItem
              ? backupItem.value == item.value
              : false;
            return (
              <Grid item xs={12} md={6} key={item.id}>
                <Box display="flex" alignItems="center">
                  <label style={{ marginRight: "10px", minWidth: "200px" }}>
                    {item.name}
                  </label>
                  <TextField
                    fullWidth
                    placeholder={Labels.description}
                    variant="outlined"
                    value={item.value}
                    onChange={(e) => {
                      handleChange(item.id, e.target.value);
                      setButtonDisable(false);
                    }}
                    multiline
                    rows={4}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "4px", // Adjust border radius if needed
                        border: `2px solid ${isChanged ? "black" : "green"}`,
                        "& fieldset": {
                          border: "none", // Remove the default border
                        },
                        "&:hover": {
                          border: `2px solid ${isChanged ? "black" : "green"}`,
                        },
                        "&.Mui-focused": {
                          border: `2px solid ${isChanged ? "black" : "green"}`,
                        },
                      },
                    }}
                    InputProps={{
                      sx: {
                        "& .MuiOutlinedInput-input": {
                          padding: "12px", // Adjusts padding inside the input
                        },
                      },
                    }}
                  />
                </Box>
              </Grid>
            );
          })}

          <Grid
            container
            spacing={2}
            sx={{ width: "100%", position: "relative", left: "20px" }}
          >
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={addNewField}
                sx={{
                  margin: "15px 0px 0px",
                  background: "#1C614E",
                  color: "white",
                  textTransform: "capitalize",
                  "&:hover": {
                    background: "#1C614E",
                  },
                  "&:active": {
                    backgroundColor: "#1C614E",
                  },
                  "&:disabled": {
                    color: "white",
                    backgroundColor: "grey",
                  },
                  boxShadow: "none",
                }}
              >
                {Labels.AddQuestion}
              </Button>

              {dynamicFields.map((field, index) => {
                const backupField = backupdata?.dynamicFields?.find(
                  (backup) => backup.question === field.question
                );
                const isChanged = backupField
                  ? backupField.answer == field.answer
                  : false;

                return (
                  <Grid container spacing={2} key={index} my={1}>
                    <Grid item xs={12} sm={3} md={3}>
                      <TextField
                        placeholder={Labels.Title}
                        variant="outlined"
                        value={field.question}
                        onChange={(e) =>
                          handleDynamicChange(index, "question", e.target.value)
                        }
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "4px", // Adjust border radius if needed
                            border: `2px solid ${
                              isChanged ? "black" : "green"
                            }`,
                            "& fieldset": {
                              border: "none", // Remove the default border
                            },
                            "&:hover": {
                              border: `2px solid ${
                                isChanged ? "black" : "green"
                              }`,
                            },
                            "&.Mui-focused": {
                              border: `2px solid ${
                                isChanged ? "black" : "green"
                              }`,
                            },
                          },
                        }}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} sm={9} md={9}>
                      <Box display="flex" alignItems="center">
                        <TextField
                          placeholder={Labels.description}
                          variant="outlined"
                          value={field.answer}
                          onChange={(e) =>
                            handleDynamicChange(index, "answer", e.target.value)
                          }
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "4px", // Adjust border radius if needed
                              border: `2px solid ${
                                isChanged ? "black" : "green"
                              }`,
                              "& fieldset": {
                                border: "none", // Remove the default border
                              },
                              "&:hover": {
                                border: `2px solid ${
                                  isChanged ? "black" : "green"
                                }`,
                              },
                              "&.Mui-focused": {
                                border: `2px solid ${
                                  isChanged ? "black" : "green"
                                }`,
                              },
                            },
                          }}
                          fullWidth
                          multiline
                          rows={4}
                          InputProps={{
                            sx: {
                              "& .MuiOutlinedInput-input": {
                                padding: "12px", // Adjusts padding inside the input
                              },
                            },
                          }}
                        />
                        <IconButton
                          aria-label="delete"
                          onClick={() => removeField(index)}
                          sx={{ marginLeft: "10px" }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                );
              })}

              <Grid item xs={12} xl={12} mt={4}>
                <Paper elevation={0} sx={{ my: 1 }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      p: 1.5,
                      background: "#DEF0EA",
                      borderRadius: "5px",
                      border: "1px solid #ecf4f1",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: "18px",
                        fontWeight: 500,
                      }}
                    >
                      {" "}
                      {Labels.jobDescription}
                    </Typography>
                  </Stack>
                </Paper>
              </Grid>

              <Grid container spacing={2} my={1}>
                <Grid item xs={12} sm={12} md={12}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <ReactQuill
                      theme="snow"
                      value={jobdescription}
                      onChange={(e) => setjobdescription(e)}
                      style={{
                        width: "100%",
                        margin: "0 auto",
                      }}
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, 3, 4, 5, 6, false] }],
                          [{ font: [] }],
                          [{ size: [] }],
                          ["bold", "italic", "underline", "strike"],
                          [{ script: "sub" }, { script: "super" }],
                          [{ color: [] }, { background: [] }],
                          [{ list: "ordered" }, { list: "bullet" }],
                          [{ indent: "-1" }, { indent: "+1" }],
                          [{ align: [] }],
                        ],
                      }}
                      formats={[
                        "header",
                        "font",
                        "size",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "script",
                        "color",
                        "background",
                        "list",
                        "indent",
                        "align",
                        "blockquote",
                        "code-block",
                      ]}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* --------------------- Submit Button -------------------------- */}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "15px auto",
            }}
          >
            <CustomButton
              label={Labels?.Save}
              style={{ padding: "5px 25px" }}
              onClick={profanityChecker}
              disabled={isButtonDisable}
            />
          </div>
        </Grid>
      </Box>
    </Paper>
  );
}
