import React, { useState } from "react";
import {
  Stack,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  ListItem,
  Typography,
  Paper,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect } from "react";
import { axiosAPI } from "../Axios/Axios";
import { useLocation, useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useStyles } from "../CommonCode/Commonfunc";
import { useSelector } from "react-redux";

const JobSerachSideGrid = ({ setfiltercate, reset, setReset, resetbox }) => {
  const redirect = useNavigate();
  const location = useLocation();
  const postData = location.state;
  const theme = useTheme();
  const { jobsearch } = postData
    ? postData
    : {
        jobsearch: "",
        city: "",
        skills: "",
        category: "",
        type: "",
        industryID: "",
      };
  const [jobTypes, setJobType] = useState([]);
  const [categories, setCategories] = useState([]);
  const [skills, setSkills] = useState([]);
  const [industries, setIndustries] = useState([]);

  const [skill, setskill] = useState([]);
  const [category, setcategory] = useState([]);
  const [type, settype] = useState([]);
  const [industry, setindustry] = useState([]);

  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.up("lg"));
  const Labels = useSelector((state) => state.allLabels.labels);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const handleAccordionChange = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  function stringToArray(str) {
    if (str === "") {
      return []; // Return an empty array if the string is empty
    } else {
      return str.split(",").map(Number); // Split by comma and convert each element to number
    }
  }

  useEffect(() => {
    if (postData) {
      setskill(stringToArray(postData.skill ? postData.skill : ""));
      setcategory(stringToArray(postData?.category ? postData?.category : ""));
      settype(stringToArray(postData.type ? postData.type : ""));
      setindustry(stringToArray(postData.industry ? postData.industry : ""));
    }
  }, [postData]);

  //---------- API for getting Job Types-----------
  const getJobType = async () => {
    const result = await axiosAPI.get("/getTypes");
    const { success, data } = result.data;
    if (success === true) {
      setJobType(data);
    }
  };
  //---------- API for getting Job Categories-----------
  const getJobCategorise = async () => {
    const result = await axiosAPI.get("/getAllCategories");
    const { success, data } = result.data;
    if (success === true) {
      setCategories(data);
    }
  };
  //---------- API for getting Job Skills-----------
  const getSkills = async () => {
    const result = await axiosAPI.get("/getSkills");
    const { success, data } = result.data;
    if (success === true) {
      setSkills(data);
    }
  };

  //---------- API for getting Industries-----------
  const getIndustries = async () => {
    const result = await axiosAPI.get("/getIndustry");
    const { success, data } = result.data;
    if (success === true) {
      setIndustries(data);
    }
  };

  const redirectToSignUp = () => {
    redirect("/signup", { state: "2" });
  };

  const setValues = (data) => {
    if (data.name === "types") {
      const index = type.findIndex((num) => num === data.id);
      if (index === -1) {
        type.push(data.id);
      } else {
        type.splice(index, 1);
      }
    }
    if (data.name === "skills") {
      const index = skill.findIndex((num) => num === data.id);
      if (index === -1) {
        skill.push(data.id);
      } else {
        skill.splice(index, 1);
      }
    }
    if (data.categoryName === "categories") {
      const index = category.findIndex((num) => num === data.id);
      if (index === -1) {
        category.push(data.id);
      } else {
        category.splice(index, 1);
      }
      setfiltercate(category);
    }
    if (data.name === "industries") {
      const index = industry.findIndex((num) => num === data.id);
      if (index === -1) {
        industry.push(data.id);
      } else {
        industry.splice(index, 1);
      }
    }
    const postData = {
      jobsearch: jobsearch ? jobsearch : "",
      city: "",
      skills: skill.toString(),
      category: category.toString(),
      type: type.toString(),
      industryID: industry.toString(),
    };

    redirect("/jobSearchPrelogin", { state: postData });
  };

  useEffect(() => {
    getJobType();
    getJobCategorise();
    getSkills();
    getIndustries();
  }, [Labels]);

  return (
    <Stack>
      <Card
        sx={{
          mt: "10px",
          padding: 4,
          borderRadius: "10px",
          background:
            "linear-gradient(123.21deg, #01201D 2.44%, #0B7D5E 95.08%)",
          textAlign: "left",
          boxShadow: "none",
        }}
      >
        <Typography
          sx={{
            color: "#FFFFFF",
            fontWeight: 500,
            fontSize: "20px",
          }}
        >
          {Labels?.CreateYourAccountand}
        </Typography>
        <Typography
          sx={{
            color: "#FFFFFF",
            fontWeight: "300",
            fontSize: "10px",
          }}
        >
          {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. */}
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce justo
          est.
        </Typography>
        <Button
          sx={{
            background:
              "linear-gradient(271.86deg, #1D8568 3.79%, #2B4947 96.18%)",

            borderRadius: "6px",
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.11)",
            color: "white",
            fontSize: "12px",
            textTransform: "capitalize",
            my: 2,
            fontWeight: 500,
          }}
          onClick={redirectToSignUp}
        >
          {Labels?.CreateAnAccount}
        </Button>
      </Card>

      {resetbox && (
        <Paper
          sx={{
            mt: "10px",
            background: "#FFFFFF",
            overflowY: "auto",
            maxHeight: "64vh",
            padding: 0,
            boxShadow: "none",
            borderRadius: 2,
          }}
          className={classes.customColoredScrollbar}
        >
          <Accordion sx={{ margin: "0px !important" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ fontWeight: "bold" }}>
                {Labels?.types}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0px!important" }}>
              {jobTypes.map((val, index) => {
                const isChecked = postData?.type
                  ?.split(",")
                  ?.includes(val.id.toString());
                return (
                  <ListItem sx={{ pt: 0, mt: 0 }} key={index}>
                    <FormControlLabel
                      sx={{
                        ml: 2,
                      }}
                      control={
                        <Checkbox
                          size="sm"
                          sx={{
                            color: "#1C614E",
                            "&.Mui-checked": {
                              color: "#1C614E;",
                            },
                          }}
                          checked={isChecked}
                          onChange={(e) => {
                            setValues({ name: "types", id: val.id });
                          }}
                        />
                      }
                      label={val.name}
                    />
                  </ListItem>
                );
              })}
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ margin: "0px!important" }}
            onChange={handleAccordionChange}
            default={postData?.isCategoryMore ? true : false}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ fontWeight: "bold" }}>
                {Labels?.categories}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0px!important" }}>
              {categories.map((val, index) => {
                const isChecked = postData?.category
                  ?.split(",")
                  ?.includes(val.id.toString());
                return (
                  <ListItem sx={{ pt: 0, mt: 0 }} key={index}>
                    <FormControlLabel
                      sx={{
                        ml: 2,
                      }}
                      control={
                        <Checkbox
                          size="sm"
                          checked={isChecked}
                          sx={{
                            color: "#1C614E",
                            "&.Mui-checked": {
                              color: "#1C614E;",
                            },
                          }}
                          onChange={(e) =>
                            setValues({
                              categoryName: "categories",
                              id: val.id,
                            })
                          }
                        />
                      }
                      label={val.categoryName}
                    />
                  </ListItem>
                );
              })}
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ margin: "0px!important" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ fontWeight: "bold" }}>
                {Labels?.skills}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0px!important" }}>
              {skills.map((val, index) => {
                const isChecked = postData?.skills
                  ?.split(",")
                  ?.includes(val.id.toString());
                return (
                  <ListItem sx={{ pt: 0, mt: 0 }} key={index}>
                    <FormControlLabel
                      sx={{
                        ml: 2,
                      }}
                      control={
                        <Checkbox
                          size="sm"
                          checked={isChecked}
                          sx={{
                            color: "#1C614E",
                            "&.Mui-checked": {
                              color: "#1C614E;",
                            },
                          }}
                          onChange={(e) =>
                            setValues({ name: "skills", id: val.id })
                          }
                        />
                      }
                      label={val.name}
                    />
                  </ListItem>
                );
              })}
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ margin: "0px!important" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ fontWeight: "bold" }}>
                {Labels?.Industries}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0px!important" }}>
              {industries.map((val, index) => {
                const isChecked = postData?.industryID?.toString()?.split(",")?.includes(val.id.toString());
                return (
                  <ListItem sx={{ pt: 0, mt: 0 }} key={index}>
                    <FormControlLabel
                      sx={{
                        ml: 2,
                      }}
                      control={
                        <Checkbox
                          size="sm"
                          checked={isChecked}
                          sx={{
                            color: "#1C614E",
                            "&.Mui-checked": {
                              color: "#1C614E;",
                            },
                          }}
                          onChange={(e) =>
                            setValues({ name: "industries", id: val.id })
                          }
                        />
                      }
                      label={val.name}
                    />
                  </ListItem>
                );
              })}
            </AccordionDetails>
          </Accordion>
        </Paper>
      )}

      {!resetbox && (
        <Paper
          sx={{
            mt: "10px",
            background: "#FFFFFF",
            overflowY: "auto",
            maxHeight: "64vh",
            padding: 0,
            boxShadow: "none",
            borderRadius: 2,
          }}
          className={classes.customColoredScrollbar}
        >
          <Accordion sx={{ margin: "0px !important" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ fontWeight: "bold" }}>
                {Labels?.types}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0px!important" }}>
              {jobTypes.map((val, index) => {
                return (
                  <ListItem sx={{ pt: 0, mt: 0 }} key={index}>
                    <FormControlLabel
                      sx={{
                        ml: 2,
                      }}
                      control={
                        <Checkbox
                          size="sm"
                          sx={{
                            color: "#1C614E",
                            "&.Mui-checked": {
                              color: "#1C614E;",
                            },
                          }}
                          onChange={(e) => {
                            setValues({ name: "types", id: val.id });
                            // handleReset();
                          }}
                          //  checked={categoryname !== undefined && categoryname !== null}
                        />
                      }
                      label={val.name}
                    />
                  </ListItem>
                );
              })}
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ margin: "0px!important" }}
            onChange={handleAccordionChange}
            default={postData?.isCategoryMore ? true : false}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ fontWeight: "bold" }}>
                {Labels?.categories}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0px!important" }}>
              {categories.map((val, index) => {
                return (
                  <ListItem sx={{ pt: 0, mt: 0 }} key={index}>
                    <FormControlLabel
                      sx={{
                        ml: 2,
                      }}
                      control={
                        <Checkbox
                          size="sm"
                          sx={{
                            color: "#1C614E",
                            "&.Mui-checked": {
                              color: "#1C614E;",
                            },
                          }}
                          // checked={category.length > 0}
                          onChange={(e) =>
                            setValues({
                              categoryName: "categories",
                              id: val.id,
                            })
                          }
                        />
                      }
                      label={val.categoryName}
                    />
                  </ListItem>
                );
              })}
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ margin: "0px!important" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ fontWeight: "bold" }}>
                {Labels?.skills}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0px!important" }}>
              {skills.map((val, index) => {
                return (
                  <ListItem sx={{ pt: 0, mt: 0 }} key={index}>
                    <FormControlLabel
                      sx={{
                        ml: 2,
                      }}
                      control={
                        <Checkbox
                          size="sm"
                          sx={{
                            color: "#1C614E",
                            "&.Mui-checked": {
                              color: "#1C614E;",
                            },
                          }}
                          onChange={(e) =>
                            setValues({ name: "skills", id: val.id })
                          }
                        />
                      }
                      label={val.name}
                    />
                  </ListItem>
                );
              })}
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ margin: "0px!important" }}
            // defaultExpanded={postData.isMoreIndustry ? true : false}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ fontWeight: "bold" }}>
                {Labels?.Industries}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0px!important" }}>
              {industries.map((val, index) => (
                <ListItem sx={{ pt: 0, mt: 0 }} key={index}>
                  <FormControlLabel
                    sx={{
                      ml: 2,
                    }}
                    control={
                      <Checkbox
                        size="sm"
                        sx={{
                          color: "#1C614E",
                          "&.Mui-checked": {
                            color: "#1C614E;",
                          },
                        }}
                        onChange={(e) =>
                          setValues({ name: "industries", id: val.id })
                        }
                      />
                    }
                    label={val.name}
                  />
                </ListItem>
              ))}
            </AccordionDetails>
          </Accordion>
        </Paper>
      )}
    </Stack>
  );
};

export default JobSerachSideGrid;
