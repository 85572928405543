import React, { useState, useEffect } from "react";
import {
  Avatar,
  Badge,
  Box,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Stack,
  Typography,
  Divider,
  Dialog,
  Skeleton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import chatImage from "../../../Images/chat.png";
import SearchIcon from "@mui/icons-material/Search";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import debounce from "lodash/debounce";
import "./messages.css";
import { axiosAPI, axiosupload } from "../../Axios/Axios";
import { PUBLIC_IMAGE_FOLDER } from "../../Axios/Constant";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import EmojiPicker from "emoji-picker-react";
import { errorNotify, useStyles,warningNotify } from "../../CommonCode/Commonfunc";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
  parseISO,
  isToday
} from "date-fns";
import { useRef } from "react";
import { profanityfn,profanitySend } from "../../Profanity/Profanity";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    width: 10,
    height: 10,
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
}));

function OnlineChat() {
  const classes = useStyles();
  const lastChatMessageRef = useRef(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [user, setUser] = useState({});
  const Labels = useSelector((state) => state.allLabels.labels);

  // -------------------- JobSeeker Data --------------------
  const data = useSelector((state) => state.getJobseekerProfileData.jobseekerDetails);

  const tokens = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const [openChat, setOpenChat] = useState(false);
  const viewDetailedChat = (chat) => {
    setUser(chat);
    if (data.userid === chat.toUser) {
      var fromChatId = chat.fromUser;
      localStorage.setItem("userid", fromChatId);
    } else {
      var toChatId = chat.toUser;
      localStorage.setItem("userid", toChatId);
    }
    getOnlineChat();
  };
  const viewSearchDetailedChat = (searchChat) => {
    setUser(searchChat);
    if (data.userid !== searchChat.userid) {
      var searchId = searchChat.userid;
      localStorage.setItem("userid", searchId);
    }
    getOnlineChat();
  };

  // --------------- Online Chat list--------------------
  const [loading, setloading] = useState(true);

  const setchatview = async (id) => {
    const postdata = {
      type: "chat",
      messageID: id.messageid,
      toUser: id.toUser,
      fromUser: id.fromUser,
    };
    const result = await axiosAPI.post("/readMessage", postdata);
    getOnlineChatList();
  };

  const [onlineChatList, setOnlineChatList] = useState([]);

  const getOnlineChatList = async () => {
    try {
      const result = await axiosAPI.get("/getOnlineChatList");
      const { data, status } = result.data;
      if (status) {
        setOnlineChatList(data);
        setloading(false);
      } else {
        setloading(false);
      }
    } catch (error) {
    }
  };

  // -------------------- Search Member list --------------------
  const [membersList, setMembersList] = useState([]);
  const getMemberList = async (searchKeyword) => {
    const result = await axiosAPI.get(`/membersList?search=${searchKeyword}`);
    const { status, data, message } = result.data;
    if (status) {
      setMembersList(data);
    } else {
      setMembersList([]);
    }
  };
  const debouncedGetMemberList = debounce(getMemberList, 800);

  // -------------------- Emoji --------------------
  const [selectedEmoji, setSelectedEmoji] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const ProfanityList = useSelector((state) => state.Profanity.wordlist);

  function onEmojiClick(emojiData, event) {
    if (profanityfn(ProfanityList, emojiData.emoji).length) {
      setSelectedEmoji(emojiData.unified);
      setChatContent((prevValue) => prevValue + emojiData.emoji);
    }
  }

  function handleInputChange(event) {
    const newValue = profanityfn(ProfanityList, event.target.value);
    setChatContent(newValue);

    if (
      event.nativeEvent.inputType === "deleteContentBackward" &&
      newValue === chatContent
    ) {
      setChatContent((prevContent) =>
        prevContent.replace(/\s*:[^ ]+\s*$/, " ")
      );
    }
  }

  function handleEmojiClick() {
    setShowEmojiPicker((prevState) => !prevState);
  }

  // -------------------- Send Online Chat --------------------
  const [chatContent, setChatContent] = useState("");
  const [attachment, setAttachment] = useState("");
  const [attachmentPreview, setAttachmentPreview] = useState(null);
  const [refreshState, setRefreshState] = useState(false);
  const [btndisable, setbtndisable] = useState(false);

  const sendChatProfanity = (e) => {
    const userId = localStorage.getItem("userid");
    const data = {
      "message": chatContent,
  };

    let profanityDetected;
    for (const key in data) {
      if (profanitySend(ProfanityList, data[key])) {
        profanityDetected = true;
        break;
      } else {
        profanityDetected = false;
      }
    }

    if (profanityDetected == false) {
      sendChat(e);
    } else {
      warningNotify(Labels.ProfanityLabel);
    }
  };

  const sendChat = async (e) => {
    e.preventDefault();
    setbtndisable(true);
    const userId = localStorage.getItem("userid");
    setShowEmojiPicker(false);
    try {
      if ((chatContent || attachment ) && chatContent?.trim().length) {
        
        const data = new FormData();
        if (attachment) {
          data.append("attachment", attachment);
        }
        data.append("message", chatContent);
        data.append("toUser", userId);
        const result = await axiosupload.post("/saveOnlineChat", data, tokens);
        const { success, message } = result.data;
        if (success) {
          setbtndisable(false);
          setChatContent("");
          getOnlineChat();
          getOnlineChatList();
          // Reset attachment and clear attachmentPreview
          setAttachment(null);
          setAttachmentPreview("");
          // Toggle refreshState to trigger a re-render
          setRefreshState((prev) => !prev);
        } else {
          errorNotify(message);
          setbtndisable(false);
        }
      } else {
        errorNotify(Labels.enterMessage);
        setbtndisable(false);
      }
    } catch (error) {
      setbtndisable(false);
    }
  };

  // -------------------- online chat response --------------------
  const [onlineChat, setOnlineChat] = useState([]);
  const getOnlineChat = async () => {
    const userId = localStorage.getItem("userid");

    const result = await axiosAPI.get(`/getOnlineChat?userid=${userId}`);
    const { status, data } = result.data;
    if (status) {
      setOnlineChat(data);
    } else {
      setOnlineChat([]);
    }
  };

  // -------------------- Time Difference --------------------
  const getTimeDifference = (createdOn) => {
    const dateNow = new Date();
    const createdDate = parseISO(createdOn);

    const diffInMinutes = differenceInMinutes(dateNow, createdDate);
    const diffInHours = differenceInHours(dateNow, createdDate);
    const diffInDays = differenceInDays(dateNow, createdDate);
    const diffInMonths = differenceInMonths(dateNow, createdDate);
    const diffInYears = differenceInYears(dateNow, createdDate);

    if (isToday(createdDate)) {
      if (diffInHours < 1) {
        return `${diffInMinutes} ${Labels.minAgo}`;
      } else {
        return `${diffInHours} ${diffInHours === 1 ? Labels.hourAgo : Labels.hoursAgo}`;
      }
    }

    if (diffInDays < 1) {
      if (diffInHours < 1) {
        return `${diffInMinutes} ${Labels.minAgo}`;
      } else {
        return `${diffInHours} ${ diffInHours === 1 ? Labels.hourAgo : Labels.hoursAgo}`;
      }
    } else if (diffInDays == 1) {
      return Labels.Yesterday;
    } else if (diffInDays > 1 && diffInDays < 30) {
      return `${diffInDays} ${diffInDays === 1 ? Labels.dayago : Labels.daysago}`;
    } else if (diffInDays > 30) {
      return `${diffInMonths} ${diffInMonths >= 1 ? Labels.monthAgo : Labels.monthsAgo}`;
    } else{
      return `${diffInYears} ${ diffInYears === 1 ? Labels.yearAgo : Labels.yearsago} `;
    }
  };

  const getTimeDifferenceFormat = (createdOn) => {
    const [datePart, timePart] = createdOn.split(" ");
    const [day, month, year] = datePart.split("-");
    const [hours, minutes, seconds] = timePart.split(":");
    const createdDate = new Date(year, month - 1, day, hours, minutes, seconds);
    const dateNow = new Date();

    const diffInSeconds = Math.floor((dateNow - createdDate) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInMonths = Math.floor(diffInDays / 30);
    const diffInYears = Math.floor(diffInMonths / 12);

    if (diffInSeconds < 60) {
      return `${Labels.Few} ${Labels?.secAgo}`;
    }
    if (diffInMinutes < 60) {
      return `${diffInMinutes} ${Labels?.minAgo}`;
    }
    if (diffInHours < 24) {
      return `${diffInHours} ${ diffInHours === 1 ? Labels.hourAgo : Labels.hoursAgo}`;
    }
    if (diffInDays < 30) {
      return `${diffInDays} ${diffInDays === 1 ? Labels.dayago : Labels.daysago}`;
    }
    if (diffInMonths < 12){
      return `${diffInMonths} ${diffInMonths >= 1 ? Labels.monthAgo : Labels.monthsAgo}`;
    }
    if (diffInMonths >12){
      return `${diffInYears} ${ diffInYears === 1 ? Labels.yearAgo : Labels.yearsago} `;
    }
   
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]
    setAttachment(selectedFile)
    const reader = new FileReader()
    reader.onload = (e) => {setAttachmentPreview(e.target.result)}
    reader.readAsDataURL(selectedFile);
    setRefreshState((prev) => !prev)
  };

  const removeAttachment = () => {
    setAttachment(null);
    setAttachmentPreview(null);
  };

  const [openImage, setOpenImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
    setOpenImage(true);
  };
  
  useEffect(() => {
    window.scrollTo(0, 0);
    getOnlineChatList();
    getOnlineChat();
  }, []);

  useEffect(() => {
    if (lastChatMessageRef.current) {
      lastChatMessageRef.current.scrollTop =
        lastChatMessageRef.current.scrollHeight;
    }
  }, [onlineChat]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      sendChatProfanity(e);
    }
  };

  const handleClearSearch = () => {
    setIsSearching(false);
    setSearchText("");
    // Optionally, you can also clear the input field value here
    // by using a reference or controlled input value.
  };

  return (
    <>
      <Dialog open={openImage} onClose={() => setOpenImage(false)} maxWidth="lg">
      <Stack direction="row" spacing={1} justifyContent={`space-between`} sx={{padding:"20px"}}>
          <img
            src={selectedImage}
            alt="post"
            style={{
              height: `auto`,
              maxHeight:"80vh",
              maxWidth:'100vw',
              borderRadius:"10px",
              margin: 1,
            }}
          />
          <CloseOutlinedIcon onClick={() => setOpenImage(false)} sx={{cursor:"pointer",fontSize:"30px"}}/>
        </Stack>
      </Dialog>

      <Grid container spacing={2}>
        {/* ---------------------------------------- Web view ---------------------------------------- */}
        {Object.keys(user).length === 0 ? (
          <Grid
            item
            xs={12}
            md={6}
            lg={8}
            xl={9}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <Paper elevation={0} sx={{ my: 1 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ p: 1.5 }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 600, color: "#2B4947" }}
                >
                  {Labels?.chat}
                </Typography>
              </Stack>
            </Paper>
            <Paper elevation={0} sx={{ height: "78.5vh" }}>
              <Stack direction="row" justifyContent="center">
                <img src={chatImage} alt="alt" height="700px" />
              </Stack>
            </Paper>
          </Grid>
        ) : (
          /* -------------------- Web view - chat -------------------- */
          <Grid
            item
            xs={12}
            md={6}
            lg={8}
            xl={9}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <Paper
              elevation={0}
              sx={{ my: 1, height: "85vh", position: "relative" }}
            >
              {data?.userid === user.fromUser ? (
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{ p: 1.5 }}
                >
                  <Avatar
                    src={`${user.toUserLogo}`}
                    alt=" "
                    style={{
                      maxWidth: 30,
                      maxHeight: 30,
                    }}
                  />
                  <Typography variant="subtitle1">{user.toUserName}</Typography>
                </Stack>
              ) : null}
              {data?.userid === user.toUser ? (
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{ p: 1.5 }}
                >
                  <Avatar
                    src={`${user.fromUserLogo}`}
                    alt=" "
                    style={{
                      maxWidth: 30,
                      maxHeight: 30,
                    }}
                  />
                  <Typography variant="subtitle1">
                    {user.fromUserName}
                  </Typography>
                </Stack>
              ) : null}
              {user.userid ? (
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{ p: 1.5 }}
                >
                  <Avatar
                    src={`${user.logo}`}
                    alt=" "
                    style={{
                      maxWidth: 30,
                      maxHeight: 30,
                    }}
                  />
                  <Typography variant="subtitle1">{user.name}</Typography>
                </Stack>
              ) : null}
              <Divider />

              <Box
                ref={lastChatMessageRef}
                sx={{
                  overflow: "auto",
                  height: "70vh",
                  background: "#fff",
                  borderRadius: 1,
                  px: 4,

                  zIndex: 1,
                }}
                className={classes.customColoredScrollbar}
              >
                {onlineChat.length > 0 ? (
                  onlineChat.map((item, i) =>
                    item.fromUser !== data?.userid ? (
                      <Stack direction="row" key={i}>
                        <Stack
                          sx={{
                            background: "#F1F6F6",
                            borderRadius: "20px 20px 20px 0px",
                            p: 1,
                            width: "47%",
                            m: 0.5,
                            wordWrap: "break-word",
                          }}
                        >
                          {item.attachmentsLink !== "public/chat/" ? (
                            item?.attachmentsLink?.endsWith("/") ? null : (
                              <img
                                src={`${item.attachmentsLink}`}
                                alt="post"
                                style={{
                                  maxHeight: 300,
                                  maxWidth: 300,
                                  margin: "10px 0",
                                }}
                                onClick={() =>
                                  handleImageClick(`${item.attachmentsLink}`)
                                }
                              />
                            )
                          ) : null}
                          <Typography
                            variant="body2"
                            sx={{ textAlign: "justify" }}
                          >
                            {item?.message}
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{ textAlign: "right" }}
                          >
                            {getTimeDifference(item.createdOn)}
                          </Typography>
                        </Stack>
                      </Stack>
                    ) : (
                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        sx={{ textAlign: "right" }}
                      >
                        <Stack
                          sx={{
                            background: "#F3F2F2",
                            borderRadius: "20px 20px 0px 20px",
                            p: 1,
                            width: "47%",
                            m: 0.5,
                            wordWrap: "break-word",
                          }}
                        >
                          {item.attachmentsLink !== "public/chat/" ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                              }}
                            >
                              {item?.attachmentsLink?.endsWith("/") ? null : (
                                <img
                                  src={`${item.attachmentsLink}`}
                                  alt=""
                                  style={{
                                    maxHeight: 300,
                                    maxWidth: 300,
                                    margin: "10px 0",
                                  }}
                                  onClick={() =>
                                    handleImageClick(`${item.attachmentsLink}`)
                                  }
                                />
                              )}
                            </div>
                          ) : null}
                          <Typography
                            variant="body2"
                            sx={{ textAlign: "justify" }}
                          >
                            {/* <Typography variant="body2"> */}
                            {item?.message}
                          </Typography>
                          <Typography variant="caption">
                            {" "}
                            {getTimeDifference(item.createdOn)}
                          </Typography>
                        </Stack>
                      </Stack>
                    )
                  )
                ) : (
                  <Typography variant="subtitle1">
                    {Labels?.startChat}
                  </Typography>
                )}
                <Divider />
                <Stack direction="row" justifyContent="flex-end">
                  {attachmentPreview ? (
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      spacing={1}
                      sx={{ background: "#F3F2F2", p: 1, width: "47%" }}
                    >
                      <img
                        src={attachmentPreview}
                        alt="Selected Attachment"
                        style={{ maxWidth: "200px", maxHeight: "200px" }}
                      />
                      <CloseOutlinedIcon onClick={removeAttachment} />
                    </Stack>
                  ) : null}
                </Stack>
              </Box>

              {/* <Divider /> */}
              <div style={{ zIndex: 2 }}>
                {showEmojiPicker && (
                  <div className="emoji-picker" style={{ marginTop: -368 }}>
                    <EmojiPicker
                      searchDisabled={true}
                      onEmojiClick={onEmojiClick}
                      style={{ height: 450, width: "100%" }}
                    />
                  </div>
                )}

                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{
                    my: 2,
                    mx: "auto",
                    position: "sticky",
                    bottom: 0,
                    width: "98%",
                  }}
                >
                  <span
                    role="img"
                    aria-label="emoji-icon"
                    onClick={handleEmojiClick}
                    style={{ cursor: "pointer" }}
                  >
                    😊
                  </span>
                  <input
                    type="text"
                    value={chatContent}
                    onKeyDown={(e) => e.key === "Enter" && sendChatProfanity(e)}
                    onChange={handleInputChange}
                    placeholder={Labels.Typehere}
                    style={{
                      padding: "10px",
                      width: "100%",
                      border: "1px solid #6DA09C",
                      borderRadius: 4,
                    }}
                  />
                  <label htmlFor="attachment-input">
                    <IconButton component="span">
                      <AttachFileIcon sx={{ transform: "rotate(45deg)" }} />
                    </IconButton>
                  </label>
                  <input
                    type="file"
                    id="attachment-input"
                    hidden
                    onChange={handleFileChange}
                    onKeyDown={(e) => e.key === "Enter" && sendChatProfanity(e)}
                  />
                  <SendIcon
                    onClick={(e) => (btndisable ? null : sendChatProfanity(e))}
                    sx={{ cursor: "pointer" }}
                  />
                </Stack>
              </div>
            </Paper>
          </Grid>
        )}
        {/* -------------------- Web view - chatlist -------------------- */}
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
          xl={3}
          sx={{ display: { xs: "none", md: "block" } }}
        >
          <Paper elevation={0} sx={{ my: 1, height: "85vh", p: 1 }}>
            <FormControl
              sx={{
                background: "#6DA09C1F",
                color: "#2B4947",
                width: "100%",
              }}
              variant="standard"
            >
              <OutlinedInput
                placeholder={Labels?.searchHere}
                value={searchText}
                endAdornment={
                  <InputAdornment position="start">
                    {isSearching && searchText ? (
                      <IconButton component="span" onClick={handleClearSearch}>
                        <CloseOutlinedIcon />
                      </IconButton>
                    ) : null}
                  </InputAdornment>
                }
                onChange={(e) => {
                  const value = e.target.value;
                  debouncedGetMemberList(value);
                  setSearchText(value);
                  setIsSearching(value.trim() !== "");
                }}
              />
            </FormControl>
            <div style={{overflowY:"auto", maxHeight:"80vh"}}>
              {loading ? (
                <>
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={50}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={50}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={50}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={50}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={50}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={50}
                    style={{ margin: "10px auto" }}
                  />
                </>
              ) : !isSearching && onlineChatList.length > 0 ? (
                onlineChatList.map((item) => (
                  <Box key={item.messageid} >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                      sx={{
                        m: 1,
                        p: 1,
                        background: "#fff",
                        cursor: "pointer",
                        border: !item.messageStatus
                          ? data?.userid === item.fromUser
                            ? "0px"
                            : data?.userid !== item.fromUser
                            ? "2px solid green"
                            : "0px"
                          : "0px",
                        borderRadius: "10px",
                      }}
                      onClick={() => {
                        viewDetailedChat(item);
                        setchatview(item);
                      }}
                    >
                      {data?.userid === item.fromUser ? (
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Avatar
                            src={`${item.toUserLogo}`}
                            alt=" "
                            style={{
                              maxWidth: 30,
                              maxHeight: 30,
                            }}
                          />
                          <Box>
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: 500 }}
                            >
                              {item.toUserName.length > 15
                                ? item.toUserName.slice(0, 15) + "..."
                                : item.toUserName}
                            </Typography>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={0.25}
                            >
                              <Typography variant="subtitle2">
                                {Labels?.you} :{/* {item.fromUserName} */}
                              </Typography>
                              {item.attach.endsWith("/") ? (
                                <Typography variant="body2">
                                  {item?.message?item?.message.length > 15
                                    ? item?.message.slice(0, 15) + "..."
                                    : item?.message:""}
                                </Typography>
                              ) : (
                                <Stack
                                  spacing={0.25}
                                  alignItems="center"
                                  direction="row"
                                >
                                  <InsertPhotoOutlinedIcon
                                    sx={{ color: "#1B1B1BBF", fontSize: 19 }}
                                  />
                                  <Typography variant="body2">
                                    {Labels?.image}
                                  </Typography>
                                </Stack>
                              )}
                            </Stack>
                          </Box>
                        </Stack>
                      ) : null}
                      {data?.userid === item.toUser ? (
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Avatar
                            src={`${item.fromUserLogo}`}
                            alt=" "
                            style={{
                              maxWidth: 30,
                              maxHeight: 30,
                            }}
                          />
                          <Box>
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: 500 }}
                            >
                              {item.fromUserName.length > 15
                                ? item.fromUserName.slice(0, 15) + "..."
                                : item.fromUserName}
                            </Typography>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={0.25}
                            >
                              <Typography variant="subtitle2">
                                {item.fromUserName} :{/* {item.fromUserName} */}
                              </Typography>
                              {item.attach.endsWith("/") ? (
                                <Typography variant="body2">
                                  {item?.message?item?.message.length > 15
                                    ? item?.message.slice(0, 15) + "..."
                                    : item?.message:""}
                                </Typography>
                              ) : (
                                <Stack
                                  spacing={0.25}
                                  alignItems="center"
                                  direction="row"
                                >
                                  <InsertPhotoOutlinedIcon
                                    sx={{ color: "#1B1B1BBF", fontSize: 19 }}
                                  />
                                  <Typography variant="body2">
                                    {Labels?.image}
                                  </Typography>
                                </Stack>
                              )}
                            </Stack>
                          </Box>
                        </Stack>
                      ) : null}
                      <Typography variant="caption">
                        {getTimeDifferenceFormat(item.createdOn, Labels)}
                      </Typography>
                    </Stack>
                  </Box>
                ))
              ) : null}
            </div>
           

            <div style={{overflowY:"auto", maxHeight:"80vh"}}>
              {isSearching && membersList.length > 0 ? (
                membersList.map((item, i) =>
                  data?.userid !== item.userid ? (
                    <Stack
                      key={item.userid}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                      sx={{
                        m: 1,
                        p: 1,
                        background: "#fff",
                        cursor: "pointer",
                      }}
                      onClick={() => viewSearchDetailedChat(item)}
                    >
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Avatar
                          src={`${item.logo}`}
                          alt=" "
                          style={{
                            maxWidth: 30,
                            maxHeight: 30,
                          }}
                        />
                        <Typography variant="subtitle1">{item.name}</Typography>
                      </Stack>
                    </Stack>
                  ) : null
                )
              ) : isSearching ? (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  sx={{
                    m: 1,
                    p: 1,
                    background: "#fff",
                    cursor: "pointer",
                  }}
                >
                  <Typography variant="subtitle1">
                    {Labels?.noMembersFound}
                  </Typography>
                </Stack>
              ) : null}
             </div>

          </Paper>
        </Grid>
      </Grid>

      {/* ---------------------------------------- Mobile view ---------------------------------------- */}
      {!openChat ? (
        /* -------------------- mobile view - chatlist -------------------- */
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
          xl={3}
          sx={{ display: { xs: "block", md: "none" } }}
        >
          <Paper elevation={0} sx={{ my: 1, height: "85vh", p: 1 }}>
            <FormControl
              sx={{
                background: "#6DA09C1F",
                color: "#2B4947",
                width: "100%",
              }}
              variant="standard"
            >
              <OutlinedInput
                placeholder={Labels?.searchHere}
                value={searchText}
                endAdornment={
                  <InputAdornment position="start">
                    {isSearching && membersList ? (
                      <IconButton component="span" onClick={handleClearSearch}>
                        <CloseOutlinedIcon />
                      </IconButton>
                    ) : null}
                  </InputAdornment>
                }
                onChange={(e) => {
                  debouncedGetMemberList(e.target.value);
                  setSearchText(e.target.value);
                  setIsSearching(e.target.value.trim() !== "");
                }}
              />
            </FormControl>
            <div style={{overflowY:"auto", maxHeight:"80vh"}}>
              {!isSearching && onlineChatList.length > 0
                ? onlineChatList.map((item, i) => (
                    <Stack
                      key={item.messageid}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                      sx={{
                        m: 1,
                        p: 1,
                        background: "#fff",
                        cursor: "pointer",
                        border: !item.messageStatus
                          ? data?.userid === item.fromUser
                            ? "0px"
                            : data?.userid !== item.fromUser
                            ? "2px solid green"
                            : "0px"
                          : "0px",
                        borderRadius: "10px",
                      }}
                      onClick={() => {
                        viewDetailedChat(item);
                        setOpenChat(true);
                        setchatview(item);
                      }}
                    >
                      {data?.userid === item.fromUser ? (
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Avatar
                            src={`${item.toUserLogo}`}
                            alt=" "
                            style={{
                              maxWidth: 30,
                              maxHeight: 30,
                            }}
                          />
                          <Box>
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: 500 }}
                            >
                              {item.toUserName.length > 15
                                ? item.toUserName.slice(0, 15) + "..."
                                : item.toUserName}
                            </Typography>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={0.25}
                            >
                              <Typography variant="subtitle2">
                                {Labels?.you} :{/* {item.fromUserName} */}
                              </Typography>

                              {item?.attach?.endsWith("/") ? (
                                <Typography variant="body2">
                                  {item?.message?item?.message.length > 15
                                    ? item?.message.slice(0, 15) + "..."
                                    : item?.message:""}
                                </Typography>
                              ) : (
                                <Stack
                                  spacing={0.25}
                                  alignItems="center"
                                  direction="row"
                                >
                                  <InsertPhotoOutlinedIcon
                                    sx={{ color: "#1B1B1BBF", fontSize: 19 }}
                                  />
                                  <Typography variant="body2">
                                    {Labels?.image}
                                  </Typography>
                                </Stack>
                              )}
                            </Stack>
                          </Box>
                        </Stack>
                      ) : null}
                      {data?.userid === item.toUser ? (
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Avatar
                            src={`${item.fromUserLogo}`}
                            alt=" "
                            style={{
                              maxWidth: 30,
                              maxHeight: 30,
                            }}
                          />
                          <Box>
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: 500 }}
                            >
                              {item.fromUserName.length > 15
                                ? item.fromUserName.slice(0, 15) + "..."
                                : item.fromUserName}
                            </Typography>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={0.25}
                            >
                              {item?.attach?.endsWith("/") ? (
                                <Typography variant="body2">
                                  {item?.message?item?.message.length > 15
                                    ? item?.message.slice(0, 15) + "..."
                                    : item?.message:""}
                                </Typography>
                              ) : (
                                <Stack
                                  spacing={0.25}
                                  alignItems="center"
                                  direction="row"
                                >
                                  <InsertPhotoOutlinedIcon
                                    sx={{ color: "#1B1B1BBF", fontSize: 19 }}
                                  />
                                  <Typography variant="body2">
                                    {Labels?.image}
                                  </Typography>
                                </Stack>
                              )}
                            </Stack>
                          </Box>
                        </Stack>
                      ) : null}
                      <Typography variant="caption">
                        {getTimeDifferenceFormat(item.createdOn, Labels)}
                      </Typography>
                    </Stack>
                  ))
                : null}
            </div>

            <div style={{overflowY:"auto", maxHeight:"80vh"}}>
              {isSearching && membersList.length > 0 ? (
                membersList.map((item, i) =>
                  data?.userid !== item.userid ? (
                    <Stack
                      key={item.userid}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                      sx={{
                        m: 1,
                        p: 1,
                        background: "#fff",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        viewSearchDetailedChat(item);
                        setOpenChat(true);
                      }}
                    >
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Avatar
                          src={`${item.logo}`}
                          alt=" "
                          style={{
                            maxWidth: 30,
                            maxHeight: 30,
                          }}
                        />
                        <Typography variant="subtitle1">{item.name}</Typography>
                      </Stack>
                    </Stack>
                  ) : null
                )
              ) : isSearching ? (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  sx={{
                    m: 1,
                    p: 1,
                    background: "#fff",
                    cursor: "pointer",
                  }}
                >
                  <Typography variant="subtitle1">
                    {Labels?.noMembersFound}
                  </Typography>
                </Stack>
              ) : null}
             </div>

          </Paper>
        </Grid>
      ) : (
        /* -------------------- mobile view - chat -------------------- */
        <Grid
          item
          xs={12}
          md={6}
          lg={8}
          xl={9}
          sx={{ display: { xs: "block", md: "none" } }}
        >
          <Paper
            elevation={0}
            sx={{ my: 1, height: "85vh", position: "relative" }}
            // key={i}
          >
            {data?.userid === user.fromUser ? (
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ p: 1.5 }}
              >
                <ArrowBackIcon
                  onClick={() => setOpenChat(false)}
                  sx={{ cursor: "pointer" }}
                />
                <Avatar
                  src={`${user.toUserLogo}`}
                  alt=" "
                  style={{
                    maxWidth: 30,
                    maxHeight: 30,
                  }}
                />
                <Typography variant="subtitle1">{user.toUserName}</Typography>
              </Stack>
            ) : null}
            {data?.userid === user.toUser ? (
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ p: 1.5 }}
              >
                <ArrowBackIcon
                  onClick={() => setOpenChat(false)}
                  sx={{ cursor: "pointer" }}
                />
                <Avatar
                  src={`${user.fromUserLogo}`}
                  alt=" "
                  style={{
                    maxWidth: 30,
                    maxHeight: 30,
                  }}
                />
                <Typography variant="subtitle1">{user.fromUserName}</Typography>
              </Stack>
            ) : null}
            {user.userid ? (
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ p: 1.5 }}
              >
                <ArrowBackIcon
                  onClick={() => setOpenChat(false)}
                  sx={{ cursor: "pointer" }}
                />
                <Avatar
                  src={`${user.logo}`}
                  alt=" "
                  style={{
                    maxWidth: 30,
                    maxHeight: 30,
                  }}
                />
                <Typography variant="subtitle1">{user.name}</Typography>
              </Stack>
            ) : null}
            <Divider />
            <Box
              ref={lastChatMessageRef}
              sx={{
                overflow: "auto",
                height: "66vh",
                background: "#fff",
                borderRadius: 1,
                px: 4,
                my: 2,
                zIndex: 1,
              }}
              className={classes.customColoredScrollbar}
            >
              {onlineChat.length > 0 ? (
                onlineChat.map((item, i) =>
                  item.fromUser !== data?.userid ? (
                    <Stack direction="row">
                      <Stack
                        sx={{
                          background: "#F1F6F6",
                          borderRadius: "20px 20px 20px 0px",
                          p: 1,
                          width: "47%",
                          m: 0.5,
                          wordWrap: "break-word",
                        }}
                      >
                        {item.attachmentsLink !== "public/chat/" ? (
                          item?.attachmentsLink?.endsWith("/") ? null : (
                            <img
                              src={`${item.attachmentsLink}`}
                              alt="post"
                              style={{
                                maxHeight: 100,
                                maxWidth: 100,
                                margin: "10px 0",
                              }}
                              onClick={() =>
                                handleImageClick(`${item.attachmentsLink}`)
                              }
                            />
                          )
                        ) : null}
                        <Typography variant="body2">{item?.message}</Typography>
                        <Typography
                          variant="caption"
                          sx={{ textAlign: "right" }}
                        >
                          {" "}
                          {getTimeDifference(item.createdOn)}
                        </Typography>
                      </Stack>
                    </Stack>
                  ) : (
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      sx={{ textAlign: "right" }}
                    >
                      <Stack
                        sx={{
                          background: "#F3F2F2",
                          borderRadius: "20px 20px 0px 20px",
                          p: 1,
                          width: "47%",
                          m: 0.5,
                          wordWrap: "break-word",
                        }}
                      >
                        {item.attachmentsLink !== "public/chat/" ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                            }}
                          >
                            {item?.attachmentsLink?.endsWith("/") ? null : (
                              <img
                                src={`${item.attachmentsLink}`}
                                alt="post"
                                style={{
                                  maxHeight: 100,
                                  maxWidth: 100,
                                  margin: "10px 0",
                                }}
                                onClick={() =>
                                  handleImageClick(`${item.attachmentsLink}`)
                                }
                              />
                            )}
                          </div>
                        ) : null}
                        <Typography variant="body2">{item?.message}</Typography>
                        <Typography variant="caption">
                          {getTimeDifference(item.createdOn)}
                        </Typography>
                      </Stack>
                    </Stack>
                  )
                )
              ) : (
                <Typography variant="subtitle1">{Labels?.startChat}</Typography>
              )}
              <Stack direction="row" justifyContent="flex-end">
                {attachmentPreview ? (
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    spacing={1}
                    sx={{ background: "#F3F2F2", p: 1, width: "47%" }}
                  >
                    <img
                      src={attachmentPreview}
                      alt="Selected Attachment"
                      style={{ maxWidth: "100px", maxHeight: "100px" }}
                    />
                    <CloseOutlinedIcon onClick={removeAttachment} />
                  </Stack>
                ) : null}
              </Stack>
            </Box>
            <Divider />
            <div style={{ zIndex: 2 }}>
              {showEmojiPicker && (
                <div className="emoji-picker" style={{ marginTop: -350 }}>
                  <EmojiPicker
                    searchDisabled={true}
                    onEmojiClick={onEmojiClick}
                    style={{ height: 450, width: "100%" }}
                  />
                </div>
              )}
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{
                  my: 2,
                  mx: "auto",
                  position: "sticky",
                  bottom: 0,
                  width: "98%",
                }}
              >
                <span
                  role="img"
                  aria-label="emoji-icon"
                  onClick={handleEmojiClick}
                  style={{ cursor: "pointer" }}
                >
                  😊
                </span>
                <input
                  type="text"
                  value={chatContent}
                  onKeyDown={(e) => e.key === "Enter" && sendChatProfanity(e)}
                  onChange={handleInputChange}
                  placeholder={Labels.Typehere}
                  style={{
                    padding: "10px",
                    width: "100%",
                    border: "1px solid #6DA09C",
                    borderRadius: 4,
                  }}
                />
                <label htmlFor="attachment-input">
                  <IconButton component="span">
                    <AttachFileIcon sx={{ transform: "rotate(45deg)" }} />
                  </IconButton>
                </label>
                <input
                  type="file"
                  id="attachment-input"
                  hidden
                  onChange={handleFileChange}
                  onKeyDown={(e) => e.key === "Enter" && sendChatProfanity(e)}
                />
                <SendIcon
                  onClick={(e) => (btndisable ? null : sendChatProfanity(e))}
                  sx={{ cursor: "pointer" }}
                />
              </Stack>
            </div>
          </Paper>
        </Grid>
      )}
    </>
  );
}

export default OnlineChat;
