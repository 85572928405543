import "react-toastify/dist/ReactToastify.css";
import { Flip, toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import { debounce } from "lodash";

let debouncedErrorNotify = null;

export const succesNotify = (message) => {
  if (!debouncedErrorNotify) {
    toast.success(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      transition: Flip,
      theme: "colored",
    });
    
    debouncedErrorNotify = debounce(() => {debouncedErrorNotify = null}, 2000);
    debouncedErrorNotify();
  } else {
    debouncedErrorNotify();
  }
};

export const errorNotify = (message) => {
  if (!debouncedErrorNotify) {
    toast.error(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      transition: Flip,
      theme: "colored",
    });
    debouncedErrorNotify = debounce(() => {
      debouncedErrorNotify = null;
    }, 2000);
    debouncedErrorNotify();
  } else {
    debouncedErrorNotify();
  }
};

export const warningNotify = (message) => {
  if (!debouncedErrorNotify) {
    toast.warning(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      transition: Flip,
      theme: "colored",
    });
    debouncedErrorNotify = debounce(() => {
      debouncedErrorNotify = null;
    }, 2000);
    debouncedErrorNotify();
  } else {
    debouncedErrorNotify();
  }
};

export const infoNotify = (message) => {
  if (!debouncedErrorNotify) {
    toast.info(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      transition: Flip,
      theme: "colored",
    });
    debouncedErrorNotify = debounce(() => {
      debouncedErrorNotify = null;
    }, 2000);
    debouncedErrorNotify();
  } else {
    debouncedErrorNotify();
  }
};




export const isValidEmail = (email) => {
  return /\S+@\S+\.\S+/.test(email);
};

export function validatePassword(password) {
  const minLength = 8;
  const maxLength = 30;
  const uppercaseRegex = /[A-Z]/;
  const numericRegex = /[0-9]/;
  const specialCharRegex = /[!@#$%^&*]/;

  if (password.length < minLength || password.length > maxLength) {
    return "Password must be between 8 and 30 characters.";
  } else if (!uppercaseRegex.test(password)) {
    return "Password must contain at least one uppercase letter.";
  } else if (!numericRegex.test(password)) {
    return "Password must contain at least one numeric digit.";
  } else if (!specialCharRegex.test(password)) {
    return "Password must contain at least one special character.";
  } else {
    return "strong";
  }
}

export function validatePhoneNumber(phoneNumber) {
  const phoneNumberPattern = /^\d{15}$/;
  return phoneNumberPattern.test(phoneNumber);
}
export const useStyles = makeStyles(() => ({
  customColoredScrollbar: {
    scrollbarWidth: "thin", // Firefox compatibility
    "-ms-overflow-style": "none", // IE and Edge compatibility
    scrollbarColor: "#1c614e29 transparent", // Firefox compatibility
  },

  customScrollbar: {
    scrollbarWidth: "none", // Firefox compatibility
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none", // IE and Edge compatibility
    scrollbarColor: "transparent transparent", // Firefox compatibility
  },
}));
