import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CustomButton from "../../Components/CustomButton";
import { errorNotify, succesNotify } from "../../CommonCode/Commonfunc";
import { axiosAPI } from "../../Axios/Axios";
import { useSelector } from "react-redux";

export default function JobDeleteAlert({
  open,
  handleClose,
  jobId,
  setIsApiCall,
}) {
  const Labels = useSelector((state) => state.allLabels.labels);
  const deleteJob = async () => {
    try {
      const postData = { jobid: jobId, language:localStorage.getItem("language") };
      const result = await axiosAPI.post("/deleteJob", postData);
      const { status, message } = result.data;
      if (status) {
        succesNotify(message);
        handleClose(false);
        setIsApiCall(Math.random());
      } else {
        errorNotify(message);
      }
    } catch (error) {
    }
  };
  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minWidth: "300px",
          padding: "20px",
        },
      }}
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        {Labels?.areYouSure}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{ textAlign: "center", fontFamily: "poppins" }}
        >
          {Labels?.wantToDelete}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center", marginTop: "20px" }}>
        <CustomButton
          label={Labels?.delete}
          style={{
            width: 100,
            borderRadius: "3px",
            fontSize: "14px",
            mb: 2,
          }}
          onClick={deleteJob}
        />
        <CustomButton
          label={Labels?.Cancel}
          style={{
            width: 100,
            borderRadius: "3px",
            fontSize: "14px",
            mb: 2,
          }}
          onClick={() => handleClose(false)}
        />
      </DialogActions>
    </Dialog>
  );
}
