import {
  Avatar,
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import InboxIcon from "@mui/icons-material/Inbox";
import SendIcon from "@mui/icons-material/Send";
import ReplyRoundedIcon from "@mui/icons-material/ReplyRounded";
import SearchIcon from "@mui/icons-material/Search";
import FullscreenExitRoundedIcon from "@mui/icons-material/FullscreenExitRounded";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { axiosAPI } from "../../Axios/Axios";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import EnquiryReplayModal from "../EmployerComponents/EnquiryReplayModal";
import { PUBLIC_IMAGE_FOLDER } from "../../Axios/Constant";
import { parseISO } from "date-fns";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import EmailIcon from "@mui/icons-material/Email";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "left",
  color: "black",
  boxShadow: "none",
}));

export default function JobEnquires({ id }) {
  const [isApiCall, setApiCall] = useState(0);
  const [enquiryMessages, setEnquiryMessages] = useState([]);
  const [skeletonLoading, setLoading] = useState(false);

  const getEnquiryMessagesList = async () => {
    setLoading(true);
    const result = await axiosAPI.get(`/getEnquiryMessage?jobid=${id}`);
    const { status, data } = result.data;
    
    if (status) {
      setLoading(false);
      setEnquiryMessages(data);
    } else {
      setLoading(false);
    }
  };


  useEffect(() => {
    window.scrollTo(0, 0);
    getEnquiryMessagesList();
  }, [id,isApiCall]);

  // --------------replay enquiry message----------------------
  const [enquiryReplayModal, setEnquiryReplayModal] = useState(false);
  const [enquiryMessage, setEnquiryMessage] = useState({});

  const enquiryReplay = (e, data) => {
    e.stopPropagation();
    setEnquiryMessage(data);
    setEnquiryReplayModal(true);
  };
  const employerLoginData = useSelector(
    (state) => state.createLoginDetails.loginDetails
  );
  const Labels = useSelector((state) => state.allLabels.labels);

  return (
    <Paper elevation={0} sx={{ height: "75vh", p: 2, overflowY: "auto" }}>
      <EnquiryReplayModal
        open={enquiryReplayModal}
        handleClose={setEnquiryReplayModal}
        data={enquiryMessage}
        EmployerId={employerLoginData?.uld_id}
        setApiCall={setApiCall}
      />
      <>
        {skeletonLoading && (
          <Stack spacing={1}>
            <Skeleton variant="rounded" width={`100%`} height={100} />
            <Skeleton variant="rounded" width={`100%`} height={100} />
            <Skeleton variant="rounded" width={`100%`} height={100} />
            <Skeleton variant="rounded" width={`100%`} height={100} />
            <Skeleton variant="rounded" width={`100%`} height={100} />
            <Skeleton variant="rounded" width={`100%`} height={100} />
            <Skeleton variant="rounded" width={`100%`} height={100} />
          </Stack>
        )}

        <TableContainer>
          <Table>
            <TableBody>
              {enquiryMessages.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography>{Labels?.EnquiriesEmpty}</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {enquiryMessages.map((data, index) => (
                    <>
                      <Accordion
                        sx={{
                          boxShadow: "none",
                          border: "1px solid #F0E5E5",
                        }}
                        key={index}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Stack
                            direction="row"
                            spacing={4}
                            alignItems="center"
                            width="100%"
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                minWidth: 120,
                              }}
                            >
                              <Avatar src={`${data.logo}`} sx={{ mr: 1 }} />
                              <Typography sx={{ mr: 1 }}>
                                {data?.displayName.length > 15
                                  ? data?.displayName.slice(0, 15) + "..."
                                  : data?.displayName}{" "}
                                {data?.replies.length >= 1 && (
                                  <span style={{ fontSize: "13px" }}>
                                    {data?.replies.length + 1}
                                  </span>
                                )}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography sx={{ width: "100%" }}>
                                {data.message.length > 50
                                  ? data?.message.slice(0, 50) + "..."
                                  : data?.message}
                              </Typography>
                            </Box>
                            <Box>
                              <Chip
                                icon={<ReplyRoundedIcon />}
                                label={Labels.Reply}
                                variant="outlined"
                                sx={{
                                  cursor: "pointer!important",
                                  mr: 3,
                                }}
                                onClick={(e) => enquiryReplay(e, data)}
                              />
                              <Typography variant="caption">
                                {data.createdOn}
                              </Typography>
                            </Box>
                          </Stack>
                        </AccordionSummary>
                        {data.message.length > 50 ? (
                          <AccordionDetails
                            sx={{
                              border: "1px solid #F0E5E5",
                              color: "#84827D",
                            }}
                          >
                            {data.message}
                          </AccordionDetails>
                        ) : (
                          ""
                        )}

                        {data.replies
                          ? Array.isArray(data.replies) &&
                            data.replies.map((data, index) => (
                              <AccordionDetails
                                sx={{
                                  border: "1px solid #F0E5E5",
                                  width: "90%",
                                  margin: "10px auto",
                                }}
                              >
                                {/* {data.createdOn} */}
                                <Grid container spacing={2}>
                                  <Grid item xs={1}>
                                    <Item>{Labels.From}</Item>
                                  </Grid>
                                  <Grid item xs={10}>
                                    <Item>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={1}
                                      >
                                        : &nbsp;
                                        {data?.fromUserLogo?.endsWith(
                                          "/"
                                        ) ? null : (
                                          <Avatar
                                            src={`${data?.fromUserLogo}`}
                                            sx={{
                                              maxWidth: 50,
                                              maxHeight: 50,
                                              borderRadius: "100%%",
                                            }}
                                          />
                                        )}
                                        <Box>
                                          <Typography
                                            variant="subtitle1"
                                            sx={{ fontWeight: 500 }}
                                          >
                                            {data?.fromUserName}
                                          </Typography>
                                        </Box>
                                      </Stack>
                                    </Item>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                  <Grid item xs={1}>
                                    <Item>{Labels.To}</Item>
                                  </Grid>
                                  <Grid item xs={10}>
                                    <Item>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={1}
                                      >
                                        : &nbsp;
                                        {data?.toUserLogo?.endsWith(
                                          "/"
                                        ) ? null : (
                                          <Avatar
                                            src={`${data?.toUserLogo}`}
                                            sx={{
                                              maxWidth: 50,
                                              maxHeight: 50,
                                              borderRadius: "100%%",
                                            }}
                                          />
                                        )}
                                        <Box>
                                          <Typography
                                            variant="subtitle1"
                                            sx={{ fontWeight: 500 }}
                                          >
                                            {data?.toUserName}
                                          </Typography>
                                        </Box>
                                      </Stack>
                                    </Item>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={0.5}>
                                  <Grid item xs={1}>
                                    <Item>{Labels.Message}</Item>
                                  </Grid>
                                  <Grid item xs={0}>
                                    <Item> :</Item>
                                  </Grid>
                                  <Grid item xs={10}>
                                    <Item> {data?.message}</Item>
                                  </Grid>
                                </Grid>
                              </AccordionDetails>
                            ))
                          : ""}
                      </Accordion>
                    </>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </Paper>
  );
}
