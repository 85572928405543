import {
  Box,
  Button,
  Grid,
  Dialog,
  Stack,
  Typography,
  Skeleton,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import React from "react";
import { useState } from "react";
import LanguageLevel from "../../CommonCode/LanguageLevel";
import AllLanguage from "../../CommonCode/AllLanguage";
import { axiosAPI } from "../../Axios/Axios";
import { errorNotify, succesNotify } from "../../CommonCode/Commonfunc";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import LanguageLevelJobSeeker from "../../CommonCode/LanguageLevelJobseeker";
import AllLanguageJobSeeker from "../../CommonCode/AllLanguagesjobseeker";

export default function LanguageEditModal({ open, handleClose, languageData }) {
  const [language, setLanguage] = useState("");
  const [languageReadLevel, setLanguageReadLevel] = useState("");
  const [languageWriteLevel, setLanguageWriteLevel] = useState("");

  const [isdisabled,setisdisabled] = useState(false)

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const setLanguageLevel = () => {
      if (Object.keys(languageData).length != 0) {
        setLanguage(languageData?.languageid);
        setLanguageReadLevel(languageData?.spokenid);
        setLanguageWriteLevel(languageData?.writtenid);
      }

      setTimeout(() => {
        setLoading(false)
      }, 1500);
    };

    setLoading(true)
    setLanguageLevel();
  }, [languageData]);

  const editLanguage = async () => {
    if (!language) {
      return errorNotify(Labels.languagefieldisrequird);
    } else if (!languageReadLevel) {
      return errorNotify(Labels.Spokenlevelfieldisrequired);
    } else if (!languageWriteLevel) {
      return errorNotify(Labels.Writtenlevelfieldisrequired);
    }
    const postData = {
      id: languageData?.id,
      language: language,
      written: languageWriteLevel,
      spoken: languageReadLevel,
      // language: localStorage.getItem("language"),
    };  
    setisdisabled(true)
    const result = await axiosAPI.post("/updateLanguageLevel", postData);
    const { status, message } = result.data;
    if (status) {
      setisdisabled(false)
      succesNotify(message);
      handleClose();
      setLanguage("");
      setLanguageReadLevel("");
      setLanguageWriteLevel("");
    } else {
      errorNotify(message);
    }
  };

  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <Dialog
      open={open}
      onClose={() => { handleClose(false)}}
    >
      <Box sx={{ px: 1.5, py: 0 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ my: 2 }}
        >
          <Typography variant="h6" sx={{ color: "#1C614E" }}>
            {Labels?.language}
          </Typography>
          <HighlightOffIcon
            sx={{
              color: "#1C614E",
              cursor: "pointer",
            }}
            onClick={() => handleClose(true)}
          />
        </Stack>
        <Box sx={{ my: 1 }}>
          <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
            {loading && (
              <>
                <Box sx={{ width: 200 }}>
                  <Skeleton variant="text" height={40} />
                </Box>
                <Box sx={{ width: 200 }}>
                  <Skeleton variant="text" height={40} />
                </Box>
                <Box sx={{ width: 200 }}>
                  <Skeleton variant="text" height={40} />
                </Box>
              </>
            )}

            <Box sx={{ width: 200, display: loading ? "none" : "block" }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                {Labels?.Spoken}
              </Typography>
              <AllLanguageJobSeeker
                value={language}
                setValue={setLanguage}
                label="Languages"
              />
            </Box>
            <Box sx={{ width: 200, display: loading ? "none" : "block" }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                {Labels.Read}
              </Typography>
              <LanguageLevelJobSeeker
                value={languageReadLevel}
                setValue={setLanguageReadLevel}
                label={Labels.Spoken}
              />
            </Box>
            <Box sx={{ width: 200, display: loading ? "none" : "block" }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                {Labels?.Written}
              </Typography>
              <LanguageLevelJobSeeker
                value={languageWriteLevel}
                setValue={setLanguageWriteLevel}
                label={Labels?.Written}
              />
            </Box>
          </Stack>
        </Box>
        <Stack direction="row" justifyContent="center" sx={{ p: 1.5 }}>
          <Button
            variant="solid"
            disabled={isdisabled}
            sx={{
              background: "#1C614E",
              borderRadius: 3,
              color: "#fff",
              "&:hover": {
                backgroundColor: "#1C614E",
              },
              "&:active": {
                backgroundColor: "#1C614E",
              },
              "&:disabled": { color: "white", backgroundColor: "#1C614E" },
            }}
            onClick={editLanguage}
          >
            {Labels?.Save}
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
}
