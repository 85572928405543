import { Dialog, DialogActions, DialogTitle, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { errorNotify, succesNotify } from "../../CommonCode/Commonfunc";
import { axiosAPI } from "../../Axios/Axios";
import CustomButton from "../../Components/CustomButton";

function DeleteCompany({ open, handleClose, companyid, getAllCompanies }) {
  const redirect = useNavigate();
  const Labels = useSelector((state) => state.allLabels.labels);
  const deleteCompamy = async () => {
    if (companyid) {
      try {
        const response = await axiosAPI.post("deleteCompany", {
          companyid: companyid,
          language:localStorage.getItem("language")
        });
        const { success, message } = response.data;
        if (success) {
          succesNotify(message);
          handleClose();
          setTimeout(() => {
            getAllCompanies();
          }, 500);
        } else {
          errorNotify(message);
        }
      } catch (error) {
      }
    } else {
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Typography variant="subtitle1">
          {Labels?.areYouSure} {Labels?.wantToDelete}
        </Typography>
      </DialogTitle>
      <DialogActions sx={{ py: 1, px: 4 }}>
        <CustomButton
          label={Labels?.delete}
          style={{
            width: `100%`,
            borderRadius: "3px",
            fontSize: "14px",
            mb: 2,
          }}
          onClick={deleteCompamy}
        />
        <CustomButton
          label={Labels?.Cancel}
          style={{
            width: `100%`,
            borderRadius: "3px",
            fontSize: "14px",
            mb: 2,
          }}
          onClick={handleClose}
        />
      </DialogActions>
    </Dialog>
  );
}

export default DeleteCompany;
