import { Paper, Typography ,Stack,} from "@mui/material";
import React from "react";

const PageHeading = ({ pageHeading }) => {
  return (
    <>
    <Stack display={{ xs: "none", md: "block" }}>
      <Paper
        sx={{
          height: 225,
          width: "100%",
          backgroundColor: "#1C614E",
      
          display: "flex",

          justifyContent: "center",
          alignItems: "center",
          marginTop: { xs: "55px", sm: "75px" },
        }}
      >
        <Typography
          component="h2"
          variant="h2"
          color="inherit"
          sx={{ color: "#fff" }}
        >
          {pageHeading}
        </Typography>
      </Paper>
      </Stack>
      <Stack display={{ xs: "block", md: "none" }}>
      <Paper
        sx={{
          height: 140,
          width: "100%",
          backgroundColor: "#1C614E",
          justifyContent:"center",
          alignItems: "center",
          marginTop: { xs: "55px", sm: "75px" },
          display: "flex",
        }}
      >
        <Typography
          component="h2"
          variant="h3"
          color="inherit"
          sx={{ color: "#fff" }}
        >
          {pageHeading}
        </Typography>
      </Paper>
      </Stack>
    </>
  );
};

export default PageHeading;
