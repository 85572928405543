import {
  Box,
  Chip,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Button, Textarea } from "@mui/joy";
import { errorNotify, succesNotify,warningNotify } from "../../CommonCode/Commonfunc";
import { axiosAPI, axiosupload } from "../../Axios/Axios";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import TextfieldCustom from "../../Components/TextfieldCustom";
import { useSelector } from "react-redux";
import {profanityfn,profanitySend}  from "../../Profanity/Profanity";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { md: 450 },
  bgcolor: "#FFFFFF",
  boxShadow: 24,
  p: 2,
  m: 1,
  borderRadius: "8px",
};

export default function ReplayEnquiryModal({
  open,
  handleClose,
  EmployerId,
  data,
  setApiCall,
}) {
  const [message, setMessage] = useState("");
  const [isButtonDisable, setButtonDisable] = useState(false);

  useEffect(()=>{
    if(!open){
      setMessage('')
    }
  },[open])

  const sendChatProfanity = (e) => {
    const data = {
      "message": message,
  };

    let profanityDetected;
    for (const key in data) {
      if (profanitySend(ProfanityList, data[key])) {
        profanityDetected = true;
        break;
      } else {
        profanityDetected = false;
      }
    }

    if (profanityDetected == false) {
      sendMessage(e);
    } else {
      warningNotify(Labels.ProfanityLabel);
    }
  };

  const sendMessage = async () => {
    if (!message || message.trim().length==0) {
      errorNotify(Labels.writeMessage);
      return;
    } else if (message.length > 1000) {
      errorNotify(Labels.messageValidation);
      return;
    } else {
      setButtonDisable(true);

      const result = await axiosAPI.post("/saveEnquiry", {
        parentID: data.id,
        jobid: data.jobid,
        message: message,
      });
      const { status, message: responseMessage } = result.data;
      setMessage("");
      if (status) {
        succesNotify(responseMessage);
        setApiCall(Math.random());
        handleClose(false);
        setButtonDisable(false);
      } else {
        errorNotify(responseMessage);
        setButtonDisable(false);
      }
    }
  };

  const Labels = useSelector((state) => state.allLabels.labels);
  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)

  return (
    <Fragment>
      <Modal
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              mb: 1,
            }}
          >
            <HighlightOffIcon
              sx={{
                color: "#1C614E",
                cursor: "pointer",
              }}
              onClick={() => handleClose(false)}
            />
          </Box>

          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            {Labels?.To} :&nbsp;
            <Chip label={data?.companyName} />
          </Typography>
          <Box
            sx={{
              margin: "10px 0px",
              maxHeight: "80vh",
              overflowY: "auto",
              overflowX: "hidden",
              p: 1.5,
            }}
          >
            <br />
            <Textarea
              minRows={5}
              placeholder={Labels.Typehere}
              value={message}
              onChange={(e) => setMessage(profanityfn(ProfanityList,e.target.value))}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              pt: 1,
            }}
          >
            {/* <div>
                <IconButton onClick={handleAttachFile}>
                  <AttachFileIcon />
                </IconButton>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileInputChange}
                />
                {selectedFile ? (
                  <span style={{ marginLeft: "8px" }}>{selectedFile}</span>
                ) : (
                  <span style={{ marginLeft: "8px" }}>Attach a file</span>
                )}
              </div> */}
            <Button
              variant="solid"
              sx={{
                background: "#1C614E",
                ml: 0.5,
                width: 90,
                borderRadius: "5px",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontStyle: "normal",
                fontSize: "15px",
                "&:hover": {
                  backgroundColor: "#1C614E",
                },
                "&:active": {
                  backgroundColor: "#1C614E",
                },
                "&:disabled": { color:"white",
                  backgroundColor: "#1C614E",
                },
              }}
              disabled={isButtonDisable}
              onClick={sendChatProfanity}
            >
              {Labels?.Send}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Fragment>
  );
}
