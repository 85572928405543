import React, { Fragment, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { axiosAPI } from "../Axios/Axios";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyProfile } from "../../redux/CompanyDetailSlice";
import { profanityfn } from "../Profanity/Profanity";
export default function BusinessCategory({
  value,
  setValue,
  style,
  isDisable,
  placeholder,
  companyinfobackup=""
}) {
  const [businessCategory, setBusinessCategory] = useState([]);
  const usertype = localStorage.getItem("userType");


  useEffect(() => {
    const getBusinessCategory = async () => {
      const result = await axiosAPI.get("/getBusinessCategory");
      const { success, data } = await result.data;
      if (success === true) {
        setBusinessCategory(data);
      } else {
        setBusinessCategory([]);
      }
    };
    getBusinessCategory();
  }, []);
  const ProfanityList = useSelector((state) => state.Profanity.wordlist);

  const handleChange = (event) => {
    setValue(profanityfn(ProfanityList, event.target.value));
  };
  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <FormControl sx={{ width: "100%" }}>
      <Select
        value={value}
        onChange={handleChange}
        fullWidth
        displayEmpty
        disabled={usertype == "Recruiter" ? true : false}
        variant="outlined"
        sx={{
          position: "relative",
          left:"15px",
          fontFamily: "Poppins",
          border: `2px solid ${companyinfobackup == value ? "black":"green"}`,
          "& fieldset": {
            border: "none", // Remove the default border
          },
          "&:hover": {
            border: `2px solid ${companyinfobackup == value ? "black":"green"}`,
          },
          "&.Mui-focused": {
            border: `2px solid ${companyinfobackup == value ? "black":"green"}`,
          },
          ...style,
        }}
      >
        <MenuItem value="" disabled>
          <span style={{ color: "#bcbcbc", fontSize: "14px" }}>
            {placeholder || Labels?.BusinessIndustry}
          </span>
        </MenuItem>

        {businessCategory &&
          businessCategory.map((val, index) => {
            return (
              <MenuItem key={index} value={val.id}>
                {val.name}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
}
