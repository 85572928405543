import React, { useEffect, useState } from "react";
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { axiosAPI } from "../Axios/Axios";
import { PUBLIC_IMAGE_FOLDER } from "../Axios/Constant";
import dummyLogo from "../../Images/dummyLogo.png";
import { useNavigate } from "react-router-dom";
import PageHeading from "./PageHead";
import { useSelector } from "react-redux";

export default function AllCompanies() {
  const redirect = useNavigate();
  const [companies, setCompanies] = useState([]);

  const getCompaniesList = async () => {
    try {
      const result = await axiosAPI.get("/getCompaniesList");
      const { data, status } = result.data;
      if (status) {
        setCompanies(data);
      }
    } catch (error) {
    }
  };
  useEffect(() => {
    getCompaniesList();
  }, []);

  const searchJobsByCompany = (companyId) => {
    const postData = { search: "", city: "", employerID: companyId };
    redirect("/jobSearchPrelogin", { state: postData });
  };

  const viewCompanyDetails = (companyId) => {
    redirect(`/company/${companyId}`)
  }
  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <div>
      <PageHeading pageHeading={Labels?.Companies} />
      <Container
        maxWidth="xl"
        sx={{
          paddingTop: {
            xs: "30px",
          },
        }}
      >
        <Grid container spacing={4}>
          {companies.length === 0 ? (
            <Grid item xs={12}>
              <Typography variant="h6">{Labels?.Nocompaniesfound}</Typography>
            </Grid>
          ) : (
            companies.map((company, index) => (
              <Grid
                item
                key={index}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                sx={{
                  cursor: "pointer",
                }}
              >
                <Card
                  sx={{
                    borderRadius: "17px",
                    boxShadow: "0px 4px 39px rgba(0, 0, 0, 0.1)",
                    cursor: "pointer",
                    padding: "12px",
                    mt: 2
                  }}
                  onClick={() => viewCompanyDetails(company?.id)}
                >
                  <CardContent>
                    <Avatar
                      src={
                        `${company?.logo}`
                      }
                      sx={{
                        marginTop: "-50px",
                        position: "absolute",
                        width: "50px",
                        height: "50px",
                      }}
                    />
                    {company?.companyName && (
                      <Typography
                        sx={{
                          paddingLeft: 1,
                          paddingTop: 1,
                          color: "#000000",
                          height: "60px",
                          fontWeight: 500,
                          fontSize: {
                            xs: "18px",
                            lg: "20px",
                          },
                          lineHeight: "34px",
                          textTransform: "capitalize",
                        }}
                      >
                        {company?.companyName.length > 25
                          ? company?.companyName + "..."
                          : company?.companyName}
                      </Typography>
                    )}
                    <Typography
                      sx={{
                        paddingLeft: 1,
                        paddingTop: 1,
                        color: "rgba(113, 109, 109, 0.85)",
                        paddingBottom: 3,
                        overflowY: "hidden",
                        height: "80px",
                        lineHeight: "23px",
                        fontSize: "15px",
                      }}
                    >
                      {company?.companyDescription
                        ? company?.companyDescription.length > 100
                          ? company?.companyDescription.slice(0, 100) + "..."
                          : company?.companyDescription
                        : "No Description"}
                    </Typography>
                  </CardContent>
                  <CardActions
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderTop: "1px solid rgba(0, 0, 0, 0.07)",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: "50px",
                      }}
                    >
                      <PlaceOutlinedIcon sx={{ color: "2B4947" }} />
                      <Typography
                        sx={{
                          paddingTop: 1,
                          color: "#2B4947",
                          fontSize: {
                            xs: "11px",
                            lg: "13px",
                          },
                          fontWeight: 500,
                          fontStyle: "normal",
                          overflowY: "hidden",
                        }}
                      >
                        {company?.state}
                      </Typography>
                    </div>
                    <Typography
                      sx={{
                        paddingTop: 1,
                        paddingLeft: 5,
                        color: "#BA8C04",

                        fontSize: {
                          xs: "11px",
                          sm: "12px",
                          lg: "13px",
                        },
                      }}
                    >
                      {company?.count === 0 || null
                        ? Labels?.Jobsnotavailable
                        : company?.count === 1
                          ? company?.count + Labels?.jobavailable
                          : company?.count + Labels?.JobsAvailable}
                    </Typography>
                  </CardActions>
                </Card>
              </Grid>
            ))
          )}
        </Grid>
      </Container>
    </div>
  );
}
