import * as React from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Dialog,
  Modal,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { axiosAPI } from "../../Axios/Axios";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Skills from "../../CommonCode/Skills";
import { errorNotify, succesNotify } from "../../CommonCode/Commonfunc";

import { useSelector } from "react-redux";
import { useState } from "react";
import SkillsNew from "../../CommonCode/SkillsNew";
import SkillsEdit from "../../CommonCode/SkillsEditJob";
import ContractTypeNew from "../../CommonCode/ContractTypeNew";


const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#1C614E"),
  backgroundColor: "#1C614E",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#1C614E",
  },
  "&:active": {
    backgroundColor: "#1C614E",
  },
  "&:disabled": { color:"white",
    backgroundColor: "grey",
  }
}));


const ContractTypeAddModal = ({ open, handleClose, skills, setskills,contractname,isButtonDisable=false,setButtonDisable={} }) => {

  const postData = {
    contractType: skills,
    language: localStorage.getItem("language"),
  }; 

  const submitKeyskills = async () => {
    if (skills.length === 0) {
      return errorNotify(Labels.skillUpdation);
    } else {
      setButtonDisable(true);
      const result = await axiosAPI.post("/updateUserProfile", postData);
      const { success, message } = result.data;
      if (success === true) {
        succesNotify(message);
        handleClose();
        setButtonDisable(true);
      } else {
        errorNotify(message);
        setButtonDisable(false);
      }
    }
  };

  const Labels = useSelector((state) => state.allLabels.labels);
  const isLargeScreen = useMediaQuery("(min-width:600px)");
  
  const textStyle = {
    textTransform: "capitalize",
    fontFamily: "Poppins",
    borderRadius: "10px",
  };
  
  return (
    <div>
      <Box>
        <ContractTypeNew value={skills} setValue={setskills} contractname={contractname}/>
      </Box>
      <Stack direction="row" justifyContent="end" sx={{ p: 1.5 }}>
        <ColorButton
         variant="contained"
         sx={{ ...textStyle}}
         onClick={submitKeyskills}
         disabled={isButtonDisable}
        >
          {Labels?.Update}
        </ColorButton>
      </Stack>
    </div>
  );
};

export default ContractTypeAddModal;
