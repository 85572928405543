import React, { Fragment, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { axiosAPI } from "../Axios/Axios";
import {profanityfn}  from "../Profanity/Profanity";
import { useSelector } from "react-redux";
export default function LanguageLevelJobSeeker({ value, setValue, label, style }) {
  const [languageLevel, setLanguageLevel] = useState([]);
  useEffect(() => {
    const getLanguageLevel = async () => {
      const result = await axiosAPI.get("/getLanguageLevel");
      const { success, data } = result.data;
      if (success === true) {
        setLanguageLevel(data);
      } else {
        setLanguageLevel([]);
      }
    };
    getLanguageLevel();
  }, []);
  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)

  const handleChange = (event) => {
    setValue(profanityfn(ProfanityList,event.target.value));
  };
  return (
    <FormControl sx={{ width: "100%" }}>
      <Select
        value={value}
        onChange={handleChange}
        placeholder={label}
        displayEmpty
        variant="outlined"
        sx={{ padding: "10px 12px", fontFamily: "Poppins", ...style }}
      >
        <MenuItem value="" disabled>
          <span style={{ color: "#bcbcbc" }}>{label}</span>
        </MenuItem>
        {languageLevel &&
          languageLevel.map((val, index) => {
            return (
              <MenuItem key={index} value={val.id}>
                {val.name}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
}
